<template>
  <Scene>
    <Background skyColor="0.949019608 0.941176471 0.901960784"></Background>
    <Viewpoint
      fieldOfView="0.25"
      position="5.67865035461008233e+02 3.87799079517475775e+02 1.25946228169990462e+02"
      description="Default View"
      orientation="9.37145543520705893e-01 2.11497642045378242e-01 2.77537344637664329e-01 -4.91978582149180887e+00"
      centerOfRotation="5.09962586649147852e+02 5.09687954966512621e+02 8.85472278778916433e+01"
    ></Viewpoint>
    <NavigationInfo
      type='"EXAMINE" "FLY" "ANY"'
      speed="4.00000000000000000e+00"
      headlight="true"
    ></NavigationInfo>
    <DirectionalLight
      ambientIntensity="1.00000000000000000e+00"
      intensity="0.00000000000000000e+00"
      color="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
    ></DirectionalLight>
    <Transform
      DEF="ROOT"
      translation="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
    >
      <Transform
        translation="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000006e-01"
              diffuseColor="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00"
              specularColor="0.00000000000000000e+00 0.00000000000000000e+00 5.00000000000000028e-02"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedLineSet
            colorPerVertex="false"
            coordIndex="
            0 1 -1 
            2 3 -1 
            4 5 -1 
            6 7 -1 
            8 9 -1 
            10 11 -1 
            12 13 -1 
            14 15 -1 
            16 17 -1 
            18 19 -1 
            20 21 -1 
            22 23 -1 
            24 25 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0000"
              point="
              5.10000000000000000e+02 5.10000000000000000e+02 9.95000000000000000e+01,
              5.10000000000000000e+02 5.10000000000000000e+02 7.94000015258789063e+01,
              5.00000000000000000e+02 5.01000000000000000e+02 9.83000030517578125e+01,
              5.00000000000000000e+02 5.01000000000000000e+02 7.70999984741210938e+01,
              5.20000000000000000e+02 5.18000000000000000e+02 1.00000000000000000e+02,
              5.20000000000000000e+02 5.18000000000000000e+02 7.70999984741210938e+01,
              5.01000000000000000e+02 5.20000000000000000e+02 1.00000000000000000e+02,
              5.01000000000000000e+02 5.20000000000000000e+02 7.74000015258789063e+01,
              5.19000000000000000e+02 5.02000000000000000e+02 9.96999969482421875e+01,
              5.19000000000000000e+02 5.02000000000000000e+02 7.70999984741210938e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 1.00000000000000000e+02,
              5.04000000000000000e+02 5.08000000000000000e+02 7.70999984741210938e+01,
              5.11000000000000000e+02 5.04000000000000000e+02 1.00000000000000000e+02,
              5.11000000000000000e+02 5.04000000000000000e+02 7.70999984741210938e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 1.00000000000000000e+02,
              5.15000000000000000e+02 5.12000000000000000e+02 7.90000000000000000e+01,
              5.07000000000000000e+02 5.17000000000000000e+02 9.95999984741210938e+01,
              5.07000000000000000e+02 5.17000000000000000e+02 7.76999969482421875e+01,
              5.17000000000000000e+02 5.09000000000000000e+02 9.94000015258789063e+01,
              5.17000000000000000e+02 5.09000000000000000e+02 8.01999969482421875e+01,
              5.01000000000000000e+02 5.11000000000000000e+02 9.95000000000000000e+01,
              5.01000000000000000e+02 5.11000000000000000e+02 8.03000030517578125e+01,
              5.13000000000000000e+02 5.21000000000000000e+02 9.95000000000000000e+01,
              5.13000000000000000e+02 5.21000000000000000e+02 8.05999984741210938e+01,
              5.10000000000000000e+02 5.00000000000000000e+02 9.83000030517578125e+01,
              5.10000000000000000e+02 5.00000000000000000e+02 8.58000030517578125e+01,
              "
            />
          </IndexedLineSet>
        </Shape>
      </Transform>
      <Transform
        translation="5.18997228640831054e+02 4.98803911718817517e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            20 19 21 -1 
            19 18 21 -1 
            18 17 21 -1 
            17 16 21 -1 
            21 16 22 -1 
            16 15 22 -1 
            10 9 11 -1 
            11 9 12 -1 
            12 9 13 -1 
            13 9 14 -1 
            9 8 14 -1 
            14 8 15 -1 
            1 0 39 -1 
            39 38 1 -1 
            38 37 1 -1 
            37 36 1 -1 
            1 36 2 -1 
            36 35 2 -1 
            30 29 31 -1 
            31 29 32 -1 
            32 29 33 -1 
            33 29 34 -1 
            29 28 34 -1 
            34 28 35 -1 
            3 2 4 -1 
            27 26 28 -1 
            7 6 8 -1 
            23 22 24 -1 
            2 35 4 -1 
            4 35 5 -1 
            28 26 35 -1 
            26 25 35 -1 
            35 25 5 -1 
            5 25 6 -1 
            8 6 15 -1 
            6 25 15 -1 
            25 24 15 -1 
            24 22 15 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0001"
              point="
              -4.31812400505874827e-01 -4.15703875336581596e-03 0.00000000000000000e+00,
              -4.17262764869094416e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.01154239699801851e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.86084974218850796e-01 -4.15703875336581596e-03 0.00000000000000000e+00,
              -3.74653117647094747e-01 -1.19514864159266931e-02 0.00000000000000000e+00,
              -2.24480092681754395e-01 -2.08891197356632585e-01 0.00000000000000000e+00,
              -7.43070677164140436e-02 -1.19514864159266931e-02 0.00000000000000000e+00,
              -6.33948409888287490e-02 -4.15703875336581596e-03 0.00000000000000000e+00,
              -4.83255755078776938e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.27366801827557730e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.76674147018047178e-02 -3.63740890919506121e-03 0.00000000000000000e+00,
              -6.75518797421942319e-03 -1.45496356367803559e-02 0.00000000000000000e+00,
              -5.19629844170643729e-04 -2.80600115852193133e-02 0.00000000000000000e+00,
              -5.19629844170643729e-04 -4.26096472219996691e-02 0.00000000000000000e+00,
              -6.23555813004866843e-03 -5.61200231704386265e-02 0.00000000000000000e+00,
              -1.67840439667145069e-01 -2.81639375540534476e-01 0.00000000000000000e+00,
              -5.71592828587796919e-03 -5.07678357754801080e-01 0.00000000000000000e+00,
              0.00000000000000000e+00 -5.21708363547410792e-01 0.00000000000000000e+00,
              -5.19629844170643729e-04 -5.35738369340020393e-01 0.00000000000000000e+00,
              -6.75518797421942319e-03 -5.49248745288459350e-01 0.00000000000000000e+00,
              -1.76674147018047178e-02 -5.59641342171873890e-01 0.00000000000000000e+00,
              -3.32563100269265277e-02 -5.63798380925239706e-01 0.00000000000000000e+00,
              -4.88452053520483931e-02 -5.63798380925239706e-01 0.00000000000000000e+00,
              -6.28752111446580497e-02 -5.59641342171873890e-01 0.00000000000000000e+00,
              -7.37874378722432889e-02 -5.51846894509313013e-01 0.00000000000000000e+00,
              -2.24480092681754395e-01 -3.54387553724436422e-01 0.00000000000000000e+00,
              -3.74653117647094747e-01 -5.51846894509313013e-01 0.00000000000000000e+00,
              -3.86084974218850796e-01 -5.60160972016044645e-01 0.00000000000000000e+00,
              -4.00634609855631152e-01 -5.63798380925239706e-01 0.00000000000000000e+00,
              -4.16743135024923717e-01 -5.63798380925239706e-01 0.00000000000000000e+00,
              -4.31292770661704072e-01 -5.59641342171873890e-01 0.00000000000000000e+00,
              -4.42724627233460122e-01 -5.49248745288459350e-01 0.00000000000000000e+00,
              -4.48960185363508846e-01 -5.35738369340020393e-01 0.00000000000000000e+00,
              -4.48960185363508846e-01 -5.21188733703240037e-01 0.00000000000000000e+00,
              -4.42724627233460122e-01 -5.07158727910630436e-01 0.00000000000000000e+00,
              -2.80600115852193022e-01 -2.81639375540534476e-01 0.00000000000000000e+00,
              -4.42724627233460122e-01 -5.61200231704386265e-02 0.00000000000000000e+00,
              -4.48960185363508846e-01 -4.26096472219996691e-02 0.00000000000000000e+00,
              -4.48960185363508846e-01 -2.80600115852193133e-02 0.00000000000000000e+00,
              -4.43244257077630821e-01 -1.45496356367803559e-02 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.98004888433184760e+02 5.19947228640831099e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            29 28 30 -1 
            28 27 30 -1 
            27 26 30 -1 
            26 25 30 -1 
            25 24 30 -1 
            24 23 30 -1 
            23 22 30 -1 
            22 21 30 -1 
            33 32 21 -1 
            32 31 21 -1 
            31 30 21 -1 
            21 20 33 -1 
            33 20 34 -1 
            1 0 2 -1 
            2 0 3 -1 
            3 0 4 -1 
            4 0 5 -1 
            5 0 6 -1 
            6 0 7 -1 
            0 74 7 -1 
            48 47 49 -1 
            49 47 50 -1 
            50 47 51 -1 
            47 46 51 -1 
            51 46 52 -1 
            46 45 52 -1 
            45 44 52 -1 
            52 44 53 -1 
            44 43 53 -1 
            43 42 53 -1 
            42 41 53 -1 
            41 40 53 -1 
            53 40 54 -1 
            40 39 54 -1 
            54 39 55 -1 
            39 38 55 -1 
            38 37 55 -1 
            37 36 55 -1 
            36 35 55 -1 
            73 72 74 -1 
            72 71 74 -1 
            74 71 7 -1 
            71 70 7 -1 
            69 68 70 -1 
            68 67 70 -1 
            67 66 70 -1 
            66 65 70 -1 
            65 64 70 -1 
            55 35 56 -1 
            56 35 57 -1 
            35 34 57 -1 
            57 34 58 -1 
            58 34 59 -1 
            59 34 60 -1 
            60 34 61 -1 
            61 34 62 -1 
            62 34 63 -1 
            63 34 64 -1 
            34 20 64 -1 
            20 19 64 -1 
            19 18 64 -1 
            64 18 70 -1 
            18 17 70 -1 
            17 16 70 -1 
            16 15 70 -1 
            15 14 70 -1 
            14 13 70 -1 
            13 12 70 -1 
            12 11 70 -1 
            11 10 70 -1 
            10 9 70 -1 
            70 9 7 -1 
            9 8 7 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0002"
              point="
              -4.47920925675167392e-01 3.60103482010314446e-01 0.00000000000000000e+00,
              -4.45322776454313729e-01 3.74653117647094802e-01 0.00000000000000000e+00,
              -4.35969439259240643e-01 3.87643863751363005e-01 0.00000000000000000e+00,
              -4.22978693154972440e-01 3.95957941258094637e-01 0.00000000000000000e+00,
              -4.07389797829850631e-01 3.99075720323119054e-01 0.00000000000000000e+00,
              -3.91281272660558066e-01 3.95957941258094637e-01 0.00000000000000000e+00,
              -3.77770896712119164e-01 3.87643863751363005e-01 0.00000000000000000e+00,
              -3.68937189361216777e-01 3.75172747491265446e-01 0.00000000000000000e+00,
              -3.65819410296192360e-01 3.60623111854485201e-01 0.00000000000000000e+00,
              -3.65819410296192360e-01 3.11777906502436197e-03 0.00000000000000000e+00,
              -3.63221261075338753e-01 -9.87296703924384067e-03 0.00000000000000000e+00,
              -3.54907183568607065e-01 -2.75403817410485585e-02 0.00000000000000000e+00,
              -3.43475326996851127e-01 -4.46881665986825910e-02 0.00000000000000000e+00,
              -3.31523840580924323e-01 -5.66396530146093397e-02 0.00000000000000000e+00,
              -3.12297536346607396e-01 -6.65126200538531526e-02 0.00000000000000000e+00,
              -2.82678635228875930e-01 -7.63855870930969932e-02 0.00000000000000000e+00,
              -2.50461584890290800e-01 -8.36604049114871851e-02 0.00000000000000000e+00,
              -2.23960462837583696e-01 -8.57389242881700930e-02 0.00000000000000000e+00,
              -1.89664893122315659e-01 -8.36604049114871851e-02 0.00000000000000000e+00,
              -1.49653395121169652e-01 -7.74248467814384472e-02 0.00000000000000000e+00,
              -1.11720416496706443e-01 -6.85911394305360744e-02 0.00000000000000000e+00,
              -8.21015153789749763e-02 -5.81985425471215206e-02 0.00000000000000000e+00,
              -8.21015153789749763e-02 3.60623111854485201e-01 0.00000000000000000e+00,
              -7.95033661581213136e-02 3.75172747491265446e-01 0.00000000000000000e+00,
              -7.01500289630482277e-02 3.87643863751363005e-01 0.00000000000000000e+00,
              -5.71592828587800250e-02 3.95957941258094637e-01 0.00000000000000000e+00,
              -4.15703875336582152e-02 3.99075720323119054e-01 0.00000000000000000e+00,
              -2.54618623643656505e-02 3.95957941258094637e-01 0.00000000000000000e+00,
              -1.19514864159266931e-02 3.87643863751363005e-01 0.00000000000000000e+00,
              -3.11777906502430646e-03 3.75172747491265446e-01 0.00000000000000000e+00,
              0.00000000000000000e+00 3.60623111854485201e-01 0.00000000000000000e+00,
              0.00000000000000000e+00 -7.89837363139506421e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 -8.21015153789750041e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 -2.27597871746778813e-01 0.00000000000000000e+00,
              -7.79444766256087718e-03 -2.74884187566315052e-01 0.00000000000000000e+00,
              -3.11777906502436197e-02 -3.16974204944143967e-01 0.00000000000000000e+00,
              -6.75518797421945649e-02 -3.52309034347753514e-01 0.00000000000000000e+00,
              -1.13798935873389351e-01 -3.77770896712119164e-01 0.00000000000000000e+00,
              -1.67320809822974370e-01 -3.93359792037240974e-01 0.00000000000000000e+00,
              -2.25519352370095905e-01 -3.99075720323118999e-01 0.00000000000000000e+00,
              -2.83198265073046684e-01 -3.95438311413923882e-01 0.00000000000000000e+00,
              -3.40357547931826709e-01 -3.84526084686338643e-01 0.00000000000000000e+00,
              -3.91800902504728765e-01 -3.67897929672875323e-01 0.00000000000000000e+00,
              -4.32851660194216281e-01 -3.47112735906046188e-01 0.00000000000000000e+00,
              -4.44283516765972275e-01 -3.35680879334290194e-01 0.00000000000000000e+00,
              -4.49999445051850300e-01 -3.21131243697509783e-01 0.00000000000000000e+00,
              -4.49999445051850300e-01 -3.05542348372388028e-01 0.00000000000000000e+00,
              -4.43763886921801576e-01 -2.90992712735607617e-01 0.00000000000000000e+00,
              -4.32851660194216281e-01 -2.79560856163851623e-01 0.00000000000000000e+00,
              -4.17782394713265171e-01 -2.73844927877973598e-01 0.00000000000000000e+00,
              -4.02193499388143305e-01 -2.73844927877973598e-01 0.00000000000000000e+00,
              -3.87124233907192195e-01 -2.80080486008022322e-01 0.00000000000000000e+00,
              -3.58544592477802238e-01 -2.93590861956461224e-01 0.00000000000000000e+00,
              -3.16454575099973212e-01 -3.06061978216558728e-01 0.00000000000000000e+00,
              -2.69168259280437028e-01 -3.15415315411631814e-01 0.00000000000000000e+00,
              -2.25519352370095905e-01 -3.18533094476656176e-01 0.00000000000000000e+00,
              -1.93821931875681475e-01 -3.16454575099973268e-01 0.00000000000000000e+00,
              -1.64722660602120707e-01 -3.10738646814095243e-01 0.00000000000000000e+00,
              -1.39260798237755057e-01 -3.01385309619022157e-01 0.00000000000000000e+00,
              -1.18995234315096676e-01 -2.89433823203095408e-01 0.00000000000000000e+00,
              -1.03406338989974811e-01 -2.75923447254656506e-01 0.00000000000000000e+00,
              -9.19744824182188170e-02 -2.60854181773705396e-01 0.00000000000000000e+00,
              -8.46996645998286390e-02 -2.45265286448583558e-01 0.00000000000000000e+00,
              -8.21015153789749763e-02 -2.30196020967632448e-01 0.00000000000000000e+00,
              -8.21015153789749763e-02 -1.43937466835291628e-01 0.00000000000000000e+00,
              -1.50692654809511051e-01 -1.61085251692925646e-01 0.00000000000000000e+00,
              -2.24480092681754451e-01 -1.66281550134632916e-01 0.00000000000000000e+00,
              -2.67609369747924819e-01 -1.63163771069608554e-01 0.00000000000000000e+00,
              -3.11258276658265998e-01 -1.53810433874535440e-01 0.00000000000000000e+00,
              -3.51269774659412004e-01 -1.39260798237755057e-01 0.00000000000000000e+00,
              -3.82447565309655679e-01 -1.20554123847608857e-01 0.00000000000000000e+00,
              -4.08948687362362784e-01 -9.66511510157553738e-02 0.00000000000000000e+00,
              -4.29733881129191919e-01 -6.59929902096824533e-02 0.00000000000000000e+00,
              -4.43763886921801576e-01 -3.27366801827558562e-02 0.00000000000000000e+00,
              -4.47920925675167392e-01 5.19629844170726996e-04 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.98940945948910723e+02 4.98917945948910756e+02 9.88522285645371426e+01"
        rotation="8.55167184663299773e-01 3.66530412295518748e-01 3.66530412295518804e-01 -1.72662016832750753e+00"
        scale="1.00000000000000022e+00 1.00000000000000022e+00 1.00000000000000022e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            20 19 21 -1 
            19 18 21 -1 
            18 17 21 -1 
            21 17 22 -1 
            22 17 23 -1 
            17 16 23 -1 
            3 2 4 -1 
            2 1 4 -1 
            1 0 4 -1 
            4 0 5 -1 
            5 0 6 -1 
            0 33 6 -1 
            30 29 31 -1 
            29 28 31 -1 
            28 27 31 -1 
            27 26 31 -1 
            31 26 32 -1 
            26 25 32 -1 
            25 24 32 -1 
            13 12 14 -1 
            12 11 14 -1 
            11 10 14 -1 
            14 10 15 -1 
            10 9 15 -1 
            15 9 16 -1 
            9 8 16 -1 
            8 7 16 -1 
            16 7 23 -1 
            23 7 24 -1 
            7 6 24 -1 
            6 33 24 -1 
            33 32 24 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0003"
              point="
              -4.23498322999143140e-01 -2.82678635228875985e-01 0.00000000000000000e+00,
              -4.40126478012606459e-01 -2.80080486008022322e-01 0.00000000000000000e+00,
              -4.53636853961045361e-01 -2.71766408501290690e-01 0.00000000000000000e+00,
              -4.62990191156118447e-01 -2.59295292241193243e-01 0.00000000000000000e+00,
              -4.65588340376972110e-01 -2.44226026760242132e-01 0.00000000000000000e+00,
              -4.64029450844459901e-01 -2.33313800032656837e-01 0.00000000000000000e+00,
              -4.58833152402752631e-01 -2.22921203149242270e-01 0.00000000000000000e+00,
              -1.21593383535950283e-01 2.02136009382413107e-01 0.00000000000000000e+00,
              -4.23498322999143140e-01 2.02136009382413107e-01 0.00000000000000000e+00,
              -4.39606848168435704e-01 2.04734158603266769e-01 0.00000000000000000e+00,
              -4.53117224116874662e-01 2.14087495798339800e-01 0.00000000000000000e+00,
              -4.62470561311947748e-01 2.27078241902608002e-01 0.00000000000000000e+00,
              -4.65588340376972110e-01 2.42147507383559224e-01 0.00000000000000000e+00,
              -4.62470561311947748e-01 2.57216772864510224e-01 0.00000000000000000e+00,
              -4.53117224116874662e-01 2.70727148812949181e-01 0.00000000000000000e+00,
              -4.39606848168435704e-01 2.79560856163851568e-01 0.00000000000000000e+00,
              -4.23498322999143140e-01 2.82678635228875985e-01 0.00000000000000000e+00,
              -4.26096472219996691e-02 2.82678635228875985e-01 0.00000000000000000e+00,
              -2.65011220527071045e-02 2.79560856163851568e-01 0.00000000000000000e+00,
              -1.24711162600973924e-02 2.71246778657119936e-01 0.00000000000000000e+00,
              -3.11777906502430646e-03 2.58775662397022377e-01 0.00000000000000000e+00,
              5.55111512312578270e-17 2.44226026760242132e-01 0.00000000000000000e+00,
              -2.07851937668290798e-03 2.32794170188486083e-01 0.00000000000000000e+00,
              -6.75518797421936767e-03 2.22921203149242186e-01 0.00000000000000000e+00,
              -3.43994956841021771e-01 -2.02136009382413162e-01 0.00000000000000000e+00,
              -4.26096472219996691e-02 -2.02136009382413162e-01 0.00000000000000000e+00,
              -2.65011220527071045e-02 -2.05253788447437524e-01 0.00000000000000000e+00,
              -1.24711162600973924e-02 -2.14087495798339911e-01 0.00000000000000000e+00,
              -3.11777906502430646e-03 -2.27597871746778840e-01 0.00000000000000000e+00,
              5.55111512312578270e-17 -2.42667137227729923e-01 0.00000000000000000e+00,
              -3.63740890919506121e-03 -2.57736402708681034e-01 0.00000000000000000e+00,
              -1.29907461042681471e-02 -2.70727148812949237e-01 0.00000000000000000e+00,
              -2.70207518968777483e-02 -2.80080486008022322e-01 0.00000000000000000e+00,
              -4.26096472219996691e-02 -2.82678635228875985e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99997228640831111e+02 4.99997228640831111e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="1.00000000000000019e-02 1.00000000000000019e-02 1.00000000000000019e-02"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedLineSet
            colorPerVertex="false"
            coordIndex="
            0 1 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0004"
              point="
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              2.00000000000000000e+01 0.00000000000000000e+00 0.00000000000000000e+00,
              "
            />
          </IndexedLineSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99997228640831111e+02 4.99997228640831111e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="1.00000000000000019e-02 1.00000000000000019e-02 1.00000000000000019e-02"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedLineSet
            colorPerVertex="false"
            coordIndex="
            0 1 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0005"
              point="
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              0.00000000000000000e+00 2.10000000000000000e+01 0.00000000000000000e+00,
              "
            />
          </IndexedLineSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99997228640831111e+02 4.99997228640831111e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="1.00000000000000019e-02 1.00000000000000019e-02 1.00000000000000019e-02"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedLineSet
            colorPerVertex="false"
            coordIndex="
            0 1 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0006"
              point="
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              0.00000000000000000e+00 0.00000000000000000e+00 2.29000015258789063e+01,
              "
            />
          </IndexedLineSet>
        </Shape>
      </Transform>
      <Transform
        translation="5.04997228640831111e+02 4.99588575103220251e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            15 14 16 -1 
            16 14 17 -1 
            17 14 18 -1 
            12 11 13 -1 
            11 10 13 -1 
            10 9 13 -1 
            9 8 13 -1 
            24 23 25 -1 
            23 22 25 -1 
            22 21 25 -1 
            25 21 26 -1 
            21 20 26 -1 
            26 20 27 -1 
            20 19 27 -1 
            27 19 28 -1 
            19 18 28 -1 
            18 14 28 -1 
            14 13 28 -1 
            28 13 29 -1 
            13 8 29 -1 
            29 8 30 -1 
            30 8 31 -1 
            8 7 31 -1 
            31 7 32 -1 
            7 6 32 -1 
            32 6 33 -1 
            6 5 33 -1 
            33 5 34 -1 
            34 5 35 -1 
            35 5 36 -1 
            36 5 37 -1 
            55 54 56 -1 
            56 54 57 -1 
            57 54 58 -1 
            58 54 59 -1 
            54 53 59 -1 
            59 53 60 -1 
            60 53 61 -1 
            61 53 62 -1 
            62 53 63 -1 
            63 53 64 -1 
            64 53 65 -1 
            53 52 65 -1 
            52 51 65 -1 
            51 50 65 -1 
            50 49 65 -1 
            49 48 65 -1 
            48 47 65 -1 
            39 38 40 -1 
            38 37 40 -1 
            40 37 41 -1 
            41 37 42 -1 
            42 37 43 -1 
            43 37 44 -1 
            44 37 45 -1 
            46 45 47 -1 
            5 4 37 -1 
            4 3 37 -1 
            3 2 37 -1 
            2 1 37 -1 
            1 0 37 -1 
            0 79 37 -1 
            66 65 67 -1 
            37 79 45 -1 
            79 78 45 -1 
            78 77 45 -1 
            45 77 47 -1 
            77 76 47 -1 
            76 75 47 -1 
            75 74 47 -1 
            74 73 47 -1 
            73 72 47 -1 
            72 71 47 -1 
            71 70 47 -1 
            67 65 68 -1 
            65 47 68 -1 
            68 47 69 -1 
            70 69 47 -1 
            127 80 128 -1 
            80 109 128 -1 
            95 111 96 -1 
            111 110 96 -1 
            128 109 129 -1 
            129 109 130 -1 
            109 108 130 -1 
            130 108 131 -1 
            131 108 132 -1 
            108 107 132 -1 
            132 107 133 -1 
            107 106 133 -1 
            133 106 134 -1 
            106 105 134 -1 
            134 105 135 -1 
            105 104 135 -1 
            135 104 136 -1 
            136 104 137 -1 
            104 103 137 -1 
            137 103 138 -1 
            103 102 138 -1 
            138 102 139 -1 
            102 101 139 -1 
            139 101 140 -1 
            101 100 140 -1 
            140 100 141 -1 
            96 110 97 -1 
            110 141 97 -1 
            100 99 141 -1 
            99 98 141 -1 
            141 98 97 -1 
            111 95 112 -1 
            127 126 80 -1 
            81 80 126 -1 
            126 125 81 -1 
            81 125 82 -1 
            125 124 82 -1 
            124 123 82 -1 
            95 94 112 -1 
            112 94 113 -1 
            82 123 83 -1 
            123 122 83 -1 
            83 122 84 -1 
            122 121 84 -1 
            84 121 85 -1 
            121 120 85 -1 
            85 120 86 -1 
            120 119 86 -1 
            86 119 87 -1 
            119 118 87 -1 
            87 118 88 -1 
            118 117 88 -1 
            88 117 89 -1 
            117 116 89 -1 
            89 116 90 -1 
            116 115 90 -1 
            90 115 91 -1 
            115 114 91 -1 
            91 114 92 -1 
            114 113 92 -1 
            92 113 93 -1 
            113 94 93 -1 
            157 156 158 -1 
            158 156 159 -1 
            159 156 160 -1 
            154 153 155 -1 
            153 152 155 -1 
            152 151 155 -1 
            151 150 155 -1 
            166 165 167 -1 
            165 164 167 -1 
            164 163 167 -1 
            167 163 168 -1 
            163 162 168 -1 
            168 162 169 -1 
            162 161 169 -1 
            169 161 170 -1 
            161 160 170 -1 
            160 156 170 -1 
            156 155 170 -1 
            170 155 171 -1 
            155 150 171 -1 
            171 150 172 -1 
            172 150 173 -1 
            150 149 173 -1 
            173 149 174 -1 
            149 148 174 -1 
            174 148 175 -1 
            148 147 175 -1 
            175 147 176 -1 
            176 147 177 -1 
            177 147 178 -1 
            178 147 179 -1 
            197 196 198 -1 
            198 196 199 -1 
            199 196 200 -1 
            200 196 201 -1 
            196 195 201 -1 
            201 195 202 -1 
            202 195 203 -1 
            203 195 204 -1 
            204 195 205 -1 
            205 195 206 -1 
            206 195 207 -1 
            195 194 207 -1 
            194 193 207 -1 
            193 192 207 -1 
            192 191 207 -1 
            191 190 207 -1 
            190 189 207 -1 
            181 180 182 -1 
            180 179 182 -1 
            182 179 183 -1 
            183 179 184 -1 
            184 179 185 -1 
            185 179 186 -1 
            186 179 187 -1 
            188 187 189 -1 
            147 146 179 -1 
            146 145 179 -1 
            145 144 179 -1 
            144 143 179 -1 
            143 142 179 -1 
            142 221 179 -1 
            208 207 209 -1 
            179 221 187 -1 
            221 220 187 -1 
            220 219 187 -1 
            187 219 189 -1 
            219 218 189 -1 
            218 217 189 -1 
            217 216 189 -1 
            216 215 189 -1 
            215 214 189 -1 
            214 213 189 -1 
            213 212 189 -1 
            209 207 210 -1 
            207 189 210 -1 
            210 189 211 -1 
            212 211 189 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0007"
              point="
              -3.46863313580844268e-01 -3.80784749808309342e-01 0.00000000000000000e+00,
              -3.49523818382998397e-01 -3.48193565981921305e-01 0.00000000000000000e+00,
              -3.56507643488652937e-01 -3.25246712063341992e-01 0.00000000000000000e+00,
              -3.68147351998077244e-01 -3.08618557049878672e-01 0.00000000000000000e+00,
              -3.82780128409924980e-01 -2.93985780638030991e-01 0.00000000000000000e+00,
              -4.01403662025003882e-01 -2.82013509028337439e-01 0.00000000000000000e+00,
              -4.23685389743044705e-01 -2.72701742220797960e-01 0.00000000000000000e+00,
              -4.48627622263239600e-01 -2.66715606415951212e-01 0.00000000000000000e+00,
              -4.75232670284780889e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              -6.29874511909989443e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              -6.40183968018336658e-01 -2.62724849212719991e-01 0.00000000000000000e+00,
              -6.48498045525068401e-01 -2.57071276508142488e-01 0.00000000000000000e+00,
              -6.54484181329915149e-01 -2.49089762101680101e-01 0.00000000000000000e+00,
              -6.56146996831261431e-01 -2.39445432193871433e-01 0.00000000000000000e+00,
              -6.56146996831261431e-01 -2.49422325201949513e-02 0.00000000000000000e+00,
              -6.54484181329915149e-01 -1.56304657126554725e-02 0.00000000000000000e+00,
              -6.48498045525068401e-01 -7.31638820592384054e-03 0.00000000000000000e+00,
              -6.40183968018336658e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              -6.29874511909989443e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.20590828659572280e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.10946498751763556e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              -3.02299858144762623e-01 -7.64895130619314134e-03 0.00000000000000000e+00,
              -2.96313722339915875e-01 -1.62955919131940186e-02 0.00000000000000000e+00,
              -2.94318343738300237e-01 -2.59399218210027982e-02 0.00000000000000000e+00,
              -2.96313722339915875e-01 -3.59168148290807676e-02 0.00000000000000000e+00,
              -3.02299858144762623e-01 -4.42308923358123440e-02 0.00000000000000000e+00,
              -3.10946498751763556e-01 -4.98844650403898471e-02 0.00000000000000000e+00,
              -3.20590828659572280e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              -6.03602026988717455e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              -6.03602026988717455e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -4.75232670284780889e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -4.38983292355430876e-01 -2.15833452074753518e-01 0.00000000000000000e+00,
              -4.04729293027696502e-01 -2.24480092681754395e-01 0.00000000000000000e+00,
              -3.73468361602385501e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -3.46863313580844268e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -3.25246712063341992e-01 -2.77357625624567727e-01 0.00000000000000000e+00,
              -3.08618557049878672e-01 -3.02964984345301169e-01 0.00000000000000000e+00,
              -2.97976537841262157e-01 -3.31565410968458041e-01 0.00000000000000000e+00,
              -2.94318343738300237e-01 -3.61828653092961194e-01 0.00000000000000000e+00,
              -2.97976537841262157e-01 -3.92091895217464403e-01 0.00000000000000000e+00,
              -3.08618557049878672e-01 -4.20692321840621275e-01 0.00000000000000000e+00,
              -3.25246712063341992e-01 -4.46632243661624018e-01 0.00000000000000000e+00,
              -3.46863313580844268e-01 -4.68248845179126294e-01 0.00000000000000000e+00,
              -3.73468361602385501e-01 -4.85874689493397349e-01 0.00000000000000000e+00,
              -4.04729293027696502e-01 -4.99509776604437239e-01 0.00000000000000000e+00,
              -4.38983292355430876e-01 -5.08156417211438227e-01 0.00000000000000000e+00,
              -4.75232670284780889e-01 -5.10816922013592301e-01 0.00000000000000000e+00,
              -5.22124067422747418e-01 -5.07158727910630325e-01 0.00000000000000000e+00,
              -5.69680590761252326e-01 -4.95519019401206073e-01 0.00000000000000000e+00,
              -6.12248667595718388e-01 -4.78225738187204263e-01 0.00000000000000000e+00,
              -6.43842162121298633e-01 -4.55944010469163441e-01 0.00000000000000000e+00,
              -6.47832919324529799e-01 -4.52285816366201521e-01 0.00000000000000000e+00,
              -6.50825987226953173e-01 -4.48295059162970300e-01 0.00000000000000000e+00,
              -6.53486492029107358e-01 -4.43639175759200644e-01 0.00000000000000000e+00,
              -6.55481870630722940e-01 -4.38650729255161631e-01 0.00000000000000000e+00,
              -6.56812123031800033e-01 -4.32997156550584128e-01 0.00000000000000000e+00,
              -6.55814433730992241e-01 -4.27011020745737324e-01 0.00000000000000000e+00,
              -6.53486492029107358e-01 -4.21357448041159821e-01 0.00000000000000000e+00,
              -6.48830608625337590e-01 -4.15371312236313017e-01 0.00000000000000000e+00,
              -6.43509599021029333e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              -6.37523463216182584e-01 -4.08387487130658422e-01 0.00000000000000000e+00,
              -6.31869890511605026e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              -6.26881444007566069e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              -6.21892997503527112e-01 -4.09052613331196968e-01 0.00000000000000000e+00,
              -6.16571987899218854e-01 -4.11380555033081796e-01 0.00000000000000000e+00,
              -6.11916104495449087e-01 -4.14041059835235981e-01 0.00000000000000000e+00,
              -6.07925347292217921e-01 -4.17034127737659355e-01 0.00000000000000000e+00,
              -6.04599716289525246e-01 -4.20027195640082729e-01 0.00000000000000000e+00,
              -6.01274085286832571e-01 -4.22687700442236858e-01 0.00000000000000000e+00,
              -5.98613580484678498e-01 -4.25015642144121741e-01 0.00000000000000000e+00,
              -5.97283328083601406e-01 -4.26013331444929533e-01 0.00000000000000000e+00,
              -5.74669037265291394e-01 -4.37985603054623085e-01 0.00000000000000000e+00,
              -5.41745290338634056e-01 -4.48960185363508846e-01 0.00000000000000000e+00,
              -5.06161038609822533e-01 -4.56941699769971232e-01 0.00000000000000000e+00,
              -4.75232670284780889e-01 -4.59269641471856116e-01 0.00000000000000000e+00,
              -4.48627622263239600e-01 -4.57274262870240533e-01 0.00000000000000000e+00,
              -4.23685389743044705e-01 -4.51288127065393729e-01 0.00000000000000000e+00,
              -4.01403662025003882e-01 -4.41976360257854251e-01 0.00000000000000000e+00,
              -3.82780128409924980e-01 -4.29671525547891453e-01 0.00000000000000000e+00,
              -3.65486847195923115e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              2.16166015175022208e-02 -5.15472805417362401e-02 0.00000000000000000e+00,
              -4.95519019401206018e-02 -5.45403484441596698e-02 0.00000000000000000e+00,
              -9.34502311756637560e-02 -6.28544259508912462e-02 0.00000000000000000e+00,
              -1.21718094698551327e-01 -7.74872023627389273e-02 0.00000000000000000e+00,
              -1.44997511717399941e-01 -9.87712407799719583e-02 0.00000000000000000e+00,
              -1.63288482232209486e-01 -1.26706541202590339e-01 0.00000000000000000e+00,
              -1.75925880042441585e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              -1.83574831348634726e-01 -2.04858869765867757e-01 0.00000000000000000e+00,
              -1.85902773050519610e-01 -2.55741024107065396e-01 0.00000000000000000e+00,
              -1.83574831348634726e-01 -3.06290615347993844e-01 0.00000000000000000e+00,
              -1.75925880042441585e-01 -3.49191255282729096e-01 0.00000000000000000e+00,
              -1.63288482232209486e-01 -3.84442943911271318e-01 0.00000000000000000e+00,
              -1.44997511717399941e-01 -4.12378244333889588e-01 0.00000000000000000e+00,
              -1.21718094698551327e-01 -4.33662282751122619e-01 0.00000000000000000e+00,
              -9.34502311756637560e-02 -4.47962496062701054e-01 0.00000000000000000e+00,
              -6.01939211487371173e-02 -4.56609136669701987e-01 0.00000000000000000e+00,
              -2.06189122166945404e-02 -4.59269641471856116e-01 0.00000000000000000e+00,
              1.86235336150788466e-02 -4.56609136669701987e-01 0.00000000000000000e+00,
              5.22124067422746752e-02 -4.47962496062701054e-01 0.00000000000000000e+00,
              8.01477071648929451e-02 -4.33662282751122619e-01 0.00000000000000000e+00,
              1.03427124183741670e-01 -4.12378244333889588e-01 0.00000000000000000e+00,
              1.21718094698551216e-01 -3.84442943911271318e-01 0.00000000000000000e+00,
              1.34355492508783425e-01 -3.49191255282729096e-01 0.00000000000000000e+00,
              1.42004443814976455e-01 -3.06290615347993844e-01 0.00000000000000000e+00,
              1.44664948617130640e-01 -2.55741024107065396e-01 0.00000000000000000e+00,
              1.42004443814976455e-01 -2.04858869765867757e-01 0.00000000000000000e+00,
              1.34355492508783425e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              1.21718094698551216e-01 -1.26706541202590339e-01 0.00000000000000000e+00,
              1.03427124183741670e-01 -9.87712407799719583e-02 0.00000000000000000e+00,
              7.24987558586999148e-02 -7.48266975605847984e-02 0.00000000000000000e+00,
              3.42539993277343191e-02 -5.10816922013592301e-01 0.00000000000000000e+00,
              -5.88636687476600251e-02 -5.06493601710091834e-01 0.00000000000000000e+00,
              -1.17394774395050860e-01 -4.93523640799590491e-01 0.00000000000000000e+00,
              -1.55306967825747155e-01 -4.72239602382357460e-01 0.00000000000000000e+00,
              -1.85570209950250420e-01 -4.42974049558662097e-01 0.00000000000000000e+00,
              -2.08849626969099034e-01 -4.06724671629312085e-01 0.00000000000000000e+00,
              -2.25145218882292941e-01 -3.63158905494038287e-01 0.00000000000000000e+00,
              -2.35122111890370911e-01 -3.12609314253109893e-01 0.00000000000000000e+00,
              -2.38447742893063586e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.35122111890370911e-01 -1.98540170860751708e-01 0.00000000000000000e+00,
              -2.25145218882292941e-01 -1.47990579619823315e-01 0.00000000000000000e+00,
              -2.08849626969099034e-01 -1.04424813484549517e-01 0.00000000000000000e+00,
              -1.85570209950250420e-01 -6.78428724549302586e-02 0.00000000000000000e+00,
              -1.55306967825747155e-01 -3.85773196312348965e-02 0.00000000000000000e+00,
              -1.17394774395050860e-01 -1.72932812140018655e-02 0.00000000000000000e+00,
              -7.24987558587000258e-02 -4.32332030350046637e-03 0.00000000000000000e+00,
              -2.06189122166945404e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              2.09514753169637302e-02 -2.99306790242337417e-03 0.00000000000000000e+00,
              5.88636687476599141e-02 -1.13071454091550616e-02 0.00000000000000000e+00,
              9.21199787745866638e-02 -2.49422325201949513e-02 0.00000000000000000e+00,
              1.20387842297474235e-01 -4.38983292355431542e-02 0.00000000000000000e+00,
              1.43667259316322848e-01 -6.78428724549302586e-02 0.00000000000000000e+00,
              1.63288482232209486e-01 -9.64432990780871302e-02 0.00000000000000000e+00,
              1.78253821744326357e-01 -1.30032172205282959e-01 0.00000000000000000e+00,
              1.88895840952942873e-01 -1.67611802535710008e-01 0.00000000000000000e+00,
              1.94881976757789732e-01 -2.09514753169637469e-01 0.00000000000000000e+00,
              1.97209918459674616e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              1.93884287456981830e-01 -3.12609314253109893e-01 0.00000000000000000e+00,
              1.83907394448903916e-01 -3.63158905494038287e-01 0.00000000000000000e+00,
              1.67279239435440541e-01 -4.06724671629312085e-01 0.00000000000000000e+00,
              1.43999822416592038e-01 -4.42974049558662097e-01 0.00000000000000000e+00,
              1.03094561083472369e-01 -4.76562922685857926e-01 0.00000000000000000e+00,
              6.04267153189256057e-01 -3.80784749808309342e-01 0.00000000000000000e+00,
              6.01606648387102094e-01 -3.48193565981921305e-01 0.00000000000000000e+00,
              5.94622823281447443e-01 -3.25246712063341992e-01 0.00000000000000000e+00,
              5.82983114772023026e-01 -3.08618557049878672e-01 0.00000000000000000e+00,
              5.68350338360175344e-01 -2.93985780638030991e-01 0.00000000000000000e+00,
              5.49726804745096498e-01 -2.82013509028337439e-01 0.00000000000000000e+00,
              5.27445077027055786e-01 -2.72701742220797960e-01 0.00000000000000000e+00,
              5.02502844506860891e-01 -2.66715606415951212e-01 0.00000000000000000e+00,
              4.75897796485319491e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              3.21255954860111048e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              3.10946498751763833e-01 -2.62724849212719991e-01 0.00000000000000000e+00,
              3.02632421245031979e-01 -2.57071276508142488e-01 0.00000000000000000e+00,
              2.96646285440185342e-01 -2.49089762101680101e-01 0.00000000000000000e+00,
              2.94983469938838949e-01 -2.39445432193871433e-01 0.00000000000000000e+00,
              2.94983469938838949e-01 -2.49422325201949513e-02 0.00000000000000000e+00,
              2.96646285440185342e-01 -1.56304657126554725e-02 0.00000000000000000e+00,
              3.02632421245031979e-01 -7.31638820592384054e-03 0.00000000000000000e+00,
              3.10946498751763833e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              3.21255954860111048e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              6.30539638110528156e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              6.40183968018336991e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              6.48830608625337701e-01 -7.64895130619314134e-03 0.00000000000000000e+00,
              6.54816744430184450e-01 -1.62955919131940186e-02 0.00000000000000000e+00,
              6.56812123031800033e-01 -2.59399218210027982e-02 0.00000000000000000e+00,
              6.54816744430184450e-01 -3.59168148290807676e-02 0.00000000000000000e+00,
              6.48830608625337701e-01 -4.42308923358123440e-02 0.00000000000000000e+00,
              6.40183968018336991e-01 -4.98844650403898471e-02 0.00000000000000000e+00,
              6.30539638110528156e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              3.47528439781382925e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              3.47528439781382925e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              4.75897796485319491e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              5.12147174414669504e-01 -2.15833452074753518e-01 0.00000000000000000e+00,
              5.46401173742403934e-01 -2.24480092681754395e-01 0.00000000000000000e+00,
              5.77662105167714879e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              6.04267153189256057e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              6.25883754706758388e-01 -2.77357625624567727e-01 0.00000000000000000e+00,
              6.42511909720221652e-01 -3.02964984345301169e-01 0.00000000000000000e+00,
              6.53153928928838168e-01 -3.31565410968458041e-01 0.00000000000000000e+00,
              6.56812123031800033e-01 -3.61828653092961194e-01 0.00000000000000000e+00,
              6.53153928928838168e-01 -3.92091895217464403e-01 0.00000000000000000e+00,
              6.42511909720221652e-01 -4.20692321840621275e-01 0.00000000000000000e+00,
              6.25883754706758388e-01 -4.46632243661624018e-01 0.00000000000000000e+00,
              6.04267153189256057e-01 -4.68248845179126294e-01 0.00000000000000000e+00,
              5.77662105167714879e-01 -4.85874689493397349e-01 0.00000000000000000e+00,
              5.46401173742403934e-01 -4.99509776604437239e-01 0.00000000000000000e+00,
              5.12147174414669504e-01 -5.08156417211438227e-01 0.00000000000000000e+00,
              4.75897796485319491e-01 -5.10816922013592301e-01 0.00000000000000000e+00,
              4.29006399347352962e-01 -5.07158727910630325e-01 0.00000000000000000e+00,
              3.81449876008848054e-01 -4.95519019401206073e-01 0.00000000000000000e+00,
              3.38881799174381992e-01 -4.78225738187204263e-01 0.00000000000000000e+00,
              3.07288304648801747e-01 -4.55944010469163441e-01 0.00000000000000000e+00,
              3.03297547445570581e-01 -4.52285816366201521e-01 0.00000000000000000e+00,
              3.00304479543147207e-01 -4.48295059162970300e-01 0.00000000000000000e+00,
              2.97643974740993023e-01 -4.43639175759200644e-01 0.00000000000000000e+00,
              2.95648596139377551e-01 -4.38650729255161631e-01 0.00000000000000000e+00,
              2.94318343738300459e-01 -4.32997156550584128e-01 0.00000000000000000e+00,
              2.95316033039108250e-01 -4.27011020745737324e-01 0.00000000000000000e+00,
              2.97643974740993023e-01 -4.21357448041159821e-01 0.00000000000000000e+00,
              3.02299858144762679e-01 -4.15371312236313017e-01 0.00000000000000000e+00,
              3.07620867749071047e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              3.13607003553917796e-01 -4.08387487130658422e-01 0.00000000000000000e+00,
              3.19260576258495243e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              3.24249022762534311e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              3.29237469266573379e-01 -4.09052613331196968e-01 0.00000000000000000e+00,
              3.34558478870881526e-01 -4.11380555033081796e-01 0.00000000000000000e+00,
              3.39214362274651293e-01 -4.14041059835235981e-01 0.00000000000000000e+00,
              3.43205119477882681e-01 -4.17034127737659355e-01 0.00000000000000000e+00,
              3.46530750480575245e-01 -4.20027195640082729e-01 0.00000000000000000e+00,
              3.49856381483267809e-01 -4.22687700442236858e-01 0.00000000000000000e+00,
              3.52516886285421993e-01 -4.25015642144121741e-01 0.00000000000000000e+00,
              3.53847138686498974e-01 -4.26013331444929533e-01 0.00000000000000000e+00,
              3.76461429504808986e-01 -4.37985603054623085e-01 0.00000000000000000e+00,
              4.09385176431466435e-01 -4.48960185363508846e-01 0.00000000000000000e+00,
              4.44969428160277847e-01 -4.56941699769971232e-01 0.00000000000000000e+00,
              4.75897796485319491e-01 -4.59269641471856116e-01 0.00000000000000000e+00,
              5.02502844506860891e-01 -4.57274262870240533e-01 0.00000000000000000e+00,
              5.27445077027055786e-01 -4.51288127065393729e-01 0.00000000000000000e+00,
              5.49726804745096498e-01 -4.41976360257854251e-01 0.00000000000000000e+00,
              5.68350338360175344e-01 -4.29671525547891453e-01 0.00000000000000000e+00,
              5.85643619574177210e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="5.09997228640831111e+02 4.99588575103220251e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            15 14 16 -1 
            16 14 17 -1 
            17 14 18 -1 
            12 11 13 -1 
            11 10 13 -1 
            10 9 13 -1 
            9 8 13 -1 
            24 23 25 -1 
            23 22 25 -1 
            22 21 25 -1 
            25 21 26 -1 
            21 20 26 -1 
            26 20 27 -1 
            20 19 27 -1 
            27 19 28 -1 
            19 18 28 -1 
            18 14 28 -1 
            14 13 28 -1 
            28 13 29 -1 
            13 8 29 -1 
            29 8 30 -1 
            30 8 31 -1 
            8 7 31 -1 
            31 7 32 -1 
            7 6 32 -1 
            32 6 33 -1 
            6 5 33 -1 
            33 5 34 -1 
            34 5 35 -1 
            35 5 36 -1 
            36 5 37 -1 
            55 54 56 -1 
            56 54 57 -1 
            57 54 58 -1 
            58 54 59 -1 
            54 53 59 -1 
            59 53 60 -1 
            60 53 61 -1 
            61 53 62 -1 
            62 53 63 -1 
            63 53 64 -1 
            64 53 65 -1 
            53 52 65 -1 
            52 51 65 -1 
            51 50 65 -1 
            50 49 65 -1 
            49 48 65 -1 
            48 47 65 -1 
            39 38 40 -1 
            38 37 40 -1 
            40 37 41 -1 
            41 37 42 -1 
            42 37 43 -1 
            43 37 44 -1 
            44 37 45 -1 
            46 45 47 -1 
            5 4 37 -1 
            4 3 37 -1 
            3 2 37 -1 
            2 1 37 -1 
            1 0 37 -1 
            0 79 37 -1 
            66 65 67 -1 
            37 79 45 -1 
            79 78 45 -1 
            78 77 45 -1 
            45 77 47 -1 
            77 76 47 -1 
            76 75 47 -1 
            75 74 47 -1 
            74 73 47 -1 
            73 72 47 -1 
            72 71 47 -1 
            71 70 47 -1 
            67 65 68 -1 
            65 47 68 -1 
            68 47 69 -1 
            70 69 47 -1 
            94 93 95 -1 
            93 92 95 -1 
            92 91 95 -1 
            91 90 95 -1 
            90 89 95 -1 
            89 88 95 -1 
            88 87 95 -1 
            95 87 96 -1 
            87 86 96 -1 
            85 84 86 -1 
            86 84 96 -1 
            104 103 105 -1 
            103 102 105 -1 
            102 101 105 -1 
            101 100 105 -1 
            100 99 105 -1 
            99 98 105 -1 
            105 98 80 -1 
            81 80 82 -1 
            82 80 83 -1 
            83 80 84 -1 
            84 80 96 -1 
            96 80 97 -1 
            80 98 97 -1 
            153 106 154 -1 
            106 135 154 -1 
            121 137 122 -1 
            137 136 122 -1 
            154 135 155 -1 
            155 135 156 -1 
            135 134 156 -1 
            156 134 157 -1 
            157 134 158 -1 
            134 133 158 -1 
            158 133 159 -1 
            133 132 159 -1 
            159 132 160 -1 
            132 131 160 -1 
            160 131 161 -1 
            131 130 161 -1 
            161 130 162 -1 
            162 130 163 -1 
            130 129 163 -1 
            163 129 164 -1 
            129 128 164 -1 
            164 128 165 -1 
            128 127 165 -1 
            165 127 166 -1 
            127 126 166 -1 
            166 126 167 -1 
            122 136 123 -1 
            136 167 123 -1 
            126 125 167 -1 
            125 124 167 -1 
            167 124 123 -1 
            137 121 138 -1 
            153 152 106 -1 
            107 106 152 -1 
            152 151 107 -1 
            107 151 108 -1 
            151 150 108 -1 
            150 149 108 -1 
            121 120 138 -1 
            138 120 139 -1 
            108 149 109 -1 
            149 148 109 -1 
            109 148 110 -1 
            148 147 110 -1 
            110 147 111 -1 
            147 146 111 -1 
            111 146 112 -1 
            146 145 112 -1 
            112 145 113 -1 
            145 144 113 -1 
            113 144 114 -1 
            144 143 114 -1 
            114 143 115 -1 
            143 142 115 -1 
            115 142 116 -1 
            142 141 116 -1 
            116 141 117 -1 
            141 140 117 -1 
            117 140 118 -1 
            140 139 118 -1 
            118 139 119 -1 
            139 120 119 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0008"
              point="
              -2.54909616356392255e-01 -3.80784749808309342e-01 0.00000000000000000e+00,
              -2.57570121158546383e-01 -3.48193565981921305e-01 0.00000000000000000e+00,
              -2.64553946264200923e-01 -3.25246712063341992e-01 0.00000000000000000e+00,
              -2.76193654773625230e-01 -3.08618557049878672e-01 0.00000000000000000e+00,
              -2.90826431185472967e-01 -2.93985780638030991e-01 0.00000000000000000e+00,
              -3.09449964800551869e-01 -2.82013509028337439e-01 0.00000000000000000e+00,
              -3.31731692518592691e-01 -2.72701742220797960e-01 0.00000000000000000e+00,
              -3.56673925038787587e-01 -2.66715606415951212e-01 0.00000000000000000e+00,
              -3.83278973060328876e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              -5.37920814685537430e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              -5.48230270793884644e-01 -2.62724849212719991e-01 0.00000000000000000e+00,
              -5.56544348300616387e-01 -2.57071276508142488e-01 0.00000000000000000e+00,
              -5.62530484105463136e-01 -2.49089762101680101e-01 0.00000000000000000e+00,
              -5.64193299606809417e-01 -2.39445432193871433e-01 0.00000000000000000e+00,
              -5.64193299606809417e-01 -2.49422325201949513e-02 0.00000000000000000e+00,
              -5.62530484105463136e-01 -1.56304657126554725e-02 0.00000000000000000e+00,
              -5.56544348300616387e-01 -7.31638820592384054e-03 0.00000000000000000e+00,
              -5.48230270793884644e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              -5.37920814685537430e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -2.28637131435120267e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -2.18992801527311542e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              -2.10346160920310610e-01 -7.64895130619314134e-03 0.00000000000000000e+00,
              -2.04360025115463861e-01 -1.62955919131940186e-02 0.00000000000000000e+00,
              -2.02364646513848223e-01 -2.59399218210027982e-02 0.00000000000000000e+00,
              -2.04360025115463861e-01 -3.59168148290807676e-02 0.00000000000000000e+00,
              -2.10346160920310610e-01 -4.42308923358123440e-02 0.00000000000000000e+00,
              -2.18992801527311542e-01 -4.98844650403898471e-02 0.00000000000000000e+00,
              -2.28637131435120267e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              -5.11648329764265442e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              -5.11648329764265442e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -3.83278973060328876e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -3.47029595130978863e-01 -2.15833452074753518e-01 0.00000000000000000e+00,
              -3.12775595803244488e-01 -2.24480092681754395e-01 0.00000000000000000e+00,
              -2.81514664377933488e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -2.54909616356392255e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.33293014838889978e-01 -2.77357625624567727e-01 0.00000000000000000e+00,
              -2.16664859825426659e-01 -3.02964984345301169e-01 0.00000000000000000e+00,
              -2.06022840616810143e-01 -3.31565410968458041e-01 0.00000000000000000e+00,
              -2.02364646513848223e-01 -3.61828653092961194e-01 0.00000000000000000e+00,
              -2.06022840616810143e-01 -3.92091895217464403e-01 0.00000000000000000e+00,
              -2.16664859825426659e-01 -4.20692321840621275e-01 0.00000000000000000e+00,
              -2.33293014838889978e-01 -4.46632243661624018e-01 0.00000000000000000e+00,
              -2.54909616356392255e-01 -4.68248845179126294e-01 0.00000000000000000e+00,
              -2.81514664377933488e-01 -4.85874689493397349e-01 0.00000000000000000e+00,
              -3.12775595803244488e-01 -4.99509776604437239e-01 0.00000000000000000e+00,
              -3.47029595130978863e-01 -5.08156417211438227e-01 0.00000000000000000e+00,
              -3.83278973060328876e-01 -5.10816922013592301e-01 0.00000000000000000e+00,
              -4.30170370198295349e-01 -5.07158727910630325e-01 0.00000000000000000e+00,
              -4.77726893536800368e-01 -4.95519019401206073e-01 0.00000000000000000e+00,
              -5.20294970371266374e-01 -4.78225738187204263e-01 0.00000000000000000e+00,
              -5.51888464896846620e-01 -4.55944010469163441e-01 0.00000000000000000e+00,
              -5.55879222100077786e-01 -4.52285816366201521e-01 0.00000000000000000e+00,
              -5.58872290002501160e-01 -4.48295059162970300e-01 0.00000000000000000e+00,
              -5.61532794804655344e-01 -4.43639175759200644e-01 0.00000000000000000e+00,
              -5.63528173406270927e-01 -4.38650729255161631e-01 0.00000000000000000e+00,
              -5.64858425807348019e-01 -4.32997156550584128e-01 0.00000000000000000e+00,
              -5.63860736506540228e-01 -4.27011020745737324e-01 0.00000000000000000e+00,
              -5.61532794804655344e-01 -4.21357448041159821e-01 0.00000000000000000e+00,
              -5.56876911400885577e-01 -4.15371312236313017e-01 0.00000000000000000e+00,
              -5.51555901796577319e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              -5.45569765991730571e-01 -4.08387487130658422e-01 0.00000000000000000e+00,
              -5.39916193287153012e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              -5.34927746783114055e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              -5.29939300279075098e-01 -4.09052613331196968e-01 0.00000000000000000e+00,
              -5.24618290674766841e-01 -4.11380555033081796e-01 0.00000000000000000e+00,
              -5.19962407270997073e-01 -4.14041059835235981e-01 0.00000000000000000e+00,
              -5.15971650067765908e-01 -4.17034127737659355e-01 0.00000000000000000e+00,
              -5.12646019065073233e-01 -4.20027195640082729e-01 0.00000000000000000e+00,
              -5.09320388062380558e-01 -4.22687700442236858e-01 0.00000000000000000e+00,
              -5.06659883260226485e-01 -4.25015642144121741e-01 0.00000000000000000e+00,
              -5.05329630859149392e-01 -4.26013331444929533e-01 0.00000000000000000e+00,
              -4.82715340040839325e-01 -4.37985603054623085e-01 0.00000000000000000e+00,
              -4.49791593114182042e-01 -4.48960185363508846e-01 0.00000000000000000e+00,
              -4.14207341385370575e-01 -4.56941699769971232e-01 0.00000000000000000e+00,
              -3.83278973060328876e-01 -4.59269641471856116e-01 0.00000000000000000e+00,
              -3.56673925038787587e-01 -4.57274262870240533e-01 0.00000000000000000e+00,
              -3.31731692518592691e-01 -4.51288127065393729e-01 0.00000000000000000e+00,
              -3.09449964800551869e-01 -4.41976360257854251e-01 0.00000000000000000e+00,
              -2.90826431185472967e-01 -4.29671525547891453e-01 0.00000000000000000e+00,
              -2.73533149971471101e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              9.47804835767407372e-03 -7.98151440646233112e-03 0.00000000000000000e+00,
              1.77921258644057056e-02 -1.99537860161558278e-03 0.00000000000000000e+00,
              2.84341450730222212e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              3.87436011813694359e-02 -1.99537860161558278e-03 0.00000000000000000e+00,
              4.73902417883703686e-02 -7.31638820592384054e-03 0.00000000000000000e+00,
              5.30438144929479272e-02 -1.52979026123862827e-02 0.00000000000000000e+00,
              5.50391930945635099e-02 -2.46096694199257060e-02 0.00000000000000000e+00,
              5.50391930945635099e-02 -4.86207252593666650e-01 0.00000000000000000e+00,
              5.30438144929479272e-02 -4.95851582501475319e-01 0.00000000000000000e+00,
              4.73902417883703686e-02 -5.03833096907937761e-01 0.00000000000000000e+00,
              3.87436011813694359e-02 -5.09154106512246019e-01 0.00000000000000000e+00,
              2.87667081732915220e-02 -5.10816922013592301e-01 0.00000000000000000e+00,
              1.84572520649441962e-02 -5.09154106512246019e-01 0.00000000000000000e+00,
              1.01431745582125643e-02 -5.03833096907937761e-01 0.00000000000000000e+00,
              4.15703875336581596e-03 -4.95851582501475319e-01 0.00000000000000000e+00,
              2.49422325201953399e-03 -4.86207252593666650e-01 0.00000000000000000e+00,
              2.49422325201953399e-03 -8.77966584710861420e-02 0.00000000000000000e+00,
              -9.89375223301066642e-02 -1.90891219554558567e-01 0.00000000000000000e+00,
              -1.07251599836838241e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -1.17228492844916155e-01 -1.98540170860751708e-01 0.00000000000000000e+00,
              -1.27537948953263425e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -1.36184589560264357e-01 -1.91556345755097113e-01 0.00000000000000000e+00,
              -1.42170725365111161e-01 -1.83907394448904027e-01 0.00000000000000000e+00,
              -1.44166103966726744e-01 -1.74263064541095303e-01 0.00000000000000000e+00,
              -1.42835851565649707e-01 -1.64951297733555879e-01 0.00000000000000000e+00,
              -1.37182278861072204e-01 -1.56637220226824192e-01 0.00000000000000000e+00,
              3.89265108865175735e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              3.18096605407552913e-01 -5.45403484441596698e-02 0.00000000000000000e+00,
              2.74198276172009758e-01 -6.28544259508912462e-02 0.00000000000000000e+00,
              2.45930412649122188e-01 -7.74872023627389273e-02 0.00000000000000000e+00,
              2.22650995630273574e-01 -9.87712407799719583e-02 0.00000000000000000e+00,
              2.04360025115463917e-01 -1.26706541202590339e-01 0.00000000000000000e+00,
              1.91722627305231819e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              1.84073675999038788e-01 -2.04858869765867757e-01 0.00000000000000000e+00,
              1.81745734297153905e-01 -2.55741024107065396e-01 0.00000000000000000e+00,
              1.84073675999038788e-01 -3.06290615347993844e-01 0.00000000000000000e+00,
              1.91722627305231819e-01 -3.49191255282729096e-01 0.00000000000000000e+00,
              2.04360025115463917e-01 -3.84442943911271318e-01 0.00000000000000000e+00,
              2.22650995630273574e-01 -4.12378244333889588e-01 0.00000000000000000e+00,
              2.45930412649122188e-01 -4.33662282751122619e-01 0.00000000000000000e+00,
              2.74198276172009758e-01 -4.47962496062701054e-01 0.00000000000000000e+00,
              3.07454586198936397e-01 -4.56609136669701987e-01 0.00000000000000000e+00,
              3.47029595130978974e-01 -4.59269641471856116e-01 0.00000000000000000e+00,
              3.86272040962752472e-01 -4.56609136669701987e-01 0.00000000000000000e+00,
              4.19860914089948190e-01 -4.47962496062701054e-01 0.00000000000000000e+00,
              4.47796214512566570e-01 -4.33662282751122619e-01 0.00000000000000000e+00,
              4.71075631531415184e-01 -4.12378244333889588e-01 0.00000000000000000e+00,
              4.89366602046224730e-01 -3.84442943911271318e-01 0.00000000000000000e+00,
              5.02003999856456828e-01 -3.49191255282729096e-01 0.00000000000000000e+00,
              5.09652951162650081e-01 -3.06290615347993844e-01 0.00000000000000000e+00,
              5.12313455964804043e-01 -2.55741024107065396e-01 0.00000000000000000e+00,
              5.09652951162650081e-01 -2.04858869765867757e-01 0.00000000000000000e+00,
              5.02003999856456828e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              4.89366602046224730e-01 -1.26706541202590339e-01 0.00000000000000000e+00,
              4.71075631531415184e-01 -9.87712407799719583e-02 0.00000000000000000e+00,
              4.40147263206373318e-01 -7.48266975605847984e-02 0.00000000000000000e+00,
              4.01902506675407722e-01 -5.10816922013592301e-01 0.00000000000000000e+00,
              3.08784838600013267e-01 -5.06493601710091834e-01 0.00000000000000000e+00,
              2.50253732952622543e-01 -4.93523640799590491e-01 0.00000000000000000e+00,
              2.12341539521926359e-01 -4.72239602382357460e-01 0.00000000000000000e+00,
              1.82078297397423206e-01 -4.42974049558662097e-01 0.00000000000000000e+00,
              1.58798880378574481e-01 -4.06724671629312085e-01 0.00000000000000000e+00,
              1.42503288465380518e-01 -3.63158905494038287e-01 0.00000000000000000e+00,
              1.32526395457302604e-01 -3.12609314253109893e-01 0.00000000000000000e+00,
              1.29200764454609818e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              1.32526395457302604e-01 -1.98540170860751708e-01 0.00000000000000000e+00,
              1.42503288465380518e-01 -1.47990579619823315e-01 0.00000000000000000e+00,
              1.58798880378574481e-01 -1.04424813484549517e-01 0.00000000000000000e+00,
              1.82078297397423206e-01 -6.78428724549302586e-02 0.00000000000000000e+00,
              2.12341539521926359e-01 -3.85773196312348965e-02 0.00000000000000000e+00,
              2.50253732952622543e-01 -1.72932812140018655e-02 0.00000000000000000e+00,
              2.95149751488973489e-01 -4.32332030350046637e-03 0.00000000000000000e+00,
              3.47029595130978974e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              3.88599982664637134e-01 -2.99306790242337417e-03 0.00000000000000000e+00,
              4.26512176095333539e-01 -1.13071454091550616e-02 0.00000000000000000e+00,
              4.59768486122260067e-01 -2.49422325201949513e-02 0.00000000000000000e+00,
              4.88036349645147749e-01 -4.38983292355431542e-02 0.00000000000000000e+00,
              5.11315766663996363e-01 -6.78428724549302586e-02 0.00000000000000000e+00,
              5.30936989579883112e-01 -9.64432990780871302e-02 0.00000000000000000e+00,
              5.45902329091999872e-01 -1.30032172205282959e-01 0.00000000000000000e+00,
              5.56544348300616387e-01 -1.67611802535710008e-01 0.00000000000000000e+00,
              5.62530484105463358e-01 -2.09514753169637469e-01 0.00000000000000000e+00,
              5.64858425807348019e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              5.61532794804655455e-01 -3.12609314253109893e-01 0.00000000000000000e+00,
              5.51555901796577541e-01 -3.63158905494038287e-01 0.00000000000000000e+00,
              5.34927746783114277e-01 -4.06724671629312085e-01 0.00000000000000000e+00,
              5.11648329764265442e-01 -4.42974049558662097e-01 0.00000000000000000e+00,
              4.70743068431145884e-01 -4.76562922685857926e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="5.14997228640831054e+02 4.99588575103220251e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            15 14 16 -1 
            16 14 17 -1 
            17 14 18 -1 
            12 11 13 -1 
            11 10 13 -1 
            10 9 13 -1 
            9 8 13 -1 
            24 23 25 -1 
            23 22 25 -1 
            22 21 25 -1 
            25 21 26 -1 
            21 20 26 -1 
            26 20 27 -1 
            20 19 27 -1 
            27 19 28 -1 
            19 18 28 -1 
            18 14 28 -1 
            14 13 28 -1 
            28 13 29 -1 
            13 8 29 -1 
            29 8 30 -1 
            30 8 31 -1 
            8 7 31 -1 
            31 7 32 -1 
            7 6 32 -1 
            32 6 33 -1 
            6 5 33 -1 
            33 5 34 -1 
            34 5 35 -1 
            35 5 36 -1 
            36 5 37 -1 
            55 54 56 -1 
            56 54 57 -1 
            57 54 58 -1 
            58 54 59 -1 
            54 53 59 -1 
            59 53 60 -1 
            60 53 61 -1 
            61 53 62 -1 
            62 53 63 -1 
            63 53 64 -1 
            64 53 65 -1 
            53 52 65 -1 
            52 51 65 -1 
            51 50 65 -1 
            50 49 65 -1 
            49 48 65 -1 
            48 47 65 -1 
            39 38 40 -1 
            38 37 40 -1 
            40 37 41 -1 
            41 37 42 -1 
            42 37 43 -1 
            43 37 44 -1 
            44 37 45 -1 
            46 45 47 -1 
            5 4 37 -1 
            4 3 37 -1 
            3 2 37 -1 
            2 1 37 -1 
            1 0 37 -1 
            0 79 37 -1 
            66 65 67 -1 
            37 79 45 -1 
            79 78 45 -1 
            78 77 45 -1 
            45 77 47 -1 
            77 76 47 -1 
            76 75 47 -1 
            75 74 47 -1 
            74 73 47 -1 
            73 72 47 -1 
            72 71 47 -1 
            71 70 47 -1 
            67 65 68 -1 
            65 47 68 -1 
            68 47 69 -1 
            70 69 47 -1 
            94 93 95 -1 
            93 92 95 -1 
            92 91 95 -1 
            91 90 95 -1 
            90 89 95 -1 
            89 88 95 -1 
            88 87 95 -1 
            95 87 96 -1 
            87 86 96 -1 
            85 84 86 -1 
            86 84 96 -1 
            104 103 105 -1 
            103 102 105 -1 
            102 101 105 -1 
            101 100 105 -1 
            100 99 105 -1 
            99 98 105 -1 
            105 98 80 -1 
            81 80 82 -1 
            82 80 83 -1 
            83 80 84 -1 
            84 80 96 -1 
            96 80 97 -1 
            80 98 97 -1 
            121 120 122 -1 
            122 120 123 -1 
            123 120 124 -1 
            118 117 119 -1 
            117 116 119 -1 
            116 115 119 -1 
            115 114 119 -1 
            130 129 131 -1 
            129 128 131 -1 
            128 127 131 -1 
            131 127 132 -1 
            127 126 132 -1 
            132 126 133 -1 
            126 125 133 -1 
            133 125 134 -1 
            125 124 134 -1 
            124 120 134 -1 
            120 119 134 -1 
            134 119 135 -1 
            119 114 135 -1 
            135 114 136 -1 
            136 114 137 -1 
            114 113 137 -1 
            137 113 138 -1 
            113 112 138 -1 
            138 112 139 -1 
            112 111 139 -1 
            139 111 140 -1 
            140 111 141 -1 
            141 111 142 -1 
            142 111 143 -1 
            161 160 162 -1 
            162 160 163 -1 
            163 160 164 -1 
            164 160 165 -1 
            160 159 165 -1 
            165 159 166 -1 
            166 159 167 -1 
            167 159 168 -1 
            168 159 169 -1 
            169 159 170 -1 
            170 159 171 -1 
            159 158 171 -1 
            158 157 171 -1 
            157 156 171 -1 
            156 155 171 -1 
            155 154 171 -1 
            154 153 171 -1 
            145 144 146 -1 
            144 143 146 -1 
            146 143 147 -1 
            147 143 148 -1 
            148 143 149 -1 
            149 143 150 -1 
            150 143 151 -1 
            152 151 153 -1 
            111 110 143 -1 
            110 109 143 -1 
            109 108 143 -1 
            108 107 143 -1 
            107 106 143 -1 
            106 185 143 -1 
            172 171 173 -1 
            143 185 151 -1 
            185 184 151 -1 
            184 183 151 -1 
            151 183 153 -1 
            183 182 153 -1 
            182 181 153 -1 
            181 180 153 -1 
            180 179 153 -1 
            179 178 153 -1 
            178 177 153 -1 
            177 176 153 -1 
            173 171 174 -1 
            171 153 174 -1 
            174 153 175 -1 
            176 175 153 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0009"
              point="
              -2.28637131435120267e-01 -3.80784749808309342e-01 0.00000000000000000e+00,
              -2.31297636237274395e-01 -3.48193565981921305e-01 0.00000000000000000e+00,
              -2.38281461342928935e-01 -3.25246712063341992e-01 0.00000000000000000e+00,
              -2.49921169852353242e-01 -3.08618557049878672e-01 0.00000000000000000e+00,
              -2.64553946264200979e-01 -2.93985780638030991e-01 0.00000000000000000e+00,
              -2.83177479879279881e-01 -2.82013509028337439e-01 0.00000000000000000e+00,
              -3.05459207597320703e-01 -2.72701742220797960e-01 0.00000000000000000e+00,
              -3.30401440117515599e-01 -2.66715606415951212e-01 0.00000000000000000e+00,
              -3.57006488139056888e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              -5.11648329764265442e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              -5.21957785872612656e-01 -2.62724849212719991e-01 0.00000000000000000e+00,
              -5.30271863379344399e-01 -2.57071276508142488e-01 0.00000000000000000e+00,
              -5.36257999184191148e-01 -2.49089762101680101e-01 0.00000000000000000e+00,
              -5.37920814685537430e-01 -2.39445432193871433e-01 0.00000000000000000e+00,
              -5.37920814685537430e-01 -2.49422325201949513e-02 0.00000000000000000e+00,
              -5.36257999184191148e-01 -1.56304657126554725e-02 0.00000000000000000e+00,
              -5.30271863379344399e-01 -7.31638820592384054e-03 0.00000000000000000e+00,
              -5.21957785872612656e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              -5.11648329764265442e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -2.02364646513848279e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.92720316606039554e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              -1.84073675999038622e-01 -7.64895130619314134e-03 0.00000000000000000e+00,
              -1.78087540194191873e-01 -1.62955919131940186e-02 0.00000000000000000e+00,
              -1.76092161592576235e-01 -2.59399218210027982e-02 0.00000000000000000e+00,
              -1.78087540194191873e-01 -3.59168148290807676e-02 0.00000000000000000e+00,
              -1.84073675999038622e-01 -4.42308923358123440e-02 0.00000000000000000e+00,
              -1.92720316606039554e-01 -4.98844650403898471e-02 0.00000000000000000e+00,
              -2.02364646513848279e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              -4.85375844842993454e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              -4.85375844842993454e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -3.57006488139056888e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -3.20757110209706875e-01 -2.15833452074753518e-01 0.00000000000000000e+00,
              -2.86503110881972500e-01 -2.24480092681754395e-01 0.00000000000000000e+00,
              -2.55242179456661500e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -2.28637131435120267e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.07020529917617990e-01 -2.77357625624567727e-01 0.00000000000000000e+00,
              -1.90392374904154671e-01 -3.02964984345301169e-01 0.00000000000000000e+00,
              -1.79750355695538155e-01 -3.31565410968458041e-01 0.00000000000000000e+00,
              -1.76092161592576235e-01 -3.61828653092961194e-01 0.00000000000000000e+00,
              -1.79750355695538155e-01 -3.92091895217464403e-01 0.00000000000000000e+00,
              -1.90392374904154671e-01 -4.20692321840621275e-01 0.00000000000000000e+00,
              -2.07020529917617990e-01 -4.46632243661624018e-01 0.00000000000000000e+00,
              -2.28637131435120267e-01 -4.68248845179126294e-01 0.00000000000000000e+00,
              -2.55242179456661500e-01 -4.85874689493397349e-01 0.00000000000000000e+00,
              -2.86503110881972500e-01 -4.99509776604437239e-01 0.00000000000000000e+00,
              -3.20757110209706875e-01 -5.08156417211438227e-01 0.00000000000000000e+00,
              -3.57006488139056888e-01 -5.10816922013592301e-01 0.00000000000000000e+00,
              -4.03897885277023361e-01 -5.07158727910630325e-01 0.00000000000000000e+00,
              -4.51454408615528380e-01 -4.95519019401206073e-01 0.00000000000000000e+00,
              -4.94022485449994386e-01 -4.78225738187204263e-01 0.00000000000000000e+00,
              -5.25615979975574632e-01 -4.55944010469163441e-01 0.00000000000000000e+00,
              -5.29606737178805798e-01 -4.52285816366201521e-01 0.00000000000000000e+00,
              -5.32599805081229172e-01 -4.48295059162970300e-01 0.00000000000000000e+00,
              -5.35260309883383356e-01 -4.43639175759200644e-01 0.00000000000000000e+00,
              -5.37255688484998939e-01 -4.38650729255161631e-01 0.00000000000000000e+00,
              -5.38585940886076031e-01 -4.32997156550584128e-01 0.00000000000000000e+00,
              -5.37588251585268240e-01 -4.27011020745737324e-01 0.00000000000000000e+00,
              -5.35260309883383356e-01 -4.21357448041159821e-01 0.00000000000000000e+00,
              -5.30604426479613589e-01 -4.15371312236313017e-01 0.00000000000000000e+00,
              -5.25283416875305331e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              -5.19297281070458583e-01 -4.08387487130658422e-01 0.00000000000000000e+00,
              -5.13643708365881024e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              -5.08655261861842067e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              -5.03666815357803110e-01 -4.09052613331196968e-01 0.00000000000000000e+00,
              -4.98345805753494853e-01 -4.11380555033081796e-01 0.00000000000000000e+00,
              -4.93689922349725085e-01 -4.14041059835235981e-01 0.00000000000000000e+00,
              -4.89699165146493920e-01 -4.17034127737659355e-01 0.00000000000000000e+00,
              -4.86373534143801245e-01 -4.20027195640082729e-01 0.00000000000000000e+00,
              -4.83047903141108625e-01 -4.22687700442236858e-01 0.00000000000000000e+00,
              -4.80387398338954497e-01 -4.25015642144121741e-01 0.00000000000000000e+00,
              -4.79057145937877404e-01 -4.26013331444929533e-01 0.00000000000000000e+00,
              -4.56442855119567337e-01 -4.37985603054623085e-01 0.00000000000000000e+00,
              -4.23519108192910054e-01 -4.48960185363508846e-01 0.00000000000000000e+00,
              -3.87934856464098587e-01 -4.56941699769971232e-01 0.00000000000000000e+00,
              -3.57006488139056888e-01 -4.59269641471856116e-01 0.00000000000000000e+00,
              -3.30401440117515599e-01 -4.57274262870240533e-01 0.00000000000000000e+00,
              -3.05459207597320703e-01 -4.51288127065393729e-01 0.00000000000000000e+00,
              -2.83177479879279881e-01 -4.41976360257854251e-01 0.00000000000000000e+00,
              -2.64553946264200979e-01 -4.29671525547891453e-01 0.00000000000000000e+00,
              -2.47260665050199113e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              3.57505332789460617e-02 -7.98151440646233112e-03 0.00000000000000000e+00,
              4.40646107856776936e-02 -1.99537860161558278e-03 0.00000000000000000e+00,
              5.47066299942942091e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              6.50160861026414238e-02 -1.99537860161558278e-03 0.00000000000000000e+00,
              7.36627267096423566e-02 -7.31638820592384054e-03 0.00000000000000000e+00,
              7.93162994142199151e-02 -1.52979026123862827e-02 0.00000000000000000e+00,
              8.13116780158354979e-02 -2.46096694199257060e-02 0.00000000000000000e+00,
              8.13116780158354979e-02 -4.86207252593666650e-01 0.00000000000000000e+00,
              7.93162994142199151e-02 -4.95851582501475319e-01 0.00000000000000000e+00,
              7.36627267096423566e-02 -5.03833096907937761e-01 0.00000000000000000e+00,
              6.50160861026414238e-02 -5.09154106512246019e-01 0.00000000000000000e+00,
              5.50391930945635099e-02 -5.10816922013592301e-01 0.00000000000000000e+00,
              4.47297369862161842e-02 -5.09154106512246019e-01 0.00000000000000000e+00,
              3.64156594794845523e-02 -5.03833096907937761e-01 0.00000000000000000e+00,
              3.04295236746378039e-02 -4.95851582501475319e-01 0.00000000000000000e+00,
              2.87667081732915220e-02 -4.86207252593666650e-01 0.00000000000000000e+00,
              2.87667081732915220e-02 -8.77966584710861420e-02 0.00000000000000000e+00,
              -7.26650374088346762e-02 -1.90891219554558567e-01 0.00000000000000000e+00,
              -8.09791149155662526e-02 -1.96877355359405370e-01 0.00000000000000000e+00,
              -9.09560079236441665e-02 -1.98540170860751708e-01 0.00000000000000000e+00,
              -1.01265464031991437e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -1.09912104638992369e-01 -1.91556345755097113e-01 0.00000000000000000e+00,
              -1.15898240443839173e-01 -1.83907394448904027e-01 0.00000000000000000e+00,
              -1.17893619045454756e-01 -1.74263064541095303e-01 0.00000000000000000e+00,
              -1.16563366644377719e-01 -1.64951297733555879e-01 0.00000000000000000e+00,
              -1.10909793939800216e-01 -1.56637220226824192e-01 0.00000000000000000e+00,
              4.86040971043532055e-01 -3.80784749808309342e-01 0.00000000000000000e+00,
              4.83380466241377871e-01 -3.48193565981921305e-01 0.00000000000000000e+00,
              4.76396641135723220e-01 -3.25246712063341992e-01 0.00000000000000000e+00,
              4.64756932626299246e-01 -3.08618557049878672e-01 0.00000000000000000e+00,
              4.50124156214451343e-01 -2.93985780638030991e-01 0.00000000000000000e+00,
              4.31500622599372496e-01 -2.82013509028337439e-01 0.00000000000000000e+00,
              4.09218894881331674e-01 -2.72701742220797960e-01 0.00000000000000000e+00,
              3.84276662361136778e-01 -2.66715606415951212e-01 0.00000000000000000e+00,
              3.57671614339595489e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              2.03029772714386936e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              1.92720316606039610e-01 -2.62724849212719991e-01 0.00000000000000000e+00,
              1.84406239099307978e-01 -2.57071276508142488e-01 0.00000000000000000e+00,
              1.78420103294461230e-01 -2.49089762101680101e-01 0.00000000000000000e+00,
              1.76757287793114837e-01 -2.39445432193871433e-01 0.00000000000000000e+00,
              1.76757287793114837e-01 -2.49422325201949513e-02 0.00000000000000000e+00,
              1.78420103294461230e-01 -1.56304657126554725e-02 0.00000000000000000e+00,
              1.84406239099307978e-01 -7.31638820592384054e-03 0.00000000000000000e+00,
              1.92720316606039610e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              2.03029772714386936e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              5.12313455964803932e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              5.21957785872612767e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              5.30604426479613700e-01 -7.64895130619314134e-03 0.00000000000000000e+00,
              5.36590562284460448e-01 -1.62955919131940186e-02 0.00000000000000000e+00,
              5.38585940886076031e-01 -2.59399218210027982e-02 0.00000000000000000e+00,
              5.36590562284460448e-01 -3.59168148290807676e-02 0.00000000000000000e+00,
              5.30604426479613700e-01 -4.42308923358123440e-02 0.00000000000000000e+00,
              5.21957785872612767e-01 -4.98844650403898471e-02 0.00000000000000000e+00,
              5.12313455964803932e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              2.29302257635658813e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              2.29302257635658813e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              3.57671614339595489e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              3.93920992268945391e-01 -2.15833452074753518e-01 0.00000000000000000e+00,
              4.28174991596679710e-01 -2.24480092681754395e-01 0.00000000000000000e+00,
              4.59435923021990655e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              4.86040971043532055e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              5.07657572561034387e-01 -2.77357625624567727e-01 0.00000000000000000e+00,
              5.24285727574497651e-01 -3.02964984345301169e-01 0.00000000000000000e+00,
              5.34927746783114166e-01 -3.31565410968458041e-01 0.00000000000000000e+00,
              5.38585940886076031e-01 -3.61828653092961194e-01 0.00000000000000000e+00,
              5.34927746783114166e-01 -3.92091895217464403e-01 0.00000000000000000e+00,
              5.24285727574497651e-01 -4.20692321840621275e-01 0.00000000000000000e+00,
              5.07657572561034387e-01 -4.46632243661624018e-01 0.00000000000000000e+00,
              4.86040971043532055e-01 -4.68248845179126294e-01 0.00000000000000000e+00,
              4.59435923021990655e-01 -4.85874689493397349e-01 0.00000000000000000e+00,
              4.28174991596679710e-01 -4.99509776604437239e-01 0.00000000000000000e+00,
              3.93920992268945391e-01 -5.08156417211438227e-01 0.00000000000000000e+00,
              3.57671614339595489e-01 -5.10816922013592301e-01 0.00000000000000000e+00,
              3.10780217201628961e-01 -5.07158727910630325e-01 0.00000000000000000e+00,
              2.63223693863123942e-01 -4.95519019401206073e-01 0.00000000000000000e+00,
              2.20655617028657991e-01 -4.78225738187204263e-01 0.00000000000000000e+00,
              1.89062122503077745e-01 -4.55944010469163441e-01 0.00000000000000000e+00,
              1.85071365299846469e-01 -4.52285816366201521e-01 0.00000000000000000e+00,
              1.82078297397423094e-01 -4.48295059162970300e-01 0.00000000000000000e+00,
              1.79417792595269021e-01 -4.43639175759200644e-01 0.00000000000000000e+00,
              1.77422413993653327e-01 -4.38650729255161631e-01 0.00000000000000000e+00,
              1.76092161592576346e-01 -4.32997156550584128e-01 0.00000000000000000e+00,
              1.77089850893384138e-01 -4.27011020745737324e-01 0.00000000000000000e+00,
              1.79417792595269021e-01 -4.21357448041159821e-01 0.00000000000000000e+00,
              1.84073675999038677e-01 -4.15371312236313017e-01 0.00000000000000000e+00,
              1.89394685603346935e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              1.95380821408193683e-01 -4.08387487130658422e-01 0.00000000000000000e+00,
              2.01034394112771242e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              2.06022840616810199e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              2.11011287120849156e-01 -4.09052613331196968e-01 0.00000000000000000e+00,
              2.16332296725157414e-01 -4.11380555033081796e-01 0.00000000000000000e+00,
              2.20988180128927292e-01 -4.14041059835235981e-01 0.00000000000000000e+00,
              2.24978937332158346e-01 -4.17034127737659355e-01 0.00000000000000000e+00,
              2.28304568334851021e-01 -4.20027195640082729e-01 0.00000000000000000e+00,
              2.31630199337543696e-01 -4.22687700442236858e-01 0.00000000000000000e+00,
              2.34290704139697881e-01 -4.25015642144121741e-01 0.00000000000000000e+00,
              2.35620956540774862e-01 -4.26013331444929533e-01 0.00000000000000000e+00,
              2.58235247359084985e-01 -4.37985603054623085e-01 0.00000000000000000e+00,
              2.91158994285742323e-01 -4.48960185363508846e-01 0.00000000000000000e+00,
              3.26743246014553734e-01 -4.56941699769971232e-01 0.00000000000000000e+00,
              3.57671614339595489e-01 -4.59269641471856116e-01 0.00000000000000000e+00,
              3.84276662361136778e-01 -4.57274262870240533e-01 0.00000000000000000e+00,
              4.09218894881331674e-01 -4.51288127065393729e-01 0.00000000000000000e+00,
              4.31500622599372496e-01 -4.41976360257854251e-01 0.00000000000000000e+00,
              4.50124156214451343e-01 -4.29671525547891453e-01 0.00000000000000000e+00,
              4.67417437428453209e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99588575103220251e+02 5.04997228640831111e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            15 14 16 -1 
            16 14 17 -1 
            17 14 18 -1 
            12 11 13 -1 
            11 10 13 -1 
            10 9 13 -1 
            9 8 13 -1 
            24 23 25 -1 
            23 22 25 -1 
            22 21 25 -1 
            25 21 26 -1 
            21 20 26 -1 
            26 20 27 -1 
            20 19 27 -1 
            27 19 28 -1 
            19 18 28 -1 
            18 14 28 -1 
            14 13 28 -1 
            28 13 29 -1 
            13 8 29 -1 
            29 8 30 -1 
            30 8 31 -1 
            8 7 31 -1 
            31 7 32 -1 
            7 6 32 -1 
            32 6 33 -1 
            6 5 33 -1 
            33 5 34 -1 
            34 5 35 -1 
            35 5 36 -1 
            36 5 37 -1 
            55 54 56 -1 
            56 54 57 -1 
            57 54 58 -1 
            58 54 59 -1 
            54 53 59 -1 
            59 53 60 -1 
            60 53 61 -1 
            61 53 62 -1 
            62 53 63 -1 
            63 53 64 -1 
            64 53 65 -1 
            53 52 65 -1 
            52 51 65 -1 
            51 50 65 -1 
            50 49 65 -1 
            49 48 65 -1 
            48 47 65 -1 
            39 38 40 -1 
            38 37 40 -1 
            40 37 41 -1 
            41 37 42 -1 
            42 37 43 -1 
            43 37 44 -1 
            44 37 45 -1 
            46 45 47 -1 
            5 4 37 -1 
            4 3 37 -1 
            3 2 37 -1 
            2 1 37 -1 
            1 0 37 -1 
            0 79 37 -1 
            66 65 67 -1 
            37 79 45 -1 
            79 78 45 -1 
            78 77 45 -1 
            45 77 47 -1 
            77 76 47 -1 
            76 75 47 -1 
            75 74 47 -1 
            74 73 47 -1 
            73 72 47 -1 
            72 71 47 -1 
            71 70 47 -1 
            67 65 68 -1 
            65 47 68 -1 
            68 47 69 -1 
            70 69 47 -1 
            127 80 128 -1 
            80 109 128 -1 
            95 111 96 -1 
            111 110 96 -1 
            128 109 129 -1 
            129 109 130 -1 
            109 108 130 -1 
            130 108 131 -1 
            131 108 132 -1 
            108 107 132 -1 
            132 107 133 -1 
            107 106 133 -1 
            133 106 134 -1 
            106 105 134 -1 
            134 105 135 -1 
            105 104 135 -1 
            135 104 136 -1 
            136 104 137 -1 
            104 103 137 -1 
            137 103 138 -1 
            103 102 138 -1 
            138 102 139 -1 
            102 101 139 -1 
            139 101 140 -1 
            101 100 140 -1 
            140 100 141 -1 
            96 110 97 -1 
            110 141 97 -1 
            100 99 141 -1 
            99 98 141 -1 
            141 98 97 -1 
            111 95 112 -1 
            127 126 80 -1 
            81 80 126 -1 
            126 125 81 -1 
            81 125 82 -1 
            125 124 82 -1 
            124 123 82 -1 
            95 94 112 -1 
            112 94 113 -1 
            82 123 83 -1 
            123 122 83 -1 
            83 122 84 -1 
            122 121 84 -1 
            84 121 85 -1 
            121 120 85 -1 
            85 120 86 -1 
            120 119 86 -1 
            86 119 87 -1 
            119 118 87 -1 
            87 118 88 -1 
            118 117 88 -1 
            88 117 89 -1 
            117 116 89 -1 
            89 116 90 -1 
            116 115 90 -1 
            90 115 91 -1 
            115 114 91 -1 
            91 114 92 -1 
            114 113 92 -1 
            92 113 93 -1 
            113 94 93 -1 
            157 156 158 -1 
            158 156 159 -1 
            159 156 160 -1 
            154 153 155 -1 
            153 152 155 -1 
            152 151 155 -1 
            151 150 155 -1 
            166 165 167 -1 
            165 164 167 -1 
            164 163 167 -1 
            167 163 168 -1 
            163 162 168 -1 
            168 162 169 -1 
            162 161 169 -1 
            169 161 170 -1 
            161 160 170 -1 
            160 156 170 -1 
            156 155 170 -1 
            170 155 171 -1 
            155 150 171 -1 
            171 150 172 -1 
            172 150 173 -1 
            150 149 173 -1 
            173 149 174 -1 
            149 148 174 -1 
            174 148 175 -1 
            148 147 175 -1 
            175 147 176 -1 
            176 147 177 -1 
            177 147 178 -1 
            178 147 179 -1 
            197 196 198 -1 
            198 196 199 -1 
            199 196 200 -1 
            200 196 201 -1 
            196 195 201 -1 
            201 195 202 -1 
            202 195 203 -1 
            203 195 204 -1 
            204 195 205 -1 
            205 195 206 -1 
            206 195 207 -1 
            195 194 207 -1 
            194 193 207 -1 
            193 192 207 -1 
            192 191 207 -1 
            191 190 207 -1 
            190 189 207 -1 
            181 180 182 -1 
            180 179 182 -1 
            182 179 183 -1 
            183 179 184 -1 
            184 179 185 -1 
            185 179 186 -1 
            186 179 187 -1 
            188 187 189 -1 
            147 146 179 -1 
            146 145 179 -1 
            145 144 179 -1 
            144 143 179 -1 
            143 142 179 -1 
            142 221 179 -1 
            208 207 209 -1 
            179 221 187 -1 
            221 220 187 -1 
            220 219 187 -1 
            187 219 189 -1 
            219 218 189 -1 
            218 217 189 -1 
            217 216 189 -1 
            216 215 189 -1 
            215 214 189 -1 
            214 213 189 -1 
            213 212 189 -1 
            209 207 210 -1 
            207 189 210 -1 
            210 189 211 -1 
            212 211 189 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0010"
              point="
              -1.00367543661264436e+00 -1.25376288801513219e-01 0.00000000000000000e+00,
              -1.00633594141479854e+00 -9.27851049751251544e-02 0.00000000000000000e+00,
              -1.01331976652045297e+00 -6.98382510565458414e-02 0.00000000000000000e+00,
              -1.02495947502987717e+00 -5.32100960430825221e-02 0.00000000000000000e+00,
              -1.03959225144172507e+00 -3.85773196312348410e-02 0.00000000000000000e+00,
              -1.05821578505680391e+00 -2.66050480215412610e-02 0.00000000000000000e+00,
              -1.08049751277484463e+00 -1.72932812140018100e-02 0.00000000000000000e+00,
              -1.10543974529503952e+00 -1.13071454091550616e-02 0.00000000000000000e+00,
              -1.13204479331658092e+00 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.28668663494178959e+00 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.29699609105013680e+00 -7.31638820592386829e-03 0.00000000000000000e+00,
              -1.30531016855686843e+00 -1.66281550134633749e-03 0.00000000000000000e+00,
              -1.31129630436171518e+00 6.31869890511604915e-03 0.00000000000000000e+00,
              -1.31295911986306146e+00 1.59630288129247178e-02 0.00000000000000000e+00,
              -1.31295911986306146e+00 2.30466228486601199e-01 0.00000000000000000e+00,
              -1.31129630436171518e+00 2.39777995294140678e-01 0.00000000000000000e+00,
              -1.30531016855686843e+00 2.48092072800872310e-01 0.00000000000000000e+00,
              -1.29699609105013680e+00 2.53413082405180567e-01 0.00000000000000000e+00,
              -1.28668663494178959e+00 2.55408461006796150e-01 0.00000000000000000e+00,
              -9.77402951691372257e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -9.67758621783563644e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -9.59111981176562711e-01 2.47759509700603009e-01 0.00000000000000000e+00,
              -9.53125845371715963e-01 2.39112869093602132e-01 0.00000000000000000e+00,
              -9.51130466770100269e-01 2.29468539185793352e-01 0.00000000000000000e+00,
              -9.53125845371715963e-01 2.19491646177715383e-01 0.00000000000000000e+00,
              -9.59111981176562711e-01 2.11177568670983806e-01 0.00000000000000000e+00,
              -9.67758621783563644e-01 2.05523995966406303e-01 0.00000000000000000e+00,
              -9.77402951691372257e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.26041415002051749e+00 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.26041415002051749e+00 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.13204479331658092e+00 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.09579541538723091e+00 3.95750089320426324e-02 0.00000000000000000e+00,
              -1.06154141605949648e+00 3.09283683250417551e-02 0.00000000000000000e+00,
              -1.03028048463418553e+00 1.72932812140018100e-02 0.00000000000000000e+00,
              -1.00367543661264436e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              -9.82058835095142024e-01 -2.19491646177715771e-02 0.00000000000000000e+00,
              -9.65430680081678760e-01 -4.75565233385050190e-02 0.00000000000000000e+00,
              -9.54788660873062245e-01 -7.61569499616618906e-02 0.00000000000000000e+00,
              -9.51130466770100269e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -9.54788660873062245e-01 -1.36683434210668253e-01 0.00000000000000000e+00,
              -9.65430680081678760e-01 -1.65283860833825125e-01 0.00000000000000000e+00,
              -9.82058835095142024e-01 -1.91223782654827867e-01 0.00000000000000000e+00,
              -1.00367543661264436e+00 -2.12840384172330144e-01 0.00000000000000000e+00,
              -1.03028048463418553e+00 -2.30466228486601199e-01 0.00000000000000000e+00,
              -1.06154141605949648e+00 -2.44101315597641116e-01 0.00000000000000000e+00,
              -1.09579541538723091e+00 -2.52747956204642021e-01 0.00000000000000000e+00,
              -1.13204479331658092e+00 -2.55408461006796150e-01 0.00000000000000000e+00,
              -1.17893619045454745e+00 -2.51750266903834230e-01 0.00000000000000000e+00,
              -1.22649271379305236e+00 -2.40110558394409923e-01 0.00000000000000000e+00,
              -1.26906079062751842e+00 -2.22817277180408113e-01 0.00000000000000000e+00,
              -1.30065428515309867e+00 -2.00535549462367291e-01 0.00000000000000000e+00,
              -1.30464504235632983e+00 -1.96877355359405370e-01 0.00000000000000000e+00,
              -1.30763811025875332e+00 -1.92886598156174177e-01 0.00000000000000000e+00,
              -1.31029861506090728e+00 -1.88230714752404465e-01 0.00000000000000000e+00,
              -1.31229399366252286e+00 -1.83242268248365481e-01 0.00000000000000000e+00,
              -1.31362424606360007e+00 -1.77588695543787950e-01 0.00000000000000000e+00,
              -1.31262655676279216e+00 -1.71602559738941174e-01 0.00000000000000000e+00,
              -1.31029861506090728e+00 -1.65948987034363643e-01 0.00000000000000000e+00,
              -1.30564273165713773e+00 -1.59962851229516867e-01 0.00000000000000000e+00,
              -1.30032172205282937e+00 -1.55639530926016401e-01 0.00000000000000000e+00,
              -1.29433558624798262e+00 -1.52979026123862272e-01 0.00000000000000000e+00,
              -1.28868201354340517e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.28369356703936610e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.27870512053532703e+00 -1.53644152324400818e-01 0.00000000000000000e+00,
              -1.27338411093101889e+00 -1.55972094026285674e-01 0.00000000000000000e+00,
              -1.26872822752724912e+00 -1.58632598828439803e-01 0.00000000000000000e+00,
              -1.26473747032401795e+00 -1.61625666730863204e-01 0.00000000000000000e+00,
              -1.26141183932132539e+00 -1.64618734633286579e-01 0.00000000000000000e+00,
              -1.25808620831863260e+00 -1.67279239435440708e-01 0.00000000000000000e+00,
              -1.25542570351647842e+00 -1.69607181137325591e-01 0.00000000000000000e+00,
              -1.25409545111540144e+00 -1.70604870438133382e-01 0.00000000000000000e+00,
              -1.23148116029709143e+00 -1.82577142047826935e-01 0.00000000000000000e+00,
              -1.19855741337043398e+00 -1.93551724356712695e-01 0.00000000000000000e+00,
              -1.16297316164162257e+00 -2.01533238763175082e-01 0.00000000000000000e+00,
              -1.13204479331658092e+00 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.10543974529503952e+00 -2.01865801863444355e-01 0.00000000000000000e+00,
              -1.08049751277484463e+00 -1.95879666058597579e-01 0.00000000000000000e+00,
              -1.05821578505680391e+00 -1.86567899251058128e-01 0.00000000000000000e+00,
              -1.03959225144172507e+00 -1.74263064541095303e-01 0.00000000000000000e+00,
              -1.02229897022772320e+00 -1.55639530926016401e-01 0.00000000000000000e+00,
              -6.35195521514297812e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -7.06364024971920634e-01 2.00868112562636481e-01 0.00000000000000000e+00,
              -7.50262354207463789e-01 1.92554035055904904e-01 0.00000000000000000e+00,
              -7.78530217730351359e-01 1.77921258644057223e-01 0.00000000000000000e+00,
              -8.01809634749199973e-01 1.56637220226824192e-01 0.00000000000000000e+00,
              -8.20100605264009519e-01 1.28701919804205811e-01 0.00000000000000000e+00,
              -8.32738003074241617e-01 9.34502311756637005e-02 0.00000000000000000e+00,
              -8.40386954380434759e-01 5.05495912409283932e-02 0.00000000000000000e+00,
              -8.42714896082319642e-01 -3.32563100269245293e-04 0.00000000000000000e+00,
              -8.40386954380434759e-01 -5.08821543411976940e-02 0.00000000000000000e+00,
              -8.32738003074241617e-01 -9.37827942759329736e-02 0.00000000000000000e+00,
              -8.20100605264009519e-01 -1.29034482904475140e-01 0.00000000000000000e+00,
              -8.01809634749199973e-01 -1.56969783327093465e-01 0.00000000000000000e+00,
              -7.78530217730351359e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -7.50262354207463789e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -7.17006044180537150e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -6.77431035248494573e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -6.38188589416721186e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -6.04599716289525357e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -5.76664415866907087e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -5.53384998848058363e-01 -1.56969783327093465e-01 0.00000000000000000e+00,
              -5.35094028333248817e-01 -1.29034482904475140e-01 0.00000000000000000e+00,
              -5.22456630523016607e-01 -9.37827942759329736e-02 0.00000000000000000e+00,
              -5.14807679216823577e-01 -5.08821543411976940e-02 0.00000000000000000e+00,
              -5.12147174414669393e-01 -3.32563100269245293e-04 0.00000000000000000e+00,
              -5.14807679216823577e-01 5.05495912409283932e-02 0.00000000000000000e+00,
              -5.22456630523016607e-01 9.34502311756637005e-02 0.00000000000000000e+00,
              -5.35094028333248817e-01 1.28701919804205811e-01 0.00000000000000000e+00,
              -5.53384998848058363e-01 1.56637220226824192e-01 0.00000000000000000e+00,
              -5.84313367173100118e-01 1.80581763446211352e-01 0.00000000000000000e+00,
              -6.22558123704065713e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -7.15675791779460058e-01 -2.51085140703295684e-01 0.00000000000000000e+00,
              -7.74206897426850893e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -8.12119090857547188e-01 -2.16831141375561309e-01 0.00000000000000000e+00,
              -8.42382332982050452e-01 -1.87565588551865947e-01 0.00000000000000000e+00,
              -8.65661750000899066e-01 -1.51316210622515934e-01 0.00000000000000000e+00,
              -8.81957341914092918e-01 -1.07750444487242136e-01 0.00000000000000000e+00,
              -8.91934234922170943e-01 -5.72008532463137154e-02 0.00000000000000000e+00,
              -8.95259865924863618e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -8.91934234922170943e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -8.81957341914092918e-01 1.07417881386972836e-01 0.00000000000000000e+00,
              -8.65661750000899066e-01 1.50983647522246633e-01 0.00000000000000000e+00,
              -8.42382332982050452e-01 1.87565588551865892e-01 0.00000000000000000e+00,
              -8.12119090857547188e-01 2.16831141375561254e-01 0.00000000000000000e+00,
              -7.74206897426850893e-01 2.38115179792794285e-01 0.00000000000000000e+00,
              -7.29310878890500058e-01 2.51085140703295684e-01 0.00000000000000000e+00,
              -6.77431035248494573e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -6.35860647714836302e-01 2.52415393104372776e-01 0.00000000000000000e+00,
              -5.97948454284140118e-01 2.44101315597641089e-01 0.00000000000000000e+00,
              -5.64692144257213369e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -5.36424280734325798e-01 2.11510131771252996e-01 0.00000000000000000e+00,
              -5.13144863715477184e-01 1.87565588551865892e-01 0.00000000000000000e+00,
              -4.93523640799590546e-01 1.58965161928709020e-01 0.00000000000000000e+00,
              -4.78558301287473675e-01 1.25376288801513192e-01 0.00000000000000000e+00,
              -4.67916282078857160e-01 8.77966584710861420e-02 0.00000000000000000e+00,
              -4.61930146274010300e-01 4.58937078371586815e-02 0.00000000000000000e+00,
              -4.59602204572125417e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.62927835574818203e-01 -5.72008532463137154e-02 0.00000000000000000e+00,
              -4.72904728582896117e-01 -1.07750444487242136e-01 0.00000000000000000e+00,
              -4.89532883596359492e-01 -1.51316210622515934e-01 0.00000000000000000e+00,
              -5.12812300615207994e-01 -1.87565588551865947e-01 0.00000000000000000e+00,
              -5.53717561948327663e-01 -2.21154461679061776e-01 0.00000000000000000e+00,
              -5.25449698425439760e-02 -1.25376288801513219e-01 0.00000000000000000e+00,
              -5.52054746446979383e-02 -9.27851049751251544e-02 0.00000000000000000e+00,
              -6.21892997503525891e-02 -6.98382510565458414e-02 0.00000000000000000e+00,
              -7.38290082597770070e-02 -5.32100960430825221e-02 0.00000000000000000e+00,
              -8.84617846716246881e-02 -3.85773196312348410e-02 0.00000000000000000e+00,
              -1.07085318286703535e-01 -2.66050480215412610e-02 0.00000000000000000e+00,
              -1.29367046004744246e-01 -1.72932812140018100e-02 0.00000000000000000e+00,
              -1.54309278524939142e-01 -1.13071454091550616e-02 0.00000000000000000e+00,
              -1.80914326546480542e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.35556168171688984e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.45865624280036199e-01 -7.31638820592386829e-03 0.00000000000000000e+00,
              -3.54179701786768053e-01 -1.66281550134633749e-03 0.00000000000000000e+00,
              -3.60165837591614690e-01 6.31869890511604915e-03 0.00000000000000000e+00,
              -3.61828653092961083e-01 1.59630288129247178e-02 0.00000000000000000e+00,
              -3.61828653092961083e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -3.60165837591614690e-01 2.39777995294140678e-01 0.00000000000000000e+00,
              -3.54179701786768053e-01 2.48092072800872310e-01 0.00000000000000000e+00,
              -3.45865624280036199e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -3.35556168171688984e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -2.62724849212718770e-02 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.66281550134630418e-02 2.53413082405180567e-01 0.00000000000000000e+00,
              -7.98151440646233112e-03 2.47759509700603009e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.39112869093602132e-01 0.00000000000000000e+00,
              0.00000000000000000e+00 2.29468539185793352e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.19491646177715383e-01 0.00000000000000000e+00,
              -7.98151440646233112e-03 2.11177568670983806e-01 0.00000000000000000e+00,
              -1.66281550134630418e-02 2.05523995966406303e-01 0.00000000000000000e+00,
              -2.62724849212718770e-02 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417107e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417107e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.80914326546480542e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.44664948617130529e-01 3.95750089320426324e-02 0.00000000000000000e+00,
              -1.10410949289396099e-01 3.09283683250417551e-02 0.00000000000000000e+00,
              -7.91500178640851537e-02 1.72932812140018100e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.09283683250416441e-02 -2.19491646177715771e-02 0.00000000000000000e+00,
              -1.43002133115783803e-02 -4.75565233385050190e-02 0.00000000000000000e+00,
              -3.65819410296186476e-03 -7.61569499616618906e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 -1.06420192086165072e-01 0.00000000000000000e+00,
              -3.65819410296186476e-03 -1.36683434210668253e-01 0.00000000000000000e+00,
              -1.43002133115783803e-02 -1.65283860833825125e-01 0.00000000000000000e+00,
              -3.09283683250416441e-02 -1.91223782654827867e-01 0.00000000000000000e+00,
              -5.25449698425439760e-02 -2.12840384172330144e-01 0.00000000000000000e+00,
              -7.91500178640851537e-02 -2.30466228486601199e-01 0.00000000000000000e+00,
              -1.10410949289396099e-01 -2.44101315597641116e-01 0.00000000000000000e+00,
              -1.44664948617130529e-01 -2.52747956204642021e-01 0.00000000000000000e+00,
              -1.80914326546480542e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.27805723684447070e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -2.75362247022951978e-01 -2.40110558394409923e-01 0.00000000000000000e+00,
              -3.17930323857418040e-01 -2.22817277180408113e-01 0.00000000000000000e+00,
              -3.49523818382998286e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -3.53514575586229451e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -3.56507643488652826e-01 -1.92886598156174177e-01 0.00000000000000000e+00,
              -3.59168148290807010e-01 -1.88230714752404465e-01 0.00000000000000000e+00,
              -3.61163526892422482e-01 -1.83242268248365481e-01 0.00000000000000000e+00,
              -3.62493779293499574e-01 -1.77588695543787950e-01 0.00000000000000000e+00,
              -3.61496089992691783e-01 -1.71602559738941174e-01 0.00000000000000000e+00,
              -3.59168148290807010e-01 -1.65948987034363643e-01 0.00000000000000000e+00,
              -3.54512264887037354e-01 -1.59962851229516867e-01 0.00000000000000000e+00,
              -3.49191255282728985e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -3.43205119477882237e-01 -1.52979026123862272e-01 0.00000000000000000e+00,
              -3.37551546773304789e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.32563100269265721e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.27574653765226653e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -3.22253644160918507e-01 -1.55972094026285674e-01 0.00000000000000000e+00,
              -3.17597760757148739e-01 -1.58632598828439803e-01 0.00000000000000000e+00,
              -3.13607003553917352e-01 -1.61625666730863204e-01 0.00000000000000000e+00,
              -3.10281372551224788e-01 -1.64618734633286579e-01 0.00000000000000000e+00,
              -3.06955741548532224e-01 -1.67279239435440708e-01 0.00000000000000000e+00,
              -3.04295236746378039e-01 -1.69607181137325591e-01 0.00000000000000000e+00,
              -3.02964984345301058e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -2.80350693526991046e-01 -1.82577142047826935e-01 0.00000000000000000e+00,
              -2.47426946600333597e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -2.11842694871522186e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -1.80914326546480542e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.54309278524939142e-01 -2.01865801863444355e-01 0.00000000000000000e+00,
              -1.29367046004744246e-01 -1.95879666058597579e-01 0.00000000000000000e+00,
              -1.07085318286703535e-01 -1.86567899251058128e-01 0.00000000000000000e+00,
              -8.84617846716246881e-02 -1.74263064541095303e-01 0.00000000000000000e+00,
              -7.11685034576228226e-02 -1.55639530926016401e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99588575103220251e+02 5.09997228640831111e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            15 14 16 -1 
            16 14 17 -1 
            17 14 18 -1 
            12 11 13 -1 
            11 10 13 -1 
            10 9 13 -1 
            9 8 13 -1 
            24 23 25 -1 
            23 22 25 -1 
            22 21 25 -1 
            25 21 26 -1 
            21 20 26 -1 
            26 20 27 -1 
            20 19 27 -1 
            27 19 28 -1 
            19 18 28 -1 
            18 14 28 -1 
            14 13 28 -1 
            28 13 29 -1 
            13 8 29 -1 
            29 8 30 -1 
            30 8 31 -1 
            8 7 31 -1 
            31 7 32 -1 
            7 6 32 -1 
            32 6 33 -1 
            6 5 33 -1 
            33 5 34 -1 
            34 5 35 -1 
            35 5 36 -1 
            36 5 37 -1 
            55 54 56 -1 
            56 54 57 -1 
            57 54 58 -1 
            58 54 59 -1 
            54 53 59 -1 
            59 53 60 -1 
            60 53 61 -1 
            61 53 62 -1 
            62 53 63 -1 
            63 53 64 -1 
            64 53 65 -1 
            53 52 65 -1 
            52 51 65 -1 
            51 50 65 -1 
            50 49 65 -1 
            49 48 65 -1 
            48 47 65 -1 
            39 38 40 -1 
            38 37 40 -1 
            40 37 41 -1 
            41 37 42 -1 
            42 37 43 -1 
            43 37 44 -1 
            44 37 45 -1 
            46 45 47 -1 
            5 4 37 -1 
            4 3 37 -1 
            3 2 37 -1 
            2 1 37 -1 
            1 0 37 -1 
            0 79 37 -1 
            66 65 67 -1 
            37 79 45 -1 
            79 78 45 -1 
            78 77 45 -1 
            45 77 47 -1 
            77 76 47 -1 
            76 75 47 -1 
            75 74 47 -1 
            74 73 47 -1 
            73 72 47 -1 
            72 71 47 -1 
            71 70 47 -1 
            67 65 68 -1 
            65 47 68 -1 
            68 47 69 -1 
            70 69 47 -1 
            94 93 95 -1 
            93 92 95 -1 
            92 91 95 -1 
            91 90 95 -1 
            90 89 95 -1 
            89 88 95 -1 
            88 87 95 -1 
            95 87 96 -1 
            87 86 96 -1 
            85 84 86 -1 
            86 84 96 -1 
            104 103 105 -1 
            103 102 105 -1 
            102 101 105 -1 
            101 100 105 -1 
            100 99 105 -1 
            99 98 105 -1 
            105 98 80 -1 
            81 80 82 -1 
            82 80 83 -1 
            83 80 84 -1 
            84 80 96 -1 
            96 80 97 -1 
            80 98 97 -1 
            153 106 154 -1 
            106 135 154 -1 
            121 137 122 -1 
            137 136 122 -1 
            154 135 155 -1 
            155 135 156 -1 
            135 134 156 -1 
            156 134 157 -1 
            157 134 158 -1 
            134 133 158 -1 
            158 133 159 -1 
            133 132 159 -1 
            159 132 160 -1 
            132 131 160 -1 
            160 131 161 -1 
            131 130 161 -1 
            161 130 162 -1 
            162 130 163 -1 
            130 129 163 -1 
            163 129 164 -1 
            129 128 164 -1 
            164 128 165 -1 
            128 127 165 -1 
            165 127 166 -1 
            127 126 166 -1 
            166 126 167 -1 
            122 136 123 -1 
            136 167 123 -1 
            126 125 167 -1 
            125 124 167 -1 
            167 124 123 -1 
            137 121 138 -1 
            153 152 106 -1 
            107 106 152 -1 
            152 151 107 -1 
            107 151 108 -1 
            151 150 108 -1 
            150 149 108 -1 
            121 120 138 -1 
            138 120 139 -1 
            108 149 109 -1 
            149 148 109 -1 
            109 148 110 -1 
            148 147 110 -1 
            110 147 111 -1 
            147 146 111 -1 
            111 146 112 -1 
            146 145 112 -1 
            112 145 113 -1 
            145 144 113 -1 
            113 144 114 -1 
            144 143 114 -1 
            114 143 115 -1 
            143 142 115 -1 
            115 142 116 -1 
            142 141 116 -1 
            116 141 117 -1 
            141 140 117 -1 
            117 140 118 -1 
            140 139 118 -1 
            118 139 119 -1 
            139 120 119 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0011"
              point="
              -8.19768042163740329e-01 -1.25376288801513219e-01 0.00000000000000000e+00,
              -8.22428546965894403e-01 -9.27851049751251544e-02 0.00000000000000000e+00,
              -8.29412372071548942e-01 -6.98382510565458414e-02 0.00000000000000000e+00,
              -8.41052080580973249e-01 -5.32100960430825221e-02 0.00000000000000000e+00,
              -8.55684856992821041e-01 -3.85773196312348410e-02 0.00000000000000000e+00,
              -8.74308390607899888e-01 -2.66050480215412610e-02 0.00000000000000000e+00,
              -8.96590118325940710e-01 -1.72932812140018100e-02 0.00000000000000000e+00,
              -9.21532350846135606e-01 -1.13071454091550616e-02 0.00000000000000000e+00,
              -9.48137398867676895e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.10277924049288556e+00 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.11308869660123277e+00 -7.31638820592386829e-03 0.00000000000000000e+00,
              -1.12140277410796441e+00 -1.66281550134633749e-03 0.00000000000000000e+00,
              -1.12738890991281115e+00 6.31869890511604915e-03 0.00000000000000000e+00,
              -1.12905172541415744e+00 1.59630288129247178e-02 0.00000000000000000e+00,
              -1.12905172541415744e+00 2.30466228486601199e-01 0.00000000000000000e+00,
              -1.12738890991281115e+00 2.39777995294140678e-01 0.00000000000000000e+00,
              -1.12140277410796441e+00 2.48092072800872310e-01 0.00000000000000000e+00,
              -1.11308869660123277e+00 2.53413082405180567e-01 0.00000000000000000e+00,
              -1.10277924049288556e+00 2.55408461006796150e-01 0.00000000000000000e+00,
              -7.93495557242468230e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -7.83851227334659617e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -7.75204586727658684e-01 2.47759509700603009e-01 0.00000000000000000e+00,
              -7.69218450922811936e-01 2.39112869093602132e-01 0.00000000000000000e+00,
              -7.67223072321196242e-01 2.29468539185793352e-01 0.00000000000000000e+00,
              -7.69218450922811936e-01 2.19491646177715383e-01 0.00000000000000000e+00,
              -7.75204586727658684e-01 2.11177568670983806e-01 0.00000000000000000e+00,
              -7.83851227334659617e-01 2.05523995966406303e-01 0.00000000000000000e+00,
              -7.93495557242468230e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.07650675557161346e+00 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.07650675557161346e+00 4.25680768344660065e-02 0.00000000000000000e+00,
              -9.48137398867676895e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -9.11888020938326882e-01 3.95750089320426324e-02 0.00000000000000000e+00,
              -8.77634021610592452e-01 3.09283683250417551e-02 0.00000000000000000e+00,
              -8.46373090185281507e-01 1.72932812140018100e-02 0.00000000000000000e+00,
              -8.19768042163740329e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -7.98151440646237997e-01 -2.19491646177715771e-02 0.00000000000000000e+00,
              -7.81523285632774734e-01 -4.75565233385050190e-02 0.00000000000000000e+00,
              -7.70881266424158218e-01 -7.61569499616618906e-02 0.00000000000000000e+00,
              -7.67223072321196242e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -7.70881266424158218e-01 -1.36683434210668253e-01 0.00000000000000000e+00,
              -7.81523285632774734e-01 -1.65283860833825125e-01 0.00000000000000000e+00,
              -7.98151440646237997e-01 -1.91223782654827867e-01 0.00000000000000000e+00,
              -8.19768042163740329e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -8.46373090185281507e-01 -2.30466228486601199e-01 0.00000000000000000e+00,
              -8.77634021610592452e-01 -2.44101315597641116e-01 0.00000000000000000e+00,
              -9.11888020938326882e-01 -2.52747956204642021e-01 0.00000000000000000e+00,
              -9.48137398867676895e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -9.95028796005643423e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -1.04258531934414833e+00 -2.40110558394409923e-01 0.00000000000000000e+00,
              -1.08515339617861439e+00 -2.22817277180408113e-01 0.00000000000000000e+00,
              -1.11674689070419464e+00 -2.00535549462367291e-01 0.00000000000000000e+00,
              -1.12073764790742580e+00 -1.96877355359405370e-01 0.00000000000000000e+00,
              -1.12373071580984929e+00 -1.92886598156174177e-01 0.00000000000000000e+00,
              -1.12639122061200325e+00 -1.88230714752404465e-01 0.00000000000000000e+00,
              -1.12838659921361906e+00 -1.83242268248365481e-01 0.00000000000000000e+00,
              -1.12971685161469604e+00 -1.77588695543787950e-01 0.00000000000000000e+00,
              -1.12871916231388814e+00 -1.71602559738941174e-01 0.00000000000000000e+00,
              -1.12639122061200325e+00 -1.65948987034363643e-01 0.00000000000000000e+00,
              -1.12173533720823371e+00 -1.59962851229516867e-01 0.00000000000000000e+00,
              -1.11641432760392534e+00 -1.55639530926016401e-01 0.00000000000000000e+00,
              -1.11042819179907859e+00 -1.52979026123862272e-01 0.00000000000000000e+00,
              -1.10477461909450114e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.09978617259046207e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.09479772608642301e+00 -1.53644152324400818e-01 0.00000000000000000e+00,
              -1.08947671648211486e+00 -1.55972094026285674e-01 0.00000000000000000e+00,
              -1.08482083307834509e+00 -1.58632598828439803e-01 0.00000000000000000e+00,
              -1.08083007587511393e+00 -1.61625666730863204e-01 0.00000000000000000e+00,
              -1.07750444487242136e+00 -1.64618734633286579e-01 0.00000000000000000e+00,
              -1.07417881386972858e+00 -1.67279239435440708e-01 0.00000000000000000e+00,
              -1.07151830906757439e+00 -1.69607181137325591e-01 0.00000000000000000e+00,
              -1.07018805666649741e+00 -1.70604870438133382e-01 0.00000000000000000e+00,
              -1.04757376584818740e+00 -1.82577142047826935e-01 0.00000000000000000e+00,
              -1.01465001892152995e+00 -1.93551724356712695e-01 0.00000000000000000e+00,
              -9.79065767192718539e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -9.48137398867676895e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -9.21532350846135606e-01 -2.01865801863444355e-01 0.00000000000000000e+00,
              -8.96590118325940710e-01 -1.95879666058597579e-01 0.00000000000000000e+00,
              -8.74308390607899888e-01 -1.86567899251058128e-01 0.00000000000000000e+00,
              -8.55684856992821041e-01 -1.74263064541095303e-01 0.00000000000000000e+00,
              -8.38391575778819176e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -5.55380377449673945e-01 2.47426946600333819e-01 0.00000000000000000e+00,
              -5.47066299942942313e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -5.36424280734325798e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -5.26114824625978583e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -5.17468184018977650e-01 2.48092072800872310e-01 0.00000000000000000e+00,
              -5.11814611314400092e-01 2.40110558394409868e-01 0.00000000000000000e+00,
              -5.09819232712784509e-01 2.30798791586870444e-01 0.00000000000000000e+00,
              -5.09819232712784509e-01 -2.30798791586870472e-01 0.00000000000000000e+00,
              -5.11814611314400092e-01 -2.40443121494679196e-01 0.00000000000000000e+00,
              -5.17468184018977650e-01 -2.48424635901141555e-01 0.00000000000000000e+00,
              -5.26114824625978583e-01 -2.53745645505449813e-01 0.00000000000000000e+00,
              -5.36091717634056497e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -5.46401173742403823e-01 -2.53745645505449813e-01 0.00000000000000000e+00,
              -5.54715251249135455e-01 -2.48424635901141555e-01 0.00000000000000000e+00,
              -5.60701387053982203e-01 -2.40443121494679196e-01 0.00000000000000000e+00,
              -5.62364202555328485e-01 -2.30798791586870472e-01 0.00000000000000000e+00,
              -5.62364202555328485e-01 1.67611802535710008e-01 0.00000000000000000e+00,
              -6.63795948137454683e-01 6.45172414522375837e-02 0.00000000000000000e+00,
              -6.72110025644186315e-01 5.85311056473907798e-02 0.00000000000000000e+00,
              -6.82086918652264229e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -6.92396374760611444e-01 5.85311056473907798e-02 0.00000000000000000e+00,
              -7.01043015367612377e-01 6.38521152516990376e-02 0.00000000000000000e+00,
              -7.07029151172459125e-01 7.15010665578921234e-02 0.00000000000000000e+00,
              -7.09024529774074708e-01 8.11453964657008475e-02 0.00000000000000000e+00,
              -7.07694277372997727e-01 9.04571632732402708e-02 0.00000000000000000e+00,
              -7.02040704668420279e-01 9.87712407799719583e-02 0.00000000000000000e+00,
              -1.75593316942172284e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -2.46761820399795107e-01 2.00868112562636481e-01 0.00000000000000000e+00,
              -2.90660149635338261e-01 1.92554035055904904e-01 0.00000000000000000e+00,
              -3.18928013158225832e-01 1.77921258644057223e-01 0.00000000000000000e+00,
              -3.42207430177074445e-01 1.56637220226824192e-01 0.00000000000000000e+00,
              -3.60498400691884102e-01 1.28701919804205811e-01 0.00000000000000000e+00,
              -3.73135798502116200e-01 9.34502311756637005e-02 0.00000000000000000e+00,
              -3.80784749808309231e-01 5.05495912409283932e-02 0.00000000000000000e+00,
              -3.83112691510194114e-01 -3.32563100269245293e-04 0.00000000000000000e+00,
              -3.80784749808309231e-01 -5.08821543411976940e-02 0.00000000000000000e+00,
              -3.73135798502116200e-01 -9.37827942759329736e-02 0.00000000000000000e+00,
              -3.60498400691884102e-01 -1.29034482904475140e-01 0.00000000000000000e+00,
              -3.42207430177074445e-01 -1.56969783327093465e-01 0.00000000000000000e+00,
              -3.18928013158225832e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -2.90660149635338261e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -2.57403839608411622e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -2.17828830676369045e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.78586384844595547e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -1.44997511717399830e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -1.17062211294781449e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -9.37827942759328348e-02 -1.56969783327093465e-01 0.00000000000000000e+00,
              -7.54918237611232890e-02 -1.29034482904475140e-01 0.00000000000000000e+00,
              -6.28544259508911907e-02 -9.37827942759329736e-02 0.00000000000000000e+00,
              -5.52054746446979383e-02 -5.08821543411976940e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 -3.32563100269245293e-04 0.00000000000000000e+00,
              -5.52054746446979383e-02 5.05495912409283932e-02 0.00000000000000000e+00,
              -6.28544259508911907e-02 9.34502311756637005e-02 0.00000000000000000e+00,
              -7.54918237611232890e-02 1.28701919804205811e-01 0.00000000000000000e+00,
              -9.37827942759328348e-02 1.56637220226824192e-01 0.00000000000000000e+00,
              -1.24711162600974701e-01 1.80581763446211352e-01 0.00000000000000000e+00,
              -1.62955919131940297e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.56073587207334752e-01 -2.51085140703295684e-01 0.00000000000000000e+00,
              -3.14604692854725476e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -3.52516886285421660e-01 -2.16831141375561309e-01 0.00000000000000000e+00,
              -3.82780128409924814e-01 -1.87565588551865947e-01 0.00000000000000000e+00,
              -4.06059545428773538e-01 -1.51316210622515934e-01 0.00000000000000000e+00,
              -4.22355137341967501e-01 -1.07750444487242136e-01 0.00000000000000000e+00,
              -4.32332030350045415e-01 -5.72008532463137154e-02 0.00000000000000000e+00,
              -4.35657661352738201e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.32332030350045415e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -4.22355137341967501e-01 1.07417881386972836e-01 0.00000000000000000e+00,
              -4.06059545428773538e-01 1.50983647522246633e-01 0.00000000000000000e+00,
              -3.82780128409924814e-01 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.52516886285421660e-01 2.16831141375561254e-01 0.00000000000000000e+00,
              -3.14604692854725476e-01 2.38115179792794285e-01 0.00000000000000000e+00,
              -2.69708674318374531e-01 2.51085140703295684e-01 0.00000000000000000e+00,
              -2.17828830676369045e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.76258443142710886e-01 2.52415393104372776e-01 0.00000000000000000e+00,
              -1.38346249712014480e-01 2.44101315597641089e-01 0.00000000000000000e+00,
              -1.05089939685087952e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -7.68220761622002701e-02 2.11510131771252996e-01 0.00000000000000000e+00,
              -5.35426591433516563e-02 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.39214362274649073e-02 1.58965161928709020e-01 0.00000000000000000e+00,
              -1.89560967153481474e-02 1.25376288801513192e-01 0.00000000000000000e+00,
              -8.31407750673163193e-03 8.77966584710861420e-02 0.00000000000000000e+00,
              -2.32794170188466154e-03 4.58937078371586815e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.32563100269256395e-03 -5.72008532463137154e-02 0.00000000000000000e+00,
              -1.33025240107704779e-02 -1.07750444487242136e-01 0.00000000000000000e+00,
              -2.99306790242337417e-02 -1.51316210622515934e-01 0.00000000000000000e+00,
              -5.32100960430825776e-02 -1.87565588551865947e-01 0.00000000000000000e+00,
              -9.41153573762021356e-02 -2.21154461679061776e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99588575103220251e+02 5.14997228640831054e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            15 14 16 -1 
            16 14 17 -1 
            17 14 18 -1 
            12 11 13 -1 
            11 10 13 -1 
            10 9 13 -1 
            9 8 13 -1 
            24 23 25 -1 
            23 22 25 -1 
            22 21 25 -1 
            25 21 26 -1 
            21 20 26 -1 
            26 20 27 -1 
            20 19 27 -1 
            27 19 28 -1 
            19 18 28 -1 
            18 14 28 -1 
            14 13 28 -1 
            28 13 29 -1 
            13 8 29 -1 
            29 8 30 -1 
            30 8 31 -1 
            8 7 31 -1 
            31 7 32 -1 
            7 6 32 -1 
            32 6 33 -1 
            6 5 33 -1 
            33 5 34 -1 
            34 5 35 -1 
            35 5 36 -1 
            36 5 37 -1 
            55 54 56 -1 
            56 54 57 -1 
            57 54 58 -1 
            58 54 59 -1 
            54 53 59 -1 
            59 53 60 -1 
            60 53 61 -1 
            61 53 62 -1 
            62 53 63 -1 
            63 53 64 -1 
            64 53 65 -1 
            53 52 65 -1 
            52 51 65 -1 
            51 50 65 -1 
            50 49 65 -1 
            49 48 65 -1 
            48 47 65 -1 
            39 38 40 -1 
            38 37 40 -1 
            40 37 41 -1 
            41 37 42 -1 
            42 37 43 -1 
            43 37 44 -1 
            44 37 45 -1 
            46 45 47 -1 
            5 4 37 -1 
            4 3 37 -1 
            3 2 37 -1 
            2 1 37 -1 
            1 0 37 -1 
            0 79 37 -1 
            66 65 67 -1 
            37 79 45 -1 
            79 78 45 -1 
            78 77 45 -1 
            45 77 47 -1 
            77 76 47 -1 
            76 75 47 -1 
            75 74 47 -1 
            74 73 47 -1 
            73 72 47 -1 
            72 71 47 -1 
            71 70 47 -1 
            67 65 68 -1 
            65 47 68 -1 
            68 47 69 -1 
            70 69 47 -1 
            94 93 95 -1 
            93 92 95 -1 
            92 91 95 -1 
            91 90 95 -1 
            90 89 95 -1 
            89 88 95 -1 
            88 87 95 -1 
            95 87 96 -1 
            87 86 96 -1 
            85 84 86 -1 
            86 84 96 -1 
            104 103 105 -1 
            103 102 105 -1 
            102 101 105 -1 
            101 100 105 -1 
            100 99 105 -1 
            99 98 105 -1 
            105 98 80 -1 
            81 80 82 -1 
            82 80 83 -1 
            83 80 84 -1 
            84 80 96 -1 
            96 80 97 -1 
            80 98 97 -1 
            121 120 122 -1 
            122 120 123 -1 
            123 120 124 -1 
            118 117 119 -1 
            117 116 119 -1 
            116 115 119 -1 
            115 114 119 -1 
            130 129 131 -1 
            129 128 131 -1 
            128 127 131 -1 
            131 127 132 -1 
            127 126 132 -1 
            132 126 133 -1 
            126 125 133 -1 
            133 125 134 -1 
            125 124 134 -1 
            124 120 134 -1 
            120 119 134 -1 
            134 119 135 -1 
            119 114 135 -1 
            135 114 136 -1 
            136 114 137 -1 
            114 113 137 -1 
            137 113 138 -1 
            113 112 138 -1 
            138 112 139 -1 
            112 111 139 -1 
            139 111 140 -1 
            140 111 141 -1 
            141 111 142 -1 
            142 111 143 -1 
            161 160 162 -1 
            162 160 163 -1 
            163 160 164 -1 
            164 160 165 -1 
            160 159 165 -1 
            165 159 166 -1 
            166 159 167 -1 
            167 159 168 -1 
            168 159 169 -1 
            169 159 170 -1 
            170 159 171 -1 
            159 158 171 -1 
            158 157 171 -1 
            157 156 171 -1 
            156 155 171 -1 
            155 154 171 -1 
            154 153 171 -1 
            145 144 146 -1 
            144 143 146 -1 
            146 143 147 -1 
            147 143 148 -1 
            148 143 149 -1 
            149 143 150 -1 
            150 143 151 -1 
            152 151 153 -1 
            111 110 143 -1 
            110 109 143 -1 
            109 108 143 -1 
            108 107 143 -1 
            107 106 143 -1 
            106 185 143 -1 
            172 171 173 -1 
            143 185 151 -1 
            185 184 151 -1 
            184 183 151 -1 
            151 183 153 -1 
            183 182 153 -1 
            182 181 153 -1 
            181 180 153 -1 
            180 179 153 -1 
            179 178 153 -1 
            178 177 153 -1 
            177 176 153 -1 
            173 171 174 -1 
            171 153 174 -1 
            174 153 175 -1 
            176 175 153 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0012"
              point="
              -7.67223072321196353e-01 -1.25376288801513219e-01 0.00000000000000000e+00,
              -7.69883577123350427e-01 -9.27851049751251544e-02 0.00000000000000000e+00,
              -7.76867402229004966e-01 -6.98382510565458414e-02 0.00000000000000000e+00,
              -7.88507110738429273e-01 -5.32100960430825221e-02 0.00000000000000000e+00,
              -8.03139887150277065e-01 -3.85773196312348410e-02 0.00000000000000000e+00,
              -8.21763420765355912e-01 -2.66050480215412610e-02 0.00000000000000000e+00,
              -8.44045148483396734e-01 -1.72932812140018100e-02 0.00000000000000000e+00,
              -8.68987381003591630e-01 -1.13071454091550616e-02 0.00000000000000000e+00,
              -8.95592429025132919e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.05023427065034158e+00 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.06054372675868880e+00 -7.31638820592386829e-03 0.00000000000000000e+00,
              -1.06885780426542043e+00 -1.66281550134633749e-03 0.00000000000000000e+00,
              -1.07484394007026718e+00 6.31869890511604915e-03 0.00000000000000000e+00,
              -1.07650675557161346e+00 1.59630288129247178e-02 0.00000000000000000e+00,
              -1.07650675557161346e+00 2.30466228486601199e-01 0.00000000000000000e+00,
              -1.07484394007026718e+00 2.39777995294140678e-01 0.00000000000000000e+00,
              -1.06885780426542043e+00 2.48092072800872310e-01 0.00000000000000000e+00,
              -1.06054372675868880e+00 2.53413082405180567e-01 0.00000000000000000e+00,
              -1.05023427065034158e+00 2.55408461006796150e-01 0.00000000000000000e+00,
              -7.40950587399924254e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -7.31306257492115641e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -7.22659616885114708e-01 2.47759509700603009e-01 0.00000000000000000e+00,
              -7.16673481080267960e-01 2.39112869093602132e-01 0.00000000000000000e+00,
              -7.14678102478652266e-01 2.29468539185793352e-01 0.00000000000000000e+00,
              -7.16673481080267960e-01 2.19491646177715383e-01 0.00000000000000000e+00,
              -7.22659616885114708e-01 2.11177568670983806e-01 0.00000000000000000e+00,
              -7.31306257492115641e-01 2.05523995966406303e-01 0.00000000000000000e+00,
              -7.40950587399924254e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.02396178572906948e+00 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.02396178572906948e+00 4.25680768344660065e-02 0.00000000000000000e+00,
              -8.95592429025132919e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -8.59343051095782906e-01 3.95750089320426324e-02 0.00000000000000000e+00,
              -8.25089051768048476e-01 3.09283683250417551e-02 0.00000000000000000e+00,
              -7.93828120342737531e-01 1.72932812140018100e-02 0.00000000000000000e+00,
              -7.67223072321196353e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -7.45606470803694021e-01 -2.19491646177715771e-02 0.00000000000000000e+00,
              -7.28978315790230758e-01 -4.75565233385050190e-02 0.00000000000000000e+00,
              -7.18336296581614242e-01 -7.61569499616618906e-02 0.00000000000000000e+00,
              -7.14678102478652266e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -7.18336296581614242e-01 -1.36683434210668253e-01 0.00000000000000000e+00,
              -7.28978315790230758e-01 -1.65283860833825125e-01 0.00000000000000000e+00,
              -7.45606470803694021e-01 -1.91223782654827867e-01 0.00000000000000000e+00,
              -7.67223072321196353e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -7.93828120342737531e-01 -2.30466228486601199e-01 0.00000000000000000e+00,
              -8.25089051768048476e-01 -2.44101315597641116e-01 0.00000000000000000e+00,
              -8.59343051095782906e-01 -2.52747956204642021e-01 0.00000000000000000e+00,
              -8.95592429025132919e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -9.42483826163099447e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -9.90040349501604355e-01 -2.40110558394409923e-01 0.00000000000000000e+00,
              -1.03260842633607042e+00 -2.22817277180408113e-01 0.00000000000000000e+00,
              -1.06420192086165066e+00 -2.00535549462367291e-01 0.00000000000000000e+00,
              -1.06819267806488183e+00 -1.96877355359405370e-01 0.00000000000000000e+00,
              -1.07118574596730531e+00 -1.92886598156174177e-01 0.00000000000000000e+00,
              -1.07384625076945928e+00 -1.88230714752404465e-01 0.00000000000000000e+00,
              -1.07584162937107486e+00 -1.83242268248365481e-01 0.00000000000000000e+00,
              -1.07717188177215206e+00 -1.77588695543787950e-01 0.00000000000000000e+00,
              -1.07617419247134416e+00 -1.71602559738941174e-01 0.00000000000000000e+00,
              -1.07384625076945928e+00 -1.65948987034363643e-01 0.00000000000000000e+00,
              -1.06919036736568973e+00 -1.59962851229516867e-01 0.00000000000000000e+00,
              -1.06386935776138136e+00 -1.55639530926016401e-01 0.00000000000000000e+00,
              -1.05788322195653461e+00 -1.52979026123862272e-01 0.00000000000000000e+00,
              -1.05222964925195717e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.04724120274791810e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.04225275624387903e+00 -1.53644152324400818e-01 0.00000000000000000e+00,
              -1.03693174663957088e+00 -1.55972094026285674e-01 0.00000000000000000e+00,
              -1.03227586323580112e+00 -1.58632598828439803e-01 0.00000000000000000e+00,
              -1.02828510603256995e+00 -1.61625666730863204e-01 0.00000000000000000e+00,
              -1.02495947502987739e+00 -1.64618734633286579e-01 0.00000000000000000e+00,
              -1.02163384402718460e+00 -1.67279239435440708e-01 0.00000000000000000e+00,
              -1.01897333922503042e+00 -1.69607181137325591e-01 0.00000000000000000e+00,
              -1.01764308682395344e+00 -1.70604870438133382e-01 0.00000000000000000e+00,
              -9.95028796005643423e-01 -1.82577142047826935e-01 0.00000000000000000e+00,
              -9.62105049078986085e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -9.26520797350174563e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -8.95592429025132919e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -8.68987381003591630e-01 -2.01865801863444355e-01 0.00000000000000000e+00,
              -8.44045148483396734e-01 -1.95879666058597579e-01 0.00000000000000000e+00,
              -8.21763420765355912e-01 -1.86567899251058128e-01 0.00000000000000000e+00,
              -8.03139887150277065e-01 -1.74263064541095303e-01 0.00000000000000000e+00,
              -7.85846605936275200e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -5.02835407607129969e-01 2.47426946600333819e-01 0.00000000000000000e+00,
              -4.94521330100398337e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -4.83879310891781822e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -4.73569854783434607e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -4.64923214176433675e-01 2.48092072800872310e-01 0.00000000000000000e+00,
              -4.59269641471856116e-01 2.40110558394409868e-01 0.00000000000000000e+00,
              -4.57274262870240533e-01 2.30798791586870444e-01 0.00000000000000000e+00,
              -4.57274262870240533e-01 -2.30798791586870472e-01 0.00000000000000000e+00,
              -4.59269641471856116e-01 -2.40443121494679196e-01 0.00000000000000000e+00,
              -4.64923214176433675e-01 -2.48424635901141555e-01 0.00000000000000000e+00,
              -4.73569854783434607e-01 -2.53745645505449813e-01 0.00000000000000000e+00,
              -4.83546747791512521e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -4.93856203899859847e-01 -2.53745645505449813e-01 0.00000000000000000e+00,
              -5.02170281406591479e-01 -2.48424635901141555e-01 0.00000000000000000e+00,
              -5.08156417211438227e-01 -2.40443121494679196e-01 0.00000000000000000e+00,
              -5.09819232712784509e-01 -2.30798791586870472e-01 0.00000000000000000e+00,
              -5.09819232712784509e-01 1.67611802535710008e-01 0.00000000000000000e+00,
              -6.11250978294910707e-01 6.45172414522375837e-02 0.00000000000000000e+00,
              -6.19565055801642339e-01 5.85311056473907798e-02 0.00000000000000000e+00,
              -6.29541948809720253e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -6.39851404918067468e-01 5.85311056473907798e-02 0.00000000000000000e+00,
              -6.48498045525068401e-01 6.38521152516990376e-02 0.00000000000000000e+00,
              -6.54484181329915149e-01 7.15010665578921234e-02 0.00000000000000000e+00,
              -6.56479559931530732e-01 8.11453964657008475e-02 0.00000000000000000e+00,
              -6.55149307530453751e-01 9.04571632732402708e-02 0.00000000000000000e+00,
              -6.49495734825876303e-01 9.87712407799719583e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 -1.25376288801513219e-01 0.00000000000000000e+00,
              -5.52054746446981603e-02 -9.27851049751251544e-02 0.00000000000000000e+00,
              -6.21892997503528111e-02 -6.98382510565458414e-02 0.00000000000000000e+00,
              -7.38290082597767849e-02 -5.32100960430825221e-02 0.00000000000000000e+00,
              -8.84617846716246881e-02 -3.85773196312348410e-02 0.00000000000000000e+00,
              -1.07085318286703535e-01 -2.66050480215412610e-02 0.00000000000000000e+00,
              -1.29367046004744357e-01 -1.72932812140018100e-02 0.00000000000000000e+00,
              -1.54309278524939253e-01 -1.13071454091550616e-02 0.00000000000000000e+00,
              -1.80914326546480542e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.35556168171689095e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.45865624280036421e-01 -7.31638820592386829e-03 0.00000000000000000e+00,
              -3.54179701786768053e-01 -1.66281550134633749e-03 0.00000000000000000e+00,
              -3.60165837591614801e-01 6.31869890511604915e-03 0.00000000000000000e+00,
              -3.61828653092961194e-01 1.59630288129247178e-02 0.00000000000000000e+00,
              -3.61828653092961194e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -3.60165837591614801e-01 2.39777995294140678e-01 0.00000000000000000e+00,
              -3.54179701786768053e-01 2.48092072800872310e-01 0.00000000000000000e+00,
              -3.45865624280036421e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -3.35556168171689095e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -2.62724849212720990e-02 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.66281550134632639e-02 2.53413082405180567e-01 0.00000000000000000e+00,
              -7.98151440646233112e-03 2.47759509700603009e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.39112869093602132e-01 0.00000000000000000e+00,
              0.00000000000000000e+00 2.29468539185793352e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.19491646177715383e-01 0.00000000000000000e+00,
              -7.98151440646233112e-03 2.11177568670983806e-01 0.00000000000000000e+00,
              -1.66281550134632639e-02 2.05523995966406303e-01 0.00000000000000000e+00,
              -2.62724849212720990e-02 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417218e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417218e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.80914326546480542e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.44664948617130640e-01 3.95750089320426324e-02 0.00000000000000000e+00,
              -1.10410949289396321e-01 3.09283683250417551e-02 0.00000000000000000e+00,
              -7.91500178640853758e-02 1.72932812140018100e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.09283683250416441e-02 -2.19491646177715771e-02 0.00000000000000000e+00,
              -1.43002133115783803e-02 -4.75565233385050190e-02 0.00000000000000000e+00,
              -3.65819410296186476e-03 -7.61569499616618906e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 -1.06420192086165072e-01 0.00000000000000000e+00,
              -3.65819410296186476e-03 -1.36683434210668253e-01 0.00000000000000000e+00,
              -1.43002133115783803e-02 -1.65283860833825125e-01 0.00000000000000000e+00,
              -3.09283683250416441e-02 -1.91223782654827867e-01 0.00000000000000000e+00,
              -5.25449698425439760e-02 -2.12840384172330144e-01 0.00000000000000000e+00,
              -7.91500178640853758e-02 -2.30466228486601199e-01 0.00000000000000000e+00,
              -1.10410949289396321e-01 -2.44101315597641116e-01 0.00000000000000000e+00,
              -1.44664948617130640e-01 -2.52747956204642021e-01 0.00000000000000000e+00,
              -1.80914326546480542e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.27805723684447070e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -2.75362247022952089e-01 -2.40110558394409923e-01 0.00000000000000000e+00,
              -3.17930323857418040e-01 -2.22817277180408113e-01 0.00000000000000000e+00,
              -3.49523818382998286e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -3.53514575586229562e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -3.56507643488652937e-01 -1.92886598156174177e-01 0.00000000000000000e+00,
              -3.59168148290807010e-01 -1.88230714752404465e-01 0.00000000000000000e+00,
              -3.61163526892422704e-01 -1.83242268248365481e-01 0.00000000000000000e+00,
              -3.62493779293499685e-01 -1.77588695543787950e-01 0.00000000000000000e+00,
              -3.61496089992691894e-01 -1.71602559738941174e-01 0.00000000000000000e+00,
              -3.59168148290807010e-01 -1.65948987034363643e-01 0.00000000000000000e+00,
              -3.54512264887037354e-01 -1.59962851229516867e-01 0.00000000000000000e+00,
              -3.49191255282729096e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -3.43205119477882348e-01 -1.52979026123862272e-01 0.00000000000000000e+00,
              -3.37551546773304789e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.32563100269265832e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.27574653765226875e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -3.22253644160918618e-01 -1.55972094026285674e-01 0.00000000000000000e+00,
              -3.17597760757148739e-01 -1.58632598828439803e-01 0.00000000000000000e+00,
              -3.13607003553917685e-01 -1.61625666730863204e-01 0.00000000000000000e+00,
              -3.10281372551225010e-01 -1.64618734633286579e-01 0.00000000000000000e+00,
              -3.06955741548532335e-01 -1.67279239435440708e-01 0.00000000000000000e+00,
              -3.04295236746378150e-01 -1.69607181137325591e-01 0.00000000000000000e+00,
              -3.02964984345301169e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -2.80350693526991046e-01 -1.82577142047826935e-01 0.00000000000000000e+00,
              -2.47426946600333708e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -2.11842694871522297e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -1.80914326546480542e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.54309278524939253e-01 -2.01865801863444355e-01 0.00000000000000000e+00,
              -1.29367046004744357e-01 -1.95879666058597579e-01 0.00000000000000000e+00,
              -1.07085318286703535e-01 -1.86567899251058128e-01 0.00000000000000000e+00,
              -8.84617846716246881e-02 -1.74263064541095303e-01 0.00000000000000000e+00,
              -7.11685034576228226e-02 -1.55639530926016401e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99588575103220251e+02 5.19997228640831054e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            15 14 16 -1 
            16 14 17 -1 
            17 14 18 -1 
            12 11 13 -1 
            11 10 13 -1 
            10 9 13 -1 
            9 8 13 -1 
            24 23 25 -1 
            23 22 25 -1 
            22 21 25 -1 
            25 21 26 -1 
            21 20 26 -1 
            26 20 27 -1 
            20 19 27 -1 
            27 19 28 -1 
            19 18 28 -1 
            18 14 28 -1 
            14 13 28 -1 
            28 13 29 -1 
            13 8 29 -1 
            29 8 30 -1 
            30 8 31 -1 
            8 7 31 -1 
            31 7 32 -1 
            7 6 32 -1 
            32 6 33 -1 
            6 5 33 -1 
            33 5 34 -1 
            34 5 35 -1 
            35 5 36 -1 
            36 5 37 -1 
            55 54 56 -1 
            56 54 57 -1 
            57 54 58 -1 
            58 54 59 -1 
            54 53 59 -1 
            59 53 60 -1 
            60 53 61 -1 
            61 53 62 -1 
            62 53 63 -1 
            63 53 64 -1 
            64 53 65 -1 
            53 52 65 -1 
            52 51 65 -1 
            51 50 65 -1 
            50 49 65 -1 
            49 48 65 -1 
            48 47 65 -1 
            39 38 40 -1 
            38 37 40 -1 
            40 37 41 -1 
            41 37 42 -1 
            42 37 43 -1 
            43 37 44 -1 
            44 37 45 -1 
            46 45 47 -1 
            5 4 37 -1 
            4 3 37 -1 
            3 2 37 -1 
            2 1 37 -1 
            1 0 37 -1 
            0 79 37 -1 
            66 65 67 -1 
            37 79 45 -1 
            79 78 45 -1 
            78 77 45 -1 
            45 77 47 -1 
            77 76 47 -1 
            76 75 47 -1 
            75 74 47 -1 
            74 73 47 -1 
            73 72 47 -1 
            72 71 47 -1 
            71 70 47 -1 
            67 65 68 -1 
            65 47 68 -1 
            68 47 69 -1 
            70 69 47 -1 
            83 82 84 -1 
            82 81 84 -1 
            81 80 84 -1 
            84 80 85 -1 
            85 80 86 -1 
            80 137 86 -1 
            110 109 111 -1 
            109 108 111 -1 
            111 108 112 -1 
            112 108 113 -1 
            134 133 135 -1 
            133 132 135 -1 
            132 131 135 -1 
            131 130 135 -1 
            130 129 135 -1 
            135 129 136 -1 
            136 129 137 -1 
            129 128 137 -1 
            106 105 107 -1 
            107 105 108 -1 
            108 105 113 -1 
            113 105 114 -1 
            114 105 115 -1 
            104 103 105 -1 
            115 105 116 -1 
            122 121 123 -1 
            121 120 123 -1 
            120 119 123 -1 
            119 118 123 -1 
            118 117 123 -1 
            117 116 123 -1 
            123 116 124 -1 
            137 128 86 -1 
            86 128 87 -1 
            128 127 87 -1 
            127 126 87 -1 
            126 125 87 -1 
            125 124 87 -1 
            87 124 88 -1 
            88 124 89 -1 
            89 124 90 -1 
            90 124 91 -1 
            91 124 92 -1 
            92 124 93 -1 
            103 102 105 -1 
            93 124 94 -1 
            124 116 94 -1 
            94 116 95 -1 
            95 116 96 -1 
            96 116 97 -1 
            97 116 98 -1 
            98 116 99 -1 
            99 116 100 -1 
            100 116 101 -1 
            101 116 102 -1 
            116 105 102 -1 
            185 138 186 -1 
            138 167 186 -1 
            153 169 154 -1 
            169 168 154 -1 
            186 167 187 -1 
            187 167 188 -1 
            167 166 188 -1 
            188 166 189 -1 
            189 166 190 -1 
            166 165 190 -1 
            190 165 191 -1 
            165 164 191 -1 
            191 164 192 -1 
            164 163 192 -1 
            192 163 193 -1 
            163 162 193 -1 
            193 162 194 -1 
            194 162 195 -1 
            162 161 195 -1 
            195 161 196 -1 
            161 160 196 -1 
            196 160 197 -1 
            160 159 197 -1 
            197 159 198 -1 
            159 158 198 -1 
            198 158 199 -1 
            154 168 155 -1 
            168 199 155 -1 
            158 157 199 -1 
            157 156 199 -1 
            199 156 155 -1 
            169 153 170 -1 
            185 184 138 -1 
            139 138 184 -1 
            184 183 139 -1 
            139 183 140 -1 
            183 182 140 -1 
            182 181 140 -1 
            153 152 170 -1 
            170 152 171 -1 
            140 181 141 -1 
            181 180 141 -1 
            141 180 142 -1 
            180 179 142 -1 
            142 179 143 -1 
            179 178 143 -1 
            143 178 144 -1 
            178 177 144 -1 
            144 177 145 -1 
            177 176 145 -1 
            145 176 146 -1 
            176 175 146 -1 
            146 175 147 -1 
            175 174 147 -1 
            147 174 148 -1 
            174 173 148 -1 
            148 173 149 -1 
            173 172 149 -1 
            149 172 150 -1 
            172 171 150 -1 
            150 171 151 -1 
            171 152 151 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0013"
              point="
              -9.82391398195411325e-01 -1.25376288801513219e-01 0.00000000000000000e+00,
              -9.85051902997565398e-01 -9.27851049751251544e-02 0.00000000000000000e+00,
              -9.92035728103219938e-01 -6.98382510565458414e-02 0.00000000000000000e+00,
              -1.00367543661264413e+00 -5.32100960430825221e-02 0.00000000000000000e+00,
              -1.01830821302449204e+00 -3.85773196312348410e-02 0.00000000000000000e+00,
              -1.03693174663957088e+00 -2.66050480215412610e-02 0.00000000000000000e+00,
              -1.05921347435761160e+00 -1.72932812140018100e-02 0.00000000000000000e+00,
              -1.08415570687780649e+00 -1.13071454091550616e-02 0.00000000000000000e+00,
              -1.11076075489934789e+00 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.26540259652455656e+00 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.27571205263290377e+00 -7.31638820592386829e-03 0.00000000000000000e+00,
              -1.28402613013963540e+00 -1.66281550134633749e-03 0.00000000000000000e+00,
              -1.29001226594448215e+00 6.31869890511604915e-03 0.00000000000000000e+00,
              -1.29167508144582843e+00 1.59630288129247178e-02 0.00000000000000000e+00,
              -1.29167508144582843e+00 2.30466228486601199e-01 0.00000000000000000e+00,
              -1.29001226594448215e+00 2.39777995294140678e-01 0.00000000000000000e+00,
              -1.28402613013963540e+00 2.48092072800872310e-01 0.00000000000000000e+00,
              -1.27571205263290377e+00 2.53413082405180567e-01 0.00000000000000000e+00,
              -1.26540259652455656e+00 2.55408461006796150e-01 0.00000000000000000e+00,
              -9.56118913274139226e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -9.46474583366330613e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -9.37827942759329680e-01 2.47759509700603009e-01 0.00000000000000000e+00,
              -9.31841806954482932e-01 2.39112869093602132e-01 0.00000000000000000e+00,
              -9.29846428352867238e-01 2.29468539185793352e-01 0.00000000000000000e+00,
              -9.31841806954482932e-01 2.19491646177715383e-01 0.00000000000000000e+00,
              -9.37827942759329680e-01 2.11177568670983806e-01 0.00000000000000000e+00,
              -9.46474583366330613e-01 2.05523995966406303e-01 0.00000000000000000e+00,
              -9.56118913274139226e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.23913011160328446e+00 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.23913011160328446e+00 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.11076075489934789e+00 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.07451137696999788e+00 3.95750089320426324e-02 0.00000000000000000e+00,
              -1.04025737764226345e+00 3.09283683250417551e-02 0.00000000000000000e+00,
              -1.00899644621695250e+00 1.72932812140018100e-02 0.00000000000000000e+00,
              -9.82391398195411325e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -9.60774796677908993e-01 -2.19491646177715771e-02 0.00000000000000000e+00,
              -9.44146641664445729e-01 -4.75565233385050190e-02 0.00000000000000000e+00,
              -9.33504622455829214e-01 -7.61569499616618906e-02 0.00000000000000000e+00,
              -9.29846428352867238e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -9.33504622455829214e-01 -1.36683434210668253e-01 0.00000000000000000e+00,
              -9.44146641664445729e-01 -1.65283860833825125e-01 0.00000000000000000e+00,
              -9.60774796677908993e-01 -1.91223782654827867e-01 0.00000000000000000e+00,
              -9.82391398195411325e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -1.00899644621695250e+00 -2.30466228486601199e-01 0.00000000000000000e+00,
              -1.04025737764226345e+00 -2.44101315597641116e-01 0.00000000000000000e+00,
              -1.07451137696999788e+00 -2.52747956204642021e-01 0.00000000000000000e+00,
              -1.11076075489934789e+00 -2.55408461006796150e-01 0.00000000000000000e+00,
              -1.15765215203731442e+00 -2.51750266903834230e-01 0.00000000000000000e+00,
              -1.20520867537581933e+00 -2.40110558394409923e-01 0.00000000000000000e+00,
              -1.24777675221028539e+00 -2.22817277180408113e-01 0.00000000000000000e+00,
              -1.27937024673586563e+00 -2.00535549462367291e-01 0.00000000000000000e+00,
              -1.28336100393909680e+00 -1.96877355359405370e-01 0.00000000000000000e+00,
              -1.28635407184152029e+00 -1.92886598156174177e-01 0.00000000000000000e+00,
              -1.28901457664367425e+00 -1.88230714752404465e-01 0.00000000000000000e+00,
              -1.29100995524528983e+00 -1.83242268248365481e-01 0.00000000000000000e+00,
              -1.29234020764636703e+00 -1.77588695543787950e-01 0.00000000000000000e+00,
              -1.29134251834555913e+00 -1.71602559738941174e-01 0.00000000000000000e+00,
              -1.28901457664367425e+00 -1.65948987034363643e-01 0.00000000000000000e+00,
              -1.28435869323990470e+00 -1.59962851229516867e-01 0.00000000000000000e+00,
              -1.27903768363559633e+00 -1.55639530926016401e-01 0.00000000000000000e+00,
              -1.27305154783074959e+00 -1.52979026123862272e-01 0.00000000000000000e+00,
              -1.26739797512617214e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.26240952862213307e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.25742108211809400e+00 -1.53644152324400818e-01 0.00000000000000000e+00,
              -1.25210007251378586e+00 -1.55972094026285674e-01 0.00000000000000000e+00,
              -1.24744418911001609e+00 -1.58632598828439803e-01 0.00000000000000000e+00,
              -1.24345343190678492e+00 -1.61625666730863204e-01 0.00000000000000000e+00,
              -1.24012780090409236e+00 -1.64618734633286579e-01 0.00000000000000000e+00,
              -1.23680216990139957e+00 -1.67279239435440708e-01 0.00000000000000000e+00,
              -1.23414166509924539e+00 -1.69607181137325591e-01 0.00000000000000000e+00,
              -1.23281141269816841e+00 -1.70604870438133382e-01 0.00000000000000000e+00,
              -1.21019712187985840e+00 -1.82577142047826935e-01 0.00000000000000000e+00,
              -1.17727337495320095e+00 -1.93551724356712695e-01 0.00000000000000000e+00,
              -1.14168912322438953e+00 -2.01533238763175082e-01 0.00000000000000000e+00,
              -1.11076075489934789e+00 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.08415570687780649e+00 -2.01865801863444355e-01 0.00000000000000000e+00,
              -1.05921347435761160e+00 -1.95879666058597579e-01 0.00000000000000000e+00,
              -1.03693174663957088e+00 -1.86567899251058128e-01 0.00000000000000000e+00,
              -1.01830821302449204e+00 -1.74263064541095303e-01 0.00000000000000000e+00,
              -1.00101493181049017e+00 -1.55639530926016401e-01 0.00000000000000000e+00,
              -8.47703342586358599e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -8.58345361794975115e-01 -2.53745645505449813e-01 0.00000000000000000e+00,
              -8.66659439301706858e-01 -2.48424635901141555e-01 0.00000000000000000e+00,
              -8.72313012006284305e-01 -2.40775684594948441e-01 0.00000000000000000e+00,
              -8.73975827507630587e-01 -2.30798791586870472e-01 0.00000000000000000e+00,
              -8.72645575106553606e-01 -2.22152150979869567e-01 0.00000000000000000e+00,
              -8.67657128602514538e-01 -2.14835762773945727e-01 0.00000000000000000e+00,
              -5.82650551671753836e-01 7.98151440646237553e-02 0.00000000000000000e+00,
              -5.76664415866907087e-01 8.84617846716246881e-02 0.00000000000000000e+00,
              -5.70678280062060228e-01 1.00766619381587541e-01 0.00000000000000000e+00,
              -5.66354959758559762e-01 1.13071454091550394e-01 0.00000000000000000e+00,
              -5.64692144257213369e-01 1.22383220899089817e-01 0.00000000000000000e+00,
              -5.67020085959098363e-01 1.37015997310937498e-01 0.00000000000000000e+00,
              -5.74003911064752792e-01 1.51648773722785235e-01 0.00000000000000000e+00,
              -5.84978493373638608e-01 1.65283860833825069e-01 0.00000000000000000e+00,
              -5.99278706685217100e-01 1.77588695543787922e-01 0.00000000000000000e+00,
              -6.17569677200026756e-01 1.88230714752404382e-01 0.00000000000000000e+00,
              -6.39851404918067468e-01 1.96544792259136070e-01 0.00000000000000000e+00,
              -6.65458763638800965e-01 2.01865801863444327e-01 0.00000000000000000e+00,
              -6.92728937860880745e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -7.30308568191307739e-01 2.00535549462367291e-01 0.00000000000000000e+00,
              -7.70881266424158218e-01 1.91556345755097113e-01 0.00000000000000000e+00,
              -8.07463207453777532e-01 1.78586384844595714e-01 0.00000000000000000e+00,
              -8.31075187572895335e-01 1.64951297733555879e-01 0.00000000000000000e+00,
              -8.40054391280165458e-01 1.59962851229516811e-01 0.00000000000000000e+00,
              -8.50363847388512673e-01 1.59297725028978321e-01 0.00000000000000000e+00,
              -8.60008177296321508e-01 1.61958229831132450e-01 0.00000000000000000e+00,
              -8.67989691702783839e-01 1.68276928736248499e-01 0.00000000000000000e+00,
              -8.72978138206822907e-01 1.77256132443518677e-01 0.00000000000000000e+00,
              -8.73643264407361286e-01 1.87233025451596646e-01 0.00000000000000000e+00,
              -8.70982759605207213e-01 1.96877355359405370e-01 0.00000000000000000e+00,
              -8.64331497599821974e-01 2.04858869765867757e-01 0.00000000000000000e+00,
              -8.31407750673164636e-01 2.25145218882292941e-01 0.00000000000000000e+00,
              -7.88174547638159972e-01 2.41440810795486960e-01 0.00000000000000000e+00,
              -7.40285461199385653e-01 2.51750266903834174e-01 0.00000000000000000e+00,
              -6.92728937860880745e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -6.57477249232338523e-01 2.52747956204642077e-01 0.00000000000000000e+00,
              -6.23555813004873505e-01 2.45764131098987426e-01 0.00000000000000000e+00,
              -5.92960007780101050e-01 2.34124422589563175e-01 0.00000000000000000e+00,
              -5.66022396658290572e-01 2.19159083077446137e-01 0.00000000000000000e+00,
              -5.44073232040518939e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -5.26779950826517074e-01 1.77588695543787922e-01 0.00000000000000000e+00,
              -5.15805368517631369e-01 1.51981336823054480e-01 0.00000000000000000e+00,
              -5.12147174414669393e-01 1.24711162600974701e-01 0.00000000000000000e+00,
              -5.14475116116554387e-01 1.05089939685088007e-01 0.00000000000000000e+00,
              -5.20793815021670325e-01 8.51361536689320131e-02 0.00000000000000000e+00,
              -5.30438144929479161e-01 6.58474938533146203e-02 0.00000000000000000e+00,
              -5.42742979639441847e-01 4.98844650403898471e-02 0.00000000000000000e+00,
              -7.90502489340044856e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -5.38419659335941381e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -5.28442766327863467e-01 -2.05856559066675548e-01 0.00000000000000000e+00,
              -5.19796125720862645e-01 -2.11510131771253052e-01 0.00000000000000000e+00,
              -5.14142553016285087e-01 -2.20156772378253984e-01 0.00000000000000000e+00,
              -5.12147174414669393e-01 -2.29801102286062681e-01 0.00000000000000000e+00,
              -5.14142553016285087e-01 -2.39445432193871377e-01 0.00000000000000000e+00,
              -5.20128688821131835e-01 -2.47759509700603037e-01 0.00000000000000000e+00,
              -5.28775329428132768e-01 -2.53745645505449813e-01 0.00000000000000000e+00,
              -5.38419659335941381e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -1.75593316942172395e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -2.46761820399795218e-01 2.00868112562636481e-01 0.00000000000000000e+00,
              -2.90660149635338483e-01 1.92554035055904904e-01 0.00000000000000000e+00,
              -3.18928013158226165e-01 1.77921258644057223e-01 0.00000000000000000e+00,
              -3.42207430177074667e-01 1.56637220226824192e-01 0.00000000000000000e+00,
              -3.60498400691884324e-01 1.28701919804205811e-01 0.00000000000000000e+00,
              -3.73135798502116312e-01 9.34502311756637005e-02 0.00000000000000000e+00,
              -3.80784749808309564e-01 5.05495912409283932e-02 0.00000000000000000e+00,
              -3.83112691510194336e-01 -3.32563100269245293e-04 0.00000000000000000e+00,
              -3.80784749808309564e-01 -5.08821543411976940e-02 0.00000000000000000e+00,
              -3.73135798502116312e-01 -9.37827942759329736e-02 0.00000000000000000e+00,
              -3.60498400691884324e-01 -1.29034482904475140e-01 0.00000000000000000e+00,
              -3.42207430177074667e-01 -1.56969783327093465e-01 0.00000000000000000e+00,
              -3.18928013158226165e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -2.90660149635338483e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -2.57403839608411733e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -2.17828830676369156e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.78586384844595658e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -1.44997511717400052e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -1.17062211294781671e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -9.37827942759330568e-02 -1.56969783327093465e-01 0.00000000000000000e+00,
              -7.54918237611232890e-02 -1.29034482904475140e-01 0.00000000000000000e+00,
              -6.28544259508914127e-02 -9.37827942759329736e-02 0.00000000000000000e+00,
              -5.52054746446981603e-02 -5.08821543411976940e-02 0.00000000000000000e+00,
              -5.25449698425441980e-02 -3.32563100269245293e-04 0.00000000000000000e+00,
              -5.52054746446981603e-02 5.05495912409283932e-02 0.00000000000000000e+00,
              -6.28544259508914127e-02 9.34502311756637005e-02 0.00000000000000000e+00,
              -7.54918237611232890e-02 1.28701919804205811e-01 0.00000000000000000e+00,
              -9.37827942759330568e-02 1.56637220226824192e-01 0.00000000000000000e+00,
              -1.24711162600974701e-01 1.80581763446211352e-01 0.00000000000000000e+00,
              -1.62955919131940519e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.56073587207334752e-01 -2.51085140703295684e-01 0.00000000000000000e+00,
              -3.14604692854725476e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -3.52516886285421882e-01 -2.16831141375561309e-01 0.00000000000000000e+00,
              -3.82780128409925036e-01 -1.87565588551865947e-01 0.00000000000000000e+00,
              -4.06059545428773760e-01 -1.51316210622515934e-01 0.00000000000000000e+00,
              -4.22355137341967724e-01 -1.07750444487242136e-01 0.00000000000000000e+00,
              -4.32332030350045748e-01 -5.72008532463137154e-02 0.00000000000000000e+00,
              -4.35657661352738312e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.32332030350045748e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -4.22355137341967724e-01 1.07417881386972836e-01 0.00000000000000000e+00,
              -4.06059545428773760e-01 1.50983647522246633e-01 0.00000000000000000e+00,
              -3.82780128409925036e-01 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.52516886285421882e-01 2.16831141375561254e-01 0.00000000000000000e+00,
              -3.14604692854725476e-01 2.38115179792794285e-01 0.00000000000000000e+00,
              -2.69708674318374753e-01 2.51085140703295684e-01 0.00000000000000000e+00,
              -2.17828830676369156e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.76258443142710997e-01 2.52415393104372776e-01 0.00000000000000000e+00,
              -1.38346249712014702e-01 2.44101315597641089e-01 0.00000000000000000e+00,
              -1.05089939685088174e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -7.68220761622004922e-02 2.11510131771252996e-01 0.00000000000000000e+00,
              -5.35426591433518784e-02 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.39214362274651293e-02 1.58965161928709020e-01 0.00000000000000000e+00,
              -1.89560967153481474e-02 1.25376288801513192e-01 0.00000000000000000e+00,
              -8.31407750673185397e-03 8.77966584710861420e-02 0.00000000000000000e+00,
              -2.32794170188488359e-03 4.58937078371586815e-02 0.00000000000000000e+00,
              -2.22044604925031308e-16 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.32563100269278600e-03 -5.72008532463137154e-02 0.00000000000000000e+00,
              -1.33025240107706999e-02 -1.07750444487242136e-01 0.00000000000000000e+00,
              -2.99306790242339638e-02 -1.51316210622515934e-01 0.00000000000000000e+00,
              -5.32100960430827996e-02 -1.87565588551865947e-01 0.00000000000000000e+00,
              -9.41153573762023576e-02 -2.21154461679061776e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99792901872025652e+02 4.99792901872025652e+02 7.99972286408310964e+01"
        rotation="8.55167184663299773e-01 3.66530412295518748e-01 3.66530412295518804e-01 -1.72662016832750753e+00"
        scale="1.00000000000000022e+00 1.00000000000000022e+00 1.00000000000000022e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            40 0 41 -1 
            52 51 18 -1 
            41 0 42 -1 
            51 50 18 -1 
            42 0 43 -1 
            50 49 18 -1 
            43 0 44 -1 
            48 47 49 -1 
            47 46 49 -1 
            46 45 49 -1 
            45 44 49 -1 
            18 49 19 -1 
            19 49 20 -1 
            20 49 21 -1 
            21 49 22 -1 
            22 49 23 -1 
            23 49 24 -1 
            24 49 25 -1 
            49 44 25 -1 
            0 35 44 -1 
            35 34 44 -1 
            34 33 44 -1 
            33 32 44 -1 
            32 31 44 -1 
            31 30 44 -1 
            30 29 44 -1 
            29 28 44 -1 
            28 27 44 -1 
            27 26 44 -1 
            44 26 25 -1 
            85 84 65 -1 
            65 84 66 -1 
            1 0 40 -1 
            40 39 1 -1 
            39 38 1 -1 
            66 84 67 -1 
            12 11 72 -1 
            73 72 11 -1 
            38 37 1 -1 
            11 10 73 -1 
            10 9 73 -1 
            73 9 74 -1 
            9 8 74 -1 
            8 7 74 -1 
            74 7 75 -1 
            37 36 1 -1 
            7 6 75 -1 
            75 6 76 -1 
            6 5 76 -1 
            76 5 77 -1 
            5 4 77 -1 
            84 83 67 -1 
            67 83 68 -1 
            71 70 36 -1 
            70 69 36 -1 
            69 68 36 -1 
            77 4 78 -1 
            83 82 68 -1 
            82 81 68 -1 
            81 80 68 -1 
            80 79 68 -1 
            68 79 36 -1 
            79 78 36 -1 
            78 4 36 -1 
            4 3 36 -1 
            3 2 36 -1 
            2 1 36 -1 
            65 64 85 -1 
            72 97 12 -1 
            52 18 53 -1 
            85 64 86 -1 
            64 63 86 -1 
            53 18 54 -1 
            12 97 13 -1 
            86 63 87 -1 
            87 63 88 -1 
            63 62 88 -1 
            62 61 88 -1 
            54 18 55 -1 
            97 96 13 -1 
            13 96 14 -1 
            96 95 14 -1 
            14 95 15 -1 
            88 61 89 -1 
            61 60 89 -1 
            57 56 58 -1 
            58 56 59 -1 
            59 56 60 -1 
            18 17 55 -1 
            55 17 56 -1 
            95 94 15 -1 
            17 16 56 -1 
            16 15 56 -1 
            89 60 90 -1 
            90 60 91 -1 
            91 60 92 -1 
            92 60 93 -1 
            60 56 93 -1 
            93 56 94 -1 
            56 15 94 -1 
            145 98 146 -1 
            98 127 146 -1 
            113 129 114 -1 
            129 128 114 -1 
            146 127 147 -1 
            147 127 148 -1 
            127 126 148 -1 
            148 126 149 -1 
            149 126 150 -1 
            126 125 150 -1 
            150 125 151 -1 
            125 124 151 -1 
            151 124 152 -1 
            124 123 152 -1 
            152 123 153 -1 
            123 122 153 -1 
            153 122 154 -1 
            154 122 155 -1 
            122 121 155 -1 
            155 121 156 -1 
            121 120 156 -1 
            156 120 157 -1 
            120 119 157 -1 
            157 119 158 -1 
            119 118 158 -1 
            158 118 159 -1 
            114 128 115 -1 
            128 159 115 -1 
            118 117 159 -1 
            117 116 159 -1 
            159 116 115 -1 
            129 113 130 -1 
            145 144 98 -1 
            99 98 144 -1 
            144 143 99 -1 
            99 143 100 -1 
            143 142 100 -1 
            142 141 100 -1 
            113 112 130 -1 
            130 112 131 -1 
            100 141 101 -1 
            141 140 101 -1 
            101 140 102 -1 
            140 139 102 -1 
            102 139 103 -1 
            139 138 103 -1 
            103 138 104 -1 
            138 137 104 -1 
            104 137 105 -1 
            137 136 105 -1 
            105 136 106 -1 
            136 135 106 -1 
            106 135 107 -1 
            135 134 107 -1 
            107 134 108 -1 
            134 133 108 -1 
            108 133 109 -1 
            133 132 109 -1 
            109 132 110 -1 
            132 131 110 -1 
            110 131 111 -1 
            131 112 111 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0014"
              point="
              -8.21430857665086611e-01 1.39676502113091627e-01 0.00000000000000000e+00,
              -8.20765731464548121e-01 1.11408638590204057e-01 0.00000000000000000e+00,
              -8.17772663562124635e-01 9.37827942759329458e-02 0.00000000000000000e+00,
              -8.12784217058085678e-01 8.18105226662393936e-02 0.00000000000000000e+00,
              -8.04802702651623347e-01 7.15010665578921234e-02 0.00000000000000000e+00,
              -7.94493246543276133e-01 6.21892997503527001e-02 0.00000000000000000e+00,
              -7.81190722532505433e-01 5.52054746446981048e-02 0.00000000000000000e+00,
              -7.64562567519042169e-01 4.98844650403898471e-02 0.00000000000000000e+00,
              -7.44608781502886230e-01 4.58937078371586815e-02 0.00000000000000000e+00,
              -7.20664238283499126e-01 4.32332030350045526e-02 0.00000000000000000e+00,
              -6.92728937860880745e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -6.59140064733684916e-01 4.35657661352737979e-02 0.00000000000000000e+00,
              -6.31204764311066535e-01 4.75565233385050190e-02 0.00000000000000000e+00,
              -6.08923036593025713e-01 5.38752222436210682e-02 0.00000000000000000e+00,
              -5.91629755379023958e-01 6.28544259508912462e-02 0.00000000000000000e+00,
              -5.79324920669061161e-01 7.41615713600462523e-02 0.00000000000000000e+00,
              -5.71010843162329418e-01 8.74640953708168967e-02 0.00000000000000000e+00,
              -5.66354959758559762e-01 1.03427124183741670e-01 0.00000000000000000e+00,
              -5.64692144257213369e-01 1.23048347099628363e-01 0.00000000000000000e+00,
              -5.65689833558021160e-01 1.38346249712014591e-01 0.00000000000000000e+00,
              -5.68682901460444645e-01 1.51981336823054480e-01 0.00000000000000000e+00,
              -5.73671347964483491e-01 1.63953608432748033e-01 0.00000000000000000e+00,
              -5.81320299270676744e-01 1.74595627641364548e-01 0.00000000000000000e+00,
              -5.91962318479293259e-01 1.83574831348634726e-01 0.00000000000000000e+00,
              -6.05264842490063848e-01 1.90891219554558511e-01 0.00000000000000000e+00,
              -6.21892997503527112e-01 1.96212229158866769e-01 0.00000000000000000e+00,
              -6.41846783519683051e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -6.65458763638800965e-01 2.02863491164252174e-01 0.00000000000000000e+00,
              -6.93061500961150045e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -7.20996801383768315e-01 2.02863491164252174e-01 0.00000000000000000e+00,
              -7.44608781502886230e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -7.64562567519042169e-01 1.96212229158866769e-01 0.00000000000000000e+00,
              -7.81190722532505433e-01 1.90891219554558511e-01 0.00000000000000000e+00,
              -7.94493246543276133e-01 1.83574831348634726e-01 0.00000000000000000e+00,
              -8.04802702651623347e-01 1.74595627641364548e-01 0.00000000000000000e+00,
              -8.13449343258624280e-01 1.60627977430055358e-01 0.00000000000000000e+00,
              -8.12119090857547188e-01 1.56304657126554725e-02 0.00000000000000000e+00,
              -8.39056701979357666e-01 3.49191255282729207e-02 0.00000000000000000e+00,
              -8.58345361794975115e-01 5.95287949481985712e-02 0.00000000000000000e+00,
              -8.70317633404668722e-01 8.91269108721632342e-02 0.00000000000000000e+00,
              -8.73975827507630587e-01 1.24046036400436155e-01 0.00000000000000000e+00,
              -8.68654817903322329e-01 1.63621045332478787e-01 0.00000000000000000e+00,
              -8.52359225990128366e-01 1.96877355359405370e-01 0.00000000000000000e+00,
              -8.26086741068856378e-01 2.22484714080138812e-01 0.00000000000000000e+00,
              -7.90169926239775666e-01 2.40775684594948414e-01 0.00000000000000000e+00,
              -7.45939033903963322e-01 2.51750266903834174e-01 0.00000000000000000e+00,
              -6.93061500961150045e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -6.40183968018336769e-01 2.51750266903834174e-01 0.00000000000000000e+00,
              -5.95953075682524425e-01 2.40775684594948414e-01 0.00000000000000000e+00,
              -5.60368823953713013e-01 2.22484714080138812e-01 0.00000000000000000e+00,
              -5.34096339032440914e-01 1.96544792259136070e-01 0.00000000000000000e+00,
              -5.17800747119246951e-01 1.63288482232209486e-01 0.00000000000000000e+00,
              -5.12147174414669393e-01 1.23380910199897609e-01 0.00000000000000000e+00,
              -5.16137931617900669e-01 8.84617846716246881e-02 0.00000000000000000e+00,
              -5.28110203227594166e-01 5.85311056473907798e-02 0.00000000000000000e+00,
              -5.47731426143480804e-01 3.35888731271958840e-02 0.00000000000000000e+00,
              -5.74003911064752792e-01 1.46327764118476811e-02 0.00000000000000000e+00,
              -5.47398863043211614e-01 -7.31638820592386829e-03 0.00000000000000000e+00,
              -5.27777640127324865e-01 -3.49191255282729207e-02 0.00000000000000000e+00,
              -5.16137931617900669e-01 -6.85079986554687770e-02 0.00000000000000000e+00,
              -5.12147174414669393e-01 -1.07085318286703590e-01 0.00000000000000000e+00,
              -5.24119446024363000e-01 -1.70937433538402628e-01 0.00000000000000000e+00,
              -5.59038571552635921e-01 -2.17496267576099855e-01 0.00000000000000000e+00,
              -6.15906861698680363e-01 -2.46096694199256699e-01 0.00000000000000000e+00,
              -6.93061500961150045e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -7.70216140223619616e-01 -2.46096694199256699e-01 0.00000000000000000e+00,
              -8.27084430369664170e-01 -2.17163704475830582e-01 0.00000000000000000e+00,
              -8.62336118998206280e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -8.73975827507630587e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -8.70317633404668722e-01 -6.75103093546609578e-02 0.00000000000000000e+00,
              -8.58345361794975115e-01 -3.39214362274651293e-02 0.00000000000000000e+00,
              -8.39056701979357666e-01 -5.98613580484680385e-03 0.00000000000000000e+00,
              -6.56812123031800033e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -7.17338607280806451e-01 -1.06420192086164878e-02 0.00000000000000000e+00,
              -7.53587985210156353e-01 -1.46327764118476811e-02 0.00000000000000000e+00,
              -7.75537149827927874e-01 -2.19491646177715771e-02 0.00000000000000000e+00,
              -7.93162994142199040e-01 -3.25911838263880371e-02 0.00000000000000000e+00,
              -8.06132955052700328e-01 -4.62262709374279546e-02 0.00000000000000000e+00,
              -8.14779595659701261e-01 -6.25218628506219731e-02 0.00000000000000000e+00,
              -8.19768042163740329e-01 -8.24756488667779397e-02 0.00000000000000000e+00,
              -8.21430857665086611e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -8.18437789762663237e-01 -1.37681123511476045e-01 0.00000000000000000e+00,
              -8.09458586055393003e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              -7.93162994142199040e-01 -1.80581763446211352e-01 0.00000000000000000e+00,
              -7.68885887822542635e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -7.35962140895885297e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -6.93061500961150045e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -6.50160861026414683e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -6.17237114099757456e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -5.92960007780100939e-01 -1.80581763446211352e-01 0.00000000000000000e+00,
              -5.76996978967176277e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              -5.68017775259906044e-01 -1.37681123511476045e-01 0.00000000000000000e+00,
              -5.64692144257213369e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -5.66354959758559762e-01 -8.24756488667779397e-02 0.00000000000000000e+00,
              -5.71343406262598719e-01 -6.25218628506219731e-02 0.00000000000000000e+00,
              -5.79990046869599651e-01 -4.62262709374279546e-02 0.00000000000000000e+00,
              -5.92960007780100939e-01 -3.25911838263880371e-02 0.00000000000000000e+00,
              -6.16571987899218854e-01 -2.06189122166945127e-02 0.00000000000000000e+00,
              -1.75593316942172395e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -2.46761820399795329e-01 2.00868112562636481e-01 0.00000000000000000e+00,
              -2.90660149635338372e-01 1.92554035055904904e-01 0.00000000000000000e+00,
              -3.18928013158225943e-01 1.77921258644057223e-01 0.00000000000000000e+00,
              -3.42207430177074556e-01 1.56637220226824192e-01 0.00000000000000000e+00,
              -3.60498400691884213e-01 1.28701919804205811e-01 0.00000000000000000e+00,
              -3.73135798502116312e-01 9.34502311756637005e-02 0.00000000000000000e+00,
              -3.80784749808309453e-01 5.05495912409283932e-02 0.00000000000000000e+00,
              -3.83112691510194225e-01 -3.32563100269245293e-04 0.00000000000000000e+00,
              -3.80784749808309453e-01 -5.08821543411976940e-02 0.00000000000000000e+00,
              -3.73135798502116312e-01 -9.37827942759329736e-02 0.00000000000000000e+00,
              -3.60498400691884213e-01 -1.29034482904475140e-01 0.00000000000000000e+00,
              -3.42207430177074556e-01 -1.56969783327093465e-01 0.00000000000000000e+00,
              -3.18928013158225943e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -2.90660149635338372e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -2.57403839608411844e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -2.17828830676369156e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.78586384844595880e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -1.44997511717399941e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -1.17062211294781671e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -9.37827942759329458e-02 -1.56969783327093465e-01 0.00000000000000000e+00,
              -7.54918237611235110e-02 -1.29034482904475140e-01 0.00000000000000000e+00,
              -6.28544259508913017e-02 -9.37827942759329736e-02 0.00000000000000000e+00,
              -5.52054746446982714e-02 -5.08821543411976940e-02 0.00000000000000000e+00,
              -5.25449698425440870e-02 -3.32563100269245293e-04 0.00000000000000000e+00,
              -5.52054746446982714e-02 5.05495912409283932e-02 0.00000000000000000e+00,
              -6.28544259508913017e-02 9.34502311756637005e-02 0.00000000000000000e+00,
              -7.54918237611235110e-02 1.28701919804205811e-01 0.00000000000000000e+00,
              -9.37827942759329458e-02 1.56637220226824192e-01 0.00000000000000000e+00,
              -1.24711162600974812e-01 1.80581763446211352e-01 0.00000000000000000e+00,
              -1.62955919131940297e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.56073587207334752e-01 -2.51085140703295684e-01 0.00000000000000000e+00,
              -3.14604692854725587e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -3.52516886285421882e-01 -2.16831141375561309e-01 0.00000000000000000e+00,
              -3.82780128409925036e-01 -1.87565588551865947e-01 0.00000000000000000e+00,
              -4.06059545428773705e-01 -1.51316210622515934e-01 0.00000000000000000e+00,
              -4.22355137341967668e-01 -1.07750444487242136e-01 0.00000000000000000e+00,
              -4.32332030350045582e-01 -5.72008532463137154e-02 0.00000000000000000e+00,
              -4.35657661352738312e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.32332030350045582e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -4.22355137341967668e-01 1.07417881386972836e-01 0.00000000000000000e+00,
              -4.06059545428773705e-01 1.50983647522246633e-01 0.00000000000000000e+00,
              -3.82780128409925036e-01 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.52516886285421882e-01 2.16831141375561254e-01 0.00000000000000000e+00,
              -3.14604692854725587e-01 2.38115179792794285e-01 0.00000000000000000e+00,
              -2.69708674318374642e-01 2.51085140703295684e-01 0.00000000000000000e+00,
              -2.17828830676369156e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.76258443142710997e-01 2.52415393104372776e-01 0.00000000000000000e+00,
              -1.38346249712014702e-01 2.44101315597641089e-01 0.00000000000000000e+00,
              -1.05089939685088063e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -7.68220761622004922e-02 2.11510131771252996e-01 0.00000000000000000e+00,
              -5.35426591433518784e-02 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.39214362274652403e-02 1.58965161928709020e-01 0.00000000000000000e+00,
              -1.89560967153482585e-02 1.25376288801513192e-01 0.00000000000000000e+00,
              -8.31407750673174295e-03 8.77966584710861420e-02 0.00000000000000000e+00,
              -2.32794170188499461e-03 4.58937078371586815e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.32563100269278600e-03 -5.72008532463137154e-02 0.00000000000000000e+00,
              -1.33025240107708109e-02 -1.07750444487242136e-01 0.00000000000000000e+00,
              -2.99306790242340748e-02 -1.51316210622515934e-01 0.00000000000000000e+00,
              -5.32100960430825776e-02 -1.87565588551865947e-01 0.00000000000000000e+00,
              -9.41153573762022466e-02 -2.21154461679061776e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99792901872025652e+02 4.99792901872025652e+02 8.49972286408310964e+01"
        rotation="8.55167184663299773e-01 3.66530412295518748e-01 3.66530412295518804e-01 -1.72662016832750753e+00"
        scale="1.00000000000000022e+00 1.00000000000000022e+00 1.00000000000000022e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            40 0 41 -1 
            52 51 18 -1 
            41 0 42 -1 
            51 50 18 -1 
            42 0 43 -1 
            50 49 18 -1 
            43 0 44 -1 
            48 47 49 -1 
            47 46 49 -1 
            46 45 49 -1 
            45 44 49 -1 
            18 49 19 -1 
            19 49 20 -1 
            20 49 21 -1 
            21 49 22 -1 
            22 49 23 -1 
            23 49 24 -1 
            24 49 25 -1 
            49 44 25 -1 
            0 35 44 -1 
            35 34 44 -1 
            34 33 44 -1 
            33 32 44 -1 
            32 31 44 -1 
            31 30 44 -1 
            30 29 44 -1 
            29 28 44 -1 
            28 27 44 -1 
            27 26 44 -1 
            44 26 25 -1 
            85 84 65 -1 
            65 84 66 -1 
            1 0 40 -1 
            40 39 1 -1 
            39 38 1 -1 
            66 84 67 -1 
            12 11 72 -1 
            73 72 11 -1 
            38 37 1 -1 
            11 10 73 -1 
            10 9 73 -1 
            73 9 74 -1 
            9 8 74 -1 
            8 7 74 -1 
            74 7 75 -1 
            37 36 1 -1 
            7 6 75 -1 
            75 6 76 -1 
            6 5 76 -1 
            76 5 77 -1 
            5 4 77 -1 
            84 83 67 -1 
            67 83 68 -1 
            71 70 36 -1 
            70 69 36 -1 
            69 68 36 -1 
            77 4 78 -1 
            83 82 68 -1 
            82 81 68 -1 
            81 80 68 -1 
            80 79 68 -1 
            68 79 36 -1 
            79 78 36 -1 
            78 4 36 -1 
            4 3 36 -1 
            3 2 36 -1 
            2 1 36 -1 
            65 64 85 -1 
            72 97 12 -1 
            52 18 53 -1 
            85 64 86 -1 
            64 63 86 -1 
            53 18 54 -1 
            12 97 13 -1 
            86 63 87 -1 
            87 63 88 -1 
            63 62 88 -1 
            62 61 88 -1 
            54 18 55 -1 
            97 96 13 -1 
            13 96 14 -1 
            96 95 14 -1 
            14 95 15 -1 
            88 61 89 -1 
            61 60 89 -1 
            57 56 58 -1 
            58 56 59 -1 
            59 56 60 -1 
            18 17 55 -1 
            55 17 56 -1 
            95 94 15 -1 
            17 16 56 -1 
            16 15 56 -1 
            89 60 90 -1 
            90 60 91 -1 
            91 60 92 -1 
            92 60 93 -1 
            60 56 93 -1 
            93 56 94 -1 
            56 15 94 -1 
            113 112 114 -1 
            114 112 115 -1 
            115 112 116 -1 
            110 109 111 -1 
            109 108 111 -1 
            108 107 111 -1 
            107 106 111 -1 
            122 121 123 -1 
            121 120 123 -1 
            120 119 123 -1 
            123 119 124 -1 
            119 118 124 -1 
            124 118 125 -1 
            118 117 125 -1 
            125 117 126 -1 
            117 116 126 -1 
            116 112 126 -1 
            112 111 126 -1 
            126 111 127 -1 
            111 106 127 -1 
            127 106 128 -1 
            128 106 129 -1 
            106 105 129 -1 
            129 105 130 -1 
            105 104 130 -1 
            130 104 131 -1 
            104 103 131 -1 
            131 103 132 -1 
            132 103 133 -1 
            133 103 134 -1 
            134 103 135 -1 
            153 152 154 -1 
            154 152 155 -1 
            155 152 156 -1 
            156 152 157 -1 
            152 151 157 -1 
            157 151 158 -1 
            158 151 159 -1 
            159 151 160 -1 
            160 151 161 -1 
            161 151 162 -1 
            162 151 163 -1 
            151 150 163 -1 
            150 149 163 -1 
            149 148 163 -1 
            148 147 163 -1 
            147 146 163 -1 
            146 145 163 -1 
            137 136 138 -1 
            136 135 138 -1 
            138 135 139 -1 
            139 135 140 -1 
            140 135 141 -1 
            141 135 142 -1 
            142 135 143 -1 
            144 143 145 -1 
            103 102 135 -1 
            102 101 135 -1 
            101 100 135 -1 
            100 99 135 -1 
            99 98 135 -1 
            98 177 135 -1 
            164 163 165 -1 
            135 177 143 -1 
            177 176 143 -1 
            176 175 143 -1 
            143 175 145 -1 
            175 174 145 -1 
            174 173 145 -1 
            173 172 145 -1 
            172 171 145 -1 
            171 170 145 -1 
            170 169 145 -1 
            169 168 145 -1 
            165 163 166 -1 
            163 145 166 -1 
            166 145 167 -1 
            168 167 145 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0015"
              point="
              -7.68885887822542524e-01 1.39676502113091627e-01 0.00000000000000000e+00,
              -7.68220761622004034e-01 1.11408638590204057e-01 0.00000000000000000e+00,
              -7.65227693719580548e-01 9.37827942759329458e-02 0.00000000000000000e+00,
              -7.60239247215541591e-01 8.18105226662393936e-02 0.00000000000000000e+00,
              -7.52257732809079260e-01 7.15010665578921234e-02 0.00000000000000000e+00,
              -7.41948276700731935e-01 6.21892997503527001e-02 0.00000000000000000e+00,
              -7.28645752689961346e-01 5.52054746446981048e-02 0.00000000000000000e+00,
              -7.12017597676498082e-01 4.98844650403898471e-02 0.00000000000000000e+00,
              -6.92063811660342143e-01 4.58937078371586815e-02 0.00000000000000000e+00,
              -6.68119268440954928e-01 4.32332030350045526e-02 0.00000000000000000e+00,
              -6.40183968018336658e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -6.06595094891140829e-01 4.35657661352737979e-02 0.00000000000000000e+00,
              -5.78659794468522448e-01 4.75565233385050190e-02 0.00000000000000000e+00,
              -5.56378066750481626e-01 5.38752222436210682e-02 0.00000000000000000e+00,
              -5.39084785536479871e-01 6.28544259508912462e-02 0.00000000000000000e+00,
              -5.26779950826516963e-01 7.41615713600462523e-02 0.00000000000000000e+00,
              -5.18465873319785331e-01 8.74640953708168967e-02 0.00000000000000000e+00,
              -5.13809989916015564e-01 1.03427124183741670e-01 0.00000000000000000e+00,
              -5.12147174414669282e-01 1.23048347099628363e-01 0.00000000000000000e+00,
              -5.13144863715477184e-01 1.38346249712014591e-01 0.00000000000000000e+00,
              -5.16137931617900447e-01 1.51981336823054480e-01 0.00000000000000000e+00,
              -5.21126378121939515e-01 1.63953608432748033e-01 0.00000000000000000e+00,
              -5.28775329428132546e-01 1.74595627641364548e-01 0.00000000000000000e+00,
              -5.39417348636749061e-01 1.83574831348634726e-01 0.00000000000000000e+00,
              -5.52719872647519761e-01 1.90891219554558511e-01 0.00000000000000000e+00,
              -5.69348027660983025e-01 1.96212229158866769e-01 0.00000000000000000e+00,
              -5.89301813677138964e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -6.12913793796256878e-01 2.02863491164252174e-01 0.00000000000000000e+00,
              -6.40516531118605847e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -6.68451831541224228e-01 2.02863491164252174e-01 0.00000000000000000e+00,
              -6.92063811660342143e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -7.12017597676498082e-01 1.96212229158866769e-01 0.00000000000000000e+00,
              -7.28645752689961346e-01 1.90891219554558511e-01 0.00000000000000000e+00,
              -7.41948276700731935e-01 1.83574831348634726e-01 0.00000000000000000e+00,
              -7.52257732809079260e-01 1.74595627641364548e-01 0.00000000000000000e+00,
              -7.60904373416080082e-01 1.60627977430055358e-01 0.00000000000000000e+00,
              -7.59574121015003101e-01 1.56304657126554725e-02 0.00000000000000000e+00,
              -7.86511732136813579e-01 3.49191255282729207e-02 0.00000000000000000e+00,
              -8.05800391952431028e-01 5.95287949481985712e-02 0.00000000000000000e+00,
              -8.17772663562124635e-01 8.91269108721632342e-02 0.00000000000000000e+00,
              -8.21430857665086500e-01 1.24046036400436155e-01 0.00000000000000000e+00,
              -8.16109848060778242e-01 1.63621045332478787e-01 0.00000000000000000e+00,
              -7.99814256147584279e-01 1.96877355359405370e-01 0.00000000000000000e+00,
              -7.73541771226312291e-01 2.22484714080138812e-01 0.00000000000000000e+00,
              -7.37624956397231468e-01 2.40775684594948414e-01 0.00000000000000000e+00,
              -6.93394064061419124e-01 2.51750266903834174e-01 0.00000000000000000e+00,
              -6.40516531118605847e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -5.87638998175792571e-01 2.51750266903834174e-01 0.00000000000000000e+00,
              -5.43408105839980227e-01 2.40775684594948414e-01 0.00000000000000000e+00,
              -5.07823854111168815e-01 2.22484714080138812e-01 0.00000000000000000e+00,
              -4.81551369189896883e-01 1.96544792259136070e-01 0.00000000000000000e+00,
              -4.65255777276702809e-01 1.63288482232209486e-01 0.00000000000000000e+00,
              -4.59602204572125306e-01 1.23380910199897609e-01 0.00000000000000000e+00,
              -4.63592961775356527e-01 8.84617846716246881e-02 0.00000000000000000e+00,
              -4.75565233385050079e-01 5.85311056473907798e-02 0.00000000000000000e+00,
              -4.95186456300936717e-01 3.35888731271958840e-02 0.00000000000000000e+00,
              -5.21458941222208816e-01 1.46327764118476811e-02 0.00000000000000000e+00,
              -4.94853893200667527e-01 -7.31638820592386829e-03 0.00000000000000000e+00,
              -4.75232670284780834e-01 -3.49191255282729207e-02 0.00000000000000000e+00,
              -4.63592961775356527e-01 -6.85079986554687770e-02 0.00000000000000000e+00,
              -4.59602204572125306e-01 -1.07085318286703590e-01 0.00000000000000000e+00,
              -4.71574476181818913e-01 -1.70937433538402628e-01 0.00000000000000000e+00,
              -5.06493601710091834e-01 -2.17496267576099855e-01 0.00000000000000000e+00,
              -5.63361891856136277e-01 -2.46096694199256699e-01 0.00000000000000000e+00,
              -6.40516531118605847e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -7.17671170381075640e-01 -2.46096694199256699e-01 0.00000000000000000e+00,
              -7.74539460527120083e-01 -2.17163704475830582e-01 0.00000000000000000e+00,
              -8.09791149155662193e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -8.21430857665086500e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -8.17772663562124635e-01 -6.75103093546609578e-02 0.00000000000000000e+00,
              -8.05800391952431028e-01 -3.39214362274651293e-02 0.00000000000000000e+00,
              -7.86511732136813579e-01 -5.98613580484680385e-03 0.00000000000000000e+00,
              -6.04267153189255946e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -6.64793637438262364e-01 -1.06420192086164878e-02 0.00000000000000000e+00,
              -7.01043015367612266e-01 -1.46327764118476811e-02 0.00000000000000000e+00,
              -7.22992179985383787e-01 -2.19491646177715771e-02 0.00000000000000000e+00,
              -7.40618024299654953e-01 -3.25911838263880371e-02 0.00000000000000000e+00,
              -7.53587985210156353e-01 -4.62262709374279546e-02 0.00000000000000000e+00,
              -7.62234625817157174e-01 -6.25218628506219731e-02 0.00000000000000000e+00,
              -7.67223072321196131e-01 -8.24756488667779397e-02 0.00000000000000000e+00,
              -7.68885887822542524e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -7.65892819920119150e-01 -1.37681123511476045e-01 0.00000000000000000e+00,
              -7.56913616212848916e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              -7.40618024299654953e-01 -1.80581763446211352e-01 0.00000000000000000e+00,
              -7.16340917979998548e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -6.83417171053341210e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -6.40516531118605847e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -5.97615891183870707e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -5.64692144257213258e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -5.40415037937556963e-01 -1.80581763446211352e-01 0.00000000000000000e+00,
              -5.24452009124632079e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              -5.15472805417361957e-01 -1.37681123511476045e-01 0.00000000000000000e+00,
              -5.12147174414669282e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -5.13809989916015564e-01 -8.24756488667779397e-02 0.00000000000000000e+00,
              -5.18798436420054632e-01 -6.25218628506219731e-02 0.00000000000000000e+00,
              -5.27445077027055564e-01 -4.62262709374279546e-02 0.00000000000000000e+00,
              -5.40415037937556963e-01 -3.25911838263880371e-02 0.00000000000000000e+00,
              -5.64027018056674767e-01 -2.06189122166945127e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 -1.25376288801513219e-01 0.00000000000000000e+00,
              -5.52054746446981603e-02 -9.27851049751251544e-02 0.00000000000000000e+00,
              -6.21892997503527001e-02 -6.98382510565458414e-02 0.00000000000000000e+00,
              -7.38290082597770070e-02 -5.32100960430825221e-02 0.00000000000000000e+00,
              -8.84617846716246881e-02 -3.85773196312348410e-02 0.00000000000000000e+00,
              -1.07085318286703646e-01 -2.66050480215412610e-02 0.00000000000000000e+00,
              -1.29367046004744468e-01 -1.72932812140018100e-02 0.00000000000000000e+00,
              -1.54309278524939364e-01 -1.13071454091550616e-02 0.00000000000000000e+00,
              -1.80914326546480653e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.35556168171689206e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.45865624280036477e-01 -7.31638820592386829e-03 0.00000000000000000e+00,
              -3.54179701786768053e-01 -1.66281550134633749e-03 0.00000000000000000e+00,
              -3.60165837591614857e-01 6.31869890511604915e-03 0.00000000000000000e+00,
              -3.61828653092961194e-01 1.59630288129247178e-02 0.00000000000000000e+00,
              -3.61828653092961194e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -3.60165837591614857e-01 2.39777995294140678e-01 0.00000000000000000e+00,
              -3.54179701786768053e-01 2.48092072800872310e-01 0.00000000000000000e+00,
              -3.45865624280036477e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -3.35556168171689206e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -2.62724849212719880e-02 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.66281550134632639e-02 2.53413082405180567e-01 0.00000000000000000e+00,
              -7.98151440646244215e-03 2.47759509700603009e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.39112869093602132e-01 0.00000000000000000e+00,
              0.00000000000000000e+00 2.29468539185793352e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.19491646177715383e-01 0.00000000000000000e+00,
              -7.98151440646244215e-03 2.11177568670983806e-01 0.00000000000000000e+00,
              -1.66281550134632639e-02 2.05523995966406303e-01 0.00000000000000000e+00,
              -2.62724849212719880e-02 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417218e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417218e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.80914326546480653e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.44664948617130640e-01 3.95750089320426324e-02 0.00000000000000000e+00,
              -1.10410949289396210e-01 3.09283683250417551e-02 0.00000000000000000e+00,
              -7.91500178640852647e-02 1.72932812140018100e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.09283683250416441e-02 -2.19491646177715771e-02 0.00000000000000000e+00,
              -1.43002133115783803e-02 -4.75565233385050190e-02 0.00000000000000000e+00,
              -3.65819410296186476e-03 -7.61569499616618906e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 -1.06420192086165072e-01 0.00000000000000000e+00,
              -3.65819410296186476e-03 -1.36683434210668253e-01 0.00000000000000000e+00,
              -1.43002133115783803e-02 -1.65283860833825125e-01 0.00000000000000000e+00,
              -3.09283683250416441e-02 -1.91223782654827867e-01 0.00000000000000000e+00,
              -5.25449698425439760e-02 -2.12840384172330144e-01 0.00000000000000000e+00,
              -7.91500178640852647e-02 -2.30466228486601199e-01 0.00000000000000000e+00,
              -1.10410949289396210e-01 -2.44101315597641116e-01 0.00000000000000000e+00,
              -1.44664948617130640e-01 -2.52747956204642021e-01 0.00000000000000000e+00,
              -1.80914326546480653e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.27805723684447070e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -2.75362247022952089e-01 -2.40110558394409923e-01 0.00000000000000000e+00,
              -3.17930323857418040e-01 -2.22817277180408113e-01 0.00000000000000000e+00,
              -3.49523818382998341e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -3.53514575586229618e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -3.56507643488652937e-01 -1.92886598156174177e-01 0.00000000000000000e+00,
              -3.59168148290807066e-01 -1.88230714752404465e-01 0.00000000000000000e+00,
              -3.61163526892422704e-01 -1.83242268248365481e-01 0.00000000000000000e+00,
              -3.62493779293499740e-01 -1.77588695543787950e-01 0.00000000000000000e+00,
              -3.61496089992692005e-01 -1.71602559738941174e-01 0.00000000000000000e+00,
              -3.59168148290807066e-01 -1.65948987034363643e-01 0.00000000000000000e+00,
              -3.54512264887037354e-01 -1.59962851229516867e-01 0.00000000000000000e+00,
              -3.49191255282729096e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -3.43205119477882292e-01 -1.52979026123862272e-01 0.00000000000000000e+00,
              -3.37551546773304789e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.32563100269265832e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.27574653765226764e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -3.22253644160918618e-01 -1.55972094026285674e-01 0.00000000000000000e+00,
              -3.17597760757148850e-01 -1.58632598828439803e-01 0.00000000000000000e+00,
              -3.13607003553917685e-01 -1.61625666730863204e-01 0.00000000000000000e+00,
              -3.10281372551225010e-01 -1.64618734633286579e-01 0.00000000000000000e+00,
              -3.06955741548532335e-01 -1.67279239435440708e-01 0.00000000000000000e+00,
              -3.04295236746378261e-01 -1.69607181137325591e-01 0.00000000000000000e+00,
              -3.02964984345301169e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -2.80350693526991157e-01 -1.82577142047826935e-01 0.00000000000000000e+00,
              -2.47426946600333819e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -2.11842694871522297e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -1.80914326546480653e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.54309278524939364e-01 -2.01865801863444355e-01 0.00000000000000000e+00,
              -1.29367046004744468e-01 -1.95879666058597579e-01 0.00000000000000000e+00,
              -1.07085318286703646e-01 -1.86567899251058128e-01 0.00000000000000000e+00,
              -8.84617846716246881e-02 -1.74263064541095303e-01 0.00000000000000000e+00,
              -7.11685034576228226e-02 -1.55639530926016401e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99792901872025652e+02 4.99792901872025652e+02 8.99972286408310964e+01"
        rotation="8.55167184663299773e-01 3.66530412295518748e-01 3.66530412295518804e-01 -1.72662016832750753e+00"
        scale="1.00000000000000022e+00 1.00000000000000022e+00 1.00000000000000022e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            77 76 84 -1 
            76 75 84 -1 
            75 74 84 -1 
            74 73 84 -1 
            97 96 8 -1 
            96 95 8 -1 
            8 95 9 -1 
            95 94 9 -1 
            9 94 10 -1 
            94 93 10 -1 
            10 93 11 -1 
            11 93 12 -1 
            93 92 12 -1 
            12 92 13 -1 
            13 92 14 -1 
            14 92 15 -1 
            92 91 15 -1 
            15 91 16 -1 
            73 72 84 -1 
            37 36 38 -1 
            38 36 39 -1 
            39 36 40 -1 
            36 35 40 -1 
            40 35 41 -1 
            41 35 42 -1 
            42 35 43 -1 
            43 35 44 -1 
            44 35 45 -1 
            45 35 46 -1 
            46 35 47 -1 
            35 34 47 -1 
            34 33 47 -1 
            33 32 47 -1 
            32 31 47 -1 
            31 30 47 -1 
            30 29 47 -1 
            69 68 70 -1 
            70 68 71 -1 
            71 68 72 -1 
            25 24 26 -1 
            26 24 27 -1 
            27 24 28 -1 
            28 24 29 -1 
            24 23 29 -1 
            23 22 29 -1 
            22 21 29 -1 
            21 20 29 -1 
            19 18 20 -1 
            18 17 20 -1 
            17 16 20 -1 
            48 47 49 -1 
            49 47 50 -1 
            47 29 50 -1 
            50 29 51 -1 
            51 29 52 -1 
            52 29 53 -1 
            53 29 54 -1 
            54 29 55 -1 
            55 29 56 -1 
            56 29 57 -1 
            57 29 58 -1 
            29 20 58 -1 
            58 20 59 -1 
            59 20 60 -1 
            60 20 61 -1 
            61 20 62 -1 
            62 20 63 -1 
            63 20 64 -1 
            20 16 64 -1 
            64 16 65 -1 
            65 16 66 -1 
            66 16 67 -1 
            67 16 68 -1 
            16 91 68 -1 
            91 90 68 -1 
            68 90 72 -1 
            90 89 72 -1 
            89 88 72 -1 
            88 87 72 -1 
            87 86 72 -1 
            86 85 72 -1 
            85 84 72 -1 
            77 84 78 -1 
            8 7 97 -1 
            7 6 97 -1 
            6 5 97 -1 
            97 5 98 -1 
            5 4 98 -1 
            4 3 98 -1 
            98 3 99 -1 
            3 2 99 -1 
            99 2 100 -1 
            2 1 100 -1 
            100 1 101 -1 
            1 0 101 -1 
            101 0 102 -1 
            0 83 102 -1 
            83 82 102 -1 
            82 81 102 -1 
            78 84 79 -1 
            81 80 102 -1 
            79 84 80 -1 
            84 109 80 -1 
            109 108 80 -1 
            108 107 80 -1 
            107 106 80 -1 
            106 105 80 -1 
            105 104 80 -1 
            104 103 80 -1 
            102 80 103 -1 
            157 110 158 -1 
            110 139 158 -1 
            125 141 126 -1 
            141 140 126 -1 
            158 139 159 -1 
            159 139 160 -1 
            139 138 160 -1 
            160 138 161 -1 
            161 138 162 -1 
            138 137 162 -1 
            162 137 163 -1 
            137 136 163 -1 
            163 136 164 -1 
            136 135 164 -1 
            164 135 165 -1 
            135 134 165 -1 
            165 134 166 -1 
            166 134 167 -1 
            134 133 167 -1 
            167 133 168 -1 
            133 132 168 -1 
            168 132 169 -1 
            132 131 169 -1 
            169 131 170 -1 
            131 130 170 -1 
            170 130 171 -1 
            126 140 127 -1 
            140 171 127 -1 
            130 129 171 -1 
            129 128 171 -1 
            171 128 127 -1 
            141 125 142 -1 
            157 156 110 -1 
            111 110 156 -1 
            156 155 111 -1 
            111 155 112 -1 
            155 154 112 -1 
            154 153 112 -1 
            125 124 142 -1 
            142 124 143 -1 
            112 153 113 -1 
            153 152 113 -1 
            113 152 114 -1 
            152 151 114 -1 
            114 151 115 -1 
            151 150 115 -1 
            115 150 116 -1 
            150 149 116 -1 
            116 149 117 -1 
            149 148 117 -1 
            117 148 118 -1 
            148 147 118 -1 
            118 147 119 -1 
            147 146 119 -1 
            119 146 120 -1 
            146 145 120 -1 
            120 145 121 -1 
            145 144 121 -1 
            121 144 122 -1 
            144 143 122 -1 
            122 143 123 -1 
            143 124 123 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0016"
              point="
              -8.86945788418131764e-01 1.91888908855366358e-01 0.00000000000000000e+00,
              -8.59675614196051985e-01 2.17496267576099800e-01 0.00000000000000000e+00,
              -8.21763420765355690e-01 2.37782616692525040e-01 0.00000000000000000e+00,
              -7.78197654630081836e-01 2.50752577603026383e-01 0.00000000000000000e+00,
              -7.34299325394538793e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -7.03370957069497038e-01 2.52747956204642077e-01 0.00000000000000000e+00,
              -6.73772841145532375e-01 2.45764131098987426e-01 0.00000000000000000e+00,
              -6.46170103823183295e-01 2.34456985689832420e-01 0.00000000000000000e+00,
              -6.21227871302988399e-01 2.19491646177715383e-01 0.00000000000000000e+00,
              -5.98613580484678387e-01 2.00868112562636481e-01 0.00000000000000000e+00,
              -5.78327231368253036e-01 1.78918947944865014e-01 0.00000000000000000e+00,
              -5.60368823953712791e-01 1.53976715424670063e-01 0.00000000000000000e+00,
              -5.45736047541865110e-01 1.27039104302859529e-01 0.00000000000000000e+00,
              -5.34096339032440803e-01 9.74409883788949216e-02 0.00000000000000000e+00,
              -5.25449698425439760e-01 6.61800569535839212e-02 0.00000000000000000e+00,
              -5.20128688821131613e-01 3.32563100269265832e-02 0.00000000000000000e+00,
              -5.18133310219516030e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -5.20128688821131613e-01 -3.32563100269265832e-02 0.00000000000000000e+00,
              -5.25449698425439760e-01 -6.58474938533146481e-02 0.00000000000000000e+00,
              -5.34096339032440803e-01 -9.71084252786256208e-02 0.00000000000000000e+00,
              -5.45736047541865110e-01 -1.26373978102321038e-01 0.00000000000000000e+00,
              -5.60701387053982092e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -5.78659794468522337e-01 -1.78586384844595741e-01 0.00000000000000000e+00,
              -5.99611269785486067e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -6.22225560603796191e-01 -2.19491646177715438e-01 0.00000000000000000e+00,
              -6.47500356224260387e-01 -2.34789548790101665e-01 0.00000000000000000e+00,
              -6.75435656646878657e-01 -2.46096694199256699e-01 0.00000000000000000e+00,
              -7.05698898771381922e-01 -2.53080519304911267e-01 0.00000000000000000e+00,
              -7.36959830196692867e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -7.83518664234390094e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -8.30742624472625812e-01 -2.40110558394409923e-01 0.00000000000000000e+00,
              -8.72978138206822574e-01 -2.22817277180408113e-01 0.00000000000000000e+00,
              -9.04239069632133630e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -9.08229826835364795e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -9.11222894737788169e-01 -1.92886598156174177e-01 0.00000000000000000e+00,
              -9.13883399539942354e-01 -1.88230714752404465e-01 0.00000000000000000e+00,
              -9.15878778141557937e-01 -1.83242268248365481e-01 0.00000000000000000e+00,
              -9.17209030542634918e-01 -1.77588695543787950e-01 0.00000000000000000e+00,
              -9.16543904342096427e-01 -1.71602559738941174e-01 0.00000000000000000e+00,
              -9.13883399539942354e-01 -1.65948987034363643e-01 0.00000000000000000e+00,
              -9.09227516136172587e-01 -1.59962851229516867e-01 0.00000000000000000e+00,
              -9.04239069632133630e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -8.98252933827286881e-01 -1.52979026123862272e-01 0.00000000000000000e+00,
              -8.92599361122709323e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -8.87278351518401065e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -8.82289905014362108e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -8.76968895410053850e-01 -1.55972094026285674e-01 0.00000000000000000e+00,
              -8.72313012006284083e-01 -1.58632598828439803e-01 0.00000000000000000e+00,
              -8.68322254803052918e-01 -1.61625666730863204e-01 0.00000000000000000e+00,
              -8.64996623800360243e-01 -1.64618734633286579e-01 0.00000000000000000e+00,
              -8.61670992797667568e-01 -1.67279239435440708e-01 0.00000000000000000e+00,
              -8.59343051095782684e-01 -1.69607181137325591e-01 0.00000000000000000e+00,
              -8.58012798694705703e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -8.35398507876395580e-01 -1.82577142047826935e-01 0.00000000000000000e+00,
              -8.02807324050007542e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -7.67555635421465321e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -7.36959830196692867e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -7.11685034576228670e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -6.86742802056033774e-01 -1.94216850557251242e-01 0.00000000000000000e+00,
              -6.62798258836646670e-01 -1.82244578947557689e-01 0.00000000000000000e+00,
              -6.41181657319144338e-01 -1.66281550134632916e-01 0.00000000000000000e+00,
              -6.21560434403257700e-01 -1.46660327218746223e-01 0.00000000000000000e+00,
              -6.04599716289525135e-01 -1.23380910199897609e-01 0.00000000000000000e+00,
              -5.90632066078215945e-01 -9.71084252786256208e-02 0.00000000000000000e+00,
              -5.79657483769330129e-01 -6.71777462543917125e-02 0.00000000000000000e+00,
              -5.73006221763944890e-01 -3.45865624280036477e-02 0.00000000000000000e+00,
              -5.70678280062060006e-01 -6.65126200538546097e-04 0.00000000000000000e+00,
              -5.71343406262598608e-01 1.79584074145403561e-02 0.00000000000000000e+00,
              -5.73006221763944890e-01 3.59168148290807121e-02 0.00000000000000000e+00,
              -6.07260221091679209e-01 1.69607181137325091e-02 0.00000000000000000e+00,
              -6.47500356224260387e-01 2.66050480215407337e-03 0.00000000000000000e+00,
              -6.91066122359534241e-01 -6.31869890511607690e-03 0.00000000000000000e+00,
              -7.34631888494807983e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -7.78530217730351137e-01 -4.65588340376971166e-03 0.00000000000000000e+00,
              -8.21763420765355690e-01 8.64664060700093273e-03 0.00000000000000000e+00,
              -8.59675614196051985e-01 2.89329897234261169e-02 0.00000000000000000e+00,
              -8.86945788418131764e-01 5.45403484441596142e-02 0.00000000000000000e+00,
              -8.96922681426209789e-01 6.98382510565458414e-02 0.00000000000000000e+00,
              -9.04571632732402819e-01 8.74640953708168967e-02 0.00000000000000000e+00,
              -9.09560079236441887e-01 1.05422502785357253e-01 0.00000000000000000e+00,
              -9.11222894737788169e-01 1.23048347099628363e-01 0.00000000000000000e+00,
              -9.09560079236441887e-01 1.40341628313630173e-01 0.00000000000000000e+00,
              -9.04571632732402819e-01 1.58632598828439775e-01 0.00000000000000000e+00,
              -8.96922681426209789e-01 1.76258443142710886e-01 0.00000000000000000e+00,
              -8.44710274683935003e-01 8.54687167692013139e-02 0.00000000000000000e+00,
              -8.27084430369663948e-01 7.01708141568150867e-02 0.00000000000000000e+00,
              -7.98151440646237775e-01 5.62031639455058962e-02 0.00000000000000000e+00,
              -7.65227693719580548e-01 4.58937078371586815e-02 0.00000000000000000e+00,
              -7.33966762294269492e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -6.91731248560072731e-01 4.58937078371586815e-02 0.00000000000000000e+00,
              -6.48830608625337479e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -6.11916104495448976e-01 7.21661927584306695e-02 0.00000000000000000e+00,
              -5.86308745774715478e-01 8.97920370727017247e-02 0.00000000000000000e+00,
              -6.02271774587640252e-01 1.22050657798820572e-01 0.00000000000000000e+00,
              -6.22890686804334681e-01 1.50318521321708143e-01 0.00000000000000000e+00,
              -6.47500356224260387e-01 1.72932812140018211e-01 0.00000000000000000e+00,
              -6.74770530446340167e-01 1.89893530253750775e-01 0.00000000000000000e+00,
              -7.04036083270035640e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -7.33966762294269492e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -7.65227693719580548e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -7.98151440646237775e-01 1.90226093354020076e-01 0.00000000000000000e+00,
              -8.27084430369663948e-01 1.75925880042441585e-01 0.00000000000000000e+00,
              -8.44710274683935003e-01 1.60627977430055358e-01 0.00000000000000000e+00,
              -8.49366158087704770e-01 1.52979026123862272e-01 0.00000000000000000e+00,
              -8.54022041491474426e-01 1.42004443814976455e-01 0.00000000000000000e+00,
              -8.57680235594436402e-01 1.30697298405821449e-01 0.00000000000000000e+00,
              -8.58677924895244193e-01 1.23048347099628363e-01 0.00000000000000000e+00,
              -8.57680235594436402e-01 1.15066832693165977e-01 0.00000000000000000e+00,
              -8.54022041491474426e-01 1.04092250384280161e-01 0.00000000000000000e+00,
              -8.49366158087704770e-01 9.27851049751251544e-02 0.00000000000000000e+00,
              -1.75593316942172173e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -2.46761820399795107e-01 2.00868112562636481e-01 0.00000000000000000e+00,
              -2.90660149635338150e-01 1.92554035055904904e-01 0.00000000000000000e+00,
              -3.18928013158225721e-01 1.77921258644057223e-01 0.00000000000000000e+00,
              -3.42207430177074334e-01 1.56637220226824192e-01 0.00000000000000000e+00,
              -3.60498400691883991e-01 1.28701919804205811e-01 0.00000000000000000e+00,
              -3.73135798502116089e-01 9.34502311756637005e-02 0.00000000000000000e+00,
              -3.80784749808309231e-01 5.05495912409283932e-02 0.00000000000000000e+00,
              -3.83112691510194003e-01 -3.32563100269245293e-04 0.00000000000000000e+00,
              -3.80784749808309231e-01 -5.08821543411976940e-02 0.00000000000000000e+00,
              -3.73135798502116089e-01 -9.37827942759329736e-02 0.00000000000000000e+00,
              -3.60498400691883991e-01 -1.29034482904475140e-01 0.00000000000000000e+00,
              -3.42207430177074334e-01 -1.56969783327093465e-01 0.00000000000000000e+00,
              -3.18928013158225721e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -2.90660149635338150e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -2.57403839608411622e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -2.17828830676368934e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.78586384844595658e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -1.44997511717399719e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -1.17062211294781449e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -9.37827942759327238e-02 -1.56969783327093465e-01 0.00000000000000000e+00,
              -7.54918237611232890e-02 -1.29034482904475140e-01 0.00000000000000000e+00,
              -6.28544259508910796e-02 -9.37827942759329736e-02 0.00000000000000000e+00,
              -5.52054746446980493e-02 -5.08821543411976940e-02 0.00000000000000000e+00,
              -5.25449698425438649e-02 -3.32563100269245293e-04 0.00000000000000000e+00,
              -5.52054746446980493e-02 5.05495912409283932e-02 0.00000000000000000e+00,
              -6.28544259508910796e-02 9.34502311756637005e-02 0.00000000000000000e+00,
              -7.54918237611232890e-02 1.28701919804205811e-01 0.00000000000000000e+00,
              -9.37827942759327238e-02 1.56637220226824192e-01 0.00000000000000000e+00,
              -1.24711162600974590e-01 1.80581763446211352e-01 0.00000000000000000e+00,
              -1.62955919131940075e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.56073587207334530e-01 -2.51085140703295684e-01 0.00000000000000000e+00,
              -3.14604692854725365e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -3.52516886285421660e-01 -2.16831141375561309e-01 0.00000000000000000e+00,
              -3.82780128409924814e-01 -1.87565588551865947e-01 0.00000000000000000e+00,
              -4.06059545428773427e-01 -1.51316210622515934e-01 0.00000000000000000e+00,
              -4.22355137341967390e-01 -1.07750444487242136e-01 0.00000000000000000e+00,
              -4.32332030350045415e-01 -5.72008532463137154e-02 0.00000000000000000e+00,
              -4.35657661352738090e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.32332030350045415e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -4.22355137341967390e-01 1.07417881386972836e-01 0.00000000000000000e+00,
              -4.06059545428773427e-01 1.50983647522246633e-01 0.00000000000000000e+00,
              -3.82780128409924814e-01 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.52516886285421660e-01 2.16831141375561254e-01 0.00000000000000000e+00,
              -3.14604692854725365e-01 2.38115179792794285e-01 0.00000000000000000e+00,
              -2.69708674318374420e-01 2.51085140703295684e-01 0.00000000000000000e+00,
              -2.17828830676368934e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.76258443142710775e-01 2.52415393104372776e-01 0.00000000000000000e+00,
              -1.38346249712014480e-01 2.44101315597641089e-01 0.00000000000000000e+00,
              -1.05089939685087841e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -7.68220761622002701e-02 2.11510131771252996e-01 0.00000000000000000e+00,
              -5.35426591433516563e-02 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.39214362274650183e-02 1.58965161928709020e-01 0.00000000000000000e+00,
              -1.89560967153480364e-02 1.25376288801513192e-01 0.00000000000000000e+00,
              -8.31407750673152091e-03 8.77966584710861420e-02 0.00000000000000000e+00,
              -2.32794170188477256e-03 4.58937078371586815e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.32563100269234191e-03 -5.72008532463137154e-02 0.00000000000000000e+00,
              -1.33025240107705889e-02 -1.07750444487242136e-01 0.00000000000000000e+00,
              -2.99306790242338527e-02 -1.51316210622515934e-01 0.00000000000000000e+00,
              -5.32100960430823555e-02 -1.87565588551865947e-01 0.00000000000000000e+00,
              -9.41153573762020246e-02 -2.21154461679061776e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99792901872025652e+02 4.99792901872025652e+02 9.49972286408310964e+01"
        rotation="8.55167184663299773e-01 3.66530412295518748e-01 3.66530412295518804e-01 -1.72662016832750753e+00"
        scale="1.00000000000000022e+00 1.00000000000000022e+00 1.00000000000000022e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            77 76 84 -1 
            76 75 84 -1 
            75 74 84 -1 
            74 73 84 -1 
            97 96 8 -1 
            96 95 8 -1 
            8 95 9 -1 
            95 94 9 -1 
            9 94 10 -1 
            94 93 10 -1 
            10 93 11 -1 
            11 93 12 -1 
            93 92 12 -1 
            12 92 13 -1 
            13 92 14 -1 
            14 92 15 -1 
            92 91 15 -1 
            15 91 16 -1 
            73 72 84 -1 
            37 36 38 -1 
            38 36 39 -1 
            39 36 40 -1 
            36 35 40 -1 
            40 35 41 -1 
            41 35 42 -1 
            42 35 43 -1 
            43 35 44 -1 
            44 35 45 -1 
            45 35 46 -1 
            46 35 47 -1 
            35 34 47 -1 
            34 33 47 -1 
            33 32 47 -1 
            32 31 47 -1 
            31 30 47 -1 
            30 29 47 -1 
            69 68 70 -1 
            70 68 71 -1 
            71 68 72 -1 
            25 24 26 -1 
            26 24 27 -1 
            27 24 28 -1 
            28 24 29 -1 
            24 23 29 -1 
            23 22 29 -1 
            22 21 29 -1 
            21 20 29 -1 
            19 18 20 -1 
            18 17 20 -1 
            17 16 20 -1 
            48 47 49 -1 
            49 47 50 -1 
            47 29 50 -1 
            50 29 51 -1 
            51 29 52 -1 
            52 29 53 -1 
            53 29 54 -1 
            54 29 55 -1 
            55 29 56 -1 
            56 29 57 -1 
            57 29 58 -1 
            29 20 58 -1 
            58 20 59 -1 
            59 20 60 -1 
            60 20 61 -1 
            61 20 62 -1 
            62 20 63 -1 
            63 20 64 -1 
            20 16 64 -1 
            64 16 65 -1 
            65 16 66 -1 
            66 16 67 -1 
            67 16 68 -1 
            16 91 68 -1 
            91 90 68 -1 
            68 90 72 -1 
            90 89 72 -1 
            89 88 72 -1 
            88 87 72 -1 
            87 86 72 -1 
            86 85 72 -1 
            85 84 72 -1 
            77 84 78 -1 
            8 7 97 -1 
            7 6 97 -1 
            6 5 97 -1 
            97 5 98 -1 
            5 4 98 -1 
            4 3 98 -1 
            98 3 99 -1 
            3 2 99 -1 
            99 2 100 -1 
            2 1 100 -1 
            100 1 101 -1 
            1 0 101 -1 
            101 0 102 -1 
            0 83 102 -1 
            83 82 102 -1 
            82 81 102 -1 
            78 84 79 -1 
            81 80 102 -1 
            79 84 80 -1 
            84 109 80 -1 
            109 108 80 -1 
            108 107 80 -1 
            107 106 80 -1 
            106 105 80 -1 
            105 104 80 -1 
            104 103 80 -1 
            102 80 103 -1 
            125 124 126 -1 
            126 124 127 -1 
            127 124 128 -1 
            122 121 123 -1 
            121 120 123 -1 
            120 119 123 -1 
            119 118 123 -1 
            134 133 135 -1 
            133 132 135 -1 
            132 131 135 -1 
            135 131 136 -1 
            131 130 136 -1 
            136 130 137 -1 
            130 129 137 -1 
            137 129 138 -1 
            129 128 138 -1 
            128 124 138 -1 
            124 123 138 -1 
            138 123 139 -1 
            123 118 139 -1 
            139 118 140 -1 
            140 118 141 -1 
            118 117 141 -1 
            141 117 142 -1 
            117 116 142 -1 
            142 116 143 -1 
            116 115 143 -1 
            143 115 144 -1 
            144 115 145 -1 
            145 115 146 -1 
            146 115 147 -1 
            165 164 166 -1 
            166 164 167 -1 
            167 164 168 -1 
            168 164 169 -1 
            164 163 169 -1 
            169 163 170 -1 
            170 163 171 -1 
            171 163 172 -1 
            172 163 173 -1 
            173 163 174 -1 
            174 163 175 -1 
            163 162 175 -1 
            162 161 175 -1 
            161 160 175 -1 
            160 159 175 -1 
            159 158 175 -1 
            158 157 175 -1 
            149 148 150 -1 
            148 147 150 -1 
            150 147 151 -1 
            151 147 152 -1 
            152 147 153 -1 
            153 147 154 -1 
            154 147 155 -1 
            156 155 157 -1 
            115 114 147 -1 
            114 113 147 -1 
            113 112 147 -1 
            112 111 147 -1 
            111 110 147 -1 
            110 189 147 -1 
            176 175 177 -1 
            147 189 155 -1 
            189 188 155 -1 
            188 187 155 -1 
            155 187 157 -1 
            187 186 157 -1 
            186 185 157 -1 
            185 184 157 -1 
            184 183 157 -1 
            183 182 157 -1 
            182 181 157 -1 
            181 180 157 -1 
            177 175 178 -1 
            175 157 178 -1 
            178 157 179 -1 
            180 179 157 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0017"
              point="
              -8.34400818575587899e-01 1.91888908855366358e-01 0.00000000000000000e+00,
              -8.07130644353508120e-01 2.17496267576099800e-01 0.00000000000000000e+00,
              -7.69218450922811825e-01 2.37782616692525040e-01 0.00000000000000000e+00,
              -7.25652684787537972e-01 2.50752577603026383e-01 0.00000000000000000e+00,
              -6.81754355551994928e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -6.50825987226953173e-01 2.52747956204642077e-01 0.00000000000000000e+00,
              -6.21227871302988510e-01 2.45764131098987426e-01 0.00000000000000000e+00,
              -5.93625133980639430e-01 2.34456985689832420e-01 0.00000000000000000e+00,
              -5.68682901460444534e-01 2.19491646177715383e-01 0.00000000000000000e+00,
              -5.46068610642134411e-01 2.00868112562636481e-01 0.00000000000000000e+00,
              -5.25782261525709282e-01 1.78918947944865014e-01 0.00000000000000000e+00,
              -5.07823854111168815e-01 1.53976715424670063e-01 0.00000000000000000e+00,
              -4.93191077699321245e-01 1.27039104302859529e-01 0.00000000000000000e+00,
              -4.81551369189896938e-01 9.74409883788949216e-02 0.00000000000000000e+00,
              -4.72904728582895950e-01 6.61800569535839212e-02 0.00000000000000000e+00,
              -4.67583718978587692e-01 3.32563100269265832e-02 0.00000000000000000e+00,
              -4.65588340376972165e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.67583718978587692e-01 -3.32563100269265832e-02 0.00000000000000000e+00,
              -4.72904728582895950e-01 -6.58474938533146481e-02 0.00000000000000000e+00,
              -4.81551369189896938e-01 -9.71084252786256208e-02 0.00000000000000000e+00,
              -4.93191077699321245e-01 -1.26373978102321038e-01 0.00000000000000000e+00,
              -5.08156417211438116e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -5.26114824625978583e-01 -1.78586384844595741e-01 0.00000000000000000e+00,
              -5.47066299942942313e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -5.69680590761252326e-01 -2.19491646177715438e-01 0.00000000000000000e+00,
              -5.94955386381716522e-01 -2.34789548790101665e-01 0.00000000000000000e+00,
              -6.22890686804334903e-01 -2.46096694199256699e-01 0.00000000000000000e+00,
              -6.53153928928838057e-01 -2.53080519304911267e-01 0.00000000000000000e+00,
              -6.84414860354149002e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -7.30973694391846229e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -7.78197654630081948e-01 -2.40110558394409923e-01 0.00000000000000000e+00,
              -8.20433168364278709e-01 -2.22817277180408113e-01 0.00000000000000000e+00,
              -8.51694099789589765e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -8.55684856992820930e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -8.58677924895244304e-01 -1.92886598156174177e-01 0.00000000000000000e+00,
              -8.61338429697398489e-01 -1.88230714752404465e-01 0.00000000000000000e+00,
              -8.63333808299014072e-01 -1.83242268248365481e-01 0.00000000000000000e+00,
              -8.64664060700091053e-01 -1.77588695543787950e-01 0.00000000000000000e+00,
              -8.63998934499552562e-01 -1.71602559738941174e-01 0.00000000000000000e+00,
              -8.61338429697398489e-01 -1.65948987034363643e-01 0.00000000000000000e+00,
              -8.56682546293628722e-01 -1.59962851229516867e-01 0.00000000000000000e+00,
              -8.51694099789589765e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -8.45707963984742905e-01 -1.52979026123862272e-01 0.00000000000000000e+00,
              -8.40054391280165458e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -8.34733381675857200e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -8.29744935171818243e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -8.24423925567509985e-01 -1.55972094026285674e-01 0.00000000000000000e+00,
              -8.19768042163740218e-01 -1.58632598828439803e-01 0.00000000000000000e+00,
              -8.15777284960509053e-01 -1.61625666730863204e-01 0.00000000000000000e+00,
              -8.12451653957816378e-01 -1.64618734633286579e-01 0.00000000000000000e+00,
              -8.09126022955123703e-01 -1.67279239435440708e-01 0.00000000000000000e+00,
              -8.06798081253238819e-01 -1.69607181137325591e-01 0.00000000000000000e+00,
              -8.05467828852161838e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -7.82853538033851715e-01 -1.82577142047826935e-01 0.00000000000000000e+00,
              -7.50262354207463678e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -7.15010665578921456e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -6.84414860354149002e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -6.59140064733684805e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -6.34197832213489909e-01 -1.94216850557251242e-01 0.00000000000000000e+00,
              -6.10253288994102805e-01 -1.82244578947557689e-01 0.00000000000000000e+00,
              -5.88636687476600473e-01 -1.66281550134632916e-01 0.00000000000000000e+00,
              -5.69015464560713724e-01 -1.46660327218746223e-01 0.00000000000000000e+00,
              -5.52054746446981159e-01 -1.23380910199897609e-01 0.00000000000000000e+00,
              -5.38087096235672080e-01 -9.71084252786256208e-02 0.00000000000000000e+00,
              -5.27112513926786264e-01 -6.71777462543917125e-02 0.00000000000000000e+00,
              -5.20461251921401025e-01 -3.45865624280036477e-02 0.00000000000000000e+00,
              -5.18133310219516141e-01 -6.65126200538546097e-04 0.00000000000000000e+00,
              -5.18798436420054632e-01 1.79584074145403561e-02 0.00000000000000000e+00,
              -5.20461251921401025e-01 3.59168148290807121e-02 0.00000000000000000e+00,
              -5.54715251249135344e-01 1.69607181137325091e-02 0.00000000000000000e+00,
              -5.94955386381716522e-01 2.66050480215407337e-03 0.00000000000000000e+00,
              -6.38521152516990376e-01 -6.31869890511607690e-03 0.00000000000000000e+00,
              -6.82086918652264229e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -7.25985247887807272e-01 -4.65588340376971166e-03 0.00000000000000000e+00,
              -7.69218450922811825e-01 8.64664060700093273e-03 0.00000000000000000e+00,
              -8.07130644353508120e-01 2.89329897234261169e-02 0.00000000000000000e+00,
              -8.34400818575587899e-01 5.45403484441596142e-02 0.00000000000000000e+00,
              -8.44377711583665924e-01 6.98382510565458414e-02 0.00000000000000000e+00,
              -8.52026662889858954e-01 8.74640953708168967e-02 0.00000000000000000e+00,
              -8.57015109393898022e-01 1.05422502785357253e-01 0.00000000000000000e+00,
              -8.58677924895244304e-01 1.23048347099628363e-01 0.00000000000000000e+00,
              -8.57015109393898022e-01 1.40341628313630173e-01 0.00000000000000000e+00,
              -8.52026662889858954e-01 1.58632598828439775e-01 0.00000000000000000e+00,
              -8.44377711583665924e-01 1.76258443142710886e-01 0.00000000000000000e+00,
              -7.92165304841391138e-01 8.54687167692013139e-02 0.00000000000000000e+00,
              -7.74539460527120083e-01 7.01708141568150867e-02 0.00000000000000000e+00,
              -7.45606470803694021e-01 5.62031639455058962e-02 0.00000000000000000e+00,
              -7.12682723877036572e-01 4.58937078371586815e-02 0.00000000000000000e+00,
              -6.81421792451725628e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -6.39186278717528866e-01 4.58937078371586815e-02 0.00000000000000000e+00,
              -5.96285638782793503e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -5.59371134652905111e-01 7.21661927584306695e-02 0.00000000000000000e+00,
              -5.33763775932171614e-01 8.97920370727017247e-02 0.00000000000000000e+00,
              -5.49726804745096387e-01 1.22050657798820572e-01 0.00000000000000000e+00,
              -5.70345716961790927e-01 1.50318521321708143e-01 0.00000000000000000e+00,
              -5.94955386381716522e-01 1.72932812140018211e-01 0.00000000000000000e+00,
              -6.22225560603796302e-01 1.89893530253750775e-01 0.00000000000000000e+00,
              -6.51491113427491775e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -6.81421792451725628e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -7.12682723877036572e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -7.45606470803694021e-01 1.90226093354020076e-01 0.00000000000000000e+00,
              -7.74539460527120083e-01 1.75925880042441585e-01 0.00000000000000000e+00,
              -7.92165304841391138e-01 1.60627977430055358e-01 0.00000000000000000e+00,
              -7.96821188245160905e-01 1.52979026123862272e-01 0.00000000000000000e+00,
              -8.01477071648930561e-01 1.42004443814976455e-01 0.00000000000000000e+00,
              -8.05135265751892537e-01 1.30697298405821449e-01 0.00000000000000000e+00,
              -8.06132955052700328e-01 1.23048347099628363e-01 0.00000000000000000e+00,
              -8.05135265751892537e-01 1.15066832693165977e-01 0.00000000000000000e+00,
              -8.01477071648930561e-01 1.04092250384280161e-01 0.00000000000000000e+00,
              -7.96821188245160905e-01 9.27851049751251544e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 -1.25376288801513219e-01 0.00000000000000000e+00,
              -5.52054746446981603e-02 -9.27851049751251544e-02 0.00000000000000000e+00,
              -6.21892997503527001e-02 -6.98382510565458414e-02 0.00000000000000000e+00,
              -7.38290082597770070e-02 -5.32100960430825221e-02 0.00000000000000000e+00,
              -8.84617846716246881e-02 -3.85773196312348410e-02 0.00000000000000000e+00,
              -1.07085318286703646e-01 -2.66050480215412610e-02 0.00000000000000000e+00,
              -1.29367046004744468e-01 -1.72932812140018100e-02 0.00000000000000000e+00,
              -1.54309278524939364e-01 -1.13071454091550616e-02 0.00000000000000000e+00,
              -1.80914326546480653e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.35556168171689206e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.45865624280036532e-01 -7.31638820592386829e-03 0.00000000000000000e+00,
              -3.54179701786768053e-01 -1.66281550134633749e-03 0.00000000000000000e+00,
              -3.60165837591614912e-01 6.31869890511604915e-03 0.00000000000000000e+00,
              -3.61828653092961194e-01 1.59630288129247178e-02 0.00000000000000000e+00,
              -3.61828653092961194e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -3.60165837591614912e-01 2.39777995294140678e-01 0.00000000000000000e+00,
              -3.54179701786768053e-01 2.48092072800872310e-01 0.00000000000000000e+00,
              -3.45865624280036532e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -3.35556168171689206e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -2.62724849212719880e-02 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.66281550134632639e-02 2.53413082405180567e-01 0.00000000000000000e+00,
              -7.98151440646244215e-03 2.47759509700603009e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.39112869093602132e-01 0.00000000000000000e+00,
              0.00000000000000000e+00 2.29468539185793352e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.19491646177715383e-01 0.00000000000000000e+00,
              -7.98151440646244215e-03 2.11177568670983806e-01 0.00000000000000000e+00,
              -1.66281550134632639e-02 2.05523995966406303e-01 0.00000000000000000e+00,
              -2.62724849212719880e-02 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417218e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417218e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.80914326546480653e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.44664948617130640e-01 3.95750089320426324e-02 0.00000000000000000e+00,
              -1.10410949289396210e-01 3.09283683250417551e-02 0.00000000000000000e+00,
              -7.91500178640852647e-02 1.72932812140018100e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.09283683250416441e-02 -2.19491646177715771e-02 0.00000000000000000e+00,
              -1.43002133115783803e-02 -4.75565233385050190e-02 0.00000000000000000e+00,
              -3.65819410296186476e-03 -7.61569499616618906e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 -1.06420192086165072e-01 0.00000000000000000e+00,
              -3.65819410296186476e-03 -1.36683434210668253e-01 0.00000000000000000e+00,
              -1.43002133115783803e-02 -1.65283860833825125e-01 0.00000000000000000e+00,
              -3.09283683250416441e-02 -1.91223782654827867e-01 0.00000000000000000e+00,
              -5.25449698425439760e-02 -2.12840384172330144e-01 0.00000000000000000e+00,
              -7.91500178640852647e-02 -2.30466228486601199e-01 0.00000000000000000e+00,
              -1.10410949289396210e-01 -2.44101315597641116e-01 0.00000000000000000e+00,
              -1.44664948617130640e-01 -2.52747956204642021e-01 0.00000000000000000e+00,
              -1.80914326546480653e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.27805723684447070e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -2.75362247022952089e-01 -2.40110558394409923e-01 0.00000000000000000e+00,
              -3.17930323857418040e-01 -2.22817277180408113e-01 0.00000000000000000e+00,
              -3.49523818382998397e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -3.53514575586229562e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -3.56507643488652937e-01 -1.92886598156174177e-01 0.00000000000000000e+00,
              -3.59168148290807010e-01 -1.88230714752404465e-01 0.00000000000000000e+00,
              -3.61163526892422704e-01 -1.83242268248365481e-01 0.00000000000000000e+00,
              -3.62493779293499685e-01 -1.77588695543787950e-01 0.00000000000000000e+00,
              -3.61496089992692005e-01 -1.71602559738941174e-01 0.00000000000000000e+00,
              -3.59168148290807010e-01 -1.65948987034363643e-01 0.00000000000000000e+00,
              -3.54512264887037354e-01 -1.59962851229516867e-01 0.00000000000000000e+00,
              -3.49191255282729096e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -3.43205119477882237e-01 -1.52979026123862272e-01 0.00000000000000000e+00,
              -3.37551546773304789e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.32563100269265832e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.27574653765226764e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -3.22253644160918618e-01 -1.55972094026285674e-01 0.00000000000000000e+00,
              -3.17597760757148850e-01 -1.58632598828439803e-01 0.00000000000000000e+00,
              -3.13607003553917685e-01 -1.61625666730863204e-01 0.00000000000000000e+00,
              -3.10281372551225010e-01 -1.64618734633286579e-01 0.00000000000000000e+00,
              -3.06955741548532335e-01 -1.67279239435440708e-01 0.00000000000000000e+00,
              -3.04295236746378261e-01 -1.69607181137325591e-01 0.00000000000000000e+00,
              -3.02964984345301169e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -2.80350693526991157e-01 -1.82577142047826935e-01 0.00000000000000000e+00,
              -2.47426946600333819e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -2.11842694871522297e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -1.80914326546480653e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.54309278524939364e-01 -2.01865801863444355e-01 0.00000000000000000e+00,
              -1.29367046004744468e-01 -1.95879666058597579e-01 0.00000000000000000e+00,
              -1.07085318286703646e-01 -1.86567899251058128e-01 0.00000000000000000e+00,
              -8.84617846716246881e-02 -1.74263064541095303e-01 0.00000000000000000e+00,
              -7.11685034576228226e-02 -1.55639530926016401e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="5.19997228640831054e+02 4.99997228640831111e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            12 11 10 9 8 7 6 5 4 3 2 1 -1 
            0 1 2 -1 
            0 2 3 -1 
            0 3 4 -1 
            0 4 5 -1 
            0 5 6 -1 
            0 6 7 -1 
            0 7 8 -1 
            0 8 9 -1 
            0 9 10 -1 
            0 10 11 -1 
            0 11 12 -1 
            0 12 1 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0018"
              point="
              1.38567954301834106e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.38567954301834106e-01 1.38567954301834106e-01 0.00000000000000000e+00,
              -1.38567954301834106e-01 1.20003372430801392e-01 6.92839771509170532e-02,
              -1.38567954301834106e-01 6.92839771509170532e-02 1.20003372430801392e-01,
              -1.38567954301834106e-01 8.48484070202902116e-18 1.38567954301834106e-01,
              -1.38567954301834106e-01 -6.92839771509170532e-02 1.20003372430801392e-01,
              -1.38567954301834106e-01 -1.20003372430801392e-01 6.92839771509170532e-02,
              -1.38567954301834106e-01 -1.38567954301834106e-01 1.69696814040580423e-17,
              -1.38567954301834106e-01 -1.20003372430801392e-01 -6.92839771509170532e-02,
              -1.38567954301834106e-01 -6.92839771509170532e-02 -1.20003372430801392e-01,
              -1.38567954301834106e-01 -2.54545212789064509e-17 -1.38567954301834106e-01,
              -1.38567954301834106e-01 6.92839771509170532e-02 -1.20003372430801392e-01,
              -1.38567954301834106e-01 1.20003372430801392e-01 -6.92839771509170532e-02,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99997228640831111e+02 5.20997228640831054e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            12 11 10 9 8 7 6 5 4 3 2 1 -1 
            0 1 2 -1 
            0 2 3 -1 
            0 3 4 -1 
            0 4 5 -1 
            0 5 6 -1 
            0 6 7 -1 
            0 7 8 -1 
            0 8 9 -1 
            0 9 10 -1 
            0 10 11 -1 
            0 11 12 -1 
            0 12 1 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0019"
              point="
              0.00000000000000000e+00 1.38567954301834106e-01 -1.19993756148116758e-17,
              1.38567954301834106e-01 -1.38567954301834106e-01 2.39987512296233516e-17,
              1.20003372430801392e-01 -1.38567954301834106e-01 -6.92839771509170532e-02,
              6.92839771509170532e-02 -1.38567954301834106e-01 -1.20003372430801392e-01,
              2.04842163168406969e-17 -1.38567954301834106e-01 -1.38567954301834106e-01,
              -6.92839771509170532e-02 -1.38567954301834106e-01 -1.20003372430801392e-01,
              -1.20003372430801392e-01 -1.38567954301834106e-01 -6.92839771509170532e-02,
              -1.38567954301834106e-01 -1.38567954301834106e-01 -1.69696814040580423e-17,
              -1.20003372430801392e-01 -1.38567954301834106e-01 6.92839771509170532e-02,
              -6.92839771509170532e-02 -1.38567954301834106e-01 1.20003372430801392e-01,
              -3.74538977208987392e-17 -1.38567954301834106e-01 1.38567954301834106e-01,
              6.92839771509170532e-02 -1.38567954301834106e-01 1.20003372430801392e-01,
              1.20003372430801392e-01 -1.38567954301834106e-01 6.92839771509170532e-02,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99997228640831111e+02 4.99997228640831111e+02 9.99972286408310964e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            12 11 10 9 8 7 6 5 4 3 2 1 -1 
            0 1 2 -1 
            0 2 3 -1 
            0 3 4 -1 
            0 4 5 -1 
            0 5 6 -1 
            0 6 7 -1 
            0 7 8 -1 
            0 8 9 -1 
            0 9 10 -1 
            0 10 11 -1 
            0 11 12 -1 
            0 12 1 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0020"
              point="
              0.00000000000000000e+00 1.19993756148116758e-17 1.38567954301834106e-01,
              -1.19993756148116758e-17 -1.38567954301834106e-01 -1.38567954301834106e-01,
              6.92839771509170532e-02 -1.20003372430801392e-01 -1.38567954301834106e-01,
              1.20003372430801392e-01 -6.92839771509170532e-02 -1.38567954301834106e-01,
              1.38567954301834106e-01 -3.24835911044717602e-17 -1.38567954301834106e-01,
              1.20003372430801392e-01 6.92839771509170532e-02 -1.38567954301834106e-01,
              6.92839771509170532e-02 1.20003372430801392e-01 -1.38567954301834106e-01,
              2.89690561916891055e-17 1.38567954301834106e-01 -1.38567954301834106e-01,
              -6.92839771509170532e-02 1.20003372430801392e-01 -1.38567954301834106e-01,
              -1.20003372430801392e-01 6.92839771509170532e-02 -1.38567954301834106e-01,
              -1.38567954301834106e-01 2.54545212789064509e-17 -1.38567954301834106e-01,
              -1.20003372430801392e-01 -6.92839771509170532e-02 -1.38567954301834106e-01,
              -6.92839771509170532e-02 -1.20003372430801392e-01 -1.38567954301834106e-01,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="0.00000000000000000e+00"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              specularColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              shininess="7.81250000000000000e-03"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="true"
            normalPerVertex="true"
            coordIndex="
            1 50 52 -1 
            1 3 51 -1 
            50 1 51 -1 
            51 3 53 -1 
            0 1 52 -1 
            0 52 9 -1 
            9 52 63 -1 
            2 1 0 -1 
            3 1 2 -1 
            3 54 53 -1 
            3 2 54 -1 
            2 4 54 -1 
            5 56 4 -1 
            4 56 54 -1 
            6 58 5 -1 
            5 58 56 -1 
            7 59 6 -1 
            6 59 58 -1 
            8 60 7 -1 
            7 60 59 -1 
            10 12 61 -1 
            10 61 8 -1 
            8 61 60 -1 
            12 14 62 -1 
            61 12 62 -1 
            62 14 64 -1 
            9 63 15 -1 
            63 65 15 -1 
            10 11 12 -1 
            13 12 11 -1 
            14 12 13 -1 
            14 13 16 -1 
            14 16 64 -1 
            64 16 68 -1 
            20 66 70 -1 
            20 65 66 -1 
            20 18 65 -1 
            18 15 65 -1 
            17 71 69 -1 
            21 71 17 -1 
            68 16 17 -1 
            69 68 17 -1 
            19 20 70 -1 
            19 70 22 -1 
            22 70 72 -1 
            18 20 19 -1 
            21 23 71 -1 
            71 23 73 -1 
            22 72 25 -1 
            72 75 25 -1 
            24 78 74 -1 
            26 78 24 -1 
            73 23 24 -1 
            74 73 24 -1 
            29 76 79 -1 
            29 75 76 -1 
            29 27 75 -1 
            27 25 75 -1 
            26 30 78 -1 
            78 30 80 -1 
            28 29 79 -1 
            28 79 32 -1 
            32 79 81 -1 
            27 29 28 -1 
            31 34 35 -1 
            31 35 83 -1 
            31 83 30 -1 
            30 83 80 -1 
            32 81 82 -1 
            33 32 82 -1 
            82 36 33 -1 
            85 36 82 -1 
            35 89 84 -1 
            35 34 89 -1 
            34 40 89 -1 
            83 35 84 -1 
            36 85 87 -1 
            37 36 87 -1 
            38 37 88 -1 
            37 87 88 -1 
            42 88 90 -1 
            42 39 88 -1 
            39 38 88 -1 
            39 42 41 -1 
            40 45 89 -1 
            89 45 94 -1 
            42 90 91 -1 
            41 42 91 -1 
            41 91 43 -1 
            43 91 92 -1 
            44 43 93 -1 
            43 92 93 -1 
            93 47 44 -1 
            96 47 93 -1 
            46 98 95 -1 
            48 98 46 -1 
            94 45 46 -1 
            95 94 46 -1 
            47 96 99 -1 
            49 47 99 -1 
            98 48 49 -1 
            99 98 49 -1 
            50 100 104 -1 
            52 50 104 -1 
            51 101 50 -1 
            50 101 100 -1 
            53 55 102 -1 
            53 102 51 -1 
            51 102 101 -1 
            55 57 103 -1 
            102 55 103 -1 
            103 57 105 -1 
            52 104 63 -1 
            104 111 63 -1 
            53 54 55 -1 
            56 55 54 -1 
            57 55 56 -1 
            57 106 105 -1 
            57 56 106 -1 
            56 58 106 -1 
            59 107 58 -1 
            58 107 106 -1 
            60 108 59 -1 
            59 108 107 -1 
            61 109 60 -1 
            60 109 108 -1 
            62 110 61 -1 
            61 110 109 -1 
            110 62 64 -1 
            113 110 64 -1 
            67 112 115 -1 
            67 111 112 -1 
            67 65 111 -1 
            65 63 111 -1 
            64 68 113 -1 
            113 68 116 -1 
            66 67 115 -1 
            66 115 70 -1 
            70 115 118 -1 
            65 67 66 -1 
            69 119 117 -1 
            71 119 69 -1 
            116 68 69 -1 
            117 116 69 -1 
            70 118 72 -1 
            118 120 72 -1 
            71 73 119 -1 
            119 73 122 -1 
            77 121 124 -1 
            77 120 121 -1 
            77 75 120 -1 
            75 72 120 -1 
            74 125 123 -1 
            78 125 74 -1 
            122 73 74 -1 
            123 122 74 -1 
            76 77 124 -1 
            76 124 79 -1 
            79 124 127 -1 
            75 77 76 -1 
            78 80 125 -1 
            125 80 128 -1 
            79 127 81 -1 
            127 129 81 -1 
            80 83 128 -1 
            128 83 130 -1 
            86 82 81 -1 
            86 81 132 -1 
            132 81 129 -1 
            82 86 85 -1 
            84 136 131 -1 
            89 136 84 -1 
            130 83 84 -1 
            131 130 84 -1 
            86 132 133 -1 
            85 86 133 -1 
            85 133 87 -1 
            87 133 134 -1 
            88 87 135 -1 
            87 134 135 -1 
            135 90 88 -1 
            137 90 135 -1 
            89 94 136 -1 
            136 94 140 -1 
            90 137 138 -1 
            91 90 138 -1 
            92 91 139 -1 
            91 138 139 -1 
            97 139 142 -1 
            97 93 139 -1 
            93 92 139 -1 
            93 97 96 -1 
            95 144 141 -1 
            98 144 95 -1 
            140 94 95 -1 
            141 140 95 -1 
            97 142 143 -1 
            96 97 143 -1 
            96 143 99 -1 
            99 143 145 -1 
            144 98 99 -1 
            145 144 99 -1 
            100 146 150 -1 
            104 100 150 -1 
            101 147 100 -1 
            100 147 146 -1 
            102 148 101 -1 
            101 148 147 -1 
            103 149 102 -1 
            102 149 148 -1 
            149 103 105 -1 
            154 149 105 -1 
            104 150 111 -1 
            150 160 111 -1 
            105 156 154 -1 
            106 156 105 -1 
            107 157 106 -1 
            106 157 156 -1 
            108 158 107 -1 
            107 158 157 -1 
            109 159 108 -1 
            108 159 158 -1 
            114 169 159 -1 
            114 159 110 -1 
            110 159 109 -1 
            110 113 114 -1 
            112 161 172 -1 
            115 112 172 -1 
            160 112 111 -1 
            161 112 160 -1 
            114 171 169 -1 
            171 114 113 -1 
            171 113 182 -1 
            182 113 116 -1 
            115 172 118 -1 
            172 185 118 -1 
            117 194 183 -1 
            119 194 117 -1 
            182 116 117 -1 
            183 182 117 -1 
            118 185 120 -1 
            185 196 120 -1 
            119 122 194 -1 
            194 122 206 -1 
            121 197 208 -1 
            124 121 208 -1 
            196 121 120 -1 
            197 121 196 -1 
            123 125 126 -1 
            123 126 218 -1 
            123 218 122 -1 
            122 218 206 -1 
            124 208 127 -1 
            208 221 127 -1 
            126 230 219 -1 
            126 125 230 -1 
            125 128 230 -1 
            218 126 219 -1 
            127 221 129 -1 
            221 232 129 -1 
            128 130 230 -1 
            230 130 241 -1 
            129 232 132 -1 
            232 244 132 -1 
            131 255 242 -1 
            136 255 131 -1 
            241 130 131 -1 
            242 241 131 -1 
            132 244 245 -1 
            133 132 245 -1 
            134 133 247 -1 
            133 245 247 -1 
            135 134 249 -1 
            134 247 249 -1 
            249 137 135 -1 
            257 137 249 -1 
            136 140 255 -1 
            255 140 264 -1 
            137 257 258 -1 
            138 137 258 -1 
            139 138 260 -1 
            138 258 260 -1 
            260 142 139 -1 
            267 142 260 -1 
            141 270 265 -1 
            144 270 141 -1 
            264 140 141 -1 
            265 264 141 -1 
            142 267 268 -1 
            143 142 268 -1 
            145 143 271 -1 
            143 268 271 -1 
            270 144 145 -1 
            271 270 145 -1 
            146 151 150 -1 
            146 147 152 -1 
            151 146 152 -1 
            147 148 153 -1 
            152 147 153 -1 
            148 149 155 -1 
            153 148 155 -1 
            149 154 155 -1 
            151 162 160 -1 
            150 151 160 -1 
            152 163 151 -1 
            163 162 151 -1 
            153 164 152 -1 
            164 163 152 -1 
            155 165 153 -1 
            165 164 153 -1 
            154 156 166 -1 
            154 166 155 -1 
            155 166 165 -1 
            156 157 167 -1 
            166 156 167 -1 
            157 158 168 -1 
            167 157 168 -1 
            158 159 170 -1 
            168 158 170 -1 
            159 169 170 -1 
            161 173 172 -1 
            161 160 162 -1 
            161 162 173 -1 
            173 162 174 -1 
            163 175 162 -1 
            175 174 162 -1 
            164 176 163 -1 
            176 175 163 -1 
            165 177 164 -1 
            177 176 164 -1 
            166 178 165 -1 
            178 177 165 -1 
            167 179 166 -1 
            179 178 166 -1 
            168 180 167 -1 
            180 179 167 -1 
            170 181 168 -1 
            181 180 168 -1 
            169 171 184 -1 
            169 184 170 -1 
            170 184 181 -1 
            171 182 184 -1 
            173 186 185 -1 
            172 173 185 -1 
            174 187 173 -1 
            187 186 173 -1 
            175 188 174 -1 
            188 187 174 -1 
            176 189 175 -1 
            189 188 175 -1 
            177 190 176 -1 
            190 189 176 -1 
            178 191 177 -1 
            191 190 177 -1 
            179 192 178 -1 
            192 191 178 -1 
            180 193 179 -1 
            193 192 179 -1 
            181 195 180 -1 
            195 193 180 -1 
            183 194 195 -1 
            183 195 184 -1 
            184 195 181 -1 
            182 183 184 -1 
            186 198 196 -1 
            185 186 196 -1 
            187 199 186 -1 
            199 198 186 -1 
            188 200 187 -1 
            200 199 187 -1 
            189 201 188 -1 
            201 200 188 -1 
            190 202 189 -1 
            202 201 189 -1 
            191 203 190 -1 
            203 202 190 -1 
            192 204 191 -1 
            204 203 191 -1 
            193 205 192 -1 
            205 204 192 -1 
            195 207 193 -1 
            207 205 193 -1 
            194 206 207 -1 
            195 194 207 -1 
            197 209 208 -1 
            197 196 198 -1 
            197 198 209 -1 
            209 198 210 -1 
            199 211 198 -1 
            211 210 198 -1 
            200 212 199 -1 
            212 211 199 -1 
            201 213 200 -1 
            213 212 200 -1 
            202 214 201 -1 
            214 213 201 -1 
            203 215 202 -1 
            215 214 202 -1 
            204 216 203 -1 
            216 215 203 -1 
            205 217 204 -1 
            217 216 204 -1 
            207 220 205 -1 
            220 217 205 -1 
            206 218 220 -1 
            207 206 220 -1 
            209 222 221 -1 
            208 209 221 -1 
            210 223 209 -1 
            223 222 209 -1 
            211 224 210 -1 
            224 223 210 -1 
            212 225 211 -1 
            225 224 211 -1 
            213 226 212 -1 
            226 225 212 -1 
            214 227 213 -1 
            227 226 213 -1 
            215 228 214 -1 
            228 227 214 -1 
            216 229 215 -1 
            229 228 215 -1 
            217 231 216 -1 
            231 229 216 -1 
            219 230 231 -1 
            219 231 220 -1 
            220 231 217 -1 
            218 219 220 -1 
            222 233 232 -1 
            221 222 232 -1 
            223 234 222 -1 
            234 233 222 -1 
            224 235 223 -1 
            235 234 223 -1 
            225 236 224 -1 
            236 235 224 -1 
            226 237 225 -1 
            237 236 225 -1 
            227 238 226 -1 
            238 237 226 -1 
            228 239 227 -1 
            239 238 227 -1 
            229 240 228 -1 
            240 239 228 -1 
            231 243 229 -1 
            243 240 229 -1 
            230 241 243 -1 
            231 230 243 -1 
            233 246 244 -1 
            232 233 244 -1 
            234 248 233 -1 
            248 246 233 -1 
            235 250 234 -1 
            250 248 234 -1 
            236 251 235 -1 
            251 250 235 -1 
            237 252 236 -1 
            252 251 236 -1 
            238 253 237 -1 
            253 252 237 -1 
            239 254 238 -1 
            254 253 238 -1 
            240 256 239 -1 
            256 254 239 -1 
            242 255 256 -1 
            242 256 243 -1 
            243 256 240 -1 
            241 242 243 -1 
            244 246 245 -1 
            247 245 246 -1 
            248 247 246 -1 
            249 247 248 -1 
            250 249 248 -1 
            257 249 250 -1 
            257 250 259 -1 
            259 250 251 -1 
            252 261 251 -1 
            261 259 251 -1 
            253 262 252 -1 
            262 261 252 -1 
            254 263 253 -1 
            263 262 253 -1 
            256 266 254 -1 
            266 263 254 -1 
            255 264 266 -1 
            256 255 266 -1 
            257 259 258 -1 
            260 258 259 -1 
            261 260 259 -1 
            267 260 261 -1 
            267 261 269 -1 
            269 261 262 -1 
            263 272 262 -1 
            272 269 262 -1 
            265 270 272 -1 
            265 272 266 -1 
            266 272 263 -1 
            264 265 266 -1 
            267 269 268 -1 
            271 268 269 -1 
            272 271 269 -1 
            270 271 272 -1 
            273 325 337 -1 
            273 274 327 -1 
            325 273 327 -1 
            274 275 329 -1 
            327 274 329 -1 
            329 275 339 -1 
            276 273 337 -1 
            345 276 337 -1 
            274 273 277 -1 
            277 273 276 -1 
            275 274 278 -1 
            278 274 277 -1 
            349 339 275 -1 
            278 349 275 -1 
            276 345 347 -1 
            277 276 347 -1 
            348 277 347 -1 
            278 277 348 -1 
            350 278 348 -1 
            349 278 350 -1 
            279 376 378 -1 
            376 279 380 -1 
            280 377 384 -1 
            280 281 379 -1 
            377 280 379 -1 
            378 379 281 -1 
            378 281 279 -1 
            279 281 282 -1 
            382 380 279 -1 
            382 279 283 -1 
            283 279 282 -1 
            382 283 389 -1 
            284 383 392 -1 
            284 285 385 -1 
            383 284 385 -1 
            384 385 285 -1 
            384 285 280 -1 
            280 285 286 -1 
            281 280 287 -1 
            287 280 286 -1 
            282 281 288 -1 
            288 281 287 -1 
            283 282 289 -1 
            289 282 288 -1 
            399 389 283 -1 
            289 399 283 -1 
            290 391 402 -1 
            290 291 393 -1 
            391 290 393 -1 
            392 393 291 -1 
            392 291 284 -1 
            284 291 292 -1 
            285 284 293 -1 
            293 284 292 -1 
            286 285 294 -1 
            294 285 293 -1 
            287 286 295 -1 
            295 286 294 -1 
            288 287 296 -1 
            296 287 295 -1 
            289 288 297 -1 
            297 288 296 -1 
            401 399 289 -1 
            401 289 298 -1 
            298 289 297 -1 
            401 298 412 -1 
            299 403 414 -1 
            402 403 299 -1 
            402 299 290 -1 
            290 299 300 -1 
            291 290 301 -1 
            301 290 300 -1 
            292 291 302 -1 
            302 291 301 -1 
            293 292 303 -1 
            303 292 302 -1 
            294 293 304 -1 
            304 293 303 -1 
            295 294 305 -1 
            305 294 304 -1 
            296 295 306 -1 
            306 295 305 -1 
            297 296 307 -1 
            307 296 306 -1 
            298 297 308 -1 
            308 297 307 -1 
            425 412 298 -1 
            308 425 298 -1 
            414 415 309 -1 
            414 309 299 -1 
            299 309 310 -1 
            300 299 311 -1 
            311 299 310 -1 
            301 300 312 -1 
            312 300 311 -1 
            302 301 313 -1 
            313 301 312 -1 
            303 302 314 -1 
            314 302 313 -1 
            304 303 315 -1 
            315 303 314 -1 
            305 304 316 -1 
            316 304 315 -1 
            306 305 317 -1 
            317 305 316 -1 
            307 306 318 -1 
            318 306 317 -1 
            308 307 319 -1 
            319 307 318 -1 
            427 425 308 -1 
            427 308 320 -1 
            320 308 319 -1 
            427 320 440 -1 
            428 442 321 -1 
            428 321 309 -1 
            309 321 310 -1 
            311 310 322 -1 
            322 310 321 -1 
            312 311 323 -1 
            323 311 322 -1 
            313 312 324 -1 
            324 312 323 -1 
            314 313 326 -1 
            326 313 324 -1 
            315 314 328 -1 
            328 314 326 -1 
            316 315 330 -1 
            330 315 328 -1 
            317 316 331 -1 
            331 316 330 -1 
            318 317 332 -1 
            332 317 331 -1 
            319 318 333 -1 
            333 318 332 -1 
            320 319 334 -1 
            334 319 333 -1 
            454 440 320 -1 
            334 454 320 -1 
            321 442 443 -1 
            443 456 335 -1 
            443 335 321 -1 
            321 335 322 -1 
            323 322 336 -1 
            336 322 335 -1 
            324 323 338 -1 
            338 323 336 -1 
            325 338 337 -1 
            325 326 338 -1 
            326 324 338 -1 
            327 326 325 -1 
            328 326 327 -1 
            329 328 327 -1 
            330 328 329 -1 
            339 330 329 -1 
            339 340 330 -1 
            340 331 330 -1 
            332 331 341 -1 
            341 331 340 -1 
            333 332 342 -1 
            342 332 341 -1 
            334 333 343 -1 
            343 333 342 -1 
            464 454 334 -1 
            343 464 334 -1 
            335 456 457 -1 
            457 467 344 -1 
            457 344 335 -1 
            335 344 336 -1 
            338 336 346 -1 
            346 336 344 -1 
            337 346 345 -1 
            338 346 337 -1 
            340 349 351 -1 
            339 349 340 -1 
            341 340 352 -1 
            352 340 351 -1 
            342 341 353 -1 
            353 341 352 -1 
            473 465 343 -1 
            473 343 353 -1 
            353 343 342 -1 
            464 343 465 -1 
            344 467 468 -1 
            468 475 354 -1 
            468 354 344 -1 
            344 354 346 -1 
            345 355 347 -1 
            345 346 355 -1 
            346 354 355 -1 
            347 356 348 -1 
            355 356 347 -1 
            348 357 350 -1 
            356 357 348 -1 
            350 351 349 -1 
            350 357 351 -1 
            357 358 351 -1 
            352 351 359 -1 
            359 351 358 -1 
            353 352 360 -1 
            360 352 359 -1 
            483 473 353 -1 
            360 483 353 -1 
            354 475 476 -1 
            476 485 361 -1 
            476 361 354 -1 
            354 361 355 -1 
            356 355 362 -1 
            362 355 361 -1 
            357 356 363 -1 
            363 356 362 -1 
            358 357 364 -1 
            364 357 363 -1 
            359 358 365 -1 
            365 358 364 -1 
            360 359 366 -1 
            366 359 365 -1 
            492 483 360 -1 
            366 492 360 -1 
            361 485 486 -1 
            486 495 367 -1 
            486 367 361 -1 
            361 367 362 -1 
            363 362 368 -1 
            368 362 367 -1 
            364 363 369 -1 
            369 363 368 -1 
            365 364 370 -1 
            370 364 369 -1 
            500 493 366 -1 
            500 366 370 -1 
            370 366 365 -1 
            492 366 493 -1 
            367 495 496 -1 
            496 502 371 -1 
            496 371 367 -1 
            367 371 368 -1 
            369 368 372 -1 
            372 368 371 -1 
            370 369 373 -1 
            373 369 372 -1 
            506 500 370 -1 
            373 506 370 -1 
            371 502 503 -1 
            503 508 374 -1 
            503 374 371 -1 
            371 374 372 -1 
            373 372 375 -1 
            375 372 374 -1 
            511 506 373 -1 
            375 511 373 -1 
            374 508 509 -1 
            375 374 509 -1 
            512 375 509 -1 
            511 375 512 -1 
            376 381 378 -1 
            376 380 381 -1 
            377 386 384 -1 
            377 379 387 -1 
            386 377 387 -1 
            379 378 381 -1 
            379 381 387 -1 
            387 381 388 -1 
            380 382 390 -1 
            380 390 381 -1 
            381 390 388 -1 
            382 389 390 -1 
            383 394 392 -1 
            383 385 395 -1 
            394 383 395 -1 
            385 384 386 -1 
            385 386 395 -1 
            395 386 396 -1 
            387 397 386 -1 
            397 396 386 -1 
            388 398 387 -1 
            398 397 387 -1 
            390 400 388 -1 
            400 398 388 -1 
            389 399 400 -1 
            390 389 400 -1 
            391 404 402 -1 
            391 393 405 -1 
            404 391 405 -1 
            393 392 394 -1 
            393 394 405 -1 
            405 394 406 -1 
            395 407 394 -1 
            407 406 394 -1 
            396 408 395 -1 
            408 407 395 -1 
            397 409 396 -1 
            409 408 396 -1 
            398 410 397 -1 
            410 409 397 -1 
            400 411 398 -1 
            411 410 398 -1 
            399 401 413 -1 
            399 413 400 -1 
            400 413 411 -1 
            401 412 413 -1 
            403 416 414 -1 
            403 402 404 -1 
            403 404 416 -1 
            416 404 417 -1 
            405 418 404 -1 
            418 417 404 -1 
            406 419 405 -1 
            419 418 405 -1 
            407 420 406 -1 
            420 419 406 -1 
            408 421 407 -1 
            421 420 407 -1 
            409 422 408 -1 
            422 421 408 -1 
            410 423 409 -1 
            423 422 409 -1 
            411 424 410 -1 
            424 423 410 -1 
            413 426 411 -1 
            426 424 411 -1 
            412 425 426 -1 
            413 412 426 -1 
            415 414 416 -1 
            415 416 429 -1 
            429 416 430 -1 
            417 431 416 -1 
            431 430 416 -1 
            418 432 417 -1 
            432 431 417 -1 
            419 433 418 -1 
            433 432 418 -1 
            420 434 419 -1 
            434 433 419 -1 
            421 435 420 -1 
            435 434 420 -1 
            422 436 421 -1 
            436 435 421 -1 
            423 437 422 -1 
            437 436 422 -1 
            424 438 423 -1 
            438 437 423 -1 
            426 439 424 -1 
            439 438 424 -1 
            425 427 441 -1 
            425 441 426 -1 
            426 441 439 -1 
            427 440 441 -1 
            442 428 429 -1 
            442 429 444 -1 
            444 429 430 -1 
            431 445 430 -1 
            445 444 430 -1 
            432 446 431 -1 
            446 445 431 -1 
            433 447 432 -1 
            447 446 432 -1 
            434 448 433 -1 
            448 447 433 -1 
            435 449 434 -1 
            449 448 434 -1 
            436 450 435 -1 
            450 449 435 -1 
            437 451 436 -1 
            451 450 436 -1 
            438 452 437 -1 
            452 451 437 -1 
            439 453 438 -1 
            453 452 438 -1 
            441 455 439 -1 
            455 453 439 -1 
            440 454 455 -1 
            441 440 455 -1 
            442 444 443 -1 
            456 443 444 -1 
            456 444 458 -1 
            458 444 445 -1 
            446 459 445 -1 
            459 458 445 -1 
            447 460 446 -1 
            460 459 446 -1 
            517 448 514 -1 
            517 460 448 -1 
            460 447 448 -1 
            449 514 448 -1 
            515 514 449 -1 
            450 515 449 -1 
            516 515 450 -1 
            516 461 520 -1 
            516 450 461 -1 
            450 451 461 -1 
            452 462 451 -1 
            462 461 451 -1 
            453 463 452 -1 
            463 462 452 -1 
            455 466 453 -1 
            466 463 453 -1 
            454 464 466 -1 
            455 454 466 -1 
            456 458 457 -1 
            467 457 458 -1 
            467 458 469 -1 
            469 458 459 -1 
            460 470 459 -1 
            470 469 459 -1 
            522 460 517 -1 
            470 460 522 -1 
            471 520 461 -1 
            527 520 471 -1 
            462 472 461 -1 
            472 471 461 -1 
            463 474 462 -1 
            474 472 462 -1 
            465 473 474 -1 
            465 474 466 -1 
            466 474 463 -1 
            464 465 466 -1 
            467 469 468 -1 
            475 468 469 -1 
            475 469 477 -1 
            477 469 470 -1 
            523 470 522 -1 
            523 478 470 -1 
            478 477 470 -1 
            478 525 479 -1 
            523 525 478 -1 
            479 528 480 -1 
            525 528 479 -1 
            527 480 528 -1 
            527 471 480 -1 
            471 481 480 -1 
            472 482 471 -1 
            482 481 471 -1 
            474 484 472 -1 
            484 482 472 -1 
            473 483 484 -1 
            474 473 484 -1 
            475 477 476 -1 
            485 476 477 -1 
            485 477 487 -1 
            487 477 478 -1 
            479 488 478 -1 
            488 487 478 -1 
            480 489 479 -1 
            489 488 479 -1 
            481 490 480 -1 
            490 489 480 -1 
            482 491 481 -1 
            491 490 481 -1 
            484 494 482 -1 
            494 491 482 -1 
            483 492 494 -1 
            484 483 494 -1 
            485 487 486 -1 
            495 486 487 -1 
            495 487 497 -1 
            497 487 488 -1 
            489 498 488 -1 
            498 497 488 -1 
            490 499 489 -1 
            499 498 489 -1 
            491 501 490 -1 
            501 499 490 -1 
            493 500 501 -1 
            493 501 494 -1 
            494 501 491 -1 
            492 493 494 -1 
            495 497 496 -1 
            502 496 497 -1 
            502 497 504 -1 
            504 497 498 -1 
            499 505 498 -1 
            505 504 498 -1 
            501 507 499 -1 
            507 505 499 -1 
            500 506 507 -1 
            501 500 507 -1 
            502 504 503 -1 
            508 503 504 -1 
            508 504 510 -1 
            510 504 505 -1 
            507 513 505 -1 
            513 510 505 -1 
            506 511 513 -1 
            507 506 513 -1 
            508 510 509 -1 
            512 509 510 -1 
            513 512 510 -1 
            511 512 513 -1 
            514 518 517 -1 
            514 515 519 -1 
            518 514 519 -1 
            515 516 521 -1 
            519 515 521 -1 
            516 520 521 -1 
            518 524 522 -1 
            517 518 522 -1 
            519 526 518 -1 
            526 524 518 -1 
            521 529 519 -1 
            529 526 519 -1 
            520 527 529 -1 
            521 520 529 -1 
            522 524 523 -1 
            525 523 524 -1 
            526 525 524 -1 
            528 525 526 -1 
            529 528 526 -1 
            527 528 529 -1 
            530 624 632 -1 
            530 531 625 -1 
            624 530 625 -1 
            531 532 626 -1 
            625 531 626 -1 
            532 533 627 -1 
            626 532 627 -1 
            533 534 628 -1 
            627 533 628 -1 
            628 534 638 -1 
            535 629 644 -1 
            535 536 630 -1 
            629 535 630 -1 
            536 537 631 -1 
            630 536 631 -1 
            537 538 633 -1 
            631 537 633 -1 
            632 633 538 -1 
            632 538 530 -1 
            530 538 539 -1 
            531 530 540 -1 
            540 530 539 -1 
            532 531 541 -1 
            541 531 540 -1 
            533 532 542 -1 
            542 532 541 -1 
            534 533 543 -1 
            543 533 542 -1 
            640 638 534 -1 
            640 534 544 -1 
            544 534 543 -1 
            640 544 655 -1 
            545 641 657 -1 
            545 546 642 -1 
            641 545 642 -1 
            546 547 643 -1 
            642 546 643 -1 
            547 548 645 -1 
            643 547 645 -1 
            644 645 548 -1 
            644 548 535 -1 
            535 548 549 -1 
            536 535 550 -1 
            550 535 549 -1 
            537 536 551 -1 
            551 536 550 -1 
            538 537 552 -1 
            552 537 551 -1 
            539 538 553 -1 
            553 538 552 -1 
            540 539 554 -1 
            554 539 553 -1 
            541 540 555 -1 
            555 540 554 -1 
            542 541 556 -1 
            556 541 555 -1 
            543 542 557 -1 
            557 542 556 -1 
            544 543 558 -1 
            558 543 557 -1 
            672 655 544 -1 
            558 672 544 -1 
            545 657 658 -1 
            658 674 559 -1 
            658 559 545 -1 
            545 559 546 -1 
            547 546 560 -1 
            560 546 559 -1 
            548 547 561 -1 
            561 547 560 -1 
            549 548 562 -1 
            562 548 561 -1 
            550 549 563 -1 
            563 549 562 -1 
            551 550 564 -1 
            564 550 563 -1 
            552 551 565 -1 
            565 551 564 -1 
            553 552 566 -1 
            566 552 565 -1 
            554 553 567 -1 
            567 553 566 -1 
            555 554 568 -1 
            568 554 567 -1 
            556 555 569 -1 
            569 555 568 -1 
            557 556 570 -1 
            570 556 569 -1 
            558 557 571 -1 
            571 557 570 -1 
            687 672 558 -1 
            571 687 558 -1 
            572 559 674 -1 
            689 572 674 -1 
            560 559 573 -1 
            573 559 572 -1 
            561 560 574 -1 
            574 560 573 -1 
            562 561 575 -1 
            575 561 574 -1 
            563 562 576 -1 
            576 562 575 -1 
            564 563 577 -1 
            577 563 576 -1 
            565 564 578 -1 
            578 564 577 -1 
            566 565 579 -1 
            579 565 578 -1 
            567 566 580 -1 
            580 566 579 -1 
            568 567 581 -1 
            581 567 580 -1 
            569 568 582 -1 
            582 568 581 -1 
            570 569 583 -1 
            583 569 582 -1 
            571 570 584 -1 
            584 570 583 -1 
            703 687 571 -1 
            584 703 571 -1 
            572 689 690 -1 
            690 705 585 -1 
            690 585 572 -1 
            572 585 573 -1 
            574 573 586 -1 
            586 573 585 -1 
            575 574 587 -1 
            587 574 586 -1 
            576 575 588 -1 
            588 575 587 -1 
            577 576 589 -1 
            589 576 588 -1 
            578 577 590 -1 
            590 577 589 -1 
            579 578 591 -1 
            591 578 590 -1 
            580 579 592 -1 
            592 579 591 -1 
            581 580 593 -1 
            593 580 592 -1 
            582 581 594 -1 
            594 581 593 -1 
            583 582 595 -1 
            595 582 594 -1 
            584 583 596 -1 
            596 583 595 -1 
            717 703 584 -1 
            596 717 584 -1 
            597 585 705 -1 
            719 597 705 -1 
            586 585 598 -1 
            598 585 597 -1 
            587 586 599 -1 
            599 586 598 -1 
            588 587 600 -1 
            600 587 599 -1 
            589 588 601 -1 
            601 588 600 -1 
            590 589 602 -1 
            602 589 601 -1 
            591 590 603 -1 
            603 590 602 -1 
            592 591 604 -1 
            604 591 603 -1 
            593 592 605 -1 
            605 592 604 -1 
            594 593 606 -1 
            606 593 605 -1 
            595 594 607 -1 
            607 594 606 -1 
            596 595 608 -1 
            608 595 607 -1 
            732 717 596 -1 
            608 732 596 -1 
            597 719 720 -1 
            720 735 609 -1 
            720 609 597 -1 
            597 609 598 -1 
            599 598 610 -1 
            610 598 609 -1 
            600 599 611 -1 
            611 599 610 -1 
            601 600 612 -1 
            612 600 611 -1 
            602 601 613 -1 
            613 601 612 -1 
            603 602 614 -1 
            614 602 613 -1 
            604 603 615 -1 
            615 603 614 -1 
            605 604 616 -1 
            616 604 615 -1 
            606 605 617 -1 
            617 605 616 -1 
            607 606 618 -1 
            618 606 617 -1 
            749 733 608 -1 
            749 608 618 -1 
            618 608 607 -1 
            732 608 733 -1 
            609 735 736 -1 
            610 609 736 -1 
            738 610 736 -1 
            611 610 738 -1 
            740 611 738 -1 
            612 611 740 -1 
            742 612 740 -1 
            742 752 619 -1 
            742 619 612 -1 
            612 619 613 -1 
            614 613 620 -1 
            620 613 619 -1 
            615 614 621 -1 
            621 614 620 -1 
            616 615 622 -1 
            622 615 621 -1 
            617 616 623 -1 
            623 616 622 -1 
            761 750 618 -1 
            761 618 623 -1 
            623 618 617 -1 
            749 618 750 -1 
            619 752 753 -1 
            620 619 753 -1 
            755 620 753 -1 
            621 620 755 -1 
            757 621 755 -1 
            622 621 757 -1 
            759 622 757 -1 
            623 622 759 -1 
            762 623 759 -1 
            761 623 762 -1 
            624 634 632 -1 
            624 625 635 -1 
            634 624 635 -1 
            625 626 636 -1 
            635 625 636 -1 
            626 627 637 -1 
            636 626 637 -1 
            627 628 639 -1 
            637 627 639 -1 
            628 638 639 -1 
            629 646 644 -1 
            629 630 647 -1 
            646 629 647 -1 
            630 631 648 -1 
            647 630 648 -1 
            631 633 649 -1 
            648 631 649 -1 
            633 632 634 -1 
            633 634 649 -1 
            649 634 650 -1 
            635 651 634 -1 
            651 650 634 -1 
            636 652 635 -1 
            652 651 635 -1 
            637 653 636 -1 
            653 652 636 -1 
            639 654 637 -1 
            654 653 637 -1 
            638 640 656 -1 
            638 656 639 -1 
            639 656 654 -1 
            640 655 656 -1 
            641 659 657 -1 
            641 642 660 -1 
            659 641 660 -1 
            642 643 661 -1 
            660 642 661 -1 
            643 645 662 -1 
            661 643 662 -1 
            645 644 646 -1 
            645 646 662 -1 
            662 646 663 -1 
            647 664 646 -1 
            664 663 646 -1 
            648 665 647 -1 
            665 664 647 -1 
            649 666 648 -1 
            666 665 648 -1 
            650 667 649 -1 
            667 666 649 -1 
            651 668 650 -1 
            668 667 650 -1 
            652 669 651 -1 
            669 668 651 -1 
            653 670 652 -1 
            670 669 652 -1 
            654 671 653 -1 
            671 670 653 -1 
            656 673 654 -1 
            673 671 654 -1 
            655 672 673 -1 
            656 655 673 -1 
            657 659 658 -1 
            674 658 659 -1 
            674 659 675 -1 
            675 659 660 -1 
            661 676 660 -1 
            676 675 660 -1 
            662 677 661 -1 
            677 676 661 -1 
            663 678 662 -1 
            678 677 662 -1 
            664 679 663 -1 
            679 678 663 -1 
            665 680 664 -1 
            680 679 664 -1 
            666 681 665 -1 
            681 680 665 -1 
            667 682 666 -1 
            682 681 666 -1 
            668 683 667 -1 
            683 682 667 -1 
            669 684 668 -1 
            684 683 668 -1 
            670 685 669 -1 
            685 684 669 -1 
            671 686 670 -1 
            686 685 670 -1 
            673 688 671 -1 
            688 686 671 -1 
            672 687 688 -1 
            673 672 688 -1 
            675 691 689 -1 
            674 675 689 -1 
            676 692 675 -1 
            692 691 675 -1 
            677 693 676 -1 
            693 692 676 -1 
            678 694 677 -1 
            694 693 677 -1 
            679 695 678 -1 
            695 694 678 -1 
            680 696 679 -1 
            696 695 679 -1 
            681 697 680 -1 
            697 696 680 -1 
            682 698 681 -1 
            698 697 681 -1 
            683 699 682 -1 
            699 698 682 -1 
            684 700 683 -1 
            700 699 683 -1 
            685 701 684 -1 
            701 700 684 -1 
            686 702 685 -1 
            702 701 685 -1 
            688 704 686 -1 
            704 702 686 -1 
            687 703 704 -1 
            688 687 704 -1 
            689 691 690 -1 
            705 690 691 -1 
            705 691 706 -1 
            706 691 692 -1 
            693 707 692 -1 
            707 706 692 -1 
            694 708 693 -1 
            708 707 693 -1 
            695 709 694 -1 
            709 708 694 -1 
            696 710 695 -1 
            710 709 695 -1 
            697 711 696 -1 
            711 710 696 -1 
            698 712 697 -1 
            712 711 697 -1 
            699 713 698 -1 
            713 712 698 -1 
            700 714 699 -1 
            714 713 699 -1 
            701 715 700 -1 
            715 714 700 -1 
            702 716 701 -1 
            716 715 701 -1 
            704 718 702 -1 
            718 716 702 -1 
            703 717 718 -1 
            704 703 718 -1 
            706 721 719 -1 
            705 706 719 -1 
            707 722 706 -1 
            722 721 706 -1 
            708 723 707 -1 
            723 722 707 -1 
            709 724 708 -1 
            724 723 708 -1 
            710 725 709 -1 
            725 724 709 -1 
            711 726 710 -1 
            726 725 710 -1 
            712 727 711 -1 
            727 726 711 -1 
            713 728 712 -1 
            728 727 712 -1 
            714 729 713 -1 
            729 728 713 -1 
            715 730 714 -1 
            730 729 714 -1 
            716 731 715 -1 
            731 730 715 -1 
            718 734 716 -1 
            734 731 716 -1 
            717 732 734 -1 
            718 717 734 -1 
            719 721 720 -1 
            735 720 721 -1 
            735 721 737 -1 
            737 721 722 -1 
            723 739 722 -1 
            739 737 722 -1 
            724 741 723 -1 
            741 739 723 -1 
            725 743 724 -1 
            743 741 724 -1 
            726 744 725 -1 
            744 743 725 -1 
            727 745 726 -1 
            745 744 726 -1 
            728 746 727 -1 
            746 745 727 -1 
            729 747 728 -1 
            747 746 728 -1 
            730 748 729 -1 
            748 747 729 -1 
            731 751 730 -1 
            751 748 730 -1 
            733 749 751 -1 
            733 751 734 -1 
            734 751 731 -1 
            732 733 734 -1 
            735 737 736 -1 
            738 736 737 -1 
            739 738 737 -1 
            740 738 739 -1 
            741 740 739 -1 
            742 740 741 -1 
            743 742 741 -1 
            752 742 743 -1 
            752 743 754 -1 
            754 743 744 -1 
            745 756 744 -1 
            756 754 744 -1 
            746 758 745 -1 
            758 756 745 -1 
            747 760 746 -1 
            760 758 746 -1 
            748 763 747 -1 
            763 760 747 -1 
            750 761 763 -1 
            750 763 751 -1 
            751 763 748 -1 
            749 750 751 -1 
            752 754 753 -1 
            755 753 754 -1 
            756 755 754 -1 
            757 755 756 -1 
            758 757 756 -1 
            759 757 758 -1 
            760 759 758 -1 
            762 759 760 -1 
            763 762 760 -1 
            761 762 763 -1 
            764 770 772 -1 
            764 765 771 -1 
            770 764 771 -1 
            771 765 773 -1 
            766 764 772 -1 
            774 766 772 -1 
            765 764 767 -1 
            767 764 766 -1 
            775 773 765 -1 
            767 775 765 -1 
            768 766 774 -1 
            776 768 774 -1 
            767 766 769 -1 
            769 766 768 -1 
            778 775 767 -1 
            769 778 767 -1 
            768 776 777 -1 
            769 768 777 -1 
            779 769 777 -1 
            778 769 779 -1 
            770 780 782 -1 
            772 770 782 -1 
            771 781 770 -1 
            770 781 780 -1 
            781 771 773 -1 
            784 781 773 -1 
            772 782 774 -1 
            782 786 774 -1 
            773 775 784 -1 
            784 775 788 -1 
            774 786 776 -1 
            786 790 776 -1 
            775 778 788 -1 
            788 778 793 -1 
            776 790 791 -1 
            777 776 791 -1 
            779 777 794 -1 
            777 791 794 -1 
            793 778 779 -1 
            794 793 779 -1 
            780 783 782 -1 
            780 781 785 -1 
            783 780 785 -1 
            781 784 785 -1 
            783 787 786 -1 
            782 783 786 -1 
            785 789 783 -1 
            789 787 783 -1 
            784 788 789 -1 
            785 784 789 -1 
            787 792 790 -1 
            786 787 790 -1 
            789 795 787 -1 
            795 792 787 -1 
            788 793 795 -1 
            789 788 795 -1 
            790 792 791 -1 
            794 791 792 -1 
            795 794 792 -1 
            793 794 795 -1 
            796 966 971 -1 
            796 797 967 -1 
            966 796 967 -1 
            967 797 974 -1 
            798 968 981 -1 
            798 799 969 -1 
            968 798 969 -1 
            799 800 970 -1 
            969 799 970 -1 
            800 801 972 -1 
            970 800 972 -1 
            971 972 801 -1 
            971 801 796 -1 
            796 801 802 -1 
            797 796 803 -1 
            803 796 802 -1 
            976 974 797 -1 
            976 797 804 -1 
            804 797 803 -1 
            976 804 989 -1 
            805 977 991 -1 
            805 806 978 -1 
            977 805 978 -1 
            806 807 979 -1 
            978 806 979 -1 
            807 808 980 -1 
            979 807 980 -1 
            808 809 982 -1 
            980 808 982 -1 
            981 982 809 -1 
            981 809 798 -1 
            798 809 810 -1 
            799 798 811 -1 
            811 798 810 -1 
            800 799 812 -1 
            812 799 811 -1 
            801 800 813 -1 
            813 800 812 -1 
            802 801 814 -1 
            814 801 813 -1 
            803 802 815 -1 
            815 802 814 -1 
            804 803 816 -1 
            816 803 815 -1 
            1004 989 804 -1 
            816 1004 804 -1 
            817 992 1006 -1 
            991 992 817 -1 
            991 817 805 -1 
            805 817 818 -1 
            806 805 819 -1 
            819 805 818 -1 
            807 806 820 -1 
            820 806 819 -1 
            808 807 821 -1 
            821 807 820 -1 
            809 808 822 -1 
            822 808 821 -1 
            810 809 823 -1 
            823 809 822 -1 
            811 810 824 -1 
            824 810 823 -1 
            812 811 825 -1 
            825 811 824 -1 
            813 812 826 -1 
            826 812 825 -1 
            814 813 827 -1 
            827 813 826 -1 
            815 814 828 -1 
            828 814 827 -1 
            816 815 829 -1 
            829 815 828 -1 
            1019 1004 816 -1 
            829 1019 816 -1 
            830 817 1006 -1 
            1021 830 1006 -1 
            818 817 831 -1 
            831 817 830 -1 
            819 818 832 -1 
            832 818 831 -1 
            820 819 833 -1 
            833 819 832 -1 
            821 820 834 -1 
            834 820 833 -1 
            822 821 835 -1 
            835 821 834 -1 
            823 822 836 -1 
            836 822 835 -1 
            824 823 837 -1 
            837 823 836 -1 
            825 824 838 -1 
            838 824 837 -1 
            826 825 839 -1 
            839 825 838 -1 
            827 826 840 -1 
            840 826 839 -1 
            828 827 841 -1 
            841 827 840 -1 
            829 828 842 -1 
            842 828 841 -1 
            1035 1019 829 -1 
            842 1035 829 -1 
            830 1021 1022 -1 
            1022 1037 843 -1 
            1022 843 830 -1 
            830 843 831 -1 
            832 831 844 -1 
            844 831 843 -1 
            833 832 845 -1 
            845 832 844 -1 
            834 833 846 -1 
            846 833 845 -1 
            835 834 847 -1 
            847 834 846 -1 
            836 835 848 -1 
            848 835 847 -1 
            837 836 849 -1 
            849 836 848 -1 
            838 837 850 -1 
            850 837 849 -1 
            839 838 851 -1 
            851 838 850 -1 
            840 839 852 -1 
            852 839 851 -1 
            841 840 853 -1 
            853 840 852 -1 
            842 841 854 -1 
            854 841 853 -1 
            1049 1035 842 -1 
            854 1049 842 -1 
            855 843 1037 -1 
            1051 855 1037 -1 
            844 843 856 -1 
            856 843 855 -1 
            845 844 857 -1 
            857 844 856 -1 
            846 845 858 -1 
            858 845 857 -1 
            847 846 859 -1 
            859 846 858 -1 
            848 847 860 -1 
            860 847 859 -1 
            849 848 861 -1 
            861 848 860 -1 
            850 849 862 -1 
            862 849 861 -1 
            851 850 863 -1 
            863 850 862 -1 
            852 851 864 -1 
            864 851 863 -1 
            853 852 865 -1 
            865 852 864 -1 
            854 853 866 -1 
            866 853 865 -1 
            1064 1049 854 -1 
            866 1064 854 -1 
            855 1051 1052 -1 
            1052 1067 867 -1 
            1052 867 855 -1 
            855 867 856 -1 
            857 856 868 -1 
            868 856 867 -1 
            858 857 869 -1 
            869 857 868 -1 
            859 858 870 -1 
            870 858 869 -1 
            860 859 871 -1 
            871 859 870 -1 
            861 860 872 -1 
            872 860 871 -1 
            862 861 873 -1 
            873 861 872 -1 
            863 862 874 -1 
            874 862 873 -1 
            864 863 875 -1 
            875 863 874 -1 
            865 864 876 -1 
            876 864 875 -1 
            1081 1065 866 -1 
            1081 866 876 -1 
            876 866 865 -1 
            1064 866 1065 -1 
            867 1067 1068 -1 
            868 867 1068 -1 
            1070 868 1068 -1 
            869 868 1070 -1 
            1072 869 1070 -1 
            870 869 1072 -1 
            1074 870 1072 -1 
            1074 1084 877 -1 
            1074 877 870 -1 
            870 877 871 -1 
            872 871 878 -1 
            878 871 877 -1 
            873 872 879 -1 
            879 872 878 -1 
            874 873 880 -1 
            880 873 879 -1 
            875 874 881 -1 
            881 874 880 -1 
            1093 1082 876 -1 
            1093 876 881 -1 
            881 876 875 -1 
            1081 876 1082 -1 
            877 1084 1085 -1 
            878 877 1085 -1 
            1087 878 1085 -1 
            879 878 1087 -1 
            1089 879 1087 -1 
            880 879 1089 -1 
            1091 880 1089 -1 
            881 880 1091 -1 
            1094 881 1091 -1 
            1093 881 1094 -1 
            882 1096 1097 -1 
            1096 882 1098 -1 
            883 882 1097 -1 
            1102 883 1097 -1 
            1100 1098 882 -1 
            1100 882 884 -1 
            884 882 883 -1 
            884 885 1101 -1 
            1100 884 1101 -1 
            1101 885 1105 -1 
            886 883 1102 -1 
            1109 886 1102 -1 
            884 883 887 -1 
            887 883 886 -1 
            885 884 888 -1 
            888 884 887 -1 
            1107 1105 885 -1 
            1107 885 889 -1 
            889 885 888 -1 
            889 890 1108 -1 
            1107 889 1108 -1 
            1108 890 1115 -1 
            891 1110 1118 -1 
            1109 1110 891 -1 
            1109 891 886 -1 
            886 891 892 -1 
            887 886 893 -1 
            893 886 892 -1 
            888 887 894 -1 
            894 887 893 -1 
            889 888 895 -1 
            895 888 894 -1 
            890 889 896 -1 
            896 889 895 -1 
            1117 1115 890 -1 
            1117 890 897 -1 
            897 890 896 -1 
            1117 897 1125 -1 
            898 891 1118 -1 
            1129 898 1118 -1 
            892 891 899 -1 
            899 891 898 -1 
            893 892 900 -1 
            900 892 899 -1 
            894 893 901 -1 
            901 893 900 -1 
            895 894 902 -1 
            902 894 901 -1 
            896 895 903 -1 
            903 895 902 -1 
            897 896 904 -1 
            904 896 903 -1 
            1127 1125 897 -1 
            1127 897 905 -1 
            905 897 904 -1 
            905 906 1128 -1 
            1127 905 1128 -1 
            1128 906 1139 -1 
            907 1130 1142 -1 
            1129 1130 907 -1 
            1129 907 898 -1 
            898 907 908 -1 
            899 898 909 -1 
            909 898 908 -1 
            900 899 910 -1 
            910 899 909 -1 
            901 900 911 -1 
            911 900 910 -1 
            902 901 912 -1 
            912 901 911 -1 
            903 902 913 -1 
            913 902 912 -1 
            904 903 914 -1 
            914 903 913 -1 
            905 904 915 -1 
            915 904 914 -1 
            906 905 916 -1 
            916 905 915 -1 
            1141 1139 906 -1 
            1141 906 917 -1 
            917 906 916 -1 
            1141 917 1153 -1 
            918 907 1142 -1 
            1156 918 1142 -1 
            908 907 919 -1 
            919 907 918 -1 
            909 908 920 -1 
            920 908 919 -1 
            910 909 921 -1 
            921 909 920 -1 
            911 910 922 -1 
            922 910 921 -1 
            912 911 923 -1 
            923 911 922 -1 
            913 912 924 -1 
            924 912 923 -1 
            914 913 925 -1 
            925 913 924 -1 
            915 914 926 -1 
            926 914 925 -1 
            916 915 927 -1 
            927 915 926 -1 
            917 916 928 -1 
            928 916 927 -1 
            1155 1153 917 -1 
            1155 917 929 -1 
            929 917 928 -1 
            930 1157 1171 -1 
            1156 1157 930 -1 
            1156 930 918 -1 
            918 930 931 -1 
            919 918 932 -1 
            932 918 931 -1 
            920 919 933 -1 
            933 919 932 -1 
            921 920 934 -1 
            934 920 933 -1 
            922 921 935 -1 
            935 921 934 -1 
            923 922 936 -1 
            936 922 935 -1 
            924 923 937 -1 
            937 923 936 -1 
            925 924 938 -1 
            938 924 937 -1 
            926 925 939 -1 
            939 925 938 -1 
            927 926 940 -1 
            940 926 939 -1 
            928 927 941 -1 
            941 927 940 -1 
            1184 1169 929 -1 
            1184 929 941 -1 
            941 929 928 -1 
            942 930 1171 -1 
            1187 942 1171 -1 
            931 930 943 -1 
            943 930 942 -1 
            932 931 944 -1 
            944 931 943 -1 
            933 932 945 -1 
            945 932 944 -1 
            934 933 946 -1 
            946 933 945 -1 
            935 934 947 -1 
            947 934 946 -1 
            936 935 948 -1 
            948 935 947 -1 
            937 936 949 -1 
            949 936 948 -1 
            938 937 950 -1 
            950 937 949 -1 
            939 938 951 -1 
            951 938 950 -1 
            1198 1182 940 -1 
            1198 940 951 -1 
            951 940 939 -1 
            941 940 1182 -1 
            1185 941 1182 -1 
            1184 941 1185 -1 
            942 1187 1188 -1 
            1188 1201 952 -1 
            1188 952 942 -1 
            942 952 943 -1 
            944 943 953 -1 
            953 943 952 -1 
            945 944 954 -1 
            954 944 953 -1 
            946 945 955 -1 
            955 945 954 -1 
            947 946 956 -1 
            956 946 955 -1 
            948 947 957 -1 
            957 947 956 -1 
            949 948 958 -1 
            958 948 957 -1 
            950 949 959 -1 
            959 949 958 -1 
            1210 1199 951 -1 
            1210 951 959 -1 
            959 951 950 -1 
            1198 951 1199 -1 
            952 1201 1202 -1 
            1202 1213 960 -1 
            1202 960 952 -1 
            952 960 953 -1 
            954 953 961 -1 
            961 953 960 -1 
            955 954 962 -1 
            962 954 961 -1 
            956 955 963 -1 
            963 955 962 -1 
            957 956 964 -1 
            964 956 963 -1 
            958 957 965 -1 
            965 957 964 -1 
            1224 1211 959 -1 
            1224 959 965 -1 
            965 959 958 -1 
            1210 959 1211 -1 
            960 1213 1214 -1 
            961 960 1214 -1 
            1216 961 1214 -1 
            962 961 1216 -1 
            1218 962 1216 -1 
            963 962 1218 -1 
            1220 963 1218 -1 
            964 963 1220 -1 
            1222 964 1220 -1 
            965 964 1222 -1 
            1225 965 1222 -1 
            1224 965 1225 -1 
            966 973 971 -1 
            966 967 975 -1 
            973 966 975 -1 
            967 974 975 -1 
            968 983 981 -1 
            968 969 984 -1 
            983 968 984 -1 
            969 970 985 -1 
            984 969 985 -1 
            970 972 986 -1 
            985 970 986 -1 
            972 971 973 -1 
            972 973 986 -1 
            986 973 987 -1 
            975 988 973 -1 
            988 987 973 -1 
            974 976 990 -1 
            974 990 975 -1 
            975 990 988 -1 
            976 989 990 -1 
            977 993 991 -1 
            977 978 994 -1 
            993 977 994 -1 
            978 979 995 -1 
            994 978 995 -1 
            979 980 996 -1 
            995 979 996 -1 
            980 982 997 -1 
            996 980 997 -1 
            982 981 983 -1 
            982 983 997 -1 
            997 983 998 -1 
            984 999 983 -1 
            999 998 983 -1 
            985 1000 984 -1 
            1000 999 984 -1 
            986 1001 985 -1 
            1001 1000 985 -1 
            987 1002 986 -1 
            1002 1001 986 -1 
            988 1003 987 -1 
            1003 1002 987 -1 
            990 1005 988 -1 
            1005 1003 988 -1 
            989 1004 1005 -1 
            990 989 1005 -1 
            992 1007 1006 -1 
            992 991 993 -1 
            992 993 1007 -1 
            1007 993 1008 -1 
            994 1009 993 -1 
            1009 1008 993 -1 
            995 1010 994 -1 
            1010 1009 994 -1 
            996 1011 995 -1 
            1011 1010 995 -1 
            997 1012 996 -1 
            1012 1011 996 -1 
            998 1013 997 -1 
            1013 1012 997 -1 
            999 1014 998 -1 
            1014 1013 998 -1 
            1000 1015 999 -1 
            1015 1014 999 -1 
            1001 1016 1000 -1 
            1016 1015 1000 -1 
            1002 1017 1001 -1 
            1017 1016 1001 -1 
            1003 1018 1002 -1 
            1018 1017 1002 -1 
            1005 1020 1003 -1 
            1020 1018 1003 -1 
            1004 1019 1020 -1 
            1005 1004 1020 -1 
            1007 1023 1021 -1 
            1006 1007 1021 -1 
            1008 1024 1007 -1 
            1024 1023 1007 -1 
            1009 1025 1008 -1 
            1025 1024 1008 -1 
            1010 1026 1009 -1 
            1026 1025 1009 -1 
            1011 1027 1010 -1 
            1027 1026 1010 -1 
            1012 1028 1011 -1 
            1028 1027 1011 -1 
            1013 1029 1012 -1 
            1029 1028 1012 -1 
            1014 1030 1013 -1 
            1030 1029 1013 -1 
            1015 1031 1014 -1 
            1031 1030 1014 -1 
            1016 1032 1015 -1 
            1032 1031 1015 -1 
            1017 1033 1016 -1 
            1033 1032 1016 -1 
            1018 1034 1017 -1 
            1034 1033 1017 -1 
            1020 1036 1018 -1 
            1036 1034 1018 -1 
            1019 1035 1036 -1 
            1020 1019 1036 -1 
            1021 1023 1022 -1 
            1037 1022 1023 -1 
            1037 1023 1038 -1 
            1038 1023 1024 -1 
            1025 1039 1024 -1 
            1039 1038 1024 -1 
            1026 1040 1025 -1 
            1040 1039 1025 -1 
            1027 1041 1026 -1 
            1041 1040 1026 -1 
            1028 1042 1027 -1 
            1042 1041 1027 -1 
            1029 1043 1028 -1 
            1043 1042 1028 -1 
            1030 1044 1029 -1 
            1044 1043 1029 -1 
            1031 1045 1030 -1 
            1045 1044 1030 -1 
            1032 1046 1031 -1 
            1046 1045 1031 -1 
            1033 1047 1032 -1 
            1047 1046 1032 -1 
            1034 1048 1033 -1 
            1048 1047 1033 -1 
            1036 1050 1034 -1 
            1050 1048 1034 -1 
            1035 1049 1050 -1 
            1036 1035 1050 -1 
            1038 1053 1051 -1 
            1037 1038 1051 -1 
            1039 1054 1038 -1 
            1054 1053 1038 -1 
            1040 1055 1039 -1 
            1055 1054 1039 -1 
            1041 1056 1040 -1 
            1056 1055 1040 -1 
            1042 1057 1041 -1 
            1057 1056 1041 -1 
            1043 1058 1042 -1 
            1058 1057 1042 -1 
            1044 1059 1043 -1 
            1059 1058 1043 -1 
            1045 1060 1044 -1 
            1060 1059 1044 -1 
            1046 1061 1045 -1 
            1061 1060 1045 -1 
            1047 1062 1046 -1 
            1062 1061 1046 -1 
            1048 1063 1047 -1 
            1063 1062 1047 -1 
            1050 1066 1048 -1 
            1066 1063 1048 -1 
            1049 1064 1066 -1 
            1050 1049 1066 -1 
            1051 1053 1052 -1 
            1067 1052 1053 -1 
            1067 1053 1069 -1 
            1069 1053 1054 -1 
            1055 1071 1054 -1 
            1071 1069 1054 -1 
            1056 1073 1055 -1 
            1073 1071 1055 -1 
            1057 1075 1056 -1 
            1075 1073 1056 -1 
            1058 1076 1057 -1 
            1076 1075 1057 -1 
            1059 1077 1058 -1 
            1077 1076 1058 -1 
            1060 1078 1059 -1 
            1078 1077 1059 -1 
            1061 1079 1060 -1 
            1079 1078 1060 -1 
            1062 1080 1061 -1 
            1080 1079 1061 -1 
            1063 1083 1062 -1 
            1083 1080 1062 -1 
            1065 1081 1083 -1 
            1065 1083 1066 -1 
            1066 1083 1063 -1 
            1064 1065 1066 -1 
            1067 1069 1068 -1 
            1070 1068 1069 -1 
            1071 1070 1069 -1 
            1072 1070 1071 -1 
            1073 1072 1071 -1 
            1074 1072 1073 -1 
            1075 1074 1073 -1 
            1084 1074 1075 -1 
            1084 1075 1086 -1 
            1086 1075 1076 -1 
            1077 1088 1076 -1 
            1088 1086 1076 -1 
            1078 1090 1077 -1 
            1090 1088 1077 -1 
            1079 1092 1078 -1 
            1092 1090 1078 -1 
            1080 1095 1079 -1 
            1095 1092 1079 -1 
            1082 1093 1095 -1 
            1082 1095 1083 -1 
            1083 1095 1080 -1 
            1081 1082 1083 -1 
            1084 1086 1085 -1 
            1087 1085 1086 -1 
            1088 1087 1086 -1 
            1089 1087 1088 -1 
            1090 1089 1088 -1 
            1091 1089 1090 -1 
            1092 1091 1090 -1 
            1094 1091 1092 -1 
            1095 1094 1092 -1 
            1093 1094 1095 -1 
            1096 1099 1097 -1 
            1096 1098 1099 -1 
            1099 1103 1102 -1 
            1097 1099 1102 -1 
            1098 1100 1104 -1 
            1098 1104 1099 -1 
            1099 1104 1103 -1 
            1100 1101 1106 -1 
            1104 1100 1106 -1 
            1101 1105 1106 -1 
            1103 1111 1109 -1 
            1102 1103 1109 -1 
            1104 1112 1103 -1 
            1112 1111 1103 -1 
            1106 1113 1104 -1 
            1113 1112 1104 -1 
            1105 1107 1114 -1 
            1105 1114 1106 -1 
            1106 1114 1113 -1 
            1107 1108 1116 -1 
            1114 1107 1116 -1 
            1108 1115 1116 -1 
            1110 1119 1118 -1 
            1110 1109 1111 -1 
            1110 1111 1119 -1 
            1119 1111 1120 -1 
            1112 1121 1111 -1 
            1121 1120 1111 -1 
            1113 1122 1112 -1 
            1122 1121 1112 -1 
            1114 1123 1113 -1 
            1123 1122 1113 -1 
            1116 1124 1114 -1 
            1124 1123 1114 -1 
            1115 1117 1126 -1 
            1115 1126 1116 -1 
            1116 1126 1124 -1 
            1117 1125 1126 -1 
            1119 1131 1129 -1 
            1118 1119 1129 -1 
            1120 1132 1119 -1 
            1132 1131 1119 -1 
            1121 1133 1120 -1 
            1133 1132 1120 -1 
            1122 1134 1121 -1 
            1134 1133 1121 -1 
            1123 1135 1122 -1 
            1135 1134 1122 -1 
            1124 1136 1123 -1 
            1136 1135 1123 -1 
            1126 1137 1124 -1 
            1137 1136 1124 -1 
            1125 1127 1138 -1 
            1125 1138 1126 -1 
            1126 1138 1137 -1 
            1127 1128 1140 -1 
            1138 1127 1140 -1 
            1128 1139 1140 -1 
            1130 1143 1142 -1 
            1130 1129 1131 -1 
            1130 1131 1143 -1 
            1143 1131 1144 -1 
            1132 1145 1131 -1 
            1145 1144 1131 -1 
            1133 1146 1132 -1 
            1146 1145 1132 -1 
            1134 1147 1133 -1 
            1147 1146 1133 -1 
            1135 1148 1134 -1 
            1148 1147 1134 -1 
            1136 1149 1135 -1 
            1149 1148 1135 -1 
            1137 1150 1136 -1 
            1150 1149 1136 -1 
            1138 1151 1137 -1 
            1151 1150 1137 -1 
            1140 1152 1138 -1 
            1152 1151 1138 -1 
            1139 1141 1154 -1 
            1139 1154 1140 -1 
            1140 1154 1152 -1 
            1141 1153 1154 -1 
            1143 1158 1156 -1 
            1142 1143 1156 -1 
            1144 1159 1143 -1 
            1159 1158 1143 -1 
            1145 1160 1144 -1 
            1160 1159 1144 -1 
            1146 1161 1145 -1 
            1161 1160 1145 -1 
            1147 1162 1146 -1 
            1162 1161 1146 -1 
            1148 1163 1147 -1 
            1163 1162 1147 -1 
            1149 1164 1148 -1 
            1164 1163 1148 -1 
            1150 1165 1149 -1 
            1165 1164 1149 -1 
            1151 1166 1150 -1 
            1166 1165 1150 -1 
            1152 1167 1151 -1 
            1167 1166 1151 -1 
            1154 1168 1152 -1 
            1168 1167 1152 -1 
            1153 1155 1170 -1 
            1153 1170 1154 -1 
            1154 1170 1168 -1 
            1157 1172 1171 -1 
            1157 1156 1158 -1 
            1157 1158 1172 -1 
            1172 1158 1173 -1 
            1159 1174 1158 -1 
            1174 1173 1158 -1 
            1160 1175 1159 -1 
            1175 1174 1159 -1 
            1161 1176 1160 -1 
            1176 1175 1160 -1 
            1162 1177 1161 -1 
            1177 1176 1161 -1 
            1163 1178 1162 -1 
            1178 1177 1162 -1 
            1164 1179 1163 -1 
            1179 1178 1163 -1 
            1165 1180 1164 -1 
            1180 1179 1164 -1 
            1166 1181 1165 -1 
            1181 1180 1165 -1 
            1167 1183 1166 -1 
            1183 1181 1166 -1 
            1168 1186 1167 -1 
            1186 1183 1167 -1 
            1169 1184 1186 -1 
            1169 1186 1170 -1 
            1170 1186 1168 -1 
            1172 1189 1187 -1 
            1171 1172 1187 -1 
            1173 1190 1172 -1 
            1190 1189 1172 -1 
            1174 1191 1173 -1 
            1191 1190 1173 -1 
            1175 1192 1174 -1 
            1192 1191 1174 -1 
            1176 1193 1175 -1 
            1193 1192 1175 -1 
            1177 1194 1176 -1 
            1194 1193 1176 -1 
            1178 1195 1177 -1 
            1195 1194 1177 -1 
            1179 1196 1178 -1 
            1196 1195 1178 -1 
            1180 1197 1179 -1 
            1197 1196 1179 -1 
            1181 1200 1180 -1 
            1200 1197 1180 -1 
            1182 1198 1200 -1 
            1182 1200 1183 -1 
            1183 1200 1181 -1 
            1185 1182 1183 -1 
            1186 1185 1183 -1 
            1184 1185 1186 -1 
            1187 1189 1188 -1 
            1201 1188 1189 -1 
            1201 1189 1203 -1 
            1203 1189 1190 -1 
            1191 1204 1190 -1 
            1204 1203 1190 -1 
            1192 1205 1191 -1 
            1205 1204 1191 -1 
            1193 1206 1192 -1 
            1206 1205 1192 -1 
            1194 1207 1193 -1 
            1207 1206 1193 -1 
            1195 1208 1194 -1 
            1208 1207 1194 -1 
            1196 1209 1195 -1 
            1209 1208 1195 -1 
            1197 1212 1196 -1 
            1212 1209 1196 -1 
            1199 1210 1212 -1 
            1199 1212 1200 -1 
            1200 1212 1197 -1 
            1198 1199 1200 -1 
            1201 1203 1202 -1 
            1213 1202 1203 -1 
            1213 1203 1215 -1 
            1215 1203 1204 -1 
            1205 1217 1204 -1 
            1217 1215 1204 -1 
            1206 1219 1205 -1 
            1219 1217 1205 -1 
            1207 1221 1206 -1 
            1221 1219 1206 -1 
            1208 1223 1207 -1 
            1223 1221 1207 -1 
            1209 1226 1208 -1 
            1226 1223 1208 -1 
            1211 1224 1226 -1 
            1211 1226 1212 -1 
            1212 1226 1209 -1 
            1210 1211 1212 -1 
            1213 1215 1214 -1 
            1216 1214 1215 -1 
            1217 1216 1215 -1 
            1218 1216 1217 -1 
            1219 1218 1217 -1 
            1220 1218 1219 -1 
            1221 1220 1219 -1 
            1222 1220 1221 -1 
            1223 1222 1221 -1 
            1225 1222 1223 -1 
            1226 1225 1223 -1 
            1224 1225 1226 -1 
            1227 1310 1314 -1 
            1227 1228 1311 -1 
            1310 1227 1311 -1 
            1228 1229 1312 -1 
            1311 1228 1312 -1 
            1229 1230 1313 -1 
            1312 1229 1313 -1 
            1313 1230 1318 -1 
            1231 1227 1314 -1 
            1324 1231 1314 -1 
            1228 1227 1232 -1 
            1232 1227 1231 -1 
            1229 1228 1233 -1 
            1233 1228 1232 -1 
            1230 1229 1234 -1 
            1234 1229 1233 -1 
            1320 1318 1230 -1 
            1320 1230 1235 -1 
            1235 1230 1234 -1 
            1235 1236 1321 -1 
            1320 1235 1321 -1 
            1236 1237 1322 -1 
            1321 1236 1322 -1 
            1237 1238 1323 -1 
            1322 1237 1323 -1 
            1323 1238 1333 -1 
            1239 1325 1336 -1 
            1324 1325 1239 -1 
            1324 1239 1231 -1 
            1231 1239 1240 -1 
            1232 1231 1241 -1 
            1241 1231 1240 -1 
            1233 1232 1242 -1 
            1242 1232 1241 -1 
            1234 1233 1243 -1 
            1243 1233 1242 -1 
            1235 1234 1244 -1 
            1244 1234 1243 -1 
            1236 1235 1245 -1 
            1245 1235 1244 -1 
            1237 1236 1246 -1 
            1246 1236 1245 -1 
            1238 1237 1247 -1 
            1247 1237 1246 -1 
            1335 1333 1238 -1 
            1335 1238 1248 -1 
            1248 1238 1247 -1 
            1335 1248 1346 -1 
            1249 1239 1336 -1 
            1349 1249 1336 -1 
            1240 1239 1250 -1 
            1250 1239 1249 -1 
            1241 1240 1251 -1 
            1251 1240 1250 -1 
            1242 1241 1252 -1 
            1252 1241 1251 -1 
            1243 1242 1253 -1 
            1253 1242 1252 -1 
            1244 1243 1254 -1 
            1254 1243 1253 -1 
            1245 1244 1255 -1 
            1255 1244 1254 -1 
            1246 1245 1256 -1 
            1256 1245 1255 -1 
            1247 1246 1257 -1 
            1257 1246 1256 -1 
            1357 1347 1248 -1 
            1357 1248 1257 -1 
            1257 1248 1247 -1 
            1346 1248 1347 -1 
            1258 1249 1349 -1 
            1359 1258 1349 -1 
            1250 1249 1259 -1 
            1259 1249 1258 -1 
            1251 1250 1260 -1 
            1260 1250 1259 -1 
            1252 1251 1261 -1 
            1261 1251 1260 -1 
            1253 1252 1262 -1 
            1262 1252 1261 -1 
            1254 1253 1263 -1 
            1263 1253 1262 -1 
            1255 1254 1264 -1 
            1264 1254 1263 -1 
            1256 1255 1265 -1 
            1265 1255 1264 -1 
            1257 1256 1266 -1 
            1266 1256 1265 -1 
            1366 1357 1257 -1 
            1266 1366 1257 -1 
            1267 1360 1368 -1 
            1359 1360 1267 -1 
            1359 1267 1258 -1 
            1258 1267 1268 -1 
            1259 1258 1269 -1 
            1269 1258 1268 -1 
            1260 1259 1270 -1 
            1270 1259 1269 -1 
            1261 1260 1271 -1 
            1271 1260 1270 -1 
            1262 1261 1272 -1 
            1272 1261 1271 -1 
            1263 1262 1273 -1 
            1273 1262 1272 -1 
            1264 1263 1274 -1 
            1274 1263 1273 -1 
            1265 1264 1275 -1 
            1275 1264 1274 -1 
            1266 1265 1276 -1 
            1276 1265 1275 -1 
            1375 1366 1266 -1 
            1276 1375 1266 -1 
            1277 1267 1368 -1 
            1378 1277 1368 -1 
            1268 1267 1278 -1 
            1278 1267 1277 -1 
            1269 1268 1279 -1 
            1279 1268 1278 -1 
            1270 1269 1280 -1 
            1280 1269 1279 -1 
            1271 1270 1281 -1 
            1281 1270 1280 -1 
            1272 1271 1282 -1 
            1282 1271 1281 -1 
            1273 1272 1283 -1 
            1283 1272 1282 -1 
            1274 1273 1284 -1 
            1284 1273 1283 -1 
            1275 1274 1285 -1 
            1285 1274 1284 -1 
            1387 1376 1276 -1 
            1387 1276 1285 -1 
            1285 1276 1275 -1 
            1375 1276 1376 -1 
            1286 1277 1378 -1 
            1389 1286 1378 -1 
            1278 1277 1287 -1 
            1287 1277 1286 -1 
            1279 1278 1288 -1 
            1288 1278 1287 -1 
            1280 1279 1289 -1 
            1289 1279 1288 -1 
            1281 1280 1290 -1 
            1290 1280 1289 -1 
            1282 1281 1291 -1 
            1291 1281 1290 -1 
            1283 1282 1292 -1 
            1292 1282 1291 -1 
            1284 1283 1293 -1 
            1293 1283 1292 -1 
            1285 1284 1294 -1 
            1294 1284 1293 -1 
            1398 1387 1285 -1 
            1294 1398 1285 -1 
            1295 1286 1389 -1 
            1401 1295 1389 -1 
            1287 1286 1296 -1 
            1296 1286 1295 -1 
            1288 1287 1297 -1 
            1297 1287 1296 -1 
            1289 1288 1298 -1 
            1298 1288 1297 -1 
            1290 1289 1299 -1 
            1299 1289 1298 -1 
            1291 1290 1300 -1 
            1300 1290 1299 -1 
            1292 1291 1301 -1 
            1301 1291 1300 -1 
            1293 1292 1302 -1 
            1302 1292 1301 -1 
            1412 1399 1294 -1 
            1412 1294 1302 -1 
            1302 1294 1293 -1 
            1398 1294 1399 -1 
            1295 1401 1402 -1 
            1296 1295 1402 -1 
            1404 1296 1402 -1 
            1297 1296 1404 -1 
            1406 1297 1404 -1 
            1406 1414 1303 -1 
            1406 1303 1297 -1 
            1297 1303 1298 -1 
            1299 1298 1304 -1 
            1304 1298 1303 -1 
            1300 1299 1305 -1 
            1305 1299 1304 -1 
            1301 1300 1306 -1 
            1306 1300 1305 -1 
            1302 1301 1307 -1 
            1307 1301 1306 -1 
            1421 1412 1302 -1 
            1307 1421 1302 -1 
            1303 1414 1415 -1 
            1304 1303 1415 -1 
            1417 1304 1415 -1 
            1417 1424 1308 -1 
            1417 1308 1304 -1 
            1304 1308 1305 -1 
            1306 1305 1309 -1 
            1309 1305 1308 -1 
            1427 1422 1307 -1 
            1427 1307 1309 -1 
            1309 1307 1306 -1 
            1421 1307 1422 -1 
            1308 1424 1425 -1 
            1309 1308 1425 -1 
            1428 1309 1425 -1 
            1427 1309 1428 -1 
            1310 1315 1314 -1 
            1310 1311 1316 -1 
            1315 1310 1316 -1 
            1311 1312 1317 -1 
            1316 1311 1317 -1 
            1312 1313 1319 -1 
            1317 1312 1319 -1 
            1313 1318 1319 -1 
            1315 1326 1324 -1 
            1314 1315 1324 -1 
            1316 1327 1315 -1 
            1327 1326 1315 -1 
            1317 1328 1316 -1 
            1328 1327 1316 -1 
            1319 1329 1317 -1 
            1329 1328 1317 -1 
            1318 1320 1330 -1 
            1318 1330 1319 -1 
            1319 1330 1329 -1 
            1320 1321 1331 -1 
            1330 1320 1331 -1 
            1321 1322 1332 -1 
            1331 1321 1332 -1 
            1322 1323 1334 -1 
            1332 1322 1334 -1 
            1323 1333 1334 -1 
            1325 1337 1336 -1 
            1325 1324 1326 -1 
            1325 1326 1337 -1 
            1337 1326 1338 -1 
            1327 1339 1326 -1 
            1339 1338 1326 -1 
            1328 1340 1327 -1 
            1340 1339 1327 -1 
            1329 1341 1328 -1 
            1341 1340 1328 -1 
            1330 1342 1329 -1 
            1342 1341 1329 -1 
            1331 1343 1330 -1 
            1343 1342 1330 -1 
            1332 1344 1331 -1 
            1344 1343 1331 -1 
            1334 1345 1332 -1 
            1345 1344 1332 -1 
            1333 1335 1348 -1 
            1333 1348 1334 -1 
            1334 1348 1345 -1 
            1335 1346 1348 -1 
            1337 1350 1349 -1 
            1336 1337 1349 -1 
            1338 1351 1337 -1 
            1351 1350 1337 -1 
            1339 1352 1338 -1 
            1352 1351 1338 -1 
            1340 1353 1339 -1 
            1353 1352 1339 -1 
            1431 1341 1430 -1 
            1431 1353 1341 -1 
            1353 1340 1341 -1 
            1430 1354 1433 -1 
            1430 1341 1354 -1 
            1341 1342 1354 -1 
            1343 1355 1342 -1 
            1355 1354 1342 -1 
            1344 1356 1343 -1 
            1356 1355 1343 -1 
            1345 1358 1344 -1 
            1358 1356 1344 -1 
            1347 1357 1358 -1 
            1347 1358 1348 -1 
            1348 1358 1345 -1 
            1346 1347 1348 -1 
            1350 1361 1359 -1 
            1349 1350 1359 -1 
            1351 1362 1350 -1 
            1362 1361 1350 -1 
            1352 1363 1351 -1 
            1363 1362 1351 -1 
            1436 1353 1432 -1 
            1436 1363 1353 -1 
            1363 1352 1353 -1 
            1431 1432 1353 -1 
            1354 1435 1433 -1 
            1435 1364 1439 -1 
            1435 1354 1364 -1 
            1354 1355 1364 -1 
            1356 1365 1355 -1 
            1365 1364 1355 -1 
            1358 1367 1356 -1 
            1367 1365 1356 -1 
            1357 1366 1367 -1 
            1358 1357 1367 -1 
            1360 1369 1368 -1 
            1360 1359 1361 -1 
            1360 1361 1369 -1 
            1369 1361 1370 -1 
            1362 1371 1361 -1 
            1371 1370 1361 -1 
            1363 1372 1362 -1 
            1372 1371 1362 -1 
            1441 1363 1436 -1 
            1372 1363 1441 -1 
            1373 1439 1364 -1 
            1446 1439 1373 -1 
            1365 1374 1364 -1 
            1374 1373 1364 -1 
            1367 1377 1365 -1 
            1377 1374 1365 -1 
            1366 1375 1377 -1 
            1367 1366 1377 -1 
            1369 1379 1378 -1 
            1368 1369 1378 -1 
            1370 1380 1369 -1 
            1380 1379 1369 -1 
            1371 1381 1370 -1 
            1381 1380 1370 -1 
            1372 1382 1371 -1 
            1382 1381 1371 -1 
            1442 1372 1441 -1 
            1442 1383 1372 -1 
            1383 1382 1372 -1 
            1383 1444 1384 -1 
            1442 1444 1383 -1 
            1384 1447 1385 -1 
            1444 1447 1384 -1 
            1446 1385 1447 -1 
            1446 1373 1385 -1 
            1373 1386 1385 -1 
            1374 1388 1373 -1 
            1388 1386 1373 -1 
            1376 1387 1388 -1 
            1376 1388 1377 -1 
            1377 1388 1374 -1 
            1375 1376 1377 -1 
            1379 1390 1389 -1 
            1378 1379 1389 -1 
            1380 1391 1379 -1 
            1391 1390 1379 -1 
            1381 1392 1380 -1 
            1392 1391 1380 -1 
            1382 1393 1381 -1 
            1393 1392 1381 -1 
            1383 1394 1382 -1 
            1394 1393 1382 -1 
            1384 1395 1383 -1 
            1395 1394 1383 -1 
            1385 1396 1384 -1 
            1396 1395 1384 -1 
            1386 1397 1385 -1 
            1397 1396 1385 -1 
            1388 1400 1386 -1 
            1400 1397 1386 -1 
            1387 1398 1400 -1 
            1388 1387 1400 -1 
            1390 1403 1401 -1 
            1389 1390 1401 -1 
            1391 1405 1390 -1 
            1405 1403 1390 -1 
            1392 1407 1391 -1 
            1407 1405 1391 -1 
            1393 1408 1392 -1 
            1408 1407 1392 -1 
            1394 1409 1393 -1 
            1409 1408 1393 -1 
            1395 1410 1394 -1 
            1410 1409 1394 -1 
            1396 1411 1395 -1 
            1411 1410 1395 -1 
            1397 1413 1396 -1 
            1413 1411 1396 -1 
            1399 1412 1413 -1 
            1399 1413 1400 -1 
            1400 1413 1397 -1 
            1398 1399 1400 -1 
            1401 1403 1402 -1 
            1404 1402 1403 -1 
            1405 1404 1403 -1 
            1406 1404 1405 -1 
            1407 1406 1405 -1 
            1414 1406 1407 -1 
            1414 1407 1416 -1 
            1416 1407 1408 -1 
            1409 1418 1408 -1 
            1418 1416 1408 -1 
            1410 1419 1409 -1 
            1419 1418 1409 -1 
            1411 1420 1410 -1 
            1420 1419 1410 -1 
            1413 1423 1411 -1 
            1423 1420 1411 -1 
            1412 1421 1423 -1 
            1413 1412 1423 -1 
            1414 1416 1415 -1 
            1417 1415 1416 -1 
            1418 1417 1416 -1 
            1424 1417 1418 -1 
            1424 1418 1426 -1 
            1426 1418 1419 -1 
            1420 1429 1419 -1 
            1429 1426 1419 -1 
            1422 1427 1429 -1 
            1422 1429 1423 -1 
            1423 1429 1420 -1 
            1421 1422 1423 -1 
            1424 1426 1425 -1 
            1428 1425 1426 -1 
            1429 1428 1426 -1 
            1427 1428 1429 -1 
            1430 1434 1431 -1 
            1430 1433 1434 -1 
            1432 1437 1436 -1 
            1432 1431 1434 -1 
            1432 1434 1437 -1 
            1437 1434 1438 -1 
            1433 1435 1440 -1 
            1433 1440 1434 -1 
            1434 1440 1438 -1 
            1435 1439 1440 -1 
            1437 1443 1441 -1 
            1436 1437 1441 -1 
            1438 1445 1437 -1 
            1445 1443 1437 -1 
            1440 1448 1438 -1 
            1448 1445 1438 -1 
            1439 1446 1448 -1 
            1440 1439 1448 -1 
            1441 1443 1442 -1 
            1444 1442 1443 -1 
            1445 1444 1443 -1 
            1447 1444 1445 -1 
            1448 1447 1445 -1 
            1446 1447 1448 -1 
            1449 1455 1457 -1 
            1449 1450 1456 -1 
            1455 1449 1456 -1 
            1456 1450 1458 -1 
            1451 1449 1457 -1 
            1459 1451 1457 -1 
            1450 1449 1452 -1 
            1452 1449 1451 -1 
            1460 1458 1450 -1 
            1452 1460 1450 -1 
            1453 1451 1459 -1 
            1461 1453 1459 -1 
            1452 1451 1454 -1 
            1454 1451 1453 -1 
            1463 1460 1452 -1 
            1454 1463 1452 -1 
            1453 1461 1462 -1 
            1454 1453 1462 -1 
            1464 1454 1462 -1 
            1463 1454 1464 -1 
            1455 1553 1558 -1 
            1457 1455 1558 -1 
            1456 1554 1455 -1 
            1455 1554 1553 -1 
            1554 1456 1458 -1 
            1560 1554 1458 -1 
            1457 1558 1459 -1 
            1558 1564 1459 -1 
            1458 1460 1560 -1 
            1560 1460 1566 -1 
            1459 1564 1461 -1 
            1564 1568 1461 -1 
            1460 1463 1566 -1 
            1566 1463 1571 -1 
            1461 1568 1569 -1 
            1462 1461 1569 -1 
            1464 1462 1572 -1 
            1462 1569 1572 -1 
            1571 1463 1464 -1 
            1572 1571 1464 -1 
            1465 1574 1576 -1 
            1574 1465 1578 -1 
            1466 1575 1581 -1 
            1466 1467 1577 -1 
            1575 1466 1577 -1 
            1576 1577 1467 -1 
            1576 1467 1465 -1 
            1465 1467 1468 -1 
            1579 1578 1465 -1 
            1579 1465 1469 -1 
            1469 1465 1468 -1 
            1579 1469 1583 -1 
            1470 1580 1585 -1 
            1470 1471 1582 -1 
            1580 1470 1582 -1 
            1581 1582 1471 -1 
            1581 1471 1466 -1 
            1466 1471 1472 -1 
            1467 1466 1473 -1 
            1473 1466 1472 -1 
            1468 1467 1474 -1 
            1474 1467 1473 -1 
            1469 1468 1475 -1 
            1475 1468 1474 -1 
            1587 1583 1469 -1 
            1475 1587 1469 -1 
            1476 1584 1589 -1 
            1476 1477 1586 -1 
            1584 1476 1586 -1 
            1585 1586 1477 -1 
            1585 1477 1470 -1 
            1470 1477 1478 -1 
            1471 1470 1479 -1 
            1479 1470 1478 -1 
            1472 1471 1480 -1 
            1480 1471 1479 -1 
            1473 1472 1481 -1 
            1481 1472 1480 -1 
            1474 1473 1482 -1 
            1482 1473 1481 -1 
            1475 1474 1483 -1 
            1483 1474 1482 -1 
            1588 1587 1475 -1 
            1588 1475 1484 -1 
            1484 1475 1483 -1 
            1588 1484 1591 -1 
            1485 1590 1592 -1 
            1589 1590 1485 -1 
            1589 1485 1476 -1 
            1476 1485 1486 -1 
            1477 1476 1487 -1 
            1487 1476 1486 -1 
            1478 1477 1488 -1 
            1488 1477 1487 -1 
            1479 1478 1489 -1 
            1489 1478 1488 -1 
            1480 1479 1490 -1 
            1490 1479 1489 -1 
            1481 1480 1491 -1 
            1491 1480 1490 -1 
            1482 1481 1492 -1 
            1492 1481 1491 -1 
            1483 1482 1493 -1 
            1493 1482 1492 -1 
            1484 1483 1494 -1 
            1494 1483 1493 -1 
            1594 1591 1484 -1 
            1494 1594 1484 -1 
            1592 1593 1495 -1 
            1592 1495 1485 -1 
            1485 1495 1496 -1 
            1486 1485 1497 -1 
            1497 1485 1496 -1 
            1487 1486 1498 -1 
            1498 1486 1497 -1 
            1488 1487 1499 -1 
            1499 1487 1498 -1 
            1489 1488 1500 -1 
            1500 1488 1499 -1 
            1490 1489 1501 -1 
            1501 1489 1500 -1 
            1491 1490 1502 -1 
            1502 1490 1501 -1 
            1492 1491 1503 -1 
            1503 1491 1502 -1 
            1493 1492 1504 -1 
            1504 1492 1503 -1 
            1494 1493 1505 -1 
            1505 1493 1504 -1 
            1595 1594 1494 -1 
            1595 1494 1506 -1 
            1506 1494 1505 -1 
            1595 1506 1597 -1 
            1596 1598 1507 -1 
            1596 1507 1495 -1 
            1495 1507 1496 -1 
            1497 1496 1508 -1 
            1508 1496 1507 -1 
            1498 1497 1509 -1 
            1509 1497 1508 -1 
            1499 1498 1510 -1 
            1510 1498 1509 -1 
            1500 1499 1511 -1 
            1511 1499 1510 -1 
            1501 1500 1512 -1 
            1512 1500 1511 -1 
            1502 1501 1513 -1 
            1513 1501 1512 -1 
            1503 1502 1514 -1 
            1514 1502 1513 -1 
            1504 1503 1515 -1 
            1515 1503 1514 -1 
            1505 1504 1516 -1 
            1516 1504 1515 -1 
            1506 1505 1517 -1 
            1517 1505 1516 -1 
            1600 1597 1506 -1 
            1517 1600 1506 -1 
            1507 1598 1599 -1 
            1599 1605 1518 -1 
            1599 1518 1507 -1 
            1507 1518 1508 -1 
            1509 1508 1519 -1 
            1519 1508 1518 -1 
            1510 1509 1520 -1 
            1520 1509 1519 -1 
            1511 1510 1521 -1 
            1521 1510 1520 -1 
            1512 1511 1522 -1 
            1522 1511 1521 -1 
            1513 1512 1523 -1 
            1523 1512 1522 -1 
            1514 1513 1524 -1 
            1524 1513 1523 -1 
            1515 1514 1525 -1 
            1525 1514 1524 -1 
            1516 1515 1526 -1 
            1526 1515 1525 -1 
            1517 1516 1527 -1 
            1527 1516 1526 -1 
            1607 1600 1517 -1 
            1527 1607 1517 -1 
            1518 1605 1606 -1 
            1606 1617 1528 -1 
            1606 1528 1518 -1 
            1518 1528 1519 -1 
            1520 1519 1529 -1 
            1529 1519 1528 -1 
            1521 1520 1530 -1 
            1530 1520 1529 -1 
            1522 1521 1531 -1 
            1531 1521 1530 -1 
            1523 1522 1532 -1 
            1532 1522 1531 -1 
            1524 1523 1533 -1 
            1533 1523 1532 -1 
            1525 1524 1534 -1 
            1534 1524 1533 -1 
            1526 1525 1535 -1 
            1535 1525 1534 -1 
            1619 1608 1527 -1 
            1619 1527 1535 -1 
            1535 1527 1526 -1 
            1607 1527 1608 -1 
            1528 1617 1618 -1 
            1618 1630 1536 -1 
            1618 1536 1528 -1 
            1528 1536 1529 -1 
            1530 1529 1537 -1 
            1537 1529 1536 -1 
            1531 1530 1538 -1 
            1538 1530 1537 -1 
            1532 1531 1539 -1 
            1539 1531 1538 -1 
            1533 1532 1540 -1 
            1540 1532 1539 -1 
            1534 1533 1541 -1 
            1541 1533 1540 -1 
            1535 1534 1542 -1 
            1542 1534 1541 -1 
            1632 1619 1535 -1 
            1542 1632 1535 -1 
            1536 1630 1631 -1 
            1631 1642 1543 -1 
            1631 1543 1536 -1 
            1536 1543 1537 -1 
            1538 1537 1544 -1 
            1544 1537 1543 -1 
            1539 1538 1545 -1 
            1545 1538 1544 -1 
            1540 1539 1546 -1 
            1546 1539 1545 -1 
            1541 1540 1547 -1 
            1547 1540 1546 -1 
            1542 1541 1548 -1 
            1548 1541 1547 -1 
            1644 1632 1542 -1 
            1548 1644 1542 -1 
            1543 1642 1643 -1 
            1643 1655 1549 -1 
            1643 1549 1543 -1 
            1543 1549 1544 -1 
            1545 1544 1550 -1 
            1550 1544 1549 -1 
            1546 1545 1551 -1 
            1551 1545 1550 -1 
            1547 1546 1552 -1 
            1552 1546 1551 -1 
            1657 1645 1548 -1 
            1657 1548 1552 -1 
            1552 1548 1547 -1 
            1644 1548 1645 -1 
            1549 1655 1656 -1 
            1656 1668 1555 -1 
            1656 1555 1549 -1 
            1549 1555 1550 -1 
            1551 1550 1556 -1 
            1556 1550 1555 -1 
            1552 1551 1557 -1 
            1557 1551 1556 -1 
            1670 1657 1552 -1 
            1557 1670 1552 -1 
            1553 1559 1558 -1 
            1553 1554 1561 -1 
            1559 1553 1561 -1 
            1554 1560 1561 -1 
            1555 1668 1669 -1 
            1669 1680 1562 -1 
            1669 1562 1555 -1 
            1555 1562 1556 -1 
            1557 1556 1563 -1 
            1563 1556 1562 -1 
            1682 1670 1557 -1 
            1563 1682 1557 -1 
            1559 1565 1564 -1 
            1558 1559 1564 -1 
            1561 1567 1559 -1 
            1567 1565 1559 -1 
            1560 1566 1567 -1 
            1561 1560 1567 -1 
            1562 1680 1681 -1 
            1563 1562 1681 -1 
            1683 1563 1681 -1 
            1682 1563 1683 -1 
            1565 1570 1568 -1 
            1564 1565 1568 -1 
            1567 1573 1565 -1 
            1573 1570 1565 -1 
            1566 1571 1573 -1 
            1567 1566 1573 -1 
            1568 1570 1569 -1 
            1572 1569 1570 -1 
            1573 1572 1570 -1 
            1571 1572 1573 -1 
            1574 1708 1710 -1 
            1576 1574 1710 -1 
            1708 1574 1578 -1 
            1712 1708 1578 -1 
            1575 1709 1716 -1 
            1581 1575 1716 -1 
            1577 1711 1575 -1 
            1575 1711 1709 -1 
            1710 1577 1576 -1 
            1711 1577 1710 -1 
            1578 1714 1712 -1 
            1579 1714 1578 -1 
            1714 1579 1583 -1 
            1721 1714 1583 -1 
            1580 1715 1724 -1 
            1585 1580 1724 -1 
            1582 1717 1580 -1 
            1580 1717 1715 -1 
            1716 1582 1581 -1 
            1717 1582 1716 -1 
            1583 1587 1721 -1 
            1721 1587 1731 -1 
            1584 1723 1734 -1 
            1589 1584 1734 -1 
            1586 1725 1584 -1 
            1584 1725 1723 -1 
            1724 1586 1585 -1 
            1725 1586 1724 -1 
            1587 1733 1731 -1 
            1588 1733 1587 -1 
            1733 1588 1591 -1 
            1744 1733 1591 -1 
            1590 1735 1746 -1 
            1592 1590 1746 -1 
            1734 1590 1589 -1 
            1735 1590 1734 -1 
            1591 1594 1744 -1 
            1744 1594 1757 -1 
            1746 1593 1592 -1 
            1747 1593 1746 -1 
            1594 1759 1757 -1 
            1595 1759 1594 -1 
            1759 1595 1597 -1 
            1772 1759 1597 -1 
            1760 1598 1596 -1 
            1778 1598 1760 -1 
            1774 1600 1601 -1 
            1774 1772 1600 -1 
            1772 1597 1600 -1 
            1601 1602 1775 -1 
            1774 1601 1775 -1 
            1602 1603 1776 -1 
            1775 1602 1776 -1 
            1603 1604 1777 -1 
            1776 1603 1777 -1 
            1777 1604 1794 -1 
            1598 1778 1779 -1 
            1599 1598 1779 -1 
            1779 1605 1599 -1 
            1800 1605 1779 -1 
            1601 1607 1609 -1 
            1600 1607 1601 -1 
            1602 1601 1610 -1 
            1610 1601 1609 -1 
            1603 1602 1611 -1 
            1611 1602 1610 -1 
            1604 1603 1612 -1 
            1612 1603 1611 -1 
            1796 1794 1604 -1 
            1796 1604 1613 -1 
            1613 1604 1612 -1 
            1613 1614 1797 -1 
            1796 1613 1797 -1 
            1614 1615 1798 -1 
            1797 1614 1798 -1 
            1615 1616 1799 -1 
            1798 1615 1799 -1 
            1799 1616 1819 -1 
            1605 1800 1801 -1 
            1606 1605 1801 -1 
            1801 1617 1606 -1 
            1822 1617 1801 -1 
            1608 1619 1620 -1 
            1608 1609 1607 -1 
            1608 1620 1609 -1 
            1620 1621 1609 -1 
            1610 1609 1622 -1 
            1622 1609 1621 -1 
            1611 1610 1623 -1 
            1623 1610 1622 -1 
            1612 1611 1624 -1 
            1624 1611 1623 -1 
            1613 1612 1625 -1 
            1625 1612 1624 -1 
            1614 1613 1626 -1 
            1626 1613 1625 -1 
            1615 1614 1627 -1 
            1627 1614 1626 -1 
            1616 1615 1628 -1 
            1628 1615 1627 -1 
            1821 1819 1616 -1 
            1821 1616 1629 -1 
            1629 1616 1628 -1 
            1821 1629 1841 -1 
            1617 1822 1823 -1 
            1618 1617 1823 -1 
            1823 1630 1618 -1 
            1844 1630 1823 -1 
            1620 1632 1633 -1 
            1619 1632 1620 -1 
            1621 1620 1634 -1 
            1634 1620 1633 -1 
            1622 1621 1635 -1 
            1635 1621 1634 -1 
            1623 1622 1636 -1 
            1636 1622 1635 -1 
            1624 1623 1637 -1 
            1637 1623 1636 -1 
            1625 1624 1638 -1 
            1638 1624 1637 -1 
            1626 1625 1639 -1 
            1639 1625 1638 -1 
            1627 1626 1640 -1 
            1640 1626 1639 -1 
            1628 1627 1641 -1 
            1641 1627 1640 -1 
            1861 1842 1629 -1 
            1861 1629 1641 -1 
            1641 1629 1628 -1 
            1841 1629 1842 -1 
            1630 1844 1845 -1 
            1631 1630 1845 -1 
            1845 1642 1631 -1 
            1863 1642 1845 -1 
            1633 1644 1646 -1 
            1632 1644 1633 -1 
            1634 1633 1647 -1 
            1647 1633 1646 -1 
            1635 1634 1648 -1 
            1648 1634 1647 -1 
            1636 1635 1649 -1 
            1649 1635 1648 -1 
            1637 1636 1650 -1 
            1650 1636 1649 -1 
            1638 1637 1651 -1 
            1651 1637 1650 -1 
            1639 1638 1652 -1 
            1652 1638 1651 -1 
            1640 1639 1653 -1 
            1653 1639 1652 -1 
            1641 1640 1654 -1 
            1654 1640 1653 -1 
            1879 1861 1641 -1 
            1654 1879 1641 -1 
            1642 1863 1864 -1 
            1643 1642 1864 -1 
            1864 1655 1643 -1 
            1881 1655 1864 -1 
            1645 1657 1658 -1 
            1645 1646 1644 -1 
            1645 1658 1646 -1 
            1658 1659 1646 -1 
            1647 1646 1660 -1 
            1660 1646 1659 -1 
            1648 1647 1661 -1 
            1661 1647 1660 -1 
            1649 1648 1662 -1 
            1662 1648 1661 -1 
            1650 1649 1663 -1 
            1663 1649 1662 -1 
            1651 1650 1664 -1 
            1664 1650 1663 -1 
            1652 1651 1665 -1 
            1665 1651 1664 -1 
            1653 1652 1666 -1 
            1666 1652 1665 -1 
            1654 1653 1667 -1 
            1667 1653 1666 -1 
            1896 1879 1654 -1 
            1667 1896 1654 -1 
            1655 1881 1882 -1 
            1656 1655 1882 -1 
            1882 1668 1656 -1 
            1899 1668 1882 -1 
            1658 1670 1671 -1 
            1657 1670 1658 -1 
            1659 1658 1672 -1 
            1672 1658 1671 -1 
            1660 1659 1673 -1 
            1673 1659 1672 -1 
            1661 1660 1674 -1 
            1674 1660 1673 -1 
            1662 1661 1675 -1 
            1675 1661 1674 -1 
            1663 1662 1676 -1 
            1676 1662 1675 -1 
            1664 1663 1677 -1 
            1677 1663 1676 -1 
            1665 1664 1678 -1 
            1678 1664 1677 -1 
            1666 1665 1679 -1 
            1679 1665 1678 -1 
            1912 1897 1667 -1 
            1912 1667 1679 -1 
            1679 1667 1666 -1 
            1896 1667 1897 -1 
            1668 1899 1900 -1 
            1669 1668 1900 -1 
            1900 1680 1669 -1 
            1914 1680 1900 -1 
            1671 1682 1684 -1 
            1670 1682 1671 -1 
            1672 1671 1685 -1 
            1685 1671 1684 -1 
            1673 1672 1686 -1 
            1686 1672 1685 -1 
            1674 1673 1687 -1 
            1687 1673 1686 -1 
            1675 1674 1688 -1 
            1688 1674 1687 -1 
            1676 1675 1689 -1 
            1689 1675 1688 -1 
            1677 1676 1690 -1 
            1690 1676 1689 -1 
            1678 1677 1691 -1 
            1691 1677 1690 -1 
            1679 1678 1692 -1 
            1692 1678 1691 -1 
            1927 1912 1679 -1 
            1692 1927 1679 -1 
            1680 1914 1915 -1 
            1681 1680 1915 -1 
            1683 1681 1917 -1 
            1681 1915 1917 -1 
            1682 1683 1917 -1 
            1682 1917 1693 -1 
            1682 1693 1684 -1 
            1930 1693 1917 -1 
            1685 1684 1694 -1 
            1694 1684 1693 -1 
            1686 1685 1695 -1 
            1695 1685 1694 -1 
            1687 1686 1696 -1 
            1696 1686 1695 -1 
            1688 1687 1697 -1 
            1697 1687 1696 -1 
            1689 1688 1698 -1 
            1698 1688 1697 -1 
            1690 1689 1699 -1 
            1699 1689 1698 -1 
            1691 1690 1700 -1 
            1700 1690 1699 -1 
            1941 1928 1692 -1 
            1941 1692 1700 -1 
            1700 1692 1691 -1 
            1927 1692 1928 -1 
            1693 1930 1931 -1 
            1694 1693 1931 -1 
            1933 1694 1931 -1 
            1695 1694 1933 -1 
            1935 1695 1933 -1 
            1935 1943 1701 -1 
            1935 1701 1695 -1 
            1695 1701 1696 -1 
            1697 1696 1702 -1 
            1702 1696 1701 -1 
            1698 1697 1703 -1 
            1703 1697 1702 -1 
            1699 1698 1704 -1 
            1704 1698 1703 -1 
            1700 1699 1705 -1 
            1705 1699 1704 -1 
            1950 1941 1700 -1 
            1705 1950 1700 -1 
            1701 1943 1944 -1 
            1702 1701 1944 -1 
            1946 1702 1944 -1 
            1946 1953 1706 -1 
            1946 1706 1702 -1 
            1702 1706 1703 -1 
            1704 1703 1707 -1 
            1707 1703 1706 -1 
            1956 1951 1705 -1 
            1956 1705 1707 -1 
            1707 1705 1704 -1 
            1950 1705 1951 -1 
            1706 1953 1954 -1 
            1707 1706 1954 -1 
            1957 1707 1954 -1 
            1956 1707 1957 -1 
            1708 1713 1710 -1 
            1708 1712 1713 -1 
            1709 1718 1716 -1 
            1709 1711 1719 -1 
            1718 1709 1719 -1 
            1711 1710 1713 -1 
            1711 1713 1719 -1 
            1719 1713 1720 -1 
            1712 1714 1722 -1 
            1712 1722 1713 -1 
            1713 1722 1720 -1 
            1714 1721 1722 -1 
            1715 1726 1724 -1 
            1715 1717 1727 -1 
            1726 1715 1727 -1 
            1717 1716 1718 -1 
            1717 1718 1727 -1 
            1727 1718 1728 -1 
            1719 1729 1718 -1 
            1729 1728 1718 -1 
            1720 1730 1719 -1 
            1730 1729 1719 -1 
            1722 1732 1720 -1 
            1732 1730 1720 -1 
            1721 1731 1732 -1 
            1722 1721 1732 -1 
            1723 1736 1734 -1 
            1723 1725 1737 -1 
            1736 1723 1737 -1 
            1725 1724 1726 -1 
            1725 1726 1737 -1 
            1737 1726 1738 -1 
            1727 1739 1726 -1 
            1739 1738 1726 -1 
            1728 1740 1727 -1 
            1740 1739 1727 -1 
            1729 1741 1728 -1 
            1741 1740 1728 -1 
            1730 1742 1729 -1 
            1742 1741 1729 -1 
            1732 1743 1730 -1 
            1743 1742 1730 -1 
            1731 1733 1745 -1 
            1731 1745 1732 -1 
            1732 1745 1743 -1 
            1733 1744 1745 -1 
            1735 1748 1746 -1 
            1735 1734 1736 -1 
            1735 1736 1748 -1 
            1748 1736 1749 -1 
            1737 1750 1736 -1 
            1750 1749 1736 -1 
            1738 1751 1737 -1 
            1751 1750 1737 -1 
            1739 1752 1738 -1 
            1752 1751 1738 -1 
            1740 1753 1739 -1 
            1753 1752 1739 -1 
            1741 1754 1740 -1 
            1754 1753 1740 -1 
            1742 1755 1741 -1 
            1755 1754 1741 -1 
            1743 1756 1742 -1 
            1756 1755 1742 -1 
            1745 1758 1743 -1 
            1758 1756 1743 -1 
            1744 1757 1758 -1 
            1745 1744 1758 -1 
            1747 1746 1748 -1 
            1747 1748 1761 -1 
            1761 1748 1762 -1 
            1749 1763 1748 -1 
            1763 1762 1748 -1 
            1750 1764 1749 -1 
            1764 1763 1749 -1 
            1751 1765 1750 -1 
            1765 1764 1750 -1 
            1752 1766 1751 -1 
            1766 1765 1751 -1 
            1753 1767 1752 -1 
            1767 1766 1752 -1 
            1754 1768 1753 -1 
            1768 1767 1753 -1 
            1755 1769 1754 -1 
            1769 1768 1754 -1 
            1756 1770 1755 -1 
            1770 1769 1755 -1 
            1758 1771 1756 -1 
            1771 1770 1756 -1 
            1757 1759 1773 -1 
            1757 1773 1758 -1 
            1758 1773 1771 -1 
            1759 1772 1773 -1 
            1778 1760 1761 -1 
            1778 1761 1780 -1 
            1780 1761 1762 -1 
            1763 1781 1762 -1 
            1781 1780 1762 -1 
            1764 1782 1763 -1 
            1782 1781 1763 -1 
            1765 1783 1764 -1 
            1783 1782 1764 -1 
            1766 1784 1765 -1 
            1784 1783 1765 -1 
            1767 1785 1766 -1 
            1785 1784 1766 -1 
            1768 1786 1767 -1 
            1786 1785 1767 -1 
            1769 1787 1768 -1 
            1787 1786 1768 -1 
            1770 1788 1769 -1 
            1788 1787 1769 -1 
            1771 1789 1770 -1 
            1789 1788 1770 -1 
            1773 1790 1771 -1 
            1790 1789 1771 -1 
            1772 1774 1791 -1 
            1772 1791 1773 -1 
            1773 1791 1790 -1 
            1774 1775 1792 -1 
            1791 1774 1792 -1 
            1775 1776 1793 -1 
            1792 1775 1793 -1 
            1776 1777 1795 -1 
            1793 1776 1795 -1 
            1777 1794 1795 -1 
            1778 1780 1779 -1 
            1800 1779 1780 -1 
            1800 1780 1802 -1 
            1802 1780 1781 -1 
            1782 1803 1781 -1 
            1803 1802 1781 -1 
            1783 1804 1782 -1 
            1804 1803 1782 -1 
            1784 1805 1783 -1 
            1805 1804 1783 -1 
            1785 1806 1784 -1 
            1806 1805 1784 -1 
            1786 1807 1785 -1 
            1807 1806 1785 -1 
            1787 1808 1786 -1 
            1808 1807 1786 -1 
            1788 1809 1787 -1 
            1809 1808 1787 -1 
            1789 1810 1788 -1 
            1810 1809 1788 -1 
            1790 1811 1789 -1 
            1811 1810 1789 -1 
            1791 1812 1790 -1 
            1812 1811 1790 -1 
            1792 1813 1791 -1 
            1813 1812 1791 -1 
            1793 1814 1792 -1 
            1814 1813 1792 -1 
            1795 1815 1793 -1 
            1815 1814 1793 -1 
            1794 1796 1816 -1 
            1794 1816 1795 -1 
            1795 1816 1815 -1 
            1796 1797 1817 -1 
            1816 1796 1817 -1 
            1797 1798 1818 -1 
            1817 1797 1818 -1 
            1798 1799 1820 -1 
            1818 1798 1820 -1 
            1799 1819 1820 -1 
            1800 1802 1801 -1 
            1822 1801 1802 -1 
            1822 1802 1824 -1 
            1824 1802 1803 -1 
            1804 1825 1803 -1 
            1825 1824 1803 -1 
            1805 1826 1804 -1 
            1826 1825 1804 -1 
            1806 1827 1805 -1 
            1827 1826 1805 -1 
            1807 1828 1806 -1 
            1828 1827 1806 -1 
            1808 1829 1807 -1 
            1829 1828 1807 -1 
            1809 1830 1808 -1 
            1830 1829 1808 -1 
            1810 1831 1809 -1 
            1831 1830 1809 -1 
            1811 1832 1810 -1 
            1832 1831 1810 -1 
            1812 1833 1811 -1 
            1833 1832 1811 -1 
            1813 1834 1812 -1 
            1834 1833 1812 -1 
            1814 1835 1813 -1 
            1835 1834 1813 -1 
            1815 1836 1814 -1 
            1836 1835 1814 -1 
            1816 1837 1815 -1 
            1837 1836 1815 -1 
            1817 1838 1816 -1 
            1838 1837 1816 -1 
            1818 1839 1817 -1 
            1839 1838 1817 -1 
            1820 1840 1818 -1 
            1840 1839 1818 -1 
            1819 1821 1843 -1 
            1819 1843 1820 -1 
            1820 1843 1840 -1 
            1821 1841 1843 -1 
            1822 1824 1823 -1 
            1844 1823 1824 -1 
            1844 1824 1846 -1 
            1846 1824 1825 -1 
            1826 1847 1825 -1 
            1847 1846 1825 -1 
            1827 1848 1826 -1 
            1848 1847 1826 -1 
            1828 1849 1827 -1 
            1849 1848 1827 -1 
            1829 1850 1828 -1 
            1850 1849 1828 -1 
            1830 1851 1829 -1 
            1851 1850 1829 -1 
            1831 1852 1830 -1 
            1852 1851 1830 -1 
            1832 1853 1831 -1 
            1853 1852 1831 -1 
            1833 1854 1832 -1 
            1854 1853 1832 -1 
            1834 1855 1833 -1 
            1855 1854 1833 -1 
            1835 1856 1834 -1 
            1856 1855 1834 -1 
            1836 1857 1835 -1 
            1857 1856 1835 -1 
            1837 1858 1836 -1 
            1858 1857 1836 -1 
            1838 1859 1837 -1 
            1859 1858 1837 -1 
            1839 1860 1838 -1 
            1860 1859 1838 -1 
            1840 1862 1839 -1 
            1862 1860 1839 -1 
            1842 1861 1862 -1 
            1842 1862 1843 -1 
            1843 1862 1840 -1 
            1841 1842 1843 -1 
            1844 1846 1845 -1 
            1863 1845 1846 -1 
            1863 1846 1865 -1 
            1865 1846 1847 -1 
            1848 1866 1847 -1 
            1866 1865 1847 -1 
            1849 1867 1848 -1 
            1867 1866 1848 -1 
            1850 1868 1849 -1 
            1868 1867 1849 -1 
            1851 1869 1850 -1 
            1869 1868 1850 -1 
            1852 1870 1851 -1 
            1870 1869 1851 -1 
            1853 1871 1852 -1 
            1871 1870 1852 -1 
            1854 1872 1853 -1 
            1872 1871 1853 -1 
            1855 1873 1854 -1 
            1873 1872 1854 -1 
            1856 1874 1855 -1 
            1874 1873 1855 -1 
            1857 1875 1856 -1 
            1875 1874 1856 -1 
            1858 1876 1857 -1 
            1876 1875 1857 -1 
            1859 1877 1858 -1 
            1877 1876 1858 -1 
            1860 1878 1859 -1 
            1878 1877 1859 -1 
            1862 1880 1860 -1 
            1880 1878 1860 -1 
            1861 1879 1880 -1 
            1862 1861 1880 -1 
            1863 1865 1864 -1 
            1881 1864 1865 -1 
            1881 1865 1883 -1 
            1883 1865 1866 -1 
            1867 1884 1866 -1 
            1884 1883 1866 -1 
            1868 1885 1867 -1 
            1885 1884 1867 -1 
            1869 1886 1868 -1 
            1886 1885 1868 -1 
            1870 1887 1869 -1 
            1887 1886 1869 -1 
            1871 1888 1870 -1 
            1888 1887 1870 -1 
            1872 1889 1871 -1 
            1889 1888 1871 -1 
            1873 1890 1872 -1 
            1890 1889 1872 -1 
            1874 1891 1873 -1 
            1891 1890 1873 -1 
            1875 1892 1874 -1 
            1892 1891 1874 -1 
            1876 1893 1875 -1 
            1893 1892 1875 -1 
            1877 1894 1876 -1 
            1894 1893 1876 -1 
            1878 1895 1877 -1 
            1895 1894 1877 -1 
            1880 1898 1878 -1 
            1898 1895 1878 -1 
            1879 1896 1898 -1 
            1880 1879 1898 -1 
            1881 1883 1882 -1 
            1899 1882 1883 -1 
            1899 1883 1901 -1 
            1901 1883 1884 -1 
            1885 1902 1884 -1 
            1902 1901 1884 -1 
            1886 1903 1885 -1 
            1903 1902 1885 -1 
            1887 1904 1886 -1 
            1904 1903 1886 -1 
            1888 1905 1887 -1 
            1905 1904 1887 -1 
            1889 1906 1888 -1 
            1906 1905 1888 -1 
            1890 1907 1889 -1 
            1907 1906 1889 -1 
            1891 1908 1890 -1 
            1908 1907 1890 -1 
            1892 1909 1891 -1 
            1909 1908 1891 -1 
            1893 1910 1892 -1 
            1910 1909 1892 -1 
            1894 1911 1893 -1 
            1911 1910 1893 -1 
            1895 1913 1894 -1 
            1913 1911 1894 -1 
            1897 1912 1913 -1 
            1897 1913 1898 -1 
            1898 1913 1895 -1 
            1896 1897 1898 -1 
            1899 1901 1900 -1 
            1914 1900 1901 -1 
            1914 1901 1916 -1 
            1916 1901 1902 -1 
            1903 1918 1902 -1 
            1918 1916 1902 -1 
            1904 1919 1903 -1 
            1919 1918 1903 -1 
            1905 1920 1904 -1 
            1920 1919 1904 -1 
            1906 1921 1905 -1 
            1921 1920 1905 -1 
            1907 1922 1906 -1 
            1922 1921 1906 -1 
            1908 1923 1907 -1 
            1923 1922 1907 -1 
            1909 1924 1908 -1 
            1924 1923 1908 -1 
            1910 1925 1909 -1 
            1925 1924 1909 -1 
            1911 1926 1910 -1 
            1926 1925 1910 -1 
            1913 1929 1911 -1 
            1929 1926 1911 -1 
            1912 1927 1929 -1 
            1913 1912 1929 -1 
            1914 1916 1915 -1 
            1917 1915 1916 -1 
            1918 1917 1916 -1 
            1930 1917 1918 -1 
            1930 1918 1932 -1 
            1932 1918 1919 -1 
            1920 1934 1919 -1 
            1934 1932 1919 -1 
            1921 1936 1920 -1 
            1936 1934 1920 -1 
            1922 1937 1921 -1 
            1937 1936 1921 -1 
            1923 1938 1922 -1 
            1938 1937 1922 -1 
            1924 1939 1923 -1 
            1939 1938 1923 -1 
            1925 1940 1924 -1 
            1940 1939 1924 -1 
            1926 1942 1925 -1 
            1942 1940 1925 -1 
            1928 1941 1942 -1 
            1928 1942 1929 -1 
            1929 1942 1926 -1 
            1927 1928 1929 -1 
            1930 1932 1931 -1 
            1933 1931 1932 -1 
            1934 1933 1932 -1 
            1935 1933 1934 -1 
            1936 1935 1934 -1 
            1943 1935 1936 -1 
            1943 1936 1945 -1 
            1945 1936 1937 -1 
            1938 1947 1937 -1 
            1947 1945 1937 -1 
            1939 1948 1938 -1 
            1948 1947 1938 -1 
            1940 1949 1939 -1 
            1949 1948 1939 -1 
            1942 1952 1940 -1 
            1952 1949 1940 -1 
            1941 1950 1952 -1 
            1942 1941 1952 -1 
            1943 1945 1944 -1 
            1946 1944 1945 -1 
            1947 1946 1945 -1 
            1953 1946 1947 -1 
            1953 1947 1955 -1 
            1955 1947 1948 -1 
            1949 1958 1948 -1 
            1958 1955 1948 -1 
            1951 1956 1958 -1 
            1951 1958 1952 -1 
            1952 1958 1949 -1 
            1950 1951 1952 -1 
            1953 1955 1954 -1 
            1957 1954 1955 -1 
            1958 1957 1955 -1 
            1956 1957 1958 -1 
            1959 1969 1971 -1 
            1959 1960 1970 -1 
            1969 1959 1970 -1 
            1970 1960 1972 -1 
            1961 1959 1971 -1 
            1973 1961 1971 -1 
            1960 1959 1962 -1 
            1962 1959 1961 -1 
            1975 1972 1960 -1 
            1962 1975 1960 -1 
            1961 1973 1974 -1 
            1962 1961 1974 -1 
            1976 1962 1974 -1 
            1975 1962 1976 -1 
            1963 1979 1981 -1 
            1963 1964 1980 -1 
            1979 1963 1980 -1 
            1980 1964 1982 -1 
            1965 1963 1981 -1 
            1983 1965 1981 -1 
            1964 1963 1966 -1 
            1966 1963 1965 -1 
            1984 1982 1964 -1 
            1966 1984 1964 -1 
            1967 1965 1983 -1 
            1985 1967 1983 -1 
            1966 1965 1968 -1 
            1968 1965 1967 -1 
            1987 1984 1966 -1 
            1968 1987 1966 -1 
            1967 1985 1986 -1 
            1968 1967 1986 -1 
            1988 1968 1986 -1 
            1987 1968 1988 -1 
            1969 1989 1991 -1 
            1971 1969 1991 -1 
            1970 1990 1969 -1 
            1969 1990 1989 -1 
            1990 1970 1972 -1 
            1993 1990 1972 -1 
            1971 1991 1973 -1 
            1991 1995 1973 -1 
            1972 1975 1993 -1 
            1993 1975 1997 -1 
            1977 1974 1973 -1 
            1977 1973 1999 -1 
            1999 1973 1995 -1 
            1974 1978 1976 -1 
            1977 1978 1974 -1 
            1976 1978 2002 -1 
            1976 2002 1975 -1 
            1975 2002 1997 -1 
            1977 1999 2000 -1 
            1978 1977 2000 -1 
            2003 1978 2000 -1 
            2002 1978 2003 -1 
            1979 2005 2007 -1 
            1981 1979 2007 -1 
            1980 2006 1979 -1 
            1979 2006 2005 -1 
            2006 1980 1982 -1 
            2009 2006 1982 -1 
            1981 2007 1983 -1 
            2007 2011 1983 -1 
            1982 1984 2009 -1 
            2009 1984 2013 -1 
            1983 2011 1985 -1 
            2011 2015 1985 -1 
            1984 1987 2013 -1 
            2013 1987 2018 -1 
            1985 2015 2016 -1 
            1986 1985 2016 -1 
            1988 1986 2019 -1 
            1986 2016 2019 -1 
            2018 1987 1988 -1 
            2019 2018 1988 -1 
            1989 1992 1991 -1 
            1989 1990 1994 -1 
            1992 1989 1994 -1 
            1990 1993 1994 -1 
            1992 1996 1995 -1 
            1991 1992 1995 -1 
            1994 1998 1992 -1 
            1998 1996 1992 -1 
            1993 1997 1998 -1 
            1994 1993 1998 -1 
            1996 2001 1999 -1 
            1995 1996 1999 -1 
            1998 2004 1996 -1 
            2004 2001 1996 -1 
            1997 2002 2004 -1 
            1998 1997 2004 -1 
            1999 2001 2000 -1 
            2003 2000 2001 -1 
            2004 2003 2001 -1 
            2002 2003 2004 -1 
            2005 2008 2007 -1 
            2005 2006 2010 -1 
            2008 2005 2010 -1 
            2006 2009 2010 -1 
            2008 2012 2011 -1 
            2007 2008 2011 -1 
            2010 2014 2008 -1 
            2014 2012 2008 -1 
            2009 2013 2014 -1 
            2010 2009 2014 -1 
            2012 2017 2015 -1 
            2011 2012 2015 -1 
            2014 2020 2012 -1 
            2020 2017 2012 -1 
            2013 2018 2020 -1 
            2014 2013 2020 -1 
            2015 2017 2016 -1 
            2019 2016 2017 -1 
            2020 2019 2017 -1 
            2018 2019 2020 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0021"
              point="
              5.08000000000000000e+02 5.07140014648437500e+02 7.70999984741210938e+01,
              5.08000000000000000e+02 5.07000000000000000e+02 7.72526702880859375e+01,
              5.08666656494140625e+02 5.07140014648437500e+02 7.70999984741210938e+01,
              5.08666656494140625e+02 5.07000000000000000e+02 7.72526702880859375e+01,
              5.09333343505859375e+02 5.07140014648437500e+02 7.70999984741210938e+01,
              5.10000000000000000e+02 5.07140014648437500e+02 7.70999984741210938e+01,
              5.10666656494140625e+02 5.07140014648437500e+02 7.70999984741210938e+01,
              5.11333343505859375e+02 5.07140014648437500e+02 7.70999984741210938e+01,
              5.12000000000000000e+02 5.07140014648437500e+02 7.70999984741210938e+01,
              5.07466674804687500e+02 5.07700012207031250e+02 7.70999984741210938e+01,
              5.12533325195312500e+02 5.07700012207031250e+02 7.70999984741210938e+01,
              5.12666687011718750e+02 5.07839996337890625e+02 7.70999984741210938e+01,
              5.12666687011718750e+02 5.07700012207031250e+02 7.72526702880859375e+01,
              5.13333312988281250e+02 5.07839996337890625e+02 7.70999984741210938e+01,
              5.13333312988281250e+02 5.07700012207031250e+02 7.72526702880859375e+01,
              5.07466674804687500e+02 5.08399993896484375e+02 7.70999984741210938e+01,
              5.13866638183593750e+02 5.08399993896484375e+02 7.70999984741210938e+01,
              5.13333312988281250e+02 5.08959991455078125e+02 7.70999984741210938e+01,
              5.07466674804687500e+02 5.09100006103515625e+02 7.70999984741210938e+01,
              5.07333343505859375e+02 5.09239990234375000e+02 7.70999984741210938e+01,
              5.07333343505859375e+02 5.09100006103515625e+02 7.72526702880859375e+01,
              5.13200012207031250e+02 5.09100006103515625e+02 7.70999984741210938e+01,
              5.06799987792968750e+02 5.09799987792968750e+02 7.70999984741210938e+01,
              5.13200012207031250e+02 5.09799987792968750e+02 7.70999984741210938e+01,
              5.12666687011718750e+02 5.10359985351562500e+02 7.70999984741210938e+01,
              5.06799987792968750e+02 5.10500000000000000e+02 7.70999984741210938e+01,
              5.12533325195312500e+02 5.10500000000000000e+02 7.70999984741210938e+01,
              5.06799987792968750e+02 5.11200012207031250e+02 7.70999984741210938e+01,
              5.06666656494140625e+02 5.11339996337890625e+02 7.70999984741210938e+01,
              5.06666656494140625e+02 5.11200012207031250e+02 7.72526702880859375e+01,
              5.12533325195312500e+02 5.11200012207031250e+02 7.70999984741210938e+01,
              5.12000000000000000e+02 5.11760009765625000e+02 7.70999984741210938e+01,
              5.06133331298828125e+02 5.11899993896484375e+02 7.70999984741210938e+01,
              5.06666656494140625e+02 5.12460021972656250e+02 7.70999984741210938e+01,
              5.11866668701171875e+02 5.11899993896484375e+02 7.70999984741210938e+01,
              5.12000000000000000e+02 5.11899993896484375e+02 7.72526702880859375e+01,
              5.06799987792968750e+02 5.12599975585937500e+02 7.70999984741210938e+01,
              5.07333343505859375e+02 5.13159973144531250e+02 7.70999984741210938e+01,
              5.08000000000000000e+02 5.13159973144531250e+02 7.70999984741210938e+01,
              5.08666656494140625e+02 5.13159973144531250e+02 7.70999984741210938e+01,
              5.11866668701171875e+02 5.12599975585937500e+02 7.70999984741210938e+01,
              5.08799987792968750e+02 5.13299987792968750e+02 7.70999984741210938e+01,
              5.08666656494140625e+02 5.13299987792968750e+02 7.72526702880859375e+01,
              5.09333343505859375e+02 5.13859985351562500e+02 7.70999984741210938e+01,
              5.10000000000000000e+02 5.13859985351562500e+02 7.70999984741210938e+01,
              5.11866668701171875e+02 5.13299987792968750e+02 7.70999984741210938e+01,
              5.11333343505859375e+02 5.13859985351562500e+02 7.70999984741210938e+01,
              5.10133331298828125e+02 5.14000000000000000e+02 7.70999984741210938e+01,
              5.11200012207031250e+02 5.14000000000000000e+02 7.70999984741210938e+01,
              5.10666656494140625e+02 5.14559997558593750e+02 7.70999984741210938e+01,
              5.08000000000000000e+02 5.06440002441406250e+02 7.78633346557617188e+01,
              5.08666656494140625e+02 5.06440002441406250e+02 7.78633346557617188e+01,
              5.07466674804687500e+02 5.07000000000000000e+02 7.78633346557617188e+01,
              5.09200012207031250e+02 5.07000000000000000e+02 7.78633346557617188e+01,
              5.09333343505859375e+02 5.07140014648437500e+02 7.78633346557617188e+01,
              5.09333343505859375e+02 5.07000000000000000e+02 7.80159988403320313e+01,
              5.10000000000000000e+02 5.07140014648437500e+02 7.78633346557617188e+01,
              5.10000000000000000e+02 5.07000000000000000e+02 7.80159988403320313e+01,
              5.10666656494140625e+02 5.07140014648437500e+02 7.78633346557617188e+01,
              5.11333343505859375e+02 5.07140014648437500e+02 7.78633346557617188e+01,
              5.12000000000000000e+02 5.07140014648437500e+02 7.78633346557617188e+01,
              5.12666687011718750e+02 5.07140014648437500e+02 7.78633346557617188e+01,
              5.13333312988281250e+02 5.07140014648437500e+02 7.78633346557617188e+01,
              5.07466674804687500e+02 5.07700012207031250e+02 7.78633346557617188e+01,
              5.13866638183593750e+02 5.07700012207031250e+02 7.78633346557617188e+01,
              5.07466674804687500e+02 5.08399993896484375e+02 7.78633346557617188e+01,
              5.07333343505859375e+02 5.08540008544921875e+02 7.78633346557617188e+01,
              5.07333343505859375e+02 5.08399993896484375e+02 7.80159988403320313e+01,
              5.13866638183593750e+02 5.08399993896484375e+02 7.78633346557617188e+01,
              5.13333312988281250e+02 5.08959991455078125e+02 7.78633346557617188e+01,
              5.06799987792968750e+02 5.09100006103515625e+02 7.78633346557617188e+01,
              5.13200012207031250e+02 5.09100006103515625e+02 7.78633346557617188e+01,
              5.06799987792968750e+02 5.09799987792968750e+02 7.78633346557617188e+01,
              5.13200012207031250e+02 5.09799987792968750e+02 7.78633346557617188e+01,
              5.12666687011718750e+02 5.10359985351562500e+02 7.78633346557617188e+01,
              5.06799987792968750e+02 5.10500000000000000e+02 7.78633346557617188e+01,
              5.06666656494140625e+02 5.10640014648437500e+02 7.78633346557617188e+01,
              5.06666656494140625e+02 5.10500000000000000e+02 7.80159988403320313e+01,
              5.12533325195312500e+02 5.10500000000000000e+02 7.78633346557617188e+01,
              5.06133331298828125e+02 5.11200012207031250e+02 7.78633346557617188e+01,
              5.12533325195312500e+02 5.11200012207031250e+02 7.78633346557617188e+01,
              5.06133331298828125e+02 5.11899993896484375e+02 7.78633346557617188e+01,
              5.06666656494140625e+02 5.12460021972656250e+02 7.78633346557617188e+01,
              5.12533325195312500e+02 5.11899993896484375e+02 7.78633346557617188e+01,
              5.12000000000000000e+02 5.12460021972656250e+02 7.78633346557617188e+01,
              5.06799987792968750e+02 5.12599975585937500e+02 7.78633346557617188e+01,
              5.06666656494140625e+02 5.12599975585937500e+02 7.80159988403320313e+01,
              5.07333343505859375e+02 5.13159973144531250e+02 7.78633346557617188e+01,
              5.08000000000000000e+02 5.13159973144531250e+02 7.78633346557617188e+01,
              5.11866668701171875e+02 5.12599975585937500e+02 7.78633346557617188e+01,
              5.08133331298828125e+02 5.13299987792968750e+02 7.78633346557617188e+01,
              5.08666656494140625e+02 5.13859985351562500e+02 7.78633346557617188e+01,
              5.09333343505859375e+02 5.13859985351562500e+02 7.78633346557617188e+01,
              5.10000000000000000e+02 5.13859985351562500e+02 7.78633346557617188e+01,
              5.11866668701171875e+02 5.13299987792968750e+02 7.78633346557617188e+01,
              5.11333343505859375e+02 5.13859985351562500e+02 7.78633346557617188e+01,
              5.10133331298828125e+02 5.14000000000000000e+02 7.78633346557617188e+01,
              5.10000000000000000e+02 5.14000000000000000e+02 7.80159988403320313e+01,
              5.11200012207031250e+02 5.14000000000000000e+02 7.78633346557617188e+01,
              5.10666656494140625e+02 5.14559997558593750e+02 7.78633346557617188e+01,
              5.08000000000000000e+02 5.06440002441406250e+02 7.86266632080078125e+01,
              5.08666656494140625e+02 5.06440002441406250e+02 7.86266632080078125e+01,
              5.09333343505859375e+02 5.06440002441406250e+02 7.86266632080078125e+01,
              5.10000000000000000e+02 5.06440002441406250e+02 7.86266632080078125e+01,
              5.07466674804687500e+02 5.07000000000000000e+02 7.86266632080078125e+01,
              5.10533325195312500e+02 5.07000000000000000e+02 7.86266632080078125e+01,
              5.10666656494140625e+02 5.07140014648437500e+02 7.86266632080078125e+01,
              5.11333343505859375e+02 5.07140014648437500e+02 7.86266632080078125e+01,
              5.12000000000000000e+02 5.07140014648437500e+02 7.86266632080078125e+01,
              5.12666687011718750e+02 5.07140014648437500e+02 7.86266632080078125e+01,
              5.13333312988281250e+02 5.07140014648437500e+02 7.86266632080078125e+01,
              5.07466674804687500e+02 5.07700012207031250e+02 7.86266632080078125e+01,
              5.07333343505859375e+02 5.07839996337890625e+02 7.86266632080078125e+01,
              5.13866638183593750e+02 5.07700012207031250e+02 7.86266632080078125e+01,
              5.13333312988281250e+02 5.07700012207031250e+02 7.92373352050781250e+01,
              5.06799987792968750e+02 5.08399993896484375e+02 7.86266632080078125e+01,
              5.13866638183593750e+02 5.08399993896484375e+02 7.86266632080078125e+01,
              5.13333312988281250e+02 5.08959991455078125e+02 7.86266632080078125e+01,
              5.06799987792968750e+02 5.09100006103515625e+02 7.86266632080078125e+01,
              5.13200012207031250e+02 5.09100006103515625e+02 7.86266632080078125e+01,
              5.06799987792968750e+02 5.09799987792968750e+02 7.86266632080078125e+01,
              5.06666656494140625e+02 5.09940002441406250e+02 7.86266632080078125e+01,
              5.13200012207031250e+02 5.09799987792968750e+02 7.86266632080078125e+01,
              5.12666687011718750e+02 5.10359985351562500e+02 7.86266632080078125e+01,
              5.06133331298828125e+02 5.10500000000000000e+02 7.86266632080078125e+01,
              5.12533325195312500e+02 5.10500000000000000e+02 7.86266632080078125e+01,
              5.12666687011718750e+02 5.10500000000000000e+02 7.87793350219726563e+01,
              5.06133331298828125e+02 5.11200012207031250e+02 7.86266632080078125e+01,
              5.12533325195312500e+02 5.11200012207031250e+02 7.86266632080078125e+01,
              5.06133331298828125e+02 5.11899993896484375e+02 7.86266632080078125e+01,
              5.12533325195312500e+02 5.11899993896484375e+02 7.86266632080078125e+01,
              5.12000000000000000e+02 5.12460021972656250e+02 7.86266632080078125e+01,
              5.06133331298828125e+02 5.12599975585937500e+02 7.86266632080078125e+01,
              5.06666656494140625e+02 5.13159973144531250e+02 7.86266632080078125e+01,
              5.07333343505859375e+02 5.13159973144531250e+02 7.86266632080078125e+01,
              5.08000000000000000e+02 5.13159973144531250e+02 7.86266632080078125e+01,
              5.11866668701171875e+02 5.12599975585937500e+02 7.86266632080078125e+01,
              5.08133331298828125e+02 5.13299987792968750e+02 7.86266632080078125e+01,
              5.08666656494140625e+02 5.13859985351562500e+02 7.86266632080078125e+01,
              5.09333343505859375e+02 5.13859985351562500e+02 7.86266632080078125e+01,
              5.11866668701171875e+02 5.13299987792968750e+02 7.86266632080078125e+01,
              5.11333343505859375e+02 5.13859985351562500e+02 7.86266632080078125e+01,
              5.09466674804687500e+02 5.14000000000000000e+02 7.86266632080078125e+01,
              5.10000000000000000e+02 5.14559997558593750e+02 7.86266632080078125e+01,
              5.11200012207031250e+02 5.14000000000000000e+02 7.86266632080078125e+01,
              5.10666656494140625e+02 5.14559997558593750e+02 7.86266632080078125e+01,
              5.08000000000000000e+02 5.06440002441406250e+02 7.93899993896484375e+01,
              5.08666656494140625e+02 5.06440002441406250e+02 7.93899993896484375e+01,
              5.09333343505859375e+02 5.06440002441406250e+02 7.93899993896484375e+01,
              5.10000000000000000e+02 5.06440002441406250e+02 7.93899993896484375e+01,
              5.07466674804687500e+02 5.07000000000000000e+02 7.93899993896484375e+01,
              5.08000000000000000e+02 5.07000000000000000e+02 8.00006637573242188e+01,
              5.08666656494140625e+02 5.07000000000000000e+02 8.00006637573242188e+01,
              5.09333343505859375e+02 5.07000000000000000e+02 8.00006637573242188e+01,
              5.10533325195312500e+02 5.07000000000000000e+02 7.93899993896484375e+01,
              5.10000000000000000e+02 5.07000000000000000e+02 8.00006637573242188e+01,
              5.10666656494140625e+02 5.07140014648437500e+02 7.93899993896484375e+01,
              5.11333343505859375e+02 5.07140014648437500e+02 7.93899993896484375e+01,
              5.12000000000000000e+02 5.07140014648437500e+02 7.93899993896484375e+01,
              5.12666687011718750e+02 5.07140014648437500e+02 7.93899993896484375e+01,
              5.07466674804687500e+02 5.07700012207031250e+02 7.93899993896484375e+01,
              5.07333343505859375e+02 5.07839996337890625e+02 7.93899993896484375e+01,
              5.08000000000000000e+02 5.07700012207031250e+02 8.00006637573242188e+01,
              5.08666656494140625e+02 5.07700012207031250e+02 8.00006637573242188e+01,
              5.09333343505859375e+02 5.07700012207031250e+02 8.00006637573242188e+01,
              5.10000000000000000e+02 5.07700012207031250e+02 8.00006637573242188e+01,
              5.10666656494140625e+02 5.07700012207031250e+02 8.00006637573242188e+01,
              5.11333343505859375e+02 5.07700012207031250e+02 8.00006637573242188e+01,
              5.12000000000000000e+02 5.07700012207031250e+02 8.00006637573242188e+01,
              5.13200012207031250e+02 5.07700012207031250e+02 7.93899993896484375e+01,
              5.12666687011718750e+02 5.07700012207031250e+02 8.00006637573242188e+01,
              5.13333312988281250e+02 5.07839996337890625e+02 7.93899993896484375e+01,
              5.06799987792968750e+02 5.08399993896484375e+02 7.93899993896484375e+01,
              5.07333343505859375e+02 5.08399993896484375e+02 8.00006637573242188e+01,
              5.08000000000000000e+02 5.08399993896484375e+02 8.00006637573242188e+01,
              5.08666656494140625e+02 5.08399993896484375e+02 8.00006637573242188e+01,
              5.09333343505859375e+02 5.08399993896484375e+02 8.00006637573242188e+01,
              5.10000000000000000e+02 5.08399993896484375e+02 8.00006637573242188e+01,
              5.10666656494140625e+02 5.08399993896484375e+02 8.00006637573242188e+01,
              5.11333343505859375e+02 5.08399993896484375e+02 8.00006637573242188e+01,
              5.12000000000000000e+02 5.08399993896484375e+02 8.00006637573242188e+01,
              5.12666687011718750e+02 5.08399993896484375e+02 8.00006637573242188e+01,
              5.13866638183593750e+02 5.08399993896484375e+02 7.93899993896484375e+01,
              5.13333312988281250e+02 5.08959991455078125e+02 7.93899993896484375e+01,
              5.13333312988281250e+02 5.08399993896484375e+02 8.00006637573242188e+01,
              5.06799987792968750e+02 5.09100006103515625e+02 7.93899993896484375e+01,
              5.07333343505859375e+02 5.09100006103515625e+02 8.00006637573242188e+01,
              5.08000000000000000e+02 5.09100006103515625e+02 8.00006637573242188e+01,
              5.08666656494140625e+02 5.09100006103515625e+02 8.00006637573242188e+01,
              5.09333343505859375e+02 5.09100006103515625e+02 8.00006637573242188e+01,
              5.10000000000000000e+02 5.09100006103515625e+02 7.99110488891601563e+01,
              5.10666656494140625e+02 5.09100006103515625e+02 8.00006637573242188e+01,
              5.11333343505859375e+02 5.09100006103515625e+02 8.00006637573242188e+01,
              5.12000000000000000e+02 5.09100006103515625e+02 8.00006637573242188e+01,
              5.13200012207031250e+02 5.09100006103515625e+02 7.93899993896484375e+01,
              5.12666687011718750e+02 5.09100006103515625e+02 8.00006637573242188e+01,
              5.06799987792968750e+02 5.09799987792968750e+02 7.93899993896484375e+01,
              5.06666656494140625e+02 5.09940002441406250e+02 7.93899993896484375e+01,
              5.07333343505859375e+02 5.09799987792968750e+02 8.00006637573242188e+01,
              5.08000000000000000e+02 5.09799987792968750e+02 8.00006637573242188e+01,
              5.08666656494140625e+02 5.09799987792968750e+02 8.00006637573242188e+01,
              5.09333343505859375e+02 5.09799987792968750e+02 7.99525527954101563e+01,
              5.10000000000000000e+02 5.09799987792968750e+02 7.99806518554687500e+01,
              5.10666656494140625e+02 5.09799987792968750e+02 7.99525527954101563e+01,
              5.11333343505859375e+02 5.09799987792968750e+02 8.00006637573242188e+01,
              5.12000000000000000e+02 5.09799987792968750e+02 8.00006637573242188e+01,
              5.13200012207031250e+02 5.09799987792968750e+02 7.93899993896484375e+01,
              5.12666687011718750e+02 5.09799987792968750e+02 8.00006637573242188e+01,
              5.06133331298828125e+02 5.10500000000000000e+02 7.93899993896484375e+01,
              5.06666656494140625e+02 5.10500000000000000e+02 8.00006637573242188e+01,
              5.07333343505859375e+02 5.10500000000000000e+02 8.00006637573242188e+01,
              5.08000000000000000e+02 5.10500000000000000e+02 8.00006637573242188e+01,
              5.08666656494140625e+02 5.10500000000000000e+02 8.00006637573242188e+01,
              5.09333343505859375e+02 5.10500000000000000e+02 7.98747406005859375e+01,
              5.10000000000000000e+02 5.10500000000000000e+02 7.99739761352539063e+01,
              5.10666656494140625e+02 5.10500000000000000e+02 7.98747406005859375e+01,
              5.11333343505859375e+02 5.10500000000000000e+02 8.00006637573242188e+01,
              5.12000000000000000e+02 5.10500000000000000e+02 8.00006637573242188e+01,
              5.13200012207031250e+02 5.10500000000000000e+02 7.93899993896484375e+01,
              5.12666687011718750e+02 5.11059997558593750e+02 7.93899993896484375e+01,
              5.12666687011718750e+02 5.10500000000000000e+02 8.00006637573242188e+01,
              5.06133331298828125e+02 5.11200012207031250e+02 7.93899993896484375e+01,
              5.06666656494140625e+02 5.11200012207031250e+02 8.00006637573242188e+01,
              5.07333343505859375e+02 5.11200012207031250e+02 8.00006637573242188e+01,
              5.08000000000000000e+02 5.11200012207031250e+02 8.00006637573242188e+01,
              5.08666656494140625e+02 5.11200012207031250e+02 8.00006637573242188e+01,
              5.09333343505859375e+02 5.11200012207031250e+02 8.00006637573242188e+01,
              5.10000000000000000e+02 5.11200012207031250e+02 8.00006637573242188e+01,
              5.10666656494140625e+02 5.11200012207031250e+02 8.00006637573242188e+01,
              5.11333343505859375e+02 5.11200012207031250e+02 8.00006637573242188e+01,
              5.12533325195312500e+02 5.11200012207031250e+02 7.93899993896484375e+01,
              5.12000000000000000e+02 5.11200012207031250e+02 8.00006637573242188e+01,
              5.06133331298828125e+02 5.11899993896484375e+02 7.93899993896484375e+01,
              5.06666656494140625e+02 5.11899993896484375e+02 8.00006637573242188e+01,
              5.07333343505859375e+02 5.11899993896484375e+02 8.00006637573242188e+01,
              5.08000000000000000e+02 5.11899993896484375e+02 8.00006637573242188e+01,
              5.08666656494140625e+02 5.11899993896484375e+02 8.00006637573242188e+01,
              5.09333343505859375e+02 5.11899993896484375e+02 8.00006637573242188e+01,
              5.10000000000000000e+02 5.11899993896484375e+02 8.00006637573242188e+01,
              5.10666656494140625e+02 5.11899993896484375e+02 8.00006637573242188e+01,
              5.11333343505859375e+02 5.11899993896484375e+02 8.00006637573242188e+01,
              5.12533325195312500e+02 5.11899993896484375e+02 7.93899993896484375e+01,
              5.12000000000000000e+02 5.12460021972656250e+02 7.93899993896484375e+01,
              5.12000000000000000e+02 5.11899993896484375e+02 8.00006637573242188e+01,
              5.06133331298828125e+02 5.12599975585937500e+02 7.93899993896484375e+01,
              5.06666656494140625e+02 5.13159973144531250e+02 7.93899993896484375e+01,
              5.06666656494140625e+02 5.12599975585937500e+02 8.00006637573242188e+01,
              5.07333343505859375e+02 5.13159973144531250e+02 7.93899993896484375e+01,
              5.07333343505859375e+02 5.12599975585937500e+02 8.00006637573242188e+01,
              5.08000000000000000e+02 5.13159973144531250e+02 7.93899993896484375e+01,
              5.08000000000000000e+02 5.12599975585937500e+02 8.00006637573242188e+01,
              5.08666656494140625e+02 5.12599975585937500e+02 8.00006637573242188e+01,
              5.09333343505859375e+02 5.12599975585937500e+02 8.00006637573242188e+01,
              5.10000000000000000e+02 5.12599975585937500e+02 8.00006637573242188e+01,
              5.10666656494140625e+02 5.12599975585937500e+02 8.00006637573242188e+01,
              5.11866668701171875e+02 5.12599975585937500e+02 7.93899993896484375e+01,
              5.11333343505859375e+02 5.12599975585937500e+02 8.00006637573242188e+01,
              5.08133331298828125e+02 5.13299987792968750e+02 7.93899993896484375e+01,
              5.08666656494140625e+02 5.13859985351562500e+02 7.93899993896484375e+01,
              5.08666656494140625e+02 5.13299987792968750e+02 8.00006637573242188e+01,
              5.09333343505859375e+02 5.13859985351562500e+02 7.93899993896484375e+01,
              5.09333343505859375e+02 5.13299987792968750e+02 8.00006637573242188e+01,
              5.10000000000000000e+02 5.13299987792968750e+02 8.00006637573242188e+01,
              5.10666656494140625e+02 5.13299987792968750e+02 8.00006637573242188e+01,
              5.11866668701171875e+02 5.13299987792968750e+02 7.93899993896484375e+01,
              5.11333343505859375e+02 5.13859985351562500e+02 7.93899993896484375e+01,
              5.11333343505859375e+02 5.13299987792968750e+02 8.00006637573242188e+01,
              5.09466674804687500e+02 5.14000000000000000e+02 7.93899993896484375e+01,
              5.10000000000000000e+02 5.14559997558593750e+02 7.93899993896484375e+01,
              5.10000000000000000e+02 5.14000000000000000e+02 8.00006637573242188e+01,
              5.11200012207031250e+02 5.14000000000000000e+02 7.93899993896484375e+01,
              5.10666656494140625e+02 5.14559997558593750e+02 7.93899993896484375e+01,
              5.10666656494140625e+02 5.14000000000000000e+02 8.00006637573242188e+01,
              5.03333343505859375e+02 5.07700012207031250e+02 8.05693130493164063e+01,
              5.04000000000000000e+02 5.07700012207031250e+02 8.06025238037109375e+01,
              5.04666656494140625e+02 5.07700012207031250e+02 8.05693130493164063e+01,
              5.03333343505859375e+02 5.08399993896484375e+02 8.05671997070312500e+01,
              5.04000000000000000e+02 5.08399993896484375e+02 8.05707473754882813e+01,
              5.04666656494140625e+02 5.08399993896484375e+02 8.05671997070312500e+01,
              5.05333343505859375e+02 5.02799987792968750e+02 8.10693359375000000e+01,
              5.04000000000000000e+02 5.03500000000000000e+02 8.10693359375000000e+01,
              5.04666656494140625e+02 5.03500000000000000e+02 8.10693359375000000e+01,
              5.05333343505859375e+02 5.03500000000000000e+02 8.10693359375000000e+01,
              5.06000000000000000e+02 5.03500000000000000e+02 8.10693359375000000e+01,
              5.02666656494140625e+02 5.04200012207031250e+02 8.10693359375000000e+01,
              5.03333343505859375e+02 5.04200012207031250e+02 8.10693359375000000e+01,
              5.04000000000000000e+02 5.04200012207031250e+02 8.10693359375000000e+01,
              5.04666656494140625e+02 5.04200012207031250e+02 8.10693359375000000e+01,
              5.05333343505859375e+02 5.04200012207031250e+02 8.10693359375000000e+01,
              5.06000000000000000e+02 5.04200012207031250e+02 8.10693359375000000e+01,
              5.01333343505859375e+02 5.04899993896484375e+02 8.10693359375000000e+01,
              5.02000000000000000e+02 5.04899993896484375e+02 8.10693359375000000e+01,
              5.02666656494140625e+02 5.04899993896484375e+02 8.10693359375000000e+01,
              5.03333343505859375e+02 5.04899993896484375e+02 8.10693359375000000e+01,
              5.04000000000000000e+02 5.04899993896484375e+02 8.10693359375000000e+01,
              5.04666656494140625e+02 5.04899993896484375e+02 8.10693359375000000e+01,
              5.05333343505859375e+02 5.04899993896484375e+02 8.10693359375000000e+01,
              5.06000000000000000e+02 5.04899993896484375e+02 8.10693359375000000e+01,
              5.06666656494140625e+02 5.04899993896484375e+02 8.10693359375000000e+01,
              5.00666656494140625e+02 5.05600006103515625e+02 8.10693359375000000e+01,
              5.01333343505859375e+02 5.05600006103515625e+02 8.10693359375000000e+01,
              5.02000000000000000e+02 5.05600006103515625e+02 8.10693359375000000e+01,
              5.02666656494140625e+02 5.05600006103515625e+02 8.10693359375000000e+01,
              5.03333343505859375e+02 5.05600006103515625e+02 8.10693359375000000e+01,
              5.04000000000000000e+02 5.05600006103515625e+02 8.10693359375000000e+01,
              5.04666656494140625e+02 5.05600006103515625e+02 8.10693359375000000e+01,
              5.05333343505859375e+02 5.05600006103515625e+02 8.10693359375000000e+01,
              5.06000000000000000e+02 5.05600006103515625e+02 8.10693359375000000e+01,
              5.06666656494140625e+02 5.05600006103515625e+02 8.10693359375000000e+01,
              5.00000000000000000e+02 5.06299987792968750e+02 8.10693359375000000e+01,
              5.00666656494140625e+02 5.06299987792968750e+02 8.10693359375000000e+01,
              5.01333343505859375e+02 5.06299987792968750e+02 8.10693359375000000e+01,
              5.02000000000000000e+02 5.06299987792968750e+02 8.10693359375000000e+01,
              5.02666656494140625e+02 5.06299987792968750e+02 8.10693359375000000e+01,
              5.03333343505859375e+02 5.06299987792968750e+02 8.10693359375000000e+01,
              5.04000000000000000e+02 5.06299987792968750e+02 8.10693359375000000e+01,
              5.04666656494140625e+02 5.06299987792968750e+02 8.10693359375000000e+01,
              5.05333343505859375e+02 5.06299987792968750e+02 8.10693359375000000e+01,
              5.06000000000000000e+02 5.06299987792968750e+02 8.10693359375000000e+01,
              5.06666656494140625e+02 5.06299987792968750e+02 8.10693359375000000e+01,
              5.07333343505859375e+02 5.06299987792968750e+02 8.10693359375000000e+01,
              5.00666656494140625e+02 5.07000000000000000e+02 8.10693359375000000e+01,
              5.01333343505859375e+02 5.07000000000000000e+02 8.10693359375000000e+01,
              5.02000000000000000e+02 5.07000000000000000e+02 8.10693359375000000e+01,
              5.02666656494140625e+02 5.07000000000000000e+02 8.10693359375000000e+01,
              5.03333343505859375e+02 5.07381469726562500e+02 8.09166641235351563e+01,
              5.03333343505859375e+02 5.07000000000000000e+02 8.10693359375000000e+01,
              5.04000000000000000e+02 5.07422241210937500e+02 8.09166641235351563e+01,
              5.04000000000000000e+02 5.07000000000000000e+02 8.10693359375000000e+01,
              5.04666656494140625e+02 5.07381469726562500e+02 8.09166641235351563e+01,
              5.04666656494140625e+02 5.07000000000000000e+02 8.10693359375000000e+01,
              5.05333343505859375e+02 5.07000000000000000e+02 8.10693359375000000e+01,
              5.06000000000000000e+02 5.07000000000000000e+02 8.10693359375000000e+01,
              5.06666656494140625e+02 5.07000000000000000e+02 8.10693359375000000e+01,
              5.07333343505859375e+02 5.07000000000000000e+02 8.10693359375000000e+01,
              5.01333343505859375e+02 5.07700012207031250e+02 8.10693359375000000e+01,
              5.02000000000000000e+02 5.07700012207031250e+02 8.10693359375000000e+01,
              5.03029968261718750e+02 5.07700012207031250e+02 8.09166641235351563e+01,
              5.02666656494140625e+02 5.07700012207031250e+02 8.10693359375000000e+01,
              5.04970031738281250e+02 5.07700012207031250e+02 8.09166641235351563e+01,
              5.05333343505859375e+02 5.07700012207031250e+02 8.10693359375000000e+01,
              5.06000000000000000e+02 5.07700012207031250e+02 8.10693359375000000e+01,
              5.06666656494140625e+02 5.07700012207031250e+02 8.10693359375000000e+01,
              5.07333343505859375e+02 5.07700012207031250e+02 8.10693359375000000e+01,
              5.02000000000000000e+02 5.08399993896484375e+02 8.10693359375000000e+01,
              5.03028137207031250e+02 5.08399993896484375e+02 8.09166641235351563e+01,
              5.02666656494140625e+02 5.08399993896484375e+02 8.10693359375000000e+01,
              5.03333343505859375e+02 5.08720458984375000e+02 8.09166641235351563e+01,
              5.04000000000000000e+02 5.08717224121093750e+02 8.09166641235351563e+01,
              5.04971862792968750e+02 5.08399993896484375e+02 8.09166641235351563e+01,
              5.04666656494140625e+02 5.08720458984375000e+02 8.09166641235351563e+01,
              5.05333343505859375e+02 5.08399993896484375e+02 8.10693359375000000e+01,
              5.06000000000000000e+02 5.08399993896484375e+02 8.10693359375000000e+01,
              5.06666656494140625e+02 5.08399993896484375e+02 8.10693359375000000e+01,
              5.02666656494140625e+02 5.09100006103515625e+02 8.10693359375000000e+01,
              5.03333343505859375e+02 5.09100006103515625e+02 8.10693359375000000e+01,
              5.04000000000000000e+02 5.09100006103515625e+02 8.10693359375000000e+01,
              5.04666656494140625e+02 5.09100006103515625e+02 8.10693359375000000e+01,
              5.05333343505859375e+02 5.09100006103515625e+02 8.10693359375000000e+01,
              5.06000000000000000e+02 5.09100006103515625e+02 8.10693359375000000e+01,
              5.06666656494140625e+02 5.09100006103515625e+02 8.10693359375000000e+01,
              5.03333343505859375e+02 5.09799987792968750e+02 8.10693359375000000e+01,
              5.04000000000000000e+02 5.09799987792968750e+02 8.10693359375000000e+01,
              5.04666656494140625e+02 5.09799987792968750e+02 8.10693359375000000e+01,
              5.05333343505859375e+02 5.09799987792968750e+02 8.10693359375000000e+01,
              5.06000000000000000e+02 5.09799987792968750e+02 8.10693359375000000e+01,
              5.06666656494140625e+02 5.09799987792968750e+02 8.10693359375000000e+01,
              5.04000000000000000e+02 5.10500000000000000e+02 8.10693359375000000e+01,
              5.04666656494140625e+02 5.10500000000000000e+02 8.10693359375000000e+01,
              5.05333343505859375e+02 5.10500000000000000e+02 8.10693359375000000e+01,
              5.06000000000000000e+02 5.10500000000000000e+02 8.10693359375000000e+01,
              5.04666656494140625e+02 5.11200012207031250e+02 8.10693359375000000e+01,
              5.05333343505859375e+02 5.11200012207031250e+02 8.10693359375000000e+01,
              5.06000000000000000e+02 5.11200012207031250e+02 8.10693359375000000e+01,
              5.05333343505859375e+02 5.11899993896484375e+02 8.10693359375000000e+01,
              5.06000000000000000e+02 5.11899993896484375e+02 8.10693359375000000e+01,
              5.05333343505859375e+02 5.02239990234375000e+02 8.16800003051757813e+01,
              5.04000000000000000e+02 5.02940002441406250e+02 8.16800003051757813e+01,
              5.04799987792968750e+02 5.02799987792968750e+02 8.16800003051757813e+01,
              5.04666656494140625e+02 5.02940002441406250e+02 8.16800003051757813e+01,
              5.05866668701171875e+02 5.02799987792968750e+02 8.16800003051757813e+01,
              5.05333343505859375e+02 5.02799987792968750e+02 8.22906646728515625e+01,
              5.06000000000000000e+02 5.02940002441406250e+02 8.16800003051757813e+01,
              5.02666656494140625e+02 5.03640014648437500e+02 8.16800003051757813e+01,
              5.03466674804687500e+02 5.03500000000000000e+02 8.16800003051757813e+01,
              5.03333343505859375e+02 5.03640014648437500e+02 8.16800003051757813e+01,
              5.04000000000000000e+02 5.03500000000000000e+02 8.22906646728515625e+01,
              5.04666656494140625e+02 5.03500000000000000e+02 8.22906646728515625e+01,
              5.05333343505859375e+02 5.03500000000000000e+02 8.22906646728515625e+01,
              5.06533325195312500e+02 5.03500000000000000e+02 8.16800003051757813e+01,
              5.06000000000000000e+02 5.03500000000000000e+02 8.22906646728515625e+01,
              5.01333343505859375e+02 5.04339996337890625e+02 8.16800003051757813e+01,
              5.02133331298828125e+02 5.04200012207031250e+02 8.16800003051757813e+01,
              5.02000000000000000e+02 5.04339996337890625e+02 8.16800003051757813e+01,
              5.02666656494140625e+02 5.04200012207031250e+02 8.22906646728515625e+01,
              5.03333343505859375e+02 5.04200012207031250e+02 8.22906646728515625e+01,
              5.04000000000000000e+02 5.04200012207031250e+02 8.22906646728515625e+01,
              5.04666656494140625e+02 5.04200012207031250e+02 8.22906646728515625e+01,
              5.05333343505859375e+02 5.04200012207031250e+02 8.22906646728515625e+01,
              5.06533325195312500e+02 5.04200012207031250e+02 8.16800003051757813e+01,
              5.06000000000000000e+02 5.04200012207031250e+02 8.22906646728515625e+01,
              5.06666656494140625e+02 5.04339996337890625e+02 8.16800003051757813e+01,
              5.00799987792968750e+02 5.04899993896484375e+02 8.16800003051757813e+01,
              5.00666656494140625e+02 5.05040008544921875e+02 8.16800003051757813e+01,
              5.01333343505859375e+02 5.04899993896484375e+02 8.22906646728515625e+01,
              5.02000000000000000e+02 5.04899993896484375e+02 8.22906646728515625e+01,
              5.02666656494140625e+02 5.04899993896484375e+02 8.22906646728515625e+01,
              5.03333343505859375e+02 5.04899993896484375e+02 8.22906646728515625e+01,
              5.04000000000000000e+02 5.04899993896484375e+02 8.22906646728515625e+01,
              5.04666656494140625e+02 5.04899993896484375e+02 8.22906646728515625e+01,
              5.05333343505859375e+02 5.04899993896484375e+02 8.22906646728515625e+01,
              5.06000000000000000e+02 5.04899993896484375e+02 8.22906646728515625e+01,
              5.07200012207031250e+02 5.04899993896484375e+02 8.16800003051757813e+01,
              5.06666656494140625e+02 5.04899993896484375e+02 8.22906646728515625e+01,
              5.00133331298828125e+02 5.05600006103515625e+02 8.16800003051757813e+01,
              5.00000000000000000e+02 5.05739990234375000e+02 8.16800003051757813e+01,
              5.00666656494140625e+02 5.05600006103515625e+02 8.22906646728515625e+01,
              5.01333343505859375e+02 5.05600006103515625e+02 8.22906646728515625e+01,
              5.02000000000000000e+02 5.05600006103515625e+02 8.22906646728515625e+01,
              5.02666656494140625e+02 5.05600006103515625e+02 8.22906646728515625e+01,
              5.03333343505859375e+02 5.05600006103515625e+02 8.22906646728515625e+01,
              5.04000000000000000e+02 5.05600006103515625e+02 8.22906646728515625e+01,
              5.04666656494140625e+02 5.05600006103515625e+02 8.22906646728515625e+01,
              5.05333343505859375e+02 5.05600006103515625e+02 8.22906646728515625e+01,
              5.06000000000000000e+02 5.05600006103515625e+02 8.22906646728515625e+01,
              5.07200012207031250e+02 5.05600006103515625e+02 8.16800003051757813e+01,
              5.06666656494140625e+02 5.05600006103515625e+02 8.22906646728515625e+01,
              5.07333343505859375e+02 5.05739990234375000e+02 8.16800003051757813e+01,
              5.00000000000000000e+02 5.06859985351562500e+02 8.16800003051757813e+01,
              5.00000000000000000e+02 5.06299987792968750e+02 8.22906646728515625e+01,
              5.00666656494140625e+02 5.06299987792968750e+02 8.22906646728515625e+01,
              5.01333343505859375e+02 5.06299987792968750e+02 8.22906646728515625e+01,
              5.02000000000000000e+02 5.06299987792968750e+02 8.22906646728515625e+01,
              5.02666656494140625e+02 5.06299987792968750e+02 8.22906646728515625e+01,
              5.03333343505859375e+02 5.06299987792968750e+02 8.22906646728515625e+01,
              5.04000000000000000e+02 5.06299987792968750e+02 8.22906646728515625e+01,
              5.04666656494140625e+02 5.06299987792968750e+02 8.22906646728515625e+01,
              5.05333343505859375e+02 5.06299987792968750e+02 8.22906646728515625e+01,
              5.06000000000000000e+02 5.06299987792968750e+02 8.22906646728515625e+01,
              5.06666656494140625e+02 5.06299987792968750e+02 8.22906646728515625e+01,
              5.07866668701171875e+02 5.06299987792968750e+02 8.16800003051757813e+01,
              5.07333343505859375e+02 5.06299987792968750e+02 8.22906646728515625e+01,
              5.00133331298828125e+02 5.07000000000000000e+02 8.16800003051757813e+01,
              5.00666656494140625e+02 5.07559997558593750e+02 8.16800003051757813e+01,
              5.00666656494140625e+02 5.07000000000000000e+02 8.22906646728515625e+01,
              5.01333343505859375e+02 5.07000000000000000e+02 8.22906646728515625e+01,
              5.02000000000000000e+02 5.07000000000000000e+02 8.22906646728515625e+01,
              5.02666656494140625e+02 5.07000000000000000e+02 8.22906646728515625e+01,
              5.03333343505859375e+02 5.07000000000000000e+02 8.22906646728515625e+01,
              5.04000000000000000e+02 5.07000000000000000e+02 8.22906646728515625e+01,
              5.04666656494140625e+02 5.07000000000000000e+02 8.22906646728515625e+01,
              5.05333343505859375e+02 5.07000000000000000e+02 8.22906646728515625e+01,
              5.06000000000000000e+02 5.07000000000000000e+02 8.22906646728515625e+01,
              5.06666656494140625e+02 5.07000000000000000e+02 8.22906646728515625e+01,
              5.07866668701171875e+02 5.07000000000000000e+02 8.16800003051757813e+01,
              5.07333343505859375e+02 5.07000000000000000e+02 8.22906646728515625e+01,
              5.00799987792968750e+02 5.07700012207031250e+02 8.16800003051757813e+01,
              5.01333343505859375e+02 5.08260009765625000e+02 8.16800003051757813e+01,
              5.01333343505859375e+02 5.07700012207031250e+02 8.22906646728515625e+01,
              5.02000000000000000e+02 5.07700012207031250e+02 8.22906646728515625e+01,
              5.02666656494140625e+02 5.07700012207031250e+02 8.22906646728515625e+01,
              5.05333343505859375e+02 5.07700012207031250e+02 8.22906646728515625e+01,
              5.06000000000000000e+02 5.07700012207031250e+02 8.22906646728515625e+01,
              5.06666656494140625e+02 5.07700012207031250e+02 8.22906646728515625e+01,
              5.07866668701171875e+02 5.07700012207031250e+02 8.16800003051757813e+01,
              5.07333343505859375e+02 5.08260009765625000e+02 8.16800003051757813e+01,
              5.07333343505859375e+02 5.07700012207031250e+02 8.22906646728515625e+01,
              5.01466674804687500e+02 5.08399993896484375e+02 8.16800003051757813e+01,
              5.02000000000000000e+02 5.08959991455078125e+02 8.16800003051757813e+01,
              5.02000000000000000e+02 5.08399993896484375e+02 8.22906646728515625e+01,
              5.02666656494140625e+02 5.08399993896484375e+02 8.22906646728515625e+01,
              5.05333343505859375e+02 5.08399993896484375e+02 8.22906646728515625e+01,
              5.06000000000000000e+02 5.08399993896484375e+02 8.22906646728515625e+01,
              5.07200012207031250e+02 5.08399993896484375e+02 8.16800003051757813e+01,
              5.06666656494140625e+02 5.08399993896484375e+02 8.22906646728515625e+01,
              5.02133331298828125e+02 5.09100006103515625e+02 8.16800003051757813e+01,
              5.02666656494140625e+02 5.09660003662109375e+02 8.16800003051757813e+01,
              5.02666656494140625e+02 5.09100006103515625e+02 8.22906646728515625e+01,
              5.03333343505859375e+02 5.09100006103515625e+02 8.22906646728515625e+01,
              5.04000000000000000e+02 5.09100006103515625e+02 8.22906646728515625e+01,
              5.04666656494140625e+02 5.09100006103515625e+02 8.22906646728515625e+01,
              5.05333343505859375e+02 5.09100006103515625e+02 8.22906646728515625e+01,
              5.06000000000000000e+02 5.09100006103515625e+02 8.22906646728515625e+01,
              5.07200012207031250e+02 5.09100006103515625e+02 8.16800003051757813e+01,
              5.06666656494140625e+02 5.09100006103515625e+02 8.22906646728515625e+01,
              5.02799987792968750e+02 5.09799987792968750e+02 8.16800003051757813e+01,
              5.03333343505859375e+02 5.10359985351562500e+02 8.16800003051757813e+01,
              5.03333343505859375e+02 5.09799987792968750e+02 8.22906646728515625e+01,
              5.04000000000000000e+02 5.09799987792968750e+02 8.22906646728515625e+01,
              5.04666656494140625e+02 5.09799987792968750e+02 8.22906646728515625e+01,
              5.05333343505859375e+02 5.09799987792968750e+02 8.22906646728515625e+01,
              5.06000000000000000e+02 5.09799987792968750e+02 8.22906646728515625e+01,
              5.07200012207031250e+02 5.09799987792968750e+02 8.16800003051757813e+01,
              5.06666656494140625e+02 5.10359985351562500e+02 8.16800003051757813e+01,
              5.06666656494140625e+02 5.09799987792968750e+02 8.22906646728515625e+01,
              5.03466674804687500e+02 5.10500000000000000e+02 8.16800003051757813e+01,
              5.04000000000000000e+02 5.11059997558593750e+02 8.16800003051757813e+01,
              5.04000000000000000e+02 5.10500000000000000e+02 8.22906646728515625e+01,
              5.04666656494140625e+02 5.10500000000000000e+02 8.22906646728515625e+01,
              5.05333343505859375e+02 5.10500000000000000e+02 8.22906646728515625e+01,
              5.06533325195312500e+02 5.10500000000000000e+02 8.16800003051757813e+01,
              5.06000000000000000e+02 5.10500000000000000e+02 8.22906646728515625e+01,
              5.04133331298828125e+02 5.11200012207031250e+02 8.16800003051757813e+01,
              5.04666656494140625e+02 5.11760009765625000e+02 8.16800003051757813e+01,
              5.04666656494140625e+02 5.11200012207031250e+02 8.22906646728515625e+01,
              5.05333343505859375e+02 5.11200012207031250e+02 8.22906646728515625e+01,
              5.06533325195312500e+02 5.11200012207031250e+02 8.16800003051757813e+01,
              5.06000000000000000e+02 5.11200012207031250e+02 8.22906646728515625e+01,
              5.04799987792968750e+02 5.11899993896484375e+02 8.16800003051757813e+01,
              5.05333343505859375e+02 5.12460021972656250e+02 8.16800003051757813e+01,
              5.05333343505859375e+02 5.11899993896484375e+02 8.22906646728515625e+01,
              5.06533325195312500e+02 5.11899993896484375e+02 8.16800003051757813e+01,
              5.06000000000000000e+02 5.12460021972656250e+02 8.16800003051757813e+01,
              5.06000000000000000e+02 5.11899993896484375e+02 8.22906646728515625e+01,
              5.03333343505859375e+02 5.07427520751953125e+02 8.24433364868164063e+01,
              5.04000000000000000e+02 5.07504241943359375e+02 8.24433364868164063e+01,
              5.04666656494140625e+02 5.07427520751953125e+02 8.24433364868164063e+01,
              5.03073822021484375e+02 5.07700012207031250e+02 8.24433364868164063e+01,
              5.03333343505859375e+02 5.07700012207031250e+02 8.27404632568359375e+01,
              5.04000000000000000e+02 5.07700012207031250e+02 8.26568069458007813e+01,
              5.04926177978515625e+02 5.07700012207031250e+02 8.24433364868164063e+01,
              5.04666656494140625e+02 5.07700012207031250e+02 8.27404632568359375e+01,
              5.03096252441406250e+02 5.08399993896484375e+02 8.24433364868164063e+01,
              5.03333343505859375e+02 5.08648956298828125e+02 8.24433364868164063e+01,
              5.03333343505859375e+02 5.08399993896484375e+02 8.27148056030273438e+01,
              5.04000000000000000e+02 5.08637695312500000e+02 8.24433364868164063e+01,
              5.04000000000000000e+02 5.08399993896484375e+02 8.27025222778320313e+01,
              5.04903747558593750e+02 5.08399993896484375e+02 8.24433364868164063e+01,
              5.04666656494140625e+02 5.08648956298828125e+02 8.24433364868164063e+01,
              5.04666656494140625e+02 5.08399993896484375e+02 8.27148056030273438e+01,
              5.11333343505859375e+02 5.01399993896484375e+02 8.33593368530273438e+01,
              5.12000000000000000e+02 5.01399993896484375e+02 8.33593368530273438e+01,
              5.12666687011718750e+02 5.01399993896484375e+02 8.33593368530273438e+01,
              5.13333312988281250e+02 5.01399993896484375e+02 8.33593368530273438e+01,
              5.14000000000000000e+02 5.01399993896484375e+02 8.33593368530273438e+01,
              5.08666656494140625e+02 5.02100006103515625e+02 8.33593368530273438e+01,
              5.09333343505859375e+02 5.02100006103515625e+02 8.33593368530273438e+01,
              5.10000000000000000e+02 5.02100006103515625e+02 8.33593368530273438e+01,
              5.10666656494140625e+02 5.02100006103515625e+02 8.33593368530273438e+01,
              5.11333343505859375e+02 5.02100006103515625e+02 8.33593368530273438e+01,
              5.12000000000000000e+02 5.02100006103515625e+02 8.33593368530273438e+01,
              5.12666687011718750e+02 5.02100006103515625e+02 8.33593368530273438e+01,
              5.13333312988281250e+02 5.02100006103515625e+02 8.33593368530273438e+01,
              5.14000000000000000e+02 5.02100006103515625e+02 8.33593368530273438e+01,
              5.14666687011718750e+02 5.02100006103515625e+02 8.33593368530273438e+01,
              5.06000000000000000e+02 5.02799987792968750e+02 8.33593368530273438e+01,
              5.06666656494140625e+02 5.02799987792968750e+02 8.33593368530273438e+01,
              5.07333343505859375e+02 5.02799987792968750e+02 8.33593368530273438e+01,
              5.08000000000000000e+02 5.02799987792968750e+02 8.33593368530273438e+01,
              5.08666656494140625e+02 5.02799987792968750e+02 8.33593368530273438e+01,
              5.09333343505859375e+02 5.02799987792968750e+02 8.33593368530273438e+01,
              5.10000000000000000e+02 5.02799987792968750e+02 8.33593368530273438e+01,
              5.10666656494140625e+02 5.02799987792968750e+02 8.33593368530273438e+01,
              5.11333343505859375e+02 5.02799987792968750e+02 8.33593368530273438e+01,
              5.12000000000000000e+02 5.02799987792968750e+02 8.33593368530273438e+01,
              5.12666687011718750e+02 5.02799987792968750e+02 8.33593368530273438e+01,
              5.13333312988281250e+02 5.02799987792968750e+02 8.33593368530273438e+01,
              5.14000000000000000e+02 5.02799987792968750e+02 8.33593368530273438e+01,
              5.14666687011718750e+02 5.02799987792968750e+02 8.33593368530273438e+01,
              5.06666656494140625e+02 5.03500000000000000e+02 8.33593368530273438e+01,
              5.07333343505859375e+02 5.03500000000000000e+02 8.33593368530273438e+01,
              5.08000000000000000e+02 5.03500000000000000e+02 8.33593368530273438e+01,
              5.08666656494140625e+02 5.03500000000000000e+02 8.33593368530273438e+01,
              5.09333343505859375e+02 5.03500000000000000e+02 8.33593368530273438e+01,
              5.10000000000000000e+02 5.03500000000000000e+02 8.33593368530273438e+01,
              5.10666656494140625e+02 5.03500000000000000e+02 8.34957046508789063e+01,
              5.11333343505859375e+02 5.03500000000000000e+02 8.34957046508789063e+01,
              5.12000000000000000e+02 5.03500000000000000e+02 8.33593368530273438e+01,
              5.12666687011718750e+02 5.03500000000000000e+02 8.33593368530273438e+01,
              5.13333312988281250e+02 5.03500000000000000e+02 8.33593368530273438e+01,
              5.14000000000000000e+02 5.03500000000000000e+02 8.33593368530273438e+01,
              5.14666687011718750e+02 5.03500000000000000e+02 8.33593368530273438e+01,
              5.06666656494140625e+02 5.04200012207031250e+02 8.33593368530273438e+01,
              5.07333343505859375e+02 5.04200012207031250e+02 8.33593368530273438e+01,
              5.08000000000000000e+02 5.04200012207031250e+02 8.33593368530273438e+01,
              5.08666656494140625e+02 5.04200012207031250e+02 8.33593368530273438e+01,
              5.09333343505859375e+02 5.04200012207031250e+02 8.33593368530273438e+01,
              5.10000000000000000e+02 5.04200012207031250e+02 8.33593368530273438e+01,
              5.10666656494140625e+02 5.04200012207031250e+02 8.34666061401367188e+01,
              5.11333343505859375e+02 5.04200012207031250e+02 8.34666061401367188e+01,
              5.12000000000000000e+02 5.04200012207031250e+02 8.33593368530273438e+01,
              5.12666687011718750e+02 5.04200012207031250e+02 8.33593368530273438e+01,
              5.13333312988281250e+02 5.04200012207031250e+02 8.33593368530273438e+01,
              5.14000000000000000e+02 5.04200012207031250e+02 8.33593368530273438e+01,
              5.14666687011718750e+02 5.04200012207031250e+02 8.33593368530273438e+01,
              5.07333343505859375e+02 5.04899993896484375e+02 8.33593368530273438e+01,
              5.08000000000000000e+02 5.04899993896484375e+02 8.33593368530273438e+01,
              5.08666656494140625e+02 5.04899993896484375e+02 8.33593368530273438e+01,
              5.09333343505859375e+02 5.04899993896484375e+02 8.33593368530273438e+01,
              5.10000000000000000e+02 5.04899993896484375e+02 8.33593368530273438e+01,
              5.10666656494140625e+02 5.04899993896484375e+02 8.34331588745117188e+01,
              5.11333343505859375e+02 5.04899993896484375e+02 8.34331588745117188e+01,
              5.12000000000000000e+02 5.04899993896484375e+02 8.33593368530273438e+01,
              5.12666687011718750e+02 5.04899993896484375e+02 8.33593368530273438e+01,
              5.13333312988281250e+02 5.04899993896484375e+02 8.33593368530273438e+01,
              5.14000000000000000e+02 5.04899993896484375e+02 8.33593368530273438e+01,
              5.14666687011718750e+02 5.04899993896484375e+02 8.33593368530273438e+01,
              5.07333343505859375e+02 5.05600006103515625e+02 8.33593368530273438e+01,
              5.08000000000000000e+02 5.05600006103515625e+02 8.33593368530273438e+01,
              5.08666656494140625e+02 5.05600006103515625e+02 8.33593368530273438e+01,
              5.09333343505859375e+02 5.05600006103515625e+02 8.33593368530273438e+01,
              5.10000000000000000e+02 5.05600006103515625e+02 8.33593368530273438e+01,
              5.10666656494140625e+02 5.05600006103515625e+02 8.33593368530273438e+01,
              5.11333343505859375e+02 5.05600006103515625e+02 8.33593368530273438e+01,
              5.12000000000000000e+02 5.05600006103515625e+02 8.33593368530273438e+01,
              5.12666687011718750e+02 5.05600006103515625e+02 8.33593368530273438e+01,
              5.13333312988281250e+02 5.05600006103515625e+02 8.33593368530273438e+01,
              5.14000000000000000e+02 5.05600006103515625e+02 8.33593368530273438e+01,
              5.14666687011718750e+02 5.05600006103515625e+02 8.33593368530273438e+01,
              5.08000000000000000e+02 5.06299987792968750e+02 8.33593368530273438e+01,
              5.08666656494140625e+02 5.06299987792968750e+02 8.33593368530273438e+01,
              5.09333343505859375e+02 5.06299987792968750e+02 8.33593368530273438e+01,
              5.10000000000000000e+02 5.06299987792968750e+02 8.33593368530273438e+01,
              5.10666656494140625e+02 5.06299987792968750e+02 8.33593368530273438e+01,
              5.11333343505859375e+02 5.06299987792968750e+02 8.33593368530273438e+01,
              5.12000000000000000e+02 5.06299987792968750e+02 8.33593368530273438e+01,
              5.12666687011718750e+02 5.06299987792968750e+02 8.33593368530273438e+01,
              5.13333312988281250e+02 5.06299987792968750e+02 8.33593368530273438e+01,
              5.14000000000000000e+02 5.06299987792968750e+02 8.33593368530273438e+01,
              5.10666656494140625e+02 5.07000000000000000e+02 8.33593368530273438e+01,
              5.11333343505859375e+02 5.07000000000000000e+02 8.33593368530273438e+01,
              5.12000000000000000e+02 5.07000000000000000e+02 8.33593368530273438e+01,
              5.12666687011718750e+02 5.07000000000000000e+02 8.33593368530273438e+01,
              5.13333312988281250e+02 5.07000000000000000e+02 8.33593368530273438e+01,
              5.11333343505859375e+02 5.00839996337890625e+02 8.39700012207031250e+01,
              5.12000000000000000e+02 5.00839996337890625e+02 8.39700012207031250e+01,
              5.12666687011718750e+02 5.00839996337890625e+02 8.39700012207031250e+01,
              5.13333312988281250e+02 5.00839996337890625e+02 8.39700012207031250e+01,
              5.14000000000000000e+02 5.00839996337890625e+02 8.39700012207031250e+01,
              5.08666656494140625e+02 5.01540008544921875e+02 8.39700012207031250e+01,
              5.09333343505859375e+02 5.01540008544921875e+02 8.39700012207031250e+01,
              5.10000000000000000e+02 5.01540008544921875e+02 8.39700012207031250e+01,
              5.10799987792968750e+02 5.01399993896484375e+02 8.39700012207031250e+01,
              5.10666656494140625e+02 5.01540008544921875e+02 8.39700012207031250e+01,
              5.11333343505859375e+02 5.01399993896484375e+02 8.45806655883789063e+01,
              5.12000000000000000e+02 5.01399993896484375e+02 8.45806655883789063e+01,
              5.12666687011718750e+02 5.01399993896484375e+02 8.45806655883789063e+01,
              5.13333312988281250e+02 5.01399993896484375e+02 8.45806655883789063e+01,
              5.14533325195312500e+02 5.01399993896484375e+02 8.39700012207031250e+01,
              5.14000000000000000e+02 5.01399993896484375e+02 8.45806655883789063e+01,
              5.14666687011718750e+02 5.01540008544921875e+02 8.39700012207031250e+01,
              5.06000000000000000e+02 5.02239990234375000e+02 8.39700012207031250e+01,
              5.06666656494140625e+02 5.02239990234375000e+02 8.39700012207031250e+01,
              5.07333343505859375e+02 5.02239990234375000e+02 8.39700012207031250e+01,
              5.08133331298828125e+02 5.02100006103515625e+02 8.39700012207031250e+01,
              5.08000000000000000e+02 5.02239990234375000e+02 8.39700012207031250e+01,
              5.08666656494140625e+02 5.02100006103515625e+02 8.45806655883789063e+01,
              5.09333343505859375e+02 5.02100006103515625e+02 8.45806655883789063e+01,
              5.10000000000000000e+02 5.02100006103515625e+02 8.45806655883789063e+01,
              5.10666656494140625e+02 5.02100006103515625e+02 8.45806655883789063e+01,
              5.11333343505859375e+02 5.02100006103515625e+02 8.45806655883789063e+01,
              5.12000000000000000e+02 5.02100006103515625e+02 8.45806655883789063e+01,
              5.12666687011718750e+02 5.02100006103515625e+02 8.45806655883789063e+01,
              5.13333312988281250e+02 5.02100006103515625e+02 8.45806655883789063e+01,
              5.14000000000000000e+02 5.02100006103515625e+02 8.45806655883789063e+01,
              5.15200012207031250e+02 5.02100006103515625e+02 8.39700012207031250e+01,
              5.14666687011718750e+02 5.02100006103515625e+02 8.45806655883789063e+01,
              5.05466674804687500e+02 5.02799987792968750e+02 8.39700012207031250e+01,
              5.06000000000000000e+02 5.03359985351562500e+02 8.39700012207031250e+01,
              5.06000000000000000e+02 5.02799987792968750e+02 8.45806655883789063e+01,
              5.06666656494140625e+02 5.02799987792968750e+02 8.45806655883789063e+01,
              5.07333343505859375e+02 5.02799987792968750e+02 8.45806655883789063e+01,
              5.08000000000000000e+02 5.02799987792968750e+02 8.45806655883789063e+01,
              5.08666656494140625e+02 5.02799987792968750e+02 8.45806655883789063e+01,
              5.09333343505859375e+02 5.02799987792968750e+02 8.45806655883789063e+01,
              5.10000000000000000e+02 5.02799987792968750e+02 8.45806655883789063e+01,
              5.10666656494140625e+02 5.02799987792968750e+02 8.45806655883789063e+01,
              5.11333343505859375e+02 5.02799987792968750e+02 8.45806655883789063e+01,
              5.12000000000000000e+02 5.02799987792968750e+02 8.45806655883789063e+01,
              5.12666687011718750e+02 5.02799987792968750e+02 8.45806655883789063e+01,
              5.13333312988281250e+02 5.02799987792968750e+02 8.45806655883789063e+01,
              5.14000000000000000e+02 5.02799987792968750e+02 8.45806655883789063e+01,
              5.15200012207031250e+02 5.02799987792968750e+02 8.39700012207031250e+01,
              5.14666687011718750e+02 5.02799987792968750e+02 8.45806655883789063e+01,
              5.06133331298828125e+02 5.03500000000000000e+02 8.39700012207031250e+01,
              5.06666656494140625e+02 5.03500000000000000e+02 8.45806655883789063e+01,
              5.07333343505859375e+02 5.03500000000000000e+02 8.45806655883789063e+01,
              5.08000000000000000e+02 5.03500000000000000e+02 8.45806655883789063e+01,
              5.08666656494140625e+02 5.03500000000000000e+02 8.45806655883789063e+01,
              5.09333343505859375e+02 5.03500000000000000e+02 8.45806655883789063e+01,
              5.10000000000000000e+02 5.03500000000000000e+02 8.45806655883789063e+01,
              5.10666656494140625e+02 5.03500000000000000e+02 8.46918945312500000e+01,
              5.11333343505859375e+02 5.03500000000000000e+02 8.46918945312500000e+01,
              5.12000000000000000e+02 5.03500000000000000e+02 8.45806655883789063e+01,
              5.12666687011718750e+02 5.03500000000000000e+02 8.45806655883789063e+01,
              5.13333312988281250e+02 5.03500000000000000e+02 8.45806655883789063e+01,
              5.14000000000000000e+02 5.03500000000000000e+02 8.45806655883789063e+01,
              5.15200012207031250e+02 5.03500000000000000e+02 8.39700012207031250e+01,
              5.14666687011718750e+02 5.03500000000000000e+02 8.45806655883789063e+01,
              5.06133331298828125e+02 5.04200012207031250e+02 8.39700012207031250e+01,
              5.06666656494140625e+02 5.04760009765625000e+02 8.39700012207031250e+01,
              5.06666656494140625e+02 5.04200012207031250e+02 8.45806655883789063e+01,
              5.07333343505859375e+02 5.04200012207031250e+02 8.45806655883789063e+01,
              5.08000000000000000e+02 5.04200012207031250e+02 8.45806655883789063e+01,
              5.08666656494140625e+02 5.04200012207031250e+02 8.45806655883789063e+01,
              5.09333343505859375e+02 5.04200012207031250e+02 8.45806655883789063e+01,
              5.10000000000000000e+02 5.04200012207031250e+02 8.45806655883789063e+01,
              5.10666656494140625e+02 5.04200012207031250e+02 8.46367034912109375e+01,
              5.11333343505859375e+02 5.04200012207031250e+02 8.46367034912109375e+01,
              5.12000000000000000e+02 5.04200012207031250e+02 8.45806655883789063e+01,
              5.12666687011718750e+02 5.04200012207031250e+02 8.45806655883789063e+01,
              5.13333312988281250e+02 5.04200012207031250e+02 8.45806655883789063e+01,
              5.14000000000000000e+02 5.04200012207031250e+02 8.45806655883789063e+01,
              5.15200012207031250e+02 5.04200012207031250e+02 8.39700012207031250e+01,
              5.14666687011718750e+02 5.04200012207031250e+02 8.45806655883789063e+01,
              5.06799987792968750e+02 5.04899993896484375e+02 8.39700012207031250e+01,
              5.07333343505859375e+02 5.04899993896484375e+02 8.45806655883789063e+01,
              5.08000000000000000e+02 5.04899993896484375e+02 8.45806655883789063e+01,
              5.08666656494140625e+02 5.04899993896484375e+02 8.45806655883789063e+01,
              5.09333343505859375e+02 5.04899993896484375e+02 8.45806655883789063e+01,
              5.10000000000000000e+02 5.04899993896484375e+02 8.45806655883789063e+01,
              5.10666656494140625e+02 5.04899993896484375e+02 8.45068435668945313e+01,
              5.11333343505859375e+02 5.04899993896484375e+02 8.45068435668945313e+01,
              5.12000000000000000e+02 5.04899993896484375e+02 8.45806655883789063e+01,
              5.12666687011718750e+02 5.04899993896484375e+02 8.45806655883789063e+01,
              5.13333312988281250e+02 5.04899993896484375e+02 8.45806655883789063e+01,
              5.14000000000000000e+02 5.04899993896484375e+02 8.45806655883789063e+01,
              5.15200012207031250e+02 5.04899993896484375e+02 8.39700012207031250e+01,
              5.14666687011718750e+02 5.04899993896484375e+02 8.45806655883789063e+01,
              5.06799987792968750e+02 5.05600006103515625e+02 8.39700012207031250e+01,
              5.07333343505859375e+02 5.06160003662109375e+02 8.39700012207031250e+01,
              5.07333343505859375e+02 5.05600006103515625e+02 8.45806655883789063e+01,
              5.08000000000000000e+02 5.05600006103515625e+02 8.45806655883789063e+01,
              5.08666656494140625e+02 5.05600006103515625e+02 8.45806655883789063e+01,
              5.09333343505859375e+02 5.05600006103515625e+02 8.45806655883789063e+01,
              5.10000000000000000e+02 5.05600006103515625e+02 8.45806655883789063e+01,
              5.10666656494140625e+02 5.05600006103515625e+02 8.45806655883789063e+01,
              5.11333343505859375e+02 5.05600006103515625e+02 8.45806655883789063e+01,
              5.12000000000000000e+02 5.05600006103515625e+02 8.45806655883789063e+01,
              5.12666687011718750e+02 5.05600006103515625e+02 8.45806655883789063e+01,
              5.13333312988281250e+02 5.05600006103515625e+02 8.45806655883789063e+01,
              5.14000000000000000e+02 5.05600006103515625e+02 8.45806655883789063e+01,
              5.15200012207031250e+02 5.05600006103515625e+02 8.39700012207031250e+01,
              5.14666687011718750e+02 5.06160003662109375e+02 8.39700012207031250e+01,
              5.14666687011718750e+02 5.05600006103515625e+02 8.45806655883789063e+01,
              5.07466674804687500e+02 5.06299987792968750e+02 8.39700012207031250e+01,
              5.08000000000000000e+02 5.06859985351562500e+02 8.39700012207031250e+01,
              5.08000000000000000e+02 5.06299987792968750e+02 8.45806655883789063e+01,
              5.08666656494140625e+02 5.06859985351562500e+02 8.39700012207031250e+01,
              5.08666656494140625e+02 5.06299987792968750e+02 8.45806655883789063e+01,
              5.09333343505859375e+02 5.06859985351562500e+02 8.39700012207031250e+01,
              5.09333343505859375e+02 5.06299987792968750e+02 8.45806655883789063e+01,
              5.10000000000000000e+02 5.06859985351562500e+02 8.39700012207031250e+01,
              5.10000000000000000e+02 5.06299987792968750e+02 8.45806655883789063e+01,
              5.10666656494140625e+02 5.06299987792968750e+02 8.45806655883789063e+01,
              5.11333343505859375e+02 5.06299987792968750e+02 8.45806655883789063e+01,
              5.12000000000000000e+02 5.06299987792968750e+02 8.45806655883789063e+01,
              5.12666687011718750e+02 5.06299987792968750e+02 8.45806655883789063e+01,
              5.13333312988281250e+02 5.06299987792968750e+02 8.45806655883789063e+01,
              5.14533325195312500e+02 5.06299987792968750e+02 8.39700012207031250e+01,
              5.14000000000000000e+02 5.06859985351562500e+02 8.39700012207031250e+01,
              5.14000000000000000e+02 5.06299987792968750e+02 8.45806655883789063e+01,
              5.10133331298828125e+02 5.07000000000000000e+02 8.39700012207031250e+01,
              5.10666656494140625e+02 5.07559997558593750e+02 8.39700012207031250e+01,
              5.10666656494140625e+02 5.07000000000000000e+02 8.45806655883789063e+01,
              5.11333343505859375e+02 5.07559997558593750e+02 8.39700012207031250e+01,
              5.11333343505859375e+02 5.07000000000000000e+02 8.45806655883789063e+01,
              5.12000000000000000e+02 5.07559997558593750e+02 8.39700012207031250e+01,
              5.12000000000000000e+02 5.07000000000000000e+02 8.45806655883789063e+01,
              5.12666687011718750e+02 5.07559997558593750e+02 8.39700012207031250e+01,
              5.12666687011718750e+02 5.07000000000000000e+02 8.45806655883789063e+01,
              5.13866638183593750e+02 5.07000000000000000e+02 8.39700012207031250e+01,
              5.13333312988281250e+02 5.07559997558593750e+02 8.39700012207031250e+01,
              5.13333312988281250e+02 5.07000000000000000e+02 8.45806655883789063e+01,
              5.14666687011718750e+02 5.11200012207031250e+02 8.60315322875976563e+01,
              5.15333312988281250e+02 5.11200012207031250e+02 8.60315322875976563e+01,
              5.14666687011718750e+02 5.11899993896484375e+02 8.61806030273437500e+01,
              5.15333312988281250e+02 5.11899993896484375e+02 8.61806030273437500e+01,
              5.14666687011718750e+02 5.12599975585937500e+02 8.60486984252929688e+01,
              5.15333312988281250e+02 5.12599975585937500e+02 8.60486984252929688e+01,
              5.14666687011718750e+02 5.10990478515625000e+02 8.62600021362304688e+01,
              5.15333312988281250e+02 5.10990478515625000e+02 8.62600021362304688e+01,
              5.14467102050781250e+02 5.11200012207031250e+02 8.62600021362304688e+01,
              5.15532897949218750e+02 5.11200012207031250e+02 8.62600021362304688e+01,
              5.14597351074218750e+02 5.11899993896484375e+02 8.62600021362304688e+01,
              5.15402648925781250e+02 5.11899993896484375e+02 8.62600021362304688e+01,
              5.14482116699218750e+02 5.12599975585937500e+02 8.62600021362304688e+01,
              5.14666687011718750e+02 5.12793762207031250e+02 8.62600021362304688e+01,
              5.15517883300781250e+02 5.12599975585937500e+02 8.62600021362304688e+01,
              5.15333312988281250e+02 5.12793762207031250e+02 8.62600021362304688e+01,
              5.14666687011718750e+02 5.10984161376953125e+02 8.70233306884765625e+01,
              5.15333312988281250e+02 5.10984161376953125e+02 8.70233306884765625e+01,
              5.14461120605468750e+02 5.11200012207031250e+02 8.70233306884765625e+01,
              5.14666687011718750e+02 5.11200012207031250e+02 8.72586822509765625e+01,
              5.15538879394531250e+02 5.11200012207031250e+02 8.70233306884765625e+01,
              5.15333312988281250e+02 5.11200012207031250e+02 8.72586822509765625e+01,
              5.14579040527343750e+02 5.11899993896484375e+02 8.70233306884765625e+01,
              5.14666687011718750e+02 5.11899993896484375e+02 8.71236724853515625e+01,
              5.15420959472656250e+02 5.11899993896484375e+02 8.70233306884765625e+01,
              5.15333312988281250e+02 5.11899993896484375e+02 8.71236724853515625e+01,
              5.14493225097656250e+02 5.12599975585937500e+02 8.70233306884765625e+01,
              5.14666687011718750e+02 5.12782104492187500e+02 8.70233306884765625e+01,
              5.14666687011718750e+02 5.12599975585937500e+02 8.72219390869140625e+01,
              5.15506774902343750e+02 5.12599975585937500e+02 8.70233306884765625e+01,
              5.15333312988281250e+02 5.12782104492187500e+02 8.70233306884765625e+01,
              5.15333312988281250e+02 5.12599975585937500e+02 8.72219390869140625e+01,
              5.13333312988281250e+02 5.01399993896484375e+02 8.94660034179687500e+01,
              5.14000000000000000e+02 5.01399993896484375e+02 8.94660034179687500e+01,
              5.10666656494140625e+02 5.02100006103515625e+02 8.94660034179687500e+01,
              5.11333343505859375e+02 5.02100006103515625e+02 8.94660034179687500e+01,
              5.12000000000000000e+02 5.02100006103515625e+02 8.94660034179687500e+01,
              5.12666687011718750e+02 5.02100006103515625e+02 8.94660034179687500e+01,
              5.13333312988281250e+02 5.02100006103515625e+02 8.94660034179687500e+01,
              5.14000000000000000e+02 5.02100006103515625e+02 8.94660034179687500e+01,
              5.14666687011718750e+02 5.02100006103515625e+02 8.94660034179687500e+01,
              5.07333343505859375e+02 5.02799987792968750e+02 8.94660034179687500e+01,
              5.08000000000000000e+02 5.02799987792968750e+02 8.94660034179687500e+01,
              5.08666656494140625e+02 5.02799987792968750e+02 8.94660034179687500e+01,
              5.09333343505859375e+02 5.02799987792968750e+02 8.94660034179687500e+01,
              5.10000000000000000e+02 5.02799987792968750e+02 8.94660034179687500e+01,
              5.10666656494140625e+02 5.02799987792968750e+02 8.94660034179687500e+01,
              5.11333343505859375e+02 5.02799987792968750e+02 8.94660034179687500e+01,
              5.12000000000000000e+02 5.02799987792968750e+02 8.94660034179687500e+01,
              5.12666687011718750e+02 5.02799987792968750e+02 8.94660034179687500e+01,
              5.13333312988281250e+02 5.02799987792968750e+02 8.94660034179687500e+01,
              5.14000000000000000e+02 5.02799987792968750e+02 8.94660034179687500e+01,
              5.14666687011718750e+02 5.02799987792968750e+02 8.94660034179687500e+01,
              5.06666656494140625e+02 5.03500000000000000e+02 8.94660034179687500e+01,
              5.07333343505859375e+02 5.03500000000000000e+02 8.94660034179687500e+01,
              5.08000000000000000e+02 5.03500000000000000e+02 8.94660034179687500e+01,
              5.08666656494140625e+02 5.03500000000000000e+02 8.94660034179687500e+01,
              5.09333343505859375e+02 5.03500000000000000e+02 8.94660034179687500e+01,
              5.10000000000000000e+02 5.03500000000000000e+02 8.94660034179687500e+01,
              5.10666656494140625e+02 5.03500000000000000e+02 8.93983993530273438e+01,
              5.11333343505859375e+02 5.03500000000000000e+02 8.93983993530273438e+01,
              5.12000000000000000e+02 5.03500000000000000e+02 8.94660034179687500e+01,
              5.12666687011718750e+02 5.03500000000000000e+02 8.94660034179687500e+01,
              5.13333312988281250e+02 5.03500000000000000e+02 8.94660034179687500e+01,
              5.14000000000000000e+02 5.03500000000000000e+02 8.94660034179687500e+01,
              5.14666687011718750e+02 5.03500000000000000e+02 8.94660034179687500e+01,
              5.06666656494140625e+02 5.04200012207031250e+02 8.94660034179687500e+01,
              5.07333343505859375e+02 5.04200012207031250e+02 8.94660034179687500e+01,
              5.08000000000000000e+02 5.04200012207031250e+02 8.94660034179687500e+01,
              5.08666656494140625e+02 5.04200012207031250e+02 8.94660034179687500e+01,
              5.09333343505859375e+02 5.04200012207031250e+02 8.94660034179687500e+01,
              5.10000000000000000e+02 5.04200012207031250e+02 8.94660034179687500e+01,
              5.10666656494140625e+02 5.04200012207031250e+02 8.94456710815429688e+01,
              5.11333343505859375e+02 5.04200012207031250e+02 8.94456710815429688e+01,
              5.12000000000000000e+02 5.04200012207031250e+02 8.94660034179687500e+01,
              5.12666687011718750e+02 5.04200012207031250e+02 8.94660034179687500e+01,
              5.13333312988281250e+02 5.04200012207031250e+02 8.94660034179687500e+01,
              5.14000000000000000e+02 5.04200012207031250e+02 8.94660034179687500e+01,
              5.14666687011718750e+02 5.04200012207031250e+02 8.94660034179687500e+01,
              5.07333343505859375e+02 5.04899993896484375e+02 8.94660034179687500e+01,
              5.08000000000000000e+02 5.04899993896484375e+02 8.94660034179687500e+01,
              5.08666656494140625e+02 5.04899993896484375e+02 8.94660034179687500e+01,
              5.09333343505859375e+02 5.04899993896484375e+02 8.94660034179687500e+01,
              5.10000000000000000e+02 5.04899993896484375e+02 8.94660034179687500e+01,
              5.10666656494140625e+02 5.04899993896484375e+02 8.94957580566406250e+01,
              5.11333343505859375e+02 5.04899993896484375e+02 8.94957580566406250e+01,
              5.12000000000000000e+02 5.04899993896484375e+02 8.94660034179687500e+01,
              5.12666687011718750e+02 5.04899993896484375e+02 8.94660034179687500e+01,
              5.13333312988281250e+02 5.04899993896484375e+02 8.94660034179687500e+01,
              5.14000000000000000e+02 5.04899993896484375e+02 8.94660034179687500e+01,
              5.14666687011718750e+02 5.04899993896484375e+02 8.94660034179687500e+01,
              5.07333343505859375e+02 5.05600006103515625e+02 8.94660034179687500e+01,
              5.08000000000000000e+02 5.05600006103515625e+02 8.94660034179687500e+01,
              5.08666656494140625e+02 5.05600006103515625e+02 8.94660034179687500e+01,
              5.09333343505859375e+02 5.05600006103515625e+02 8.94660034179687500e+01,
              5.10000000000000000e+02 5.05600006103515625e+02 8.94660034179687500e+01,
              5.10666656494140625e+02 5.05600006103515625e+02 8.94660034179687500e+01,
              5.11333343505859375e+02 5.05600006103515625e+02 8.94660034179687500e+01,
              5.12000000000000000e+02 5.05600006103515625e+02 8.94660034179687500e+01,
              5.12666687011718750e+02 5.05600006103515625e+02 8.94660034179687500e+01,
              5.13333312988281250e+02 5.05600006103515625e+02 8.94660034179687500e+01,
              5.14000000000000000e+02 5.05600006103515625e+02 8.94660034179687500e+01,
              5.14666687011718750e+02 5.05600006103515625e+02 8.94660034179687500e+01,
              5.08000000000000000e+02 5.06299987792968750e+02 8.94660034179687500e+01,
              5.08666656494140625e+02 5.06299987792968750e+02 8.94660034179687500e+01,
              5.09333343505859375e+02 5.06299987792968750e+02 8.94660034179687500e+01,
              5.10000000000000000e+02 5.06299987792968750e+02 8.94660034179687500e+01,
              5.10666656494140625e+02 5.06299987792968750e+02 8.94660034179687500e+01,
              5.11333343505859375e+02 5.06299987792968750e+02 8.94660034179687500e+01,
              5.12000000000000000e+02 5.06299987792968750e+02 8.94660034179687500e+01,
              5.12666687011718750e+02 5.06299987792968750e+02 8.94660034179687500e+01,
              5.13333312988281250e+02 5.06299987792968750e+02 8.94660034179687500e+01,
              5.14000000000000000e+02 5.06299987792968750e+02 8.94660034179687500e+01,
              5.10666656494140625e+02 5.07000000000000000e+02 8.94660034179687500e+01,
              5.11333343505859375e+02 5.07000000000000000e+02 8.94660034179687500e+01,
              5.12000000000000000e+02 5.07000000000000000e+02 8.94660034179687500e+01,
              5.12666687011718750e+02 5.07000000000000000e+02 8.94660034179687500e+01,
              5.13333312988281250e+02 5.07000000000000000e+02 8.94660034179687500e+01,
              5.13333312988281250e+02 5.09100006103515625e+02 8.94660034179687500e+01,
              5.13333312988281250e+02 5.09799987792968750e+02 8.94660034179687500e+01,
              5.14000000000000000e+02 5.09799987792968750e+02 8.94660034179687500e+01,
              5.14666687011718750e+02 5.09799987792968750e+02 8.94660034179687500e+01,
              5.13333312988281250e+02 5.10500000000000000e+02 8.94660034179687500e+01,
              5.14000000000000000e+02 5.10500000000000000e+02 8.94660034179687500e+01,
              5.14666687011718750e+02 5.10500000000000000e+02 8.94660034179687500e+01,
              5.15333312988281250e+02 5.10500000000000000e+02 8.94660034179687500e+01,
              5.16000000000000000e+02 5.10500000000000000e+02 8.94660034179687500e+01,
              5.12666687011718750e+02 5.11200012207031250e+02 8.94660034179687500e+01,
              5.13333312988281250e+02 5.11200012207031250e+02 8.94660034179687500e+01,
              5.14000000000000000e+02 5.11200012207031250e+02 8.94660034179687500e+01,
              5.14666687011718750e+02 5.11200012207031250e+02 8.95105133056640625e+01,
              5.15333312988281250e+02 5.11200012207031250e+02 8.95105133056640625e+01,
              5.16000000000000000e+02 5.11200012207031250e+02 8.94660034179687500e+01,
              5.16666687011718750e+02 5.11200012207031250e+02 8.94660034179687500e+01,
              5.12666687011718750e+02 5.11899993896484375e+02 8.94660034179687500e+01,
              5.13333312988281250e+02 5.11899993896484375e+02 8.94660034179687500e+01,
              5.14000000000000000e+02 5.11899993896484375e+02 8.94660034179687500e+01,
              5.14666687011718750e+02 5.11899993896484375e+02 8.94584274291992188e+01,
              5.15333312988281250e+02 5.11899993896484375e+02 8.94584274291992188e+01,
              5.16000000000000000e+02 5.11899993896484375e+02 8.94660034179687500e+01,
              5.16666687011718750e+02 5.11899993896484375e+02 8.94660034179687500e+01,
              5.17333312988281250e+02 5.11899993896484375e+02 8.94660034179687500e+01,
              5.18000000000000000e+02 5.11899993896484375e+02 8.94660034179687500e+01,
              5.12000000000000000e+02 5.12599975585937500e+02 8.94660034179687500e+01,
              5.12666687011718750e+02 5.12599975585937500e+02 8.94660034179687500e+01,
              5.13333312988281250e+02 5.12599975585937500e+02 8.94660034179687500e+01,
              5.14000000000000000e+02 5.12599975585937500e+02 8.94660034179687500e+01,
              5.14666687011718750e+02 5.12599975585937500e+02 8.94491119384765625e+01,
              5.15333312988281250e+02 5.12599975585937500e+02 8.94491119384765625e+01,
              5.16000000000000000e+02 5.12599975585937500e+02 8.94660034179687500e+01,
              5.16666687011718750e+02 5.12599975585937500e+02 8.94660034179687500e+01,
              5.17333312988281250e+02 5.12599975585937500e+02 8.94660034179687500e+01,
              5.18000000000000000e+02 5.12599975585937500e+02 8.94660034179687500e+01,
              5.18666687011718750e+02 5.12599975585937500e+02 8.94660034179687500e+01,
              5.12000000000000000e+02 5.13299987792968750e+02 8.94660034179687500e+01,
              5.12666687011718750e+02 5.13299987792968750e+02 8.94660034179687500e+01,
              5.13333312988281250e+02 5.13299987792968750e+02 8.94660034179687500e+01,
              5.14000000000000000e+02 5.13299987792968750e+02 8.94660034179687500e+01,
              5.14666687011718750e+02 5.13299987792968750e+02 8.94660034179687500e+01,
              5.15333312988281250e+02 5.13299987792968750e+02 8.94660034179687500e+01,
              5.16000000000000000e+02 5.13299987792968750e+02 8.94660034179687500e+01,
              5.16666687011718750e+02 5.13299987792968750e+02 8.94660034179687500e+01,
              5.17333312988281250e+02 5.13299987792968750e+02 8.94660034179687500e+01,
              5.18000000000000000e+02 5.13299987792968750e+02 8.94660034179687500e+01,
              5.18666687011718750e+02 5.13299987792968750e+02 8.94660034179687500e+01,
              5.19333312988281250e+02 5.13299987792968750e+02 8.94660034179687500e+01,
              5.11333343505859375e+02 5.14000000000000000e+02 8.94660034179687500e+01,
              5.12000000000000000e+02 5.14000000000000000e+02 8.94660034179687500e+01,
              5.12666687011718750e+02 5.14000000000000000e+02 8.94660034179687500e+01,
              5.13333312988281250e+02 5.14000000000000000e+02 8.94660034179687500e+01,
              5.14000000000000000e+02 5.14000000000000000e+02 8.94660034179687500e+01,
              5.14666687011718750e+02 5.14000000000000000e+02 8.94660034179687500e+01,
              5.15333312988281250e+02 5.14000000000000000e+02 8.94660034179687500e+01,
              5.16000000000000000e+02 5.14000000000000000e+02 8.94660034179687500e+01,
              5.16666687011718750e+02 5.14000000000000000e+02 8.94660034179687500e+01,
              5.17333312988281250e+02 5.14000000000000000e+02 8.94660034179687500e+01,
              5.18000000000000000e+02 5.14000000000000000e+02 8.94660034179687500e+01,
              5.18666687011718750e+02 5.14000000000000000e+02 8.94660034179687500e+01,
              5.11333343505859375e+02 5.14700012207031250e+02 8.94660034179687500e+01,
              5.12000000000000000e+02 5.14700012207031250e+02 8.94660034179687500e+01,
              5.12666687011718750e+02 5.14700012207031250e+02 8.94660034179687500e+01,
              5.13333312988281250e+02 5.14700012207031250e+02 8.94660034179687500e+01,
              5.14000000000000000e+02 5.14700012207031250e+02 8.94660034179687500e+01,
              5.14666687011718750e+02 5.14700012207031250e+02 8.94660034179687500e+01,
              5.15333312988281250e+02 5.14700012207031250e+02 8.94660034179687500e+01,
              5.16000000000000000e+02 5.14700012207031250e+02 8.94660034179687500e+01,
              5.16666687011718750e+02 5.14700012207031250e+02 8.94660034179687500e+01,
              5.17333312988281250e+02 5.14700012207031250e+02 8.94660034179687500e+01,
              5.12000000000000000e+02 5.15400024414062500e+02 8.94660034179687500e+01,
              5.12666687011718750e+02 5.15400024414062500e+02 8.94660034179687500e+01,
              5.13333312988281250e+02 5.15400024414062500e+02 8.94660034179687500e+01,
              5.14000000000000000e+02 5.15400024414062500e+02 8.94660034179687500e+01,
              5.14666687011718750e+02 5.15400024414062500e+02 8.94660034179687500e+01,
              5.15333312988281250e+02 5.15400024414062500e+02 8.94660034179687500e+01,
              5.16000000000000000e+02 5.15400024414062500e+02 8.94660034179687500e+01,
              5.16666687011718750e+02 5.15400024414062500e+02 8.94660034179687500e+01,
              5.12666687011718750e+02 5.16099975585937500e+02 8.94660034179687500e+01,
              5.13333312988281250e+02 5.16099975585937500e+02 8.94660034179687500e+01,
              5.14000000000000000e+02 5.16099975585937500e+02 8.94660034179687500e+01,
              5.14666687011718750e+02 5.16099975585937500e+02 8.94660034179687500e+01,
              5.15333312988281250e+02 5.16099975585937500e+02 8.94660034179687500e+01,
              5.16000000000000000e+02 5.16099975585937500e+02 8.94660034179687500e+01,
              5.13333312988281250e+02 5.00839996337890625e+02 9.00766677856445313e+01,
              5.14000000000000000e+02 5.00839996337890625e+02 9.00766677856445313e+01,
              5.10666656494140625e+02 5.01540008544921875e+02 9.00766677856445313e+01,
              5.11333343505859375e+02 5.01540008544921875e+02 9.00766677856445313e+01,
              5.12000000000000000e+02 5.01540008544921875e+02 9.00766677856445313e+01,
              5.12799987792968750e+02 5.01399993896484375e+02 9.00766677856445313e+01,
              5.12666687011718750e+02 5.01540008544921875e+02 9.00766677856445313e+01,
              5.13333312988281250e+02 5.01399993896484375e+02 9.06873321533203125e+01,
              5.14533325195312500e+02 5.01399993896484375e+02 9.00766677856445313e+01,
              5.14000000000000000e+02 5.01399993896484375e+02 9.06873321533203125e+01,
              5.14666687011718750e+02 5.01540008544921875e+02 9.00766677856445313e+01,
              5.07333343505859375e+02 5.02239990234375000e+02 9.00766677856445313e+01,
              5.08000000000000000e+02 5.02239990234375000e+02 9.00766677856445313e+01,
              5.08666656494140625e+02 5.02239990234375000e+02 9.00766677856445313e+01,
              5.09333343505859375e+02 5.02239990234375000e+02 9.00766677856445313e+01,
              5.10133331298828125e+02 5.02100006103515625e+02 9.00766677856445313e+01,
              5.10000000000000000e+02 5.02239990234375000e+02 9.00766677856445313e+01,
              5.10666656494140625e+02 5.02100006103515625e+02 9.06873321533203125e+01,
              5.11333343505859375e+02 5.02100006103515625e+02 9.06873321533203125e+01,
              5.12000000000000000e+02 5.02100006103515625e+02 9.06873321533203125e+01,
              5.12666687011718750e+02 5.02100006103515625e+02 9.06873321533203125e+01,
              5.13333312988281250e+02 5.02100006103515625e+02 9.06873321533203125e+01,
              5.14000000000000000e+02 5.02100006103515625e+02 9.06873321533203125e+01,
              5.15200012207031250e+02 5.02100006103515625e+02 9.00766677856445313e+01,
              5.14666687011718750e+02 5.02100006103515625e+02 9.06873321533203125e+01,
              5.06799987792968750e+02 5.02799987792968750e+02 9.00766677856445313e+01,
              5.06666656494140625e+02 5.02940002441406250e+02 9.00766677856445313e+01,
              5.07333343505859375e+02 5.02799987792968750e+02 9.06873321533203125e+01,
              5.08000000000000000e+02 5.02799987792968750e+02 9.06873321533203125e+01,
              5.08666656494140625e+02 5.02799987792968750e+02 9.06873321533203125e+01,
              5.09333343505859375e+02 5.02799987792968750e+02 9.06873321533203125e+01,
              5.10000000000000000e+02 5.02799987792968750e+02 9.06873321533203125e+01,
              5.10666656494140625e+02 5.02799987792968750e+02 9.06873321533203125e+01,
              5.11333343505859375e+02 5.02799987792968750e+02 9.06873321533203125e+01,
              5.12000000000000000e+02 5.02799987792968750e+02 9.06873321533203125e+01,
              5.12666687011718750e+02 5.02799987792968750e+02 9.06873321533203125e+01,
              5.13333312988281250e+02 5.02799987792968750e+02 9.06873321533203125e+01,
              5.14000000000000000e+02 5.02799987792968750e+02 9.06873321533203125e+01,
              5.15200012207031250e+02 5.02799987792968750e+02 9.00766677856445313e+01,
              5.14666687011718750e+02 5.02799987792968750e+02 9.06873321533203125e+01,
              5.06133331298828125e+02 5.03500000000000000e+02 9.00766677856445313e+01,
              5.06666656494140625e+02 5.03500000000000000e+02 9.06873321533203125e+01,
              5.07333343505859375e+02 5.03500000000000000e+02 9.06873321533203125e+01,
              5.08000000000000000e+02 5.03500000000000000e+02 9.06873321533203125e+01,
              5.08666656494140625e+02 5.03500000000000000e+02 9.06873321533203125e+01,
              5.09333343505859375e+02 5.03500000000000000e+02 9.06873321533203125e+01,
              5.10000000000000000e+02 5.03500000000000000e+02 9.06873321533203125e+01,
              5.10666656494140625e+02 5.03500000000000000e+02 9.05680465698242188e+01,
              5.11333343505859375e+02 5.03500000000000000e+02 9.05680465698242188e+01,
              5.12000000000000000e+02 5.03500000000000000e+02 9.06873321533203125e+01,
              5.12666687011718750e+02 5.03500000000000000e+02 9.06873321533203125e+01,
              5.13333312988281250e+02 5.03500000000000000e+02 9.06873321533203125e+01,
              5.14000000000000000e+02 5.03500000000000000e+02 9.06873321533203125e+01,
              5.15200012207031250e+02 5.03500000000000000e+02 9.00766677856445313e+01,
              5.14666687011718750e+02 5.03500000000000000e+02 9.06873321533203125e+01,
              5.06133331298828125e+02 5.04200012207031250e+02 9.00766677856445313e+01,
              5.06666656494140625e+02 5.04760009765625000e+02 9.00766677856445313e+01,
              5.06666656494140625e+02 5.04200012207031250e+02 9.06873321533203125e+01,
              5.07333343505859375e+02 5.04200012207031250e+02 9.06873321533203125e+01,
              5.08000000000000000e+02 5.04200012207031250e+02 9.06873321533203125e+01,
              5.08666656494140625e+02 5.04200012207031250e+02 9.06873321533203125e+01,
              5.09333343505859375e+02 5.04200012207031250e+02 9.06873321533203125e+01,
              5.10000000000000000e+02 5.04200012207031250e+02 9.06873321533203125e+01,
              5.10666656494140625e+02 5.04200012207031250e+02 9.06033401489257813e+01,
              5.11333343505859375e+02 5.04200012207031250e+02 9.06033401489257813e+01,
              5.12000000000000000e+02 5.04200012207031250e+02 9.06873321533203125e+01,
              5.12666687011718750e+02 5.04200012207031250e+02 9.06873321533203125e+01,
              5.13333312988281250e+02 5.04200012207031250e+02 9.06873321533203125e+01,
              5.14000000000000000e+02 5.04200012207031250e+02 9.06873321533203125e+01,
              5.15200012207031250e+02 5.04200012207031250e+02 9.00766677856445313e+01,
              5.14666687011718750e+02 5.04200012207031250e+02 9.06873321533203125e+01,
              5.06799987792968750e+02 5.04899993896484375e+02 9.00766677856445313e+01,
              5.07333343505859375e+02 5.04899993896484375e+02 9.06873321533203125e+01,
              5.08000000000000000e+02 5.04899993896484375e+02 9.06873321533203125e+01,
              5.08666656494140625e+02 5.04899993896484375e+02 9.06873321533203125e+01,
              5.09333343505859375e+02 5.04899993896484375e+02 9.06873321533203125e+01,
              5.10000000000000000e+02 5.04899993896484375e+02 9.06873321533203125e+01,
              5.10666656494140625e+02 5.04899993896484375e+02 9.06575775146484375e+01,
              5.11333343505859375e+02 5.04899993896484375e+02 9.06575775146484375e+01,
              5.12000000000000000e+02 5.04899993896484375e+02 9.06873321533203125e+01,
              5.12666687011718750e+02 5.04899993896484375e+02 9.06873321533203125e+01,
              5.13333312988281250e+02 5.04899993896484375e+02 9.06873321533203125e+01,
              5.14000000000000000e+02 5.04899993896484375e+02 9.06873321533203125e+01,
              5.15200012207031250e+02 5.04899993896484375e+02 9.00766677856445313e+01,
              5.14666687011718750e+02 5.04899993896484375e+02 9.06873321533203125e+01,
              5.06799987792968750e+02 5.05600006103515625e+02 9.00766677856445313e+01,
              5.07333343505859375e+02 5.06160003662109375e+02 9.00766677856445313e+01,
              5.07333343505859375e+02 5.05600006103515625e+02 9.06873321533203125e+01,
              5.08000000000000000e+02 5.05600006103515625e+02 9.06873321533203125e+01,
              5.08666656494140625e+02 5.05600006103515625e+02 9.06873321533203125e+01,
              5.09333343505859375e+02 5.05600006103515625e+02 9.06873321533203125e+01,
              5.10000000000000000e+02 5.05600006103515625e+02 9.06873321533203125e+01,
              5.10666656494140625e+02 5.05600006103515625e+02 9.06873321533203125e+01,
              5.11333343505859375e+02 5.05600006103515625e+02 9.06873321533203125e+01,
              5.12000000000000000e+02 5.05600006103515625e+02 9.06873321533203125e+01,
              5.12666687011718750e+02 5.05600006103515625e+02 9.06873321533203125e+01,
              5.13333312988281250e+02 5.05600006103515625e+02 9.06873321533203125e+01,
              5.14000000000000000e+02 5.05600006103515625e+02 9.06873321533203125e+01,
              5.15200012207031250e+02 5.05600006103515625e+02 9.00766677856445313e+01,
              5.14666687011718750e+02 5.06160003662109375e+02 9.00766677856445313e+01,
              5.14666687011718750e+02 5.05600006103515625e+02 9.06873321533203125e+01,
              5.07466674804687500e+02 5.06299987792968750e+02 9.00766677856445313e+01,
              5.08000000000000000e+02 5.06859985351562500e+02 9.00766677856445313e+01,
              5.08000000000000000e+02 5.06299987792968750e+02 9.06873321533203125e+01,
              5.08666656494140625e+02 5.06859985351562500e+02 9.00766677856445313e+01,
              5.08666656494140625e+02 5.06299987792968750e+02 9.06873321533203125e+01,
              5.09333343505859375e+02 5.06859985351562500e+02 9.00766677856445313e+01,
              5.09333343505859375e+02 5.06299987792968750e+02 9.06873321533203125e+01,
              5.10000000000000000e+02 5.06859985351562500e+02 9.00766677856445313e+01,
              5.10000000000000000e+02 5.06299987792968750e+02 9.06873321533203125e+01,
              5.10666656494140625e+02 5.06299987792968750e+02 9.06873321533203125e+01,
              5.11333343505859375e+02 5.06299987792968750e+02 9.06873321533203125e+01,
              5.12000000000000000e+02 5.06299987792968750e+02 9.06873321533203125e+01,
              5.12666687011718750e+02 5.06299987792968750e+02 9.06873321533203125e+01,
              5.13333312988281250e+02 5.06299987792968750e+02 9.06873321533203125e+01,
              5.14533325195312500e+02 5.06299987792968750e+02 9.00766677856445313e+01,
              5.14000000000000000e+02 5.06859985351562500e+02 9.00766677856445313e+01,
              5.14000000000000000e+02 5.06299987792968750e+02 9.06873321533203125e+01,
              5.10133331298828125e+02 5.07000000000000000e+02 9.00766677856445313e+01,
              5.10666656494140625e+02 5.07559997558593750e+02 9.00766677856445313e+01,
              5.10666656494140625e+02 5.07000000000000000e+02 9.06873321533203125e+01,
              5.11333343505859375e+02 5.07559997558593750e+02 9.00766677856445313e+01,
              5.11333343505859375e+02 5.07000000000000000e+02 9.06873321533203125e+01,
              5.12000000000000000e+02 5.07559997558593750e+02 9.00766677856445313e+01,
              5.12000000000000000e+02 5.07000000000000000e+02 9.06873321533203125e+01,
              5.12666687011718750e+02 5.07559997558593750e+02 9.00766677856445313e+01,
              5.12666687011718750e+02 5.07000000000000000e+02 9.06873321533203125e+01,
              5.13866638183593750e+02 5.07000000000000000e+02 9.00766677856445313e+01,
              5.13333312988281250e+02 5.07559997558593750e+02 9.00766677856445313e+01,
              5.13333312988281250e+02 5.07000000000000000e+02 9.06873321533203125e+01,
              5.13333312988281250e+02 5.08540008544921875e+02 9.00766677856445313e+01,
              5.12799987792968750e+02 5.09100006103515625e+02 9.00766677856445313e+01,
              5.13866638183593750e+02 5.09100006103515625e+02 9.00766677856445313e+01,
              5.13333312988281250e+02 5.09100006103515625e+02 9.06873321533203125e+01,
              5.14000000000000000e+02 5.09239990234375000e+02 9.00766677856445313e+01,
              5.14666687011718750e+02 5.09239990234375000e+02 9.00766677856445313e+01,
              5.12799987792968750e+02 5.09799987792968750e+02 9.00766677856445313e+01,
              5.13333312988281250e+02 5.09799987792968750e+02 9.06873321533203125e+01,
              5.14000000000000000e+02 5.09799987792968750e+02 9.06873321533203125e+01,
              5.15200012207031250e+02 5.09799987792968750e+02 9.00766677856445313e+01,
              5.14666687011718750e+02 5.09799987792968750e+02 9.06873321533203125e+01,
              5.15333312988281250e+02 5.09940002441406250e+02 9.00766677856445313e+01,
              5.16000000000000000e+02 5.09940002441406250e+02 9.00766677856445313e+01,
              5.12799987792968750e+02 5.10500000000000000e+02 9.00766677856445313e+01,
              5.12666687011718750e+02 5.10640014648437500e+02 9.00766677856445313e+01,
              5.13333312988281250e+02 5.10500000000000000e+02 9.06873321533203125e+01,
              5.14000000000000000e+02 5.10500000000000000e+02 9.06873321533203125e+01,
              5.14666687011718750e+02 5.10500000000000000e+02 9.06873321533203125e+01,
              5.15333312988281250e+02 5.10500000000000000e+02 9.06873321533203125e+01,
              5.16533325195312500e+02 5.10500000000000000e+02 9.00766677856445313e+01,
              5.16000000000000000e+02 5.10500000000000000e+02 9.06873321533203125e+01,
              5.16666687011718750e+02 5.10640014648437500e+02 9.00766677856445313e+01,
              5.12133361816406250e+02 5.11200012207031250e+02 9.00766677856445313e+01,
              5.12666687011718750e+02 5.11200012207031250e+02 9.06873321533203125e+01,
              5.13333312988281250e+02 5.11200012207031250e+02 9.06873321533203125e+01,
              5.14000000000000000e+02 5.11200012207031250e+02 9.06873321533203125e+01,
              5.14666687011718750e+02 5.11200012207031250e+02 9.05538330078125000e+01,
              5.15333312988281250e+02 5.11200012207031250e+02 9.05538330078125000e+01,
              5.16000000000000000e+02 5.11200012207031250e+02 9.06873321533203125e+01,
              5.17200012207031250e+02 5.11200012207031250e+02 9.00766677856445313e+01,
              5.16666687011718750e+02 5.11200012207031250e+02 9.06873321533203125e+01,
              5.17333312988281250e+02 5.11339996337890625e+02 9.00766677856445313e+01,
              5.18000000000000000e+02 5.11339996337890625e+02 9.00766677856445313e+01,
              5.12133361816406250e+02 5.11899993896484375e+02 9.00766677856445313e+01,
              5.12000000000000000e+02 5.12039978027343750e+02 9.00766677856445313e+01,
              5.12666687011718750e+02 5.11899993896484375e+02 9.06873321533203125e+01,
              5.13333312988281250e+02 5.11899993896484375e+02 9.06873321533203125e+01,
              5.14000000000000000e+02 5.11899993896484375e+02 9.06873321533203125e+01,
              5.14666687011718750e+02 5.11899993896484375e+02 9.06048812866210938e+01,
              5.15333312988281250e+02 5.11899993896484375e+02 9.06048812866210938e+01,
              5.16000000000000000e+02 5.11899993896484375e+02 9.06873321533203125e+01,
              5.16666687011718750e+02 5.11899993896484375e+02 9.06873321533203125e+01,
              5.17333312988281250e+02 5.11899993896484375e+02 9.06873321533203125e+01,
              5.18533325195312500e+02 5.11899993896484375e+02 9.00766677856445313e+01,
              5.18000000000000000e+02 5.11899993896484375e+02 9.06873321533203125e+01,
              5.18666687011718750e+02 5.12039978027343750e+02 9.00766677856445313e+01,
              5.11466674804687500e+02 5.12599975585937500e+02 9.00766677856445313e+01,
              5.12000000000000000e+02 5.12599975585937500e+02 9.06873321533203125e+01,
              5.12666687011718750e+02 5.12599975585937500e+02 9.06873321533203125e+01,
              5.13333312988281250e+02 5.12599975585937500e+02 9.06873321533203125e+01,
              5.14000000000000000e+02 5.12599975585937500e+02 9.06873321533203125e+01,
              5.14666687011718750e+02 5.12599975585937500e+02 9.05270004272460938e+01,
              5.15333312988281250e+02 5.12599975585937500e+02 9.05270004272460938e+01,
              5.16000000000000000e+02 5.12599975585937500e+02 9.06873321533203125e+01,
              5.16666687011718750e+02 5.12599975585937500e+02 9.06873321533203125e+01,
              5.17333312988281250e+02 5.12599975585937500e+02 9.06873321533203125e+01,
              5.18000000000000000e+02 5.12599975585937500e+02 9.06873321533203125e+01,
              5.19200012207031250e+02 5.12599975585937500e+02 9.00766677856445313e+01,
              5.18666687011718750e+02 5.12599975585937500e+02 9.06873321533203125e+01,
              5.19333312988281250e+02 5.12739990234375000e+02 9.00766677856445313e+01,
              5.11466674804687500e+02 5.13299987792968750e+02 9.00766677856445313e+01,
              5.11333343505859375e+02 5.13440002441406250e+02 9.00766677856445313e+01,
              5.12000000000000000e+02 5.13299987792968750e+02 9.06873321533203125e+01,
              5.12666687011718750e+02 5.13299987792968750e+02 9.06873321533203125e+01,
              5.13333312988281250e+02 5.13299987792968750e+02 9.06873321533203125e+01,
              5.14000000000000000e+02 5.13299987792968750e+02 9.06873321533203125e+01,
              5.14666687011718750e+02 5.13299987792968750e+02 9.06873321533203125e+01,
              5.15333312988281250e+02 5.13299987792968750e+02 9.06873321533203125e+01,
              5.16000000000000000e+02 5.13299987792968750e+02 9.06873321533203125e+01,
              5.16666687011718750e+02 5.13299987792968750e+02 9.06873321533203125e+01,
              5.17333312988281250e+02 5.13299987792968750e+02 9.06873321533203125e+01,
              5.18000000000000000e+02 5.13299987792968750e+02 9.06873321533203125e+01,
              5.18666687011718750e+02 5.13299987792968750e+02 9.06873321533203125e+01,
              5.19333312988281250e+02 5.13859985351562500e+02 9.00766677856445313e+01,
              5.19333312988281250e+02 5.13299987792968750e+02 9.06873321533203125e+01,
              5.10799987792968750e+02 5.14000000000000000e+02 9.00766677856445313e+01,
              5.11333343505859375e+02 5.14000000000000000e+02 9.06873321533203125e+01,
              5.12000000000000000e+02 5.14000000000000000e+02 9.06873321533203125e+01,
              5.12666687011718750e+02 5.14000000000000000e+02 9.06873321533203125e+01,
              5.13333312988281250e+02 5.14000000000000000e+02 9.06873321533203125e+01,
              5.14000000000000000e+02 5.14000000000000000e+02 9.06873321533203125e+01,
              5.14666687011718750e+02 5.14000000000000000e+02 9.06873321533203125e+01,
              5.15333312988281250e+02 5.14000000000000000e+02 9.06873321533203125e+01,
              5.16000000000000000e+02 5.14000000000000000e+02 9.06873321533203125e+01,
              5.16666687011718750e+02 5.14000000000000000e+02 9.06873321533203125e+01,
              5.17333312988281250e+02 5.14000000000000000e+02 9.06873321533203125e+01,
              5.18000000000000000e+02 5.14559997558593750e+02 9.00766677856445313e+01,
              5.18000000000000000e+02 5.14000000000000000e+02 9.06873321533203125e+01,
              5.19200012207031250e+02 5.14000000000000000e+02 9.00766677856445313e+01,
              5.18666687011718750e+02 5.14559997558593750e+02 9.00766677856445313e+01,
              5.18666687011718750e+02 5.14000000000000000e+02 9.06873321533203125e+01,
              5.10799987792968750e+02 5.14700012207031250e+02 9.00766677856445313e+01,
              5.11333343505859375e+02 5.15260009765625000e+02 9.00766677856445313e+01,
              5.11333343505859375e+02 5.14700012207031250e+02 9.06873321533203125e+01,
              5.12000000000000000e+02 5.14700012207031250e+02 9.06873321533203125e+01,
              5.12666687011718750e+02 5.14700012207031250e+02 9.06873321533203125e+01,
              5.13333312988281250e+02 5.14700012207031250e+02 9.06873321533203125e+01,
              5.14000000000000000e+02 5.14700012207031250e+02 9.06873321533203125e+01,
              5.14666687011718750e+02 5.14700012207031250e+02 9.06873321533203125e+01,
              5.15333312988281250e+02 5.14700012207031250e+02 9.06873321533203125e+01,
              5.16000000000000000e+02 5.14700012207031250e+02 9.06873321533203125e+01,
              5.16666687011718750e+02 5.14700012207031250e+02 9.06873321533203125e+01,
              5.17866638183593750e+02 5.14700012207031250e+02 9.00766677856445313e+01,
              5.17333312988281250e+02 5.15260009765625000e+02 9.00766677856445313e+01,
              5.17333312988281250e+02 5.14700012207031250e+02 9.06873321533203125e+01,
              5.11466674804687500e+02 5.15400024414062500e+02 9.00766677856445313e+01,
              5.12000000000000000e+02 5.15960021972656250e+02 9.00766677856445313e+01,
              5.12000000000000000e+02 5.15400024414062500e+02 9.06873321533203125e+01,
              5.12666687011718750e+02 5.15400024414062500e+02 9.06873321533203125e+01,
              5.13333312988281250e+02 5.15400024414062500e+02 9.06873321533203125e+01,
              5.14000000000000000e+02 5.15400024414062500e+02 9.06873321533203125e+01,
              5.14666687011718750e+02 5.15400024414062500e+02 9.06873321533203125e+01,
              5.15333312988281250e+02 5.15400024414062500e+02 9.06873321533203125e+01,
              5.16000000000000000e+02 5.15400024414062500e+02 9.06873321533203125e+01,
              5.17200012207031250e+02 5.15400024414062500e+02 9.00766677856445313e+01,
              5.16666687011718750e+02 5.15960021972656250e+02 9.00766677856445313e+01,
              5.16666687011718750e+02 5.15400024414062500e+02 9.06873321533203125e+01,
              5.12133361816406250e+02 5.16099975585937500e+02 9.00766677856445313e+01,
              5.12666687011718750e+02 5.16659973144531250e+02 9.00766677856445313e+01,
              5.12666687011718750e+02 5.16099975585937500e+02 9.06873321533203125e+01,
              5.13333312988281250e+02 5.16659973144531250e+02 9.00766677856445313e+01,
              5.13333312988281250e+02 5.16099975585937500e+02 9.06873321533203125e+01,
              5.14000000000000000e+02 5.16659973144531250e+02 9.00766677856445313e+01,
              5.14000000000000000e+02 5.16099975585937500e+02 9.06873321533203125e+01,
              5.14666687011718750e+02 5.16659973144531250e+02 9.00766677856445313e+01,
              5.14666687011718750e+02 5.16099975585937500e+02 9.06873321533203125e+01,
              5.15333312988281250e+02 5.16659973144531250e+02 9.00766677856445313e+01,
              5.15333312988281250e+02 5.16099975585937500e+02 9.06873321533203125e+01,
              5.16533325195312500e+02 5.16099975585937500e+02 9.00766677856445313e+01,
              5.16000000000000000e+02 5.16659973144531250e+02 9.00766677856445313e+01,
              5.16000000000000000e+02 5.16099975585937500e+02 9.06873321533203125e+01,
              5.08000000000000000e+02 5.07000000000000000e+02 9.17559967041015625e+01,
              5.08666656494140625e+02 5.07000000000000000e+02 9.17559967041015625e+01,
              5.09333343505859375e+02 5.07000000000000000e+02 9.17559967041015625e+01,
              5.10000000000000000e+02 5.07000000000000000e+02 9.17559967041015625e+01,
              5.08000000000000000e+02 5.07700012207031250e+02 9.17559967041015625e+01,
              5.08666656494140625e+02 5.07700012207031250e+02 9.17559967041015625e+01,
              5.09333343505859375e+02 5.07700012207031250e+02 9.17559967041015625e+01,
              5.10000000000000000e+02 5.07700012207031250e+02 9.17559967041015625e+01,
              5.10666656494140625e+02 5.07700012207031250e+02 9.17559967041015625e+01,
              5.11333343505859375e+02 5.07700012207031250e+02 9.17559967041015625e+01,
              5.12000000000000000e+02 5.07700012207031250e+02 9.17559967041015625e+01,
              5.12666687011718750e+02 5.07700012207031250e+02 9.17559967041015625e+01,
              5.07333343505859375e+02 5.08399993896484375e+02 9.17559967041015625e+01,
              5.08000000000000000e+02 5.08399993896484375e+02 9.17559967041015625e+01,
              5.08666656494140625e+02 5.08399993896484375e+02 9.17559967041015625e+01,
              5.09333343505859375e+02 5.08399993896484375e+02 9.17559967041015625e+01,
              5.10000000000000000e+02 5.08399993896484375e+02 9.17559967041015625e+01,
              5.10666656494140625e+02 5.08399993896484375e+02 9.17559967041015625e+01,
              5.11333343505859375e+02 5.08399993896484375e+02 9.17559967041015625e+01,
              5.12000000000000000e+02 5.08399993896484375e+02 9.17559967041015625e+01,
              5.12666687011718750e+02 5.08399993896484375e+02 9.17559967041015625e+01,
              5.13333312988281250e+02 5.08399993896484375e+02 9.17559967041015625e+01,
              5.07333343505859375e+02 5.09100006103515625e+02 9.17559967041015625e+01,
              5.08000000000000000e+02 5.09100006103515625e+02 9.17559967041015625e+01,
              5.08666656494140625e+02 5.09100006103515625e+02 9.17559967041015625e+01,
              5.09333343505859375e+02 5.09100006103515625e+02 9.17559967041015625e+01,
              5.10000000000000000e+02 5.09100006103515625e+02 9.17734909057617188e+01,
              5.10666656494140625e+02 5.09100006103515625e+02 9.17559967041015625e+01,
              5.11333343505859375e+02 5.09100006103515625e+02 9.17559967041015625e+01,
              5.12000000000000000e+02 5.09100006103515625e+02 9.17559967041015625e+01,
              5.12666687011718750e+02 5.09100006103515625e+02 9.17559967041015625e+01,
              5.07333343505859375e+02 5.09799987792968750e+02 9.17559967041015625e+01,
              5.08000000000000000e+02 5.09799987792968750e+02 9.17559967041015625e+01,
              5.08666656494140625e+02 5.09799987792968750e+02 9.17559967041015625e+01,
              5.09333343505859375e+02 5.09799987792968750e+02 9.16635513305664063e+01,
              5.10000000000000000e+02 5.09799987792968750e+02 9.17265014648437500e+01,
              5.10666656494140625e+02 5.09799987792968750e+02 9.16635513305664063e+01,
              5.11333343505859375e+02 5.09799987792968750e+02 9.17559967041015625e+01,
              5.12000000000000000e+02 5.09799987792968750e+02 9.17559967041015625e+01,
              5.12666687011718750e+02 5.09799987792968750e+02 9.17559967041015625e+01,
              5.06666656494140625e+02 5.10500000000000000e+02 9.17559967041015625e+01,
              5.07333343505859375e+02 5.10500000000000000e+02 9.17559967041015625e+01,
              5.08000000000000000e+02 5.10500000000000000e+02 9.17559967041015625e+01,
              5.08666656494140625e+02 5.10500000000000000e+02 9.17559967041015625e+01,
              5.09333343505859375e+02 5.10500000000000000e+02 9.17280883789062500e+01,
              5.10000000000000000e+02 5.10500000000000000e+02 9.16721878051757813e+01,
              5.10666656494140625e+02 5.10500000000000000e+02 9.17280883789062500e+01,
              5.11333343505859375e+02 5.10500000000000000e+02 9.17559967041015625e+01,
              5.12000000000000000e+02 5.10500000000000000e+02 9.17559967041015625e+01,
              5.12666687011718750e+02 5.10500000000000000e+02 9.17559967041015625e+01,
              5.06666656494140625e+02 5.11200012207031250e+02 9.17559967041015625e+01,
              5.07333343505859375e+02 5.11200012207031250e+02 9.17559967041015625e+01,
              5.08000000000000000e+02 5.11200012207031250e+02 9.17559967041015625e+01,
              5.08666656494140625e+02 5.11200012207031250e+02 9.17559967041015625e+01,
              5.09333343505859375e+02 5.11200012207031250e+02 9.17559967041015625e+01,
              5.10000000000000000e+02 5.11200012207031250e+02 9.17559967041015625e+01,
              5.10666656494140625e+02 5.11200012207031250e+02 9.17559967041015625e+01,
              5.11333343505859375e+02 5.11200012207031250e+02 9.17559967041015625e+01,
              5.12000000000000000e+02 5.11200012207031250e+02 9.17559967041015625e+01,
              5.06666656494140625e+02 5.11899993896484375e+02 9.17559967041015625e+01,
              5.07333343505859375e+02 5.11899993896484375e+02 9.17559967041015625e+01,
              5.08000000000000000e+02 5.11899993896484375e+02 9.17559967041015625e+01,
              5.08666656494140625e+02 5.11899993896484375e+02 9.17559967041015625e+01,
              5.09333343505859375e+02 5.11899993896484375e+02 9.17559967041015625e+01,
              5.10000000000000000e+02 5.11899993896484375e+02 9.17559967041015625e+01,
              5.10666656494140625e+02 5.11899993896484375e+02 9.17559967041015625e+01,
              5.11333343505859375e+02 5.11899993896484375e+02 9.17559967041015625e+01,
              5.12000000000000000e+02 5.11899993896484375e+02 9.17559967041015625e+01,
              5.06666656494140625e+02 5.12599975585937500e+02 9.17559967041015625e+01,
              5.07333343505859375e+02 5.12599975585937500e+02 9.17559967041015625e+01,
              5.08000000000000000e+02 5.12599975585937500e+02 9.17559967041015625e+01,
              5.08666656494140625e+02 5.12599975585937500e+02 9.17559967041015625e+01,
              5.09333343505859375e+02 5.12599975585937500e+02 9.17559967041015625e+01,
              5.10000000000000000e+02 5.12599975585937500e+02 9.17559967041015625e+01,
              5.10666656494140625e+02 5.12599975585937500e+02 9.17559967041015625e+01,
              5.11333343505859375e+02 5.12599975585937500e+02 9.17559967041015625e+01,
              5.08666656494140625e+02 5.13299987792968750e+02 9.17559967041015625e+01,
              5.09333343505859375e+02 5.13299987792968750e+02 9.17559967041015625e+01,
              5.10000000000000000e+02 5.13299987792968750e+02 9.17559967041015625e+01,
              5.10666656494140625e+02 5.13299987792968750e+02 9.17559967041015625e+01,
              5.11333343505859375e+02 5.13299987792968750e+02 9.17559967041015625e+01,
              5.10000000000000000e+02 5.14000000000000000e+02 9.17559967041015625e+01,
              5.10666656494140625e+02 5.14000000000000000e+02 9.17559967041015625e+01,
              5.08000000000000000e+02 5.06440002441406250e+02 9.23666687011718750e+01,
              5.08666656494140625e+02 5.06440002441406250e+02 9.23666687011718750e+01,
              5.09333343505859375e+02 5.06440002441406250e+02 9.23666687011718750e+01,
              5.10000000000000000e+02 5.06440002441406250e+02 9.23666687011718750e+01,
              5.07466674804687500e+02 5.07000000000000000e+02 9.23666687011718750e+01,
              5.08000000000000000e+02 5.07000000000000000e+02 9.29773330688476563e+01,
              5.08666656494140625e+02 5.07000000000000000e+02 9.29773330688476563e+01,
              5.09333343505859375e+02 5.07000000000000000e+02 9.29773330688476563e+01,
              5.10533325195312500e+02 5.07000000000000000e+02 9.23666687011718750e+01,
              5.10000000000000000e+02 5.07000000000000000e+02 9.29773330688476563e+01,
              5.10666656494140625e+02 5.07140014648437500e+02 9.23666687011718750e+01,
              5.11333343505859375e+02 5.07140014648437500e+02 9.23666687011718750e+01,
              5.12000000000000000e+02 5.07140014648437500e+02 9.23666687011718750e+01,
              5.12666687011718750e+02 5.07140014648437500e+02 9.23666687011718750e+01,
              5.07466674804687500e+02 5.07700012207031250e+02 9.23666687011718750e+01,
              5.07333343505859375e+02 5.07839996337890625e+02 9.23666687011718750e+01,
              5.08000000000000000e+02 5.07700012207031250e+02 9.29773330688476563e+01,
              5.08666656494140625e+02 5.07700012207031250e+02 9.29773330688476563e+01,
              5.09333343505859375e+02 5.07700012207031250e+02 9.29773330688476563e+01,
              5.10000000000000000e+02 5.07700012207031250e+02 9.29773330688476563e+01,
              5.10666656494140625e+02 5.07700012207031250e+02 9.29773330688476563e+01,
              5.11333343505859375e+02 5.07700012207031250e+02 9.29773330688476563e+01,
              5.12000000000000000e+02 5.07700012207031250e+02 9.29773330688476563e+01,
              5.13200012207031250e+02 5.07700012207031250e+02 9.23666687011718750e+01,
              5.12666687011718750e+02 5.07700012207031250e+02 9.29773330688476563e+01,
              5.13333312988281250e+02 5.07839996337890625e+02 9.23666687011718750e+01,
              5.06799987792968750e+02 5.08399993896484375e+02 9.23666687011718750e+01,
              5.07333343505859375e+02 5.08399993896484375e+02 9.29773330688476563e+01,
              5.08000000000000000e+02 5.08399993896484375e+02 9.29773330688476563e+01,
              5.08666656494140625e+02 5.08399993896484375e+02 9.29773330688476563e+01,
              5.09333343505859375e+02 5.08399993896484375e+02 9.29773330688476563e+01,
              5.10000000000000000e+02 5.08399993896484375e+02 9.29773330688476563e+01,
              5.10666656494140625e+02 5.08399993896484375e+02 9.29773330688476563e+01,
              5.11333343505859375e+02 5.08399993896484375e+02 9.29773330688476563e+01,
              5.12000000000000000e+02 5.08399993896484375e+02 9.29773330688476563e+01,
              5.12666687011718750e+02 5.08399993896484375e+02 9.29773330688476563e+01,
              5.13866638183593750e+02 5.08399993896484375e+02 9.23666687011718750e+01,
              5.13333312988281250e+02 5.08959991455078125e+02 9.23666687011718750e+01,
              5.13333312988281250e+02 5.08399993896484375e+02 9.29773330688476563e+01,
              5.06799987792968750e+02 5.09100006103515625e+02 9.23666687011718750e+01,
              5.07333343505859375e+02 5.09100006103515625e+02 9.29773330688476563e+01,
              5.08000000000000000e+02 5.09100006103515625e+02 9.29773330688476563e+01,
              5.08666656494140625e+02 5.09100006103515625e+02 9.29773330688476563e+01,
              5.09333343505859375e+02 5.09100006103515625e+02 9.29773330688476563e+01,
              5.10666656494140625e+02 5.09100006103515625e+02 9.29773330688476563e+01,
              5.11333343505859375e+02 5.09100006103515625e+02 9.29773330688476563e+01,
              5.12000000000000000e+02 5.09100006103515625e+02 9.29773330688476563e+01,
              5.13200012207031250e+02 5.09100006103515625e+02 9.23666687011718750e+01,
              5.12666687011718750e+02 5.09100006103515625e+02 9.29773330688476563e+01,
              5.06799987792968750e+02 5.09799987792968750e+02 9.23666687011718750e+01,
              5.06666656494140625e+02 5.09940002441406250e+02 9.23666687011718750e+01,
              5.07333343505859375e+02 5.09799987792968750e+02 9.29773330688476563e+01,
              5.08000000000000000e+02 5.09799987792968750e+02 9.29773330688476563e+01,
              5.08666656494140625e+02 5.09799987792968750e+02 9.29773330688476563e+01,
              5.11333343505859375e+02 5.09799987792968750e+02 9.29773330688476563e+01,
              5.12000000000000000e+02 5.09799987792968750e+02 9.29773330688476563e+01,
              5.13200012207031250e+02 5.09799987792968750e+02 9.23666687011718750e+01,
              5.12666687011718750e+02 5.09799987792968750e+02 9.29773330688476563e+01,
              5.06133331298828125e+02 5.10500000000000000e+02 9.23666687011718750e+01,
              5.06666656494140625e+02 5.10500000000000000e+02 9.29773330688476563e+01,
              5.07333343505859375e+02 5.10500000000000000e+02 9.29773330688476563e+01,
              5.08000000000000000e+02 5.10500000000000000e+02 9.29773330688476563e+01,
              5.08666656494140625e+02 5.10500000000000000e+02 9.29773330688476563e+01,
              5.11333343505859375e+02 5.10500000000000000e+02 9.29773330688476563e+01,
              5.12000000000000000e+02 5.10500000000000000e+02 9.29773330688476563e+01,
              5.13200012207031250e+02 5.10500000000000000e+02 9.23666687011718750e+01,
              5.12666687011718750e+02 5.11059997558593750e+02 9.23666687011718750e+01,
              5.12666687011718750e+02 5.10500000000000000e+02 9.29773330688476563e+01,
              5.06133331298828125e+02 5.11200012207031250e+02 9.23666687011718750e+01,
              5.06666656494140625e+02 5.11200012207031250e+02 9.29773330688476563e+01,
              5.07333343505859375e+02 5.11200012207031250e+02 9.29773330688476563e+01,
              5.08000000000000000e+02 5.11200012207031250e+02 9.29773330688476563e+01,
              5.08666656494140625e+02 5.11200012207031250e+02 9.29773330688476563e+01,
              5.09333343505859375e+02 5.11200012207031250e+02 9.29773330688476563e+01,
              5.10000000000000000e+02 5.11200012207031250e+02 9.29773330688476563e+01,
              5.10666656494140625e+02 5.11200012207031250e+02 9.29773330688476563e+01,
              5.11333343505859375e+02 5.11200012207031250e+02 9.29773330688476563e+01,
              5.12533325195312500e+02 5.11200012207031250e+02 9.23666687011718750e+01,
              5.12000000000000000e+02 5.11200012207031250e+02 9.29773330688476563e+01,
              5.06133331298828125e+02 5.11899993896484375e+02 9.23666687011718750e+01,
              5.06666656494140625e+02 5.11899993896484375e+02 9.29773330688476563e+01,
              5.07333343505859375e+02 5.11899993896484375e+02 9.29773330688476563e+01,
              5.08000000000000000e+02 5.11899993896484375e+02 9.29773330688476563e+01,
              5.08666656494140625e+02 5.11899993896484375e+02 9.29773330688476563e+01,
              5.09333343505859375e+02 5.11899993896484375e+02 9.29773330688476563e+01,
              5.10000000000000000e+02 5.11899993896484375e+02 9.29773330688476563e+01,
              5.10666656494140625e+02 5.11899993896484375e+02 9.29773330688476563e+01,
              5.11333343505859375e+02 5.11899993896484375e+02 9.29773330688476563e+01,
              5.12533325195312500e+02 5.11899993896484375e+02 9.23666687011718750e+01,
              5.12000000000000000e+02 5.12460021972656250e+02 9.23666687011718750e+01,
              5.12000000000000000e+02 5.11899993896484375e+02 9.29773330688476563e+01,
              5.06133331298828125e+02 5.12599975585937500e+02 9.23666687011718750e+01,
              5.06666656494140625e+02 5.13159973144531250e+02 9.23666687011718750e+01,
              5.06666656494140625e+02 5.12599975585937500e+02 9.29773330688476563e+01,
              5.07333343505859375e+02 5.13159973144531250e+02 9.23666687011718750e+01,
              5.07333343505859375e+02 5.12599975585937500e+02 9.29773330688476563e+01,
              5.08000000000000000e+02 5.13159973144531250e+02 9.23666687011718750e+01,
              5.08000000000000000e+02 5.12599975585937500e+02 9.29773330688476563e+01,
              5.08666656494140625e+02 5.12599975585937500e+02 9.29773330688476563e+01,
              5.09333343505859375e+02 5.12599975585937500e+02 9.29773330688476563e+01,
              5.10000000000000000e+02 5.12599975585937500e+02 9.29773330688476563e+01,
              5.10666656494140625e+02 5.12599975585937500e+02 9.29773330688476563e+01,
              5.11866668701171875e+02 5.12599975585937500e+02 9.23666687011718750e+01,
              5.11333343505859375e+02 5.12599975585937500e+02 9.29773330688476563e+01,
              5.08133331298828125e+02 5.13299987792968750e+02 9.23666687011718750e+01,
              5.08666656494140625e+02 5.13859985351562500e+02 9.23666687011718750e+01,
              5.08666656494140625e+02 5.13299987792968750e+02 9.29773330688476563e+01,
              5.09333343505859375e+02 5.13859985351562500e+02 9.23666687011718750e+01,
              5.09333343505859375e+02 5.13299987792968750e+02 9.29773330688476563e+01,
              5.10000000000000000e+02 5.13299987792968750e+02 9.29773330688476563e+01,
              5.10666656494140625e+02 5.13299987792968750e+02 9.29773330688476563e+01,
              5.11866668701171875e+02 5.13299987792968750e+02 9.23666687011718750e+01,
              5.11333343505859375e+02 5.13859985351562500e+02 9.23666687011718750e+01,
              5.11333343505859375e+02 5.13299987792968750e+02 9.29773330688476563e+01,
              5.09466674804687500e+02 5.14000000000000000e+02 9.23666687011718750e+01,
              5.10000000000000000e+02 5.14559997558593750e+02 9.23666687011718750e+01,
              5.10000000000000000e+02 5.14000000000000000e+02 9.29773330688476563e+01,
              5.11200012207031250e+02 5.14000000000000000e+02 9.23666687011718750e+01,
              5.10666656494140625e+02 5.14559997558593750e+02 9.23666687011718750e+01,
              5.10666656494140625e+02 5.14000000000000000e+02 9.29773330688476563e+01,
              5.10000000000000000e+02 5.08723297119140625e+02 9.31299972534179688e+01,
              5.09641235351562500e+02 5.09100006103515625e+02 9.31299972534179688e+01,
              5.09333343505859375e+02 5.09434722900390625e+02 9.31299972534179688e+01,
              5.10358764648437500e+02 5.09100006103515625e+02 9.31299972534179688e+01,
              5.10000000000000000e+02 5.09100006103515625e+02 9.35407943725585938e+01,
              5.10666656494140625e+02 5.09434722900390625e+02 9.31299972534179688e+01,
              5.08985443115234375e+02 5.09799987792968750e+02 9.31299972534179688e+01,
              5.09333343505859375e+02 5.09799987792968750e+02 9.35283279418945313e+01,
              5.10000000000000000e+02 5.09799987792968750e+02 9.34749679565429688e+01,
              5.11014556884765625e+02 5.09799987792968750e+02 9.31299972534179688e+01,
              5.10666656494140625e+02 5.09799987792968750e+02 9.35283279418945313e+01,
              5.08970123291015625e+02 5.10500000000000000e+02 9.31299972534179688e+01,
              5.09333343505859375e+02 5.10881347656250000e+02 9.31299972534179688e+01,
              5.09333343505859375e+02 5.10500000000000000e+02 9.35458602905273438e+01,
              5.10000000000000000e+02 5.10875030517578125e+02 9.31299972534179688e+01,
              5.10000000000000000e+02 5.10500000000000000e+02 9.35389709472656250e+01,
              5.11029876708984375e+02 5.10500000000000000e+02 9.31299972534179688e+01,
              5.10666656494140625e+02 5.10881347656250000e+02 9.31299972534179688e+01,
              5.10666656494140625e+02 5.10500000000000000e+02 9.35458602905273438e+01,
              5.14666687011718750e+02 5.11200012207031250e+02 9.36511993408203125e+01,
              5.15333312988281250e+02 5.11200012207031250e+02 9.36511993408203125e+01,
              5.14666687011718750e+02 5.11899993896484375e+02 9.36509475708007813e+01,
              5.15333312988281250e+02 5.11899993896484375e+02 9.36509475708007813e+01,
              5.14666687011718750e+02 5.12599975585937500e+02 9.35456848144531250e+01,
              5.15333312988281250e+02 5.12599975585937500e+02 9.35456848144531250e+01,
              5.14666687011718750e+02 5.10977966308593750e+02 9.38933334350585938e+01,
              5.15333312988281250e+02 5.10977966308593750e+02 9.38933334350585938e+01,
              5.14455200195312500e+02 5.11200012207031250e+02 9.38933334350585938e+01,
              5.15544799804687500e+02 5.11200012207031250e+02 9.38933334350585938e+01,
              5.14454956054687500e+02 5.11899993896484375e+02 9.38933334350585938e+01,
              5.15545043945312500e+02 5.11899993896484375e+02 9.38933334350585938e+01,
              5.14363037109375000e+02 5.12599975585937500e+02 9.38933334350585938e+01,
              5.14666687011718750e+02 5.12918823242187500e+02 9.38933334350585938e+01,
              5.15636962890625000e+02 5.12599975585937500e+02 9.38933334350585938e+01,
              5.15333312988281250e+02 5.12918823242187500e+02 9.38933334350585938e+01,
              5.05333343505859375e+02 5.02799987792968750e+02 9.48093338012695313e+01,
              5.04000000000000000e+02 5.03500000000000000e+02 9.48093338012695313e+01,
              5.04666656494140625e+02 5.03500000000000000e+02 9.48093338012695313e+01,
              5.05333343505859375e+02 5.03500000000000000e+02 9.48093338012695313e+01,
              5.06000000000000000e+02 5.03500000000000000e+02 9.48093338012695313e+01,
              5.02666656494140625e+02 5.04200012207031250e+02 9.48093338012695313e+01,
              5.03333343505859375e+02 5.04200012207031250e+02 9.48093338012695313e+01,
              5.04000000000000000e+02 5.04200012207031250e+02 9.48093338012695313e+01,
              5.04666656494140625e+02 5.04200012207031250e+02 9.48093338012695313e+01,
              5.05333343505859375e+02 5.04200012207031250e+02 9.48093338012695313e+01,
              5.06000000000000000e+02 5.04200012207031250e+02 9.48093338012695313e+01,
              5.01333343505859375e+02 5.04899993896484375e+02 9.48093338012695313e+01,
              5.02000000000000000e+02 5.04899993896484375e+02 9.48093338012695313e+01,
              5.02666656494140625e+02 5.04899993896484375e+02 9.48093338012695313e+01,
              5.03333343505859375e+02 5.04899993896484375e+02 9.48093338012695313e+01,
              5.04000000000000000e+02 5.04899993896484375e+02 9.48093338012695313e+01,
              5.04666656494140625e+02 5.04899993896484375e+02 9.48093338012695313e+01,
              5.05333343505859375e+02 5.04899993896484375e+02 9.48093338012695313e+01,
              5.06000000000000000e+02 5.04899993896484375e+02 9.48093338012695313e+01,
              5.06666656494140625e+02 5.04899993896484375e+02 9.48093338012695313e+01,
              5.00666656494140625e+02 5.05600006103515625e+02 9.48093338012695313e+01,
              5.01333343505859375e+02 5.05600006103515625e+02 9.48093338012695313e+01,
              5.02000000000000000e+02 5.05600006103515625e+02 9.48093338012695313e+01,
              5.02666656494140625e+02 5.05600006103515625e+02 9.48093338012695313e+01,
              5.03333343505859375e+02 5.05600006103515625e+02 9.48093338012695313e+01,
              5.04000000000000000e+02 5.05600006103515625e+02 9.48093338012695313e+01,
              5.04666656494140625e+02 5.05600006103515625e+02 9.48093338012695313e+01,
              5.05333343505859375e+02 5.05600006103515625e+02 9.48093338012695313e+01,
              5.06000000000000000e+02 5.05600006103515625e+02 9.48093338012695313e+01,
              5.06666656494140625e+02 5.05600006103515625e+02 9.48093338012695313e+01,
              5.00000000000000000e+02 5.06299987792968750e+02 9.48093338012695313e+01,
              5.00666656494140625e+02 5.06299987792968750e+02 9.48093338012695313e+01,
              5.01333343505859375e+02 5.06299987792968750e+02 9.48093338012695313e+01,
              5.02000000000000000e+02 5.06299987792968750e+02 9.48093338012695313e+01,
              5.02666656494140625e+02 5.06299987792968750e+02 9.48093338012695313e+01,
              5.03333343505859375e+02 5.06299987792968750e+02 9.48093338012695313e+01,
              5.04000000000000000e+02 5.06299987792968750e+02 9.48093338012695313e+01,
              5.04666656494140625e+02 5.06299987792968750e+02 9.48093338012695313e+01,
              5.05333343505859375e+02 5.06299987792968750e+02 9.48093338012695313e+01,
              5.06000000000000000e+02 5.06299987792968750e+02 9.48093338012695313e+01,
              5.06666656494140625e+02 5.06299987792968750e+02 9.48093338012695313e+01,
              5.07333343505859375e+02 5.06299987792968750e+02 9.48093338012695313e+01,
              5.00666656494140625e+02 5.07000000000000000e+02 9.48093338012695313e+01,
              5.01333343505859375e+02 5.07000000000000000e+02 9.48093338012695313e+01,
              5.02000000000000000e+02 5.07000000000000000e+02 9.48093338012695313e+01,
              5.02666656494140625e+02 5.07000000000000000e+02 9.48093338012695313e+01,
              5.03333343505859375e+02 5.07000000000000000e+02 9.48093338012695313e+01,
              5.04000000000000000e+02 5.07000000000000000e+02 9.48093338012695313e+01,
              5.04666656494140625e+02 5.07000000000000000e+02 9.48093338012695313e+01,
              5.05333343505859375e+02 5.07000000000000000e+02 9.48093338012695313e+01,
              5.06000000000000000e+02 5.07000000000000000e+02 9.48093338012695313e+01,
              5.06666656494140625e+02 5.07000000000000000e+02 9.48093338012695313e+01,
              5.07333343505859375e+02 5.07000000000000000e+02 9.48093338012695313e+01,
              5.01333343505859375e+02 5.07700012207031250e+02 9.48093338012695313e+01,
              5.02000000000000000e+02 5.07700012207031250e+02 9.48093338012695313e+01,
              5.02666656494140625e+02 5.07700012207031250e+02 9.48093338012695313e+01,
              5.03333343505859375e+02 5.07700012207031250e+02 9.48427810668945313e+01,
              5.04000000000000000e+02 5.07700012207031250e+02 9.48180770874023438e+01,
              5.04666656494140625e+02 5.07700012207031250e+02 9.48427810668945313e+01,
              5.05333343505859375e+02 5.07700012207031250e+02 9.48093338012695313e+01,
              5.06000000000000000e+02 5.07700012207031250e+02 9.48093338012695313e+01,
              5.06666656494140625e+02 5.07700012207031250e+02 9.48093338012695313e+01,
              5.07333343505859375e+02 5.07700012207031250e+02 9.48093338012695313e+01,
              5.02000000000000000e+02 5.08399993896484375e+02 9.48093338012695313e+01,
              5.02666656494140625e+02 5.08399993896484375e+02 9.48093338012695313e+01,
              5.03333343505859375e+02 5.08399993896484375e+02 9.48937759399414063e+01,
              5.04000000000000000e+02 5.08399993896484375e+02 9.48197860717773438e+01,
              5.04666656494140625e+02 5.08399993896484375e+02 9.48937759399414063e+01,
              5.05333343505859375e+02 5.08399993896484375e+02 9.48093338012695313e+01,
              5.06000000000000000e+02 5.08399993896484375e+02 9.48093338012695313e+01,
              5.06666656494140625e+02 5.08399993896484375e+02 9.48093338012695313e+01,
              5.02666656494140625e+02 5.09100006103515625e+02 9.48093338012695313e+01,
              5.03333343505859375e+02 5.09100006103515625e+02 9.48093338012695313e+01,
              5.04000000000000000e+02 5.09100006103515625e+02 9.48093338012695313e+01,
              5.04666656494140625e+02 5.09100006103515625e+02 9.48093338012695313e+01,
              5.05333343505859375e+02 5.09100006103515625e+02 9.48093338012695313e+01,
              5.06000000000000000e+02 5.09100006103515625e+02 9.48093338012695313e+01,
              5.06666656494140625e+02 5.09100006103515625e+02 9.48093338012695313e+01,
              5.03333343505859375e+02 5.09799987792968750e+02 9.48093338012695313e+01,
              5.04000000000000000e+02 5.09799987792968750e+02 9.48093338012695313e+01,
              5.04666656494140625e+02 5.09799987792968750e+02 9.48093338012695313e+01,
              5.05333343505859375e+02 5.09799987792968750e+02 9.48093338012695313e+01,
              5.06000000000000000e+02 5.09799987792968750e+02 9.48093338012695313e+01,
              5.06666656494140625e+02 5.09799987792968750e+02 9.48093338012695313e+01,
              5.04000000000000000e+02 5.10500000000000000e+02 9.48093338012695313e+01,
              5.04666656494140625e+02 5.10500000000000000e+02 9.48093338012695313e+01,
              5.05333343505859375e+02 5.10500000000000000e+02 9.48093338012695313e+01,
              5.06000000000000000e+02 5.10500000000000000e+02 9.48093338012695313e+01,
              5.14666687011718750e+02 5.10781036376953125e+02 9.46566696166992188e+01,
              5.15333312988281250e+02 5.10781036376953125e+02 9.46566696166992188e+01,
              5.04666656494140625e+02 5.11200012207031250e+02 9.48093338012695313e+01,
              5.05333343505859375e+02 5.11200012207031250e+02 9.48093338012695313e+01,
              5.06000000000000000e+02 5.11200012207031250e+02 9.48093338012695313e+01,
              5.14267639160156250e+02 5.11200012207031250e+02 9.46566696166992188e+01,
              5.14666687011718750e+02 5.11200012207031250e+02 9.51135406494140625e+01,
              5.15732360839843750e+02 5.11200012207031250e+02 9.46566696166992188e+01,
              5.15333312988281250e+02 5.11200012207031250e+02 9.51135406494140625e+01,
              5.05333343505859375e+02 5.11899993896484375e+02 9.48093338012695313e+01,
              5.06000000000000000e+02 5.11899993896484375e+02 9.48093338012695313e+01,
              5.14310791015625000e+02 5.11899993896484375e+02 9.46566696166992188e+01,
              5.14666687011718750e+02 5.11899993896484375e+02 9.50977859497070313e+01,
              5.15689208984375000e+02 5.11899993896484375e+02 9.46566696166992188e+01,
              5.15333312988281250e+02 5.11899993896484375e+02 9.50977859497070313e+01,
              5.14296936035156250e+02 5.12599975585937500e+02 9.46566696166992188e+01,
              5.14666687011718750e+02 5.12988220214843750e+02 9.46566696166992188e+01,
              5.14666687011718750e+02 5.12599975585937500e+02 9.50800399780273438e+01,
              5.15703063964843750e+02 5.12599975585937500e+02 9.46566696166992188e+01,
              5.15333312988281250e+02 5.12988220214843750e+02 9.46566696166992188e+01,
              5.15333312988281250e+02 5.12599975585937500e+02 9.50800399780273438e+01,
              5.05333343505859375e+02 5.02239990234375000e+02 9.54199981689453125e+01,
              5.04000000000000000e+02 5.02940002441406250e+02 9.54199981689453125e+01,
              5.04799987792968750e+02 5.02799987792968750e+02 9.54199981689453125e+01,
              5.04666656494140625e+02 5.02940002441406250e+02 9.54199981689453125e+01,
              5.05866668701171875e+02 5.02799987792968750e+02 9.54199981689453125e+01,
              5.06000000000000000e+02 5.02940002441406250e+02 9.54199981689453125e+01,
              5.02666656494140625e+02 5.03640014648437500e+02 9.54199981689453125e+01,
              5.03466674804687500e+02 5.03500000000000000e+02 9.54199981689453125e+01,
              5.03333343505859375e+02 5.03640014648437500e+02 9.54199981689453125e+01,
              5.06533325195312500e+02 5.03500000000000000e+02 9.54199981689453125e+01,
              5.01333343505859375e+02 5.04339996337890625e+02 9.54199981689453125e+01,
              5.02133331298828125e+02 5.04200012207031250e+02 9.54199981689453125e+01,
              5.02000000000000000e+02 5.04339996337890625e+02 9.54199981689453125e+01,
              5.06533325195312500e+02 5.04200012207031250e+02 9.54199981689453125e+01,
              5.06666656494140625e+02 5.04339996337890625e+02 9.54199981689453125e+01,
              5.00799987792968750e+02 5.04899993896484375e+02 9.54199981689453125e+01,
              5.00666656494140625e+02 5.05040008544921875e+02 9.54199981689453125e+01,
              5.07200012207031250e+02 5.04899993896484375e+02 9.54199981689453125e+01,
              5.00133331298828125e+02 5.05600006103515625e+02 9.54199981689453125e+01,
              5.00000000000000000e+02 5.05739990234375000e+02 9.54199981689453125e+01,
              5.07200012207031250e+02 5.05600006103515625e+02 9.54199981689453125e+01,
              5.07333343505859375e+02 5.05739990234375000e+02 9.54199981689453125e+01,
              5.00000000000000000e+02 5.06859985351562500e+02 9.54199981689453125e+01,
              5.07866668701171875e+02 5.06299987792968750e+02 9.54199981689453125e+01,
              5.00133331298828125e+02 5.07000000000000000e+02 9.54199981689453125e+01,
              5.00666656494140625e+02 5.07559997558593750e+02 9.54199981689453125e+01,
              5.07866668701171875e+02 5.07000000000000000e+02 9.54199981689453125e+01,
              5.08000000000000000e+02 5.07000000000000000e+02 9.55726699829101563e+01,
              5.08666656494140625e+02 5.07000000000000000e+02 9.55726699829101563e+01,
              5.09333343505859375e+02 5.07000000000000000e+02 9.55726699829101563e+01,
              5.10000000000000000e+02 5.07000000000000000e+02 9.55726699829101563e+01,
              5.00799987792968750e+02 5.07700012207031250e+02 9.54199981689453125e+01,
              5.01333343505859375e+02 5.08260009765625000e+02 9.54199981689453125e+01,
              5.07866668701171875e+02 5.07700012207031250e+02 9.54199981689453125e+01,
              5.07333343505859375e+02 5.08260009765625000e+02 9.54199981689453125e+01,
              5.08000000000000000e+02 5.07700012207031250e+02 9.55726699829101563e+01,
              5.08666656494140625e+02 5.07700012207031250e+02 9.55726699829101563e+01,
              5.09333343505859375e+02 5.07700012207031250e+02 9.55726699829101563e+01,
              5.10000000000000000e+02 5.07700012207031250e+02 9.55726699829101563e+01,
              5.10666656494140625e+02 5.07700012207031250e+02 9.55726699829101563e+01,
              5.11333343505859375e+02 5.07700012207031250e+02 9.55726699829101563e+01,
              5.12000000000000000e+02 5.07700012207031250e+02 9.55726699829101563e+01,
              5.12666687011718750e+02 5.07700012207031250e+02 9.55726699829101563e+01,
              5.01466674804687500e+02 5.08399993896484375e+02 9.54199981689453125e+01,
              5.02000000000000000e+02 5.08959991455078125e+02 9.54199981689453125e+01,
              5.07200012207031250e+02 5.08399993896484375e+02 9.54199981689453125e+01,
              5.07333343505859375e+02 5.08399993896484375e+02 9.55726699829101563e+01,
              5.08000000000000000e+02 5.08399993896484375e+02 9.55726699829101563e+01,
              5.08666656494140625e+02 5.08399993896484375e+02 9.55726699829101563e+01,
              5.09333343505859375e+02 5.08399993896484375e+02 9.55726699829101563e+01,
              5.10000000000000000e+02 5.08399993896484375e+02 9.55726699829101563e+01,
              5.10666656494140625e+02 5.08399993896484375e+02 9.55726699829101563e+01,
              5.11333343505859375e+02 5.08399993896484375e+02 9.55726699829101563e+01,
              5.12000000000000000e+02 5.08399993896484375e+02 9.55726699829101563e+01,
              5.12666687011718750e+02 5.08399993896484375e+02 9.55726699829101563e+01,
              5.13333312988281250e+02 5.08399993896484375e+02 9.55726699829101563e+01,
              5.02133331298828125e+02 5.09100006103515625e+02 9.54199981689453125e+01,
              5.02666656494140625e+02 5.09660003662109375e+02 9.54199981689453125e+01,
              5.07200012207031250e+02 5.09100006103515625e+02 9.54199981689453125e+01,
              5.07333343505859375e+02 5.09100006103515625e+02 9.55726699829101563e+01,
              5.08000000000000000e+02 5.09100006103515625e+02 9.55726699829101563e+01,
              5.08666656494140625e+02 5.09100006103515625e+02 9.55726699829101563e+01,
              5.09333343505859375e+02 5.09100006103515625e+02 9.55726699829101563e+01,
              5.10000000000000000e+02 5.09100006103515625e+02 9.56812133789062500e+01,
              5.10666656494140625e+02 5.09100006103515625e+02 9.55726699829101563e+01,
              5.11333343505859375e+02 5.09100006103515625e+02 9.55726699829101563e+01,
              5.12000000000000000e+02 5.09100006103515625e+02 9.55726699829101563e+01,
              5.12666687011718750e+02 5.09100006103515625e+02 9.55726699829101563e+01,
              5.02799987792968750e+02 5.09799987792968750e+02 9.54199981689453125e+01,
              5.03333343505859375e+02 5.10359985351562500e+02 9.54199981689453125e+01,
              5.07200012207031250e+02 5.09799987792968750e+02 9.54199981689453125e+01,
              5.06666656494140625e+02 5.10359985351562500e+02 9.54199981689453125e+01,
              5.07333343505859375e+02 5.09799987792968750e+02 9.55726699829101563e+01,
              5.08000000000000000e+02 5.09799987792968750e+02 9.55726699829101563e+01,
              5.08666656494140625e+02 5.09799987792968750e+02 9.55726699829101563e+01,
              5.09333343505859375e+02 5.09799987792968750e+02 9.56583023071289063e+01,
              5.10000000000000000e+02 5.09799987792968750e+02 9.56037826538085938e+01,
              5.10666656494140625e+02 5.09799987792968750e+02 9.56583023071289063e+01,
              5.11333343505859375e+02 5.09799987792968750e+02 9.55726699829101563e+01,
              5.12000000000000000e+02 5.09799987792968750e+02 9.55726699829101563e+01,
              5.12666687011718750e+02 5.09799987792968750e+02 9.55726699829101563e+01,
              5.03466674804687500e+02 5.10500000000000000e+02 9.54199981689453125e+01,
              5.04000000000000000e+02 5.11059997558593750e+02 9.54199981689453125e+01,
              5.06533325195312500e+02 5.10500000000000000e+02 9.54199981689453125e+01,
              5.06666656494140625e+02 5.10500000000000000e+02 9.55726699829101563e+01,
              5.07333343505859375e+02 5.10500000000000000e+02 9.55726699829101563e+01,
              5.08000000000000000e+02 5.10500000000000000e+02 9.55726699829101563e+01,
              5.08666656494140625e+02 5.10500000000000000e+02 9.55726699829101563e+01,
              5.09333343505859375e+02 5.10500000000000000e+02 9.57254943847656250e+01,
              5.10000000000000000e+02 5.10500000000000000e+02 9.56356735229492188e+01,
              5.10666656494140625e+02 5.10500000000000000e+02 9.57254943847656250e+01,
              5.11333343505859375e+02 5.10500000000000000e+02 9.55726699829101563e+01,
              5.12000000000000000e+02 5.10500000000000000e+02 9.55726699829101563e+01,
              5.12666687011718750e+02 5.10500000000000000e+02 9.55726699829101563e+01,
              5.04133331298828125e+02 5.11200012207031250e+02 9.54199981689453125e+01,
              5.04666656494140625e+02 5.11760009765625000e+02 9.54199981689453125e+01,
              5.06533325195312500e+02 5.11200012207031250e+02 9.54199981689453125e+01,
              5.06666656494140625e+02 5.11200012207031250e+02 9.55726699829101563e+01,
              5.07333343505859375e+02 5.11200012207031250e+02 9.55726699829101563e+01,
              5.08000000000000000e+02 5.11200012207031250e+02 9.55726699829101563e+01,
              5.08666656494140625e+02 5.11200012207031250e+02 9.55726699829101563e+01,
              5.09333343505859375e+02 5.11200012207031250e+02 9.55726699829101563e+01,
              5.10000000000000000e+02 5.11200012207031250e+02 9.55726699829101563e+01,
              5.10666656494140625e+02 5.11200012207031250e+02 9.55726699829101563e+01,
              5.11333343505859375e+02 5.11200012207031250e+02 9.55726699829101563e+01,
              5.12000000000000000e+02 5.11200012207031250e+02 9.55726699829101563e+01,
              5.04799987792968750e+02 5.11899993896484375e+02 9.54199981689453125e+01,
              5.05333343505859375e+02 5.12460021972656250e+02 9.54199981689453125e+01,
              5.06533325195312500e+02 5.11899993896484375e+02 9.54199981689453125e+01,
              5.06000000000000000e+02 5.12460021972656250e+02 9.54199981689453125e+01,
              5.06666656494140625e+02 5.11899993896484375e+02 9.55726699829101563e+01,
              5.07333343505859375e+02 5.11899993896484375e+02 9.55726699829101563e+01,
              5.08000000000000000e+02 5.11899993896484375e+02 9.55726699829101563e+01,
              5.08666656494140625e+02 5.11899993896484375e+02 9.55726699829101563e+01,
              5.09333343505859375e+02 5.11899993896484375e+02 9.55726699829101563e+01,
              5.10000000000000000e+02 5.11899993896484375e+02 9.55726699829101563e+01,
              5.10666656494140625e+02 5.11899993896484375e+02 9.55726699829101563e+01,
              5.11333343505859375e+02 5.11899993896484375e+02 9.55726699829101563e+01,
              5.12000000000000000e+02 5.11899993896484375e+02 9.55726699829101563e+01,
              5.06666656494140625e+02 5.12599975585937500e+02 9.55726699829101563e+01,
              5.07333343505859375e+02 5.12599975585937500e+02 9.55726699829101563e+01,
              5.08000000000000000e+02 5.12599975585937500e+02 9.55726699829101563e+01,
              5.08666656494140625e+02 5.12599975585937500e+02 9.55726699829101563e+01,
              5.09333343505859375e+02 5.12599975585937500e+02 9.55726699829101563e+01,
              5.10000000000000000e+02 5.12599975585937500e+02 9.55726699829101563e+01,
              5.10666656494140625e+02 5.12599975585937500e+02 9.55726699829101563e+01,
              5.11333343505859375e+02 5.12599975585937500e+02 9.55726699829101563e+01,
              5.08666656494140625e+02 5.13299987792968750e+02 9.55726699829101563e+01,
              5.09333343505859375e+02 5.13299987792968750e+02 9.55726699829101563e+01,
              5.10000000000000000e+02 5.13299987792968750e+02 9.55726699829101563e+01,
              5.10666656494140625e+02 5.13299987792968750e+02 9.55726699829101563e+01,
              5.11333343505859375e+02 5.13299987792968750e+02 9.55726699829101563e+01,
              5.10000000000000000e+02 5.14000000000000000e+02 9.55726699829101563e+01,
              5.10666656494140625e+02 5.14000000000000000e+02 9.55726699829101563e+01,
              5.05333343505859375e+02 5.02239990234375000e+02 9.61833343505859375e+01,
              5.04000000000000000e+02 5.02940002441406250e+02 9.61833343505859375e+01,
              5.04799987792968750e+02 5.02799987792968750e+02 9.61833343505859375e+01,
              5.04666656494140625e+02 5.02940002441406250e+02 9.61833343505859375e+01,
              5.05866668701171875e+02 5.02799987792968750e+02 9.61833343505859375e+01,
              5.05333343505859375e+02 5.02799987792968750e+02 9.67939987182617188e+01,
              5.06000000000000000e+02 5.02940002441406250e+02 9.61833343505859375e+01,
              5.02666656494140625e+02 5.03640014648437500e+02 9.61833343505859375e+01,
              5.03466674804687500e+02 5.03500000000000000e+02 9.61833343505859375e+01,
              5.03333343505859375e+02 5.03640014648437500e+02 9.61833343505859375e+01,
              5.04000000000000000e+02 5.03500000000000000e+02 9.67939987182617188e+01,
              5.04666656494140625e+02 5.03500000000000000e+02 9.67939987182617188e+01,
              5.05333343505859375e+02 5.03500000000000000e+02 9.67939987182617188e+01,
              5.06533325195312500e+02 5.03500000000000000e+02 9.61833343505859375e+01,
              5.06000000000000000e+02 5.03500000000000000e+02 9.67939987182617188e+01,
              5.01333343505859375e+02 5.04339996337890625e+02 9.61833343505859375e+01,
              5.02133331298828125e+02 5.04200012207031250e+02 9.61833343505859375e+01,
              5.02000000000000000e+02 5.04339996337890625e+02 9.61833343505859375e+01,
              5.02666656494140625e+02 5.04200012207031250e+02 9.67939987182617188e+01,
              5.03333343505859375e+02 5.04200012207031250e+02 9.67939987182617188e+01,
              5.04000000000000000e+02 5.04200012207031250e+02 9.67939987182617188e+01,
              5.04666656494140625e+02 5.04200012207031250e+02 9.67939987182617188e+01,
              5.05333343505859375e+02 5.04200012207031250e+02 9.67939987182617188e+01,
              5.06533325195312500e+02 5.04200012207031250e+02 9.61833343505859375e+01,
              5.06000000000000000e+02 5.04200012207031250e+02 9.67939987182617188e+01,
              5.06666656494140625e+02 5.04339996337890625e+02 9.61833343505859375e+01,
              5.00799987792968750e+02 5.04899993896484375e+02 9.61833343505859375e+01,
              5.00666656494140625e+02 5.05040008544921875e+02 9.61833343505859375e+01,
              5.01333343505859375e+02 5.04899993896484375e+02 9.67939987182617188e+01,
              5.02000000000000000e+02 5.04899993896484375e+02 9.67939987182617188e+01,
              5.02666656494140625e+02 5.04899993896484375e+02 9.67939987182617188e+01,
              5.03333343505859375e+02 5.04899993896484375e+02 9.67939987182617188e+01,
              5.04000000000000000e+02 5.04899993896484375e+02 9.67939987182617188e+01,
              5.04666656494140625e+02 5.04899993896484375e+02 9.67939987182617188e+01,
              5.05333343505859375e+02 5.04899993896484375e+02 9.67939987182617188e+01,
              5.06000000000000000e+02 5.04899993896484375e+02 9.67939987182617188e+01,
              5.07200012207031250e+02 5.04899993896484375e+02 9.61833343505859375e+01,
              5.06666656494140625e+02 5.04899993896484375e+02 9.67939987182617188e+01,
              5.00133331298828125e+02 5.05600006103515625e+02 9.61833343505859375e+01,
              5.00000000000000000e+02 5.05739990234375000e+02 9.61833343505859375e+01,
              5.00666656494140625e+02 5.05600006103515625e+02 9.67939987182617188e+01,
              5.01333343505859375e+02 5.05600006103515625e+02 9.67939987182617188e+01,
              5.02000000000000000e+02 5.05600006103515625e+02 9.67939987182617188e+01,
              5.02666656494140625e+02 5.05600006103515625e+02 9.67939987182617188e+01,
              5.03333343505859375e+02 5.05600006103515625e+02 9.67939987182617188e+01,
              5.04000000000000000e+02 5.05600006103515625e+02 9.67939987182617188e+01,
              5.04666656494140625e+02 5.05600006103515625e+02 9.67939987182617188e+01,
              5.05333343505859375e+02 5.05600006103515625e+02 9.67939987182617188e+01,
              5.06000000000000000e+02 5.05600006103515625e+02 9.67939987182617188e+01,
              5.07200012207031250e+02 5.05600006103515625e+02 9.61833343505859375e+01,
              5.06666656494140625e+02 5.05600006103515625e+02 9.67939987182617188e+01,
              5.07333343505859375e+02 5.05739990234375000e+02 9.61833343505859375e+01,
              5.00000000000000000e+02 5.06859985351562500e+02 9.61833343505859375e+01,
              5.00000000000000000e+02 5.06299987792968750e+02 9.67939987182617188e+01,
              5.00666656494140625e+02 5.06299987792968750e+02 9.67939987182617188e+01,
              5.01333343505859375e+02 5.06299987792968750e+02 9.67939987182617188e+01,
              5.02000000000000000e+02 5.06299987792968750e+02 9.67939987182617188e+01,
              5.02666656494140625e+02 5.06299987792968750e+02 9.67939987182617188e+01,
              5.03333343505859375e+02 5.06299987792968750e+02 9.67939987182617188e+01,
              5.04000000000000000e+02 5.06299987792968750e+02 9.67939987182617188e+01,
              5.04666656494140625e+02 5.06299987792968750e+02 9.67939987182617188e+01,
              5.05333343505859375e+02 5.06299987792968750e+02 9.67939987182617188e+01,
              5.06000000000000000e+02 5.06299987792968750e+02 9.67939987182617188e+01,
              5.06666656494140625e+02 5.06299987792968750e+02 9.67939987182617188e+01,
              5.07866668701171875e+02 5.06299987792968750e+02 9.61833343505859375e+01,
              5.07333343505859375e+02 5.06299987792968750e+02 9.67939987182617188e+01,
              5.08000000000000000e+02 5.06440002441406250e+02 9.61833343505859375e+01,
              5.08666656494140625e+02 5.06440002441406250e+02 9.61833343505859375e+01,
              5.09333343505859375e+02 5.06440002441406250e+02 9.61833343505859375e+01,
              5.10000000000000000e+02 5.06440002441406250e+02 9.61833343505859375e+01,
              5.00133331298828125e+02 5.07000000000000000e+02 9.61833343505859375e+01,
              5.00666656494140625e+02 5.07559997558593750e+02 9.61833343505859375e+01,
              5.00666656494140625e+02 5.07000000000000000e+02 9.67939987182617188e+01,
              5.01333343505859375e+02 5.07000000000000000e+02 9.67939987182617188e+01,
              5.02000000000000000e+02 5.07000000000000000e+02 9.67939987182617188e+01,
              5.02666656494140625e+02 5.07000000000000000e+02 9.67939987182617188e+01,
              5.03333343505859375e+02 5.07000000000000000e+02 9.67939987182617188e+01,
              5.04000000000000000e+02 5.07000000000000000e+02 9.67939987182617188e+01,
              5.04666656494140625e+02 5.07000000000000000e+02 9.67939987182617188e+01,
              5.05333343505859375e+02 5.07000000000000000e+02 9.67939987182617188e+01,
              5.06000000000000000e+02 5.07000000000000000e+02 9.67939987182617188e+01,
              5.06666656494140625e+02 5.07000000000000000e+02 9.67939987182617188e+01,
              5.07333343505859375e+02 5.07000000000000000e+02 9.67939987182617188e+01,
              5.08000000000000000e+02 5.07000000000000000e+02 9.67939987182617188e+01,
              5.08666656494140625e+02 5.07000000000000000e+02 9.67939987182617188e+01,
              5.09333343505859375e+02 5.07000000000000000e+02 9.67939987182617188e+01,
              5.10533325195312500e+02 5.07000000000000000e+02 9.61833343505859375e+01,
              5.10000000000000000e+02 5.07000000000000000e+02 9.67939987182617188e+01,
              5.10666656494140625e+02 5.07140014648437500e+02 9.61833343505859375e+01,
              5.11333343505859375e+02 5.07140014648437500e+02 9.61833343505859375e+01,
              5.12000000000000000e+02 5.07140014648437500e+02 9.61833343505859375e+01,
              5.12666687011718750e+02 5.07140014648437500e+02 9.61833343505859375e+01,
              5.00799987792968750e+02 5.07700012207031250e+02 9.61833343505859375e+01,
              5.01333343505859375e+02 5.08260009765625000e+02 9.61833343505859375e+01,
              5.01333343505859375e+02 5.07700012207031250e+02 9.67939987182617188e+01,
              5.02000000000000000e+02 5.07700012207031250e+02 9.67939987182617188e+01,
              5.02666656494140625e+02 5.07700012207031250e+02 9.67939987182617188e+01,
              5.03333343505859375e+02 5.07700012207031250e+02 9.66710357666015625e+01,
              5.04000000000000000e+02 5.07700012207031250e+02 9.67271652221679688e+01,
              5.04666656494140625e+02 5.07700012207031250e+02 9.66710357666015625e+01,
              5.05333343505859375e+02 5.07700012207031250e+02 9.67939987182617188e+01,
              5.06000000000000000e+02 5.07700012207031250e+02 9.67939987182617188e+01,
              5.06666656494140625e+02 5.07700012207031250e+02 9.67939987182617188e+01,
              5.07333343505859375e+02 5.07700012207031250e+02 9.67939987182617188e+01,
              5.08000000000000000e+02 5.07700012207031250e+02 9.67939987182617188e+01,
              5.08666656494140625e+02 5.07700012207031250e+02 9.67939987182617188e+01,
              5.09333343505859375e+02 5.07700012207031250e+02 9.67939987182617188e+01,
              5.10000000000000000e+02 5.07700012207031250e+02 9.67939987182617188e+01,
              5.10666656494140625e+02 5.07700012207031250e+02 9.67939987182617188e+01,
              5.11333343505859375e+02 5.07700012207031250e+02 9.67939987182617188e+01,
              5.12000000000000000e+02 5.07700012207031250e+02 9.67939987182617188e+01,
              5.13200012207031250e+02 5.07700012207031250e+02 9.61833343505859375e+01,
              5.12666687011718750e+02 5.07700012207031250e+02 9.67939987182617188e+01,
              5.13333312988281250e+02 5.07839996337890625e+02 9.61833343505859375e+01,
              5.01466674804687500e+02 5.08399993896484375e+02 9.61833343505859375e+01,
              5.02000000000000000e+02 5.08959991455078125e+02 9.61833343505859375e+01,
              5.02000000000000000e+02 5.08399993896484375e+02 9.67939987182617188e+01,
              5.02666656494140625e+02 5.08399993896484375e+02 9.67939987182617188e+01,
              5.03333343505859375e+02 5.08399993896484375e+02 9.66703109741210938e+01,
              5.04000000000000000e+02 5.08399993896484375e+02 9.67236175537109375e+01,
              5.04666656494140625e+02 5.08399993896484375e+02 9.66703109741210938e+01,
              5.05333343505859375e+02 5.08399993896484375e+02 9.67939987182617188e+01,
              5.06000000000000000e+02 5.08399993896484375e+02 9.67939987182617188e+01,
              5.06666656494140625e+02 5.08399993896484375e+02 9.67939987182617188e+01,
              5.07333343505859375e+02 5.08399993896484375e+02 9.67939987182617188e+01,
              5.08000000000000000e+02 5.08399993896484375e+02 9.67939987182617188e+01,
              5.08666656494140625e+02 5.08399993896484375e+02 9.67939987182617188e+01,
              5.09333343505859375e+02 5.08399993896484375e+02 9.67939987182617188e+01,
              5.10000000000000000e+02 5.08399993896484375e+02 9.67939987182617188e+01,
              5.10666656494140625e+02 5.08399993896484375e+02 9.67939987182617188e+01,
              5.11333343505859375e+02 5.08399993896484375e+02 9.67939987182617188e+01,
              5.12000000000000000e+02 5.08399993896484375e+02 9.67939987182617188e+01,
              5.12666687011718750e+02 5.08399993896484375e+02 9.67939987182617188e+01,
              5.13866638183593750e+02 5.08399993896484375e+02 9.61833343505859375e+01,
              5.13333312988281250e+02 5.08959991455078125e+02 9.61833343505859375e+01,
              5.13333312988281250e+02 5.08399993896484375e+02 9.67939987182617188e+01,
              5.02133331298828125e+02 5.09100006103515625e+02 9.61833343505859375e+01,
              5.02666656494140625e+02 5.09660003662109375e+02 9.61833343505859375e+01,
              5.02666656494140625e+02 5.09100006103515625e+02 9.67939987182617188e+01,
              5.03333343505859375e+02 5.09100006103515625e+02 9.67939987182617188e+01,
              5.04000000000000000e+02 5.09100006103515625e+02 9.67939987182617188e+01,
              5.04666656494140625e+02 5.09100006103515625e+02 9.67939987182617188e+01,
              5.05333343505859375e+02 5.09100006103515625e+02 9.67939987182617188e+01,
              5.06000000000000000e+02 5.09100006103515625e+02 9.67939987182617188e+01,
              5.06666656494140625e+02 5.09100006103515625e+02 9.67939987182617188e+01,
              5.07333343505859375e+02 5.09100006103515625e+02 9.67939987182617188e+01,
              5.08000000000000000e+02 5.09100006103515625e+02 9.67939987182617188e+01,
              5.08666656494140625e+02 5.09100006103515625e+02 9.67939987182617188e+01,
              5.09333343505859375e+02 5.09100006103515625e+02 9.67939987182617188e+01,
              5.10000000000000000e+02 5.09100006103515625e+02 9.66854553222656250e+01,
              5.10666656494140625e+02 5.09100006103515625e+02 9.67939987182617188e+01,
              5.11333343505859375e+02 5.09100006103515625e+02 9.67939987182617188e+01,
              5.12000000000000000e+02 5.09100006103515625e+02 9.67939987182617188e+01,
              5.13200012207031250e+02 5.09100006103515625e+02 9.61833343505859375e+01,
              5.12666687011718750e+02 5.09100006103515625e+02 9.67939987182617188e+01,
              5.02799987792968750e+02 5.09799987792968750e+02 9.61833343505859375e+01,
              5.03333343505859375e+02 5.10359985351562500e+02 9.61833343505859375e+01,
              5.03333343505859375e+02 5.09799987792968750e+02 9.67939987182617188e+01,
              5.04000000000000000e+02 5.09799987792968750e+02 9.67939987182617188e+01,
              5.04666656494140625e+02 5.09799987792968750e+02 9.67939987182617188e+01,
              5.05333343505859375e+02 5.09799987792968750e+02 9.67939987182617188e+01,
              5.06000000000000000e+02 5.09799987792968750e+02 9.67939987182617188e+01,
              5.06666656494140625e+02 5.09799987792968750e+02 9.67939987182617188e+01,
              5.07333343505859375e+02 5.09799987792968750e+02 9.67939987182617188e+01,
              5.08000000000000000e+02 5.09799987792968750e+02 9.67939987182617188e+01,
              5.08666656494140625e+02 5.09799987792968750e+02 9.67939987182617188e+01,
              5.09333343505859375e+02 5.09799987792968750e+02 9.67163925170898438e+01,
              5.10000000000000000e+02 5.09799987792968750e+02 9.67751693725585938e+01,
              5.10666656494140625e+02 5.09799987792968750e+02 9.67163925170898438e+01,
              5.11333343505859375e+02 5.09799987792968750e+02 9.67939987182617188e+01,
              5.12000000000000000e+02 5.09799987792968750e+02 9.67939987182617188e+01,
              5.13200012207031250e+02 5.09799987792968750e+02 9.61833343505859375e+01,
              5.12666687011718750e+02 5.09799987792968750e+02 9.67939987182617188e+01,
              5.03466674804687500e+02 5.10500000000000000e+02 9.61833343505859375e+01,
              5.04000000000000000e+02 5.11059997558593750e+02 9.61833343505859375e+01,
              5.04000000000000000e+02 5.10500000000000000e+02 9.67939987182617188e+01,
              5.04666656494140625e+02 5.10500000000000000e+02 9.67939987182617188e+01,
              5.05333343505859375e+02 5.10500000000000000e+02 9.67939987182617188e+01,
              5.06000000000000000e+02 5.10500000000000000e+02 9.67939987182617188e+01,
              5.06666656494140625e+02 5.10500000000000000e+02 9.67939987182617188e+01,
              5.07333343505859375e+02 5.10500000000000000e+02 9.67939987182617188e+01,
              5.08000000000000000e+02 5.10500000000000000e+02 9.67939987182617188e+01,
              5.08666656494140625e+02 5.10500000000000000e+02 9.67939987182617188e+01,
              5.09333343505859375e+02 5.10500000000000000e+02 9.67182159423828125e+01,
              5.10000000000000000e+02 5.10500000000000000e+02 9.67805786132812500e+01,
              5.10666656494140625e+02 5.10500000000000000e+02 9.67182159423828125e+01,
              5.11333343505859375e+02 5.10500000000000000e+02 9.67939987182617188e+01,
              5.12000000000000000e+02 5.10500000000000000e+02 9.67939987182617188e+01,
              5.13200012207031250e+02 5.10500000000000000e+02 9.61833343505859375e+01,
              5.12666687011718750e+02 5.11059997558593750e+02 9.61833343505859375e+01,
              5.12666687011718750e+02 5.10500000000000000e+02 9.67939987182617188e+01,
              5.04133331298828125e+02 5.11200012207031250e+02 9.61833343505859375e+01,
              5.04666656494140625e+02 5.11760009765625000e+02 9.61833343505859375e+01,
              5.04666656494140625e+02 5.11200012207031250e+02 9.67939987182617188e+01,
              5.05333343505859375e+02 5.11200012207031250e+02 9.67939987182617188e+01,
              5.06000000000000000e+02 5.11200012207031250e+02 9.67939987182617188e+01,
              5.06666656494140625e+02 5.11200012207031250e+02 9.67939987182617188e+01,
              5.07333343505859375e+02 5.11200012207031250e+02 9.67939987182617188e+01,
              5.08000000000000000e+02 5.11200012207031250e+02 9.67939987182617188e+01,
              5.08666656494140625e+02 5.11200012207031250e+02 9.67939987182617188e+01,
              5.09333343505859375e+02 5.11200012207031250e+02 9.67939987182617188e+01,
              5.10000000000000000e+02 5.11200012207031250e+02 9.67939987182617188e+01,
              5.10666656494140625e+02 5.11200012207031250e+02 9.67939987182617188e+01,
              5.11333343505859375e+02 5.11200012207031250e+02 9.67939987182617188e+01,
              5.12533325195312500e+02 5.11200012207031250e+02 9.61833343505859375e+01,
              5.12000000000000000e+02 5.11200012207031250e+02 9.67939987182617188e+01,
              5.04799987792968750e+02 5.11899993896484375e+02 9.61833343505859375e+01,
              5.05333343505859375e+02 5.12460021972656250e+02 9.61833343505859375e+01,
              5.05333343505859375e+02 5.11899993896484375e+02 9.67939987182617188e+01,
              5.06000000000000000e+02 5.12460021972656250e+02 9.61833343505859375e+01,
              5.06000000000000000e+02 5.11899993896484375e+02 9.67939987182617188e+01,
              5.06666656494140625e+02 5.11899993896484375e+02 9.67939987182617188e+01,
              5.07333343505859375e+02 5.11899993896484375e+02 9.67939987182617188e+01,
              5.08000000000000000e+02 5.11899993896484375e+02 9.67939987182617188e+01,
              5.08666656494140625e+02 5.11899993896484375e+02 9.67939987182617188e+01,
              5.09333343505859375e+02 5.11899993896484375e+02 9.67939987182617188e+01,
              5.10000000000000000e+02 5.11899993896484375e+02 9.67939987182617188e+01,
              5.10666656494140625e+02 5.11899993896484375e+02 9.67939987182617188e+01,
              5.11333343505859375e+02 5.11899993896484375e+02 9.67939987182617188e+01,
              5.12533325195312500e+02 5.11899993896484375e+02 9.61833343505859375e+01,
              5.12000000000000000e+02 5.12460021972656250e+02 9.61833343505859375e+01,
              5.12000000000000000e+02 5.11899993896484375e+02 9.67939987182617188e+01,
              5.06133331298828125e+02 5.12599975585937500e+02 9.61833343505859375e+01,
              5.06666656494140625e+02 5.13159973144531250e+02 9.61833343505859375e+01,
              5.06666656494140625e+02 5.12599975585937500e+02 9.67939987182617188e+01,
              5.07333343505859375e+02 5.13159973144531250e+02 9.61833343505859375e+01,
              5.07333343505859375e+02 5.12599975585937500e+02 9.67939987182617188e+01,
              5.08000000000000000e+02 5.13159973144531250e+02 9.61833343505859375e+01,
              5.08000000000000000e+02 5.12599975585937500e+02 9.67939987182617188e+01,
              5.08666656494140625e+02 5.12599975585937500e+02 9.67939987182617188e+01,
              5.09333343505859375e+02 5.12599975585937500e+02 9.67939987182617188e+01,
              5.10000000000000000e+02 5.12599975585937500e+02 9.67939987182617188e+01,
              5.10666656494140625e+02 5.12599975585937500e+02 9.67939987182617188e+01,
              5.11866668701171875e+02 5.12599975585937500e+02 9.61833343505859375e+01,
              5.11333343505859375e+02 5.12599975585937500e+02 9.67939987182617188e+01,
              5.08133331298828125e+02 5.13299987792968750e+02 9.61833343505859375e+01,
              5.08666656494140625e+02 5.13859985351562500e+02 9.61833343505859375e+01,
              5.08666656494140625e+02 5.13299987792968750e+02 9.67939987182617188e+01,
              5.09333343505859375e+02 5.13859985351562500e+02 9.61833343505859375e+01,
              5.09333343505859375e+02 5.13299987792968750e+02 9.67939987182617188e+01,
              5.10000000000000000e+02 5.13299987792968750e+02 9.67939987182617188e+01,
              5.10666656494140625e+02 5.13299987792968750e+02 9.67939987182617188e+01,
              5.11866668701171875e+02 5.13299987792968750e+02 9.61833343505859375e+01,
              5.11333343505859375e+02 5.13859985351562500e+02 9.61833343505859375e+01,
              5.11333343505859375e+02 5.13299987792968750e+02 9.67939987182617188e+01,
              5.09466674804687500e+02 5.14000000000000000e+02 9.61833343505859375e+01,
              5.10000000000000000e+02 5.14559997558593750e+02 9.61833343505859375e+01,
              5.10000000000000000e+02 5.14000000000000000e+02 9.67939987182617188e+01,
              5.11200012207031250e+02 5.14000000000000000e+02 9.61833343505859375e+01,
              5.10666656494140625e+02 5.14559997558593750e+02 9.61833343505859375e+01,
              5.10666656494140625e+02 5.14000000000000000e+02 9.67939987182617188e+01,
              5.10666656494140625e+02 5.03500000000000000e+02 9.72025985717773438e+01,
              5.11333343505859375e+02 5.03500000000000000e+02 9.72025985717773438e+01,
              5.10666656494140625e+02 5.04200012207031250e+02 9.72809524536132813e+01,
              5.11333343505859375e+02 5.04200012207031250e+02 9.72809524536132813e+01,
              5.14666687011718750e+02 5.11200012207031250e+02 9.71687698364257813e+01,
              5.15333312988281250e+02 5.11200012207031250e+02 9.71687698364257813e+01,
              5.14666687011718750e+02 5.11899993896484375e+02 9.72969741821289063e+01,
              5.15333312988281250e+02 5.11899993896484375e+02 9.72969741821289063e+01,
              5.14666687011718750e+02 5.12599975585937500e+02 9.71642456054687500e+01,
              5.15333312988281250e+02 5.12599975585937500e+02 9.71642456054687500e+01,
              5.10666656494140625e+02 5.03161956787109375e+02 9.77099990844726563e+01,
              5.11333343505859375e+02 5.03161956787109375e+02 9.77099990844726563e+01,
              5.10344726562500000e+02 5.03500000000000000e+02 9.77099990844726563e+01,
              5.11655273437500000e+02 5.03500000000000000e+02 9.77099990844726563e+01,
              5.10381286621093750e+02 5.04200012207031250e+02 9.77099990844726563e+01,
              5.10666656494140625e+02 5.04877227783203125e+02 9.77099990844726563e+01,
              5.11618713378906250e+02 5.04200012207031250e+02 9.77099990844726563e+01,
              5.11333343505859375e+02 5.04877227783203125e+02 9.77099990844726563e+01,
              5.10666656494140625e+02 5.04899993896484375e+02 9.77394485473632813e+01,
              5.11333343505859375e+02 5.04899993896484375e+02 9.77394485473632813e+01,
              5.14666687011718750e+02 5.10793762207031250e+02 9.77099990844726563e+01,
              5.15333312988281250e+02 5.10793762207031250e+02 9.77099990844726563e+01,
              5.14279785156250000e+02 5.11200012207031250e+02 9.77099990844726563e+01,
              5.15720214843750000e+02 5.11200012207031250e+02 9.77099990844726563e+01,
              5.14388000488281250e+02 5.11899993896484375e+02 9.77099990844726563e+01,
              5.15611999511718750e+02 5.11899993896484375e+02 9.77099990844726563e+01,
              5.14331176757812500e+02 5.12599975585937500e+02 9.77099990844726563e+01,
              5.14666687011718750e+02 5.12952209472656250e+02 9.77099990844726563e+01,
              5.15668823242187500e+02 5.12599975585937500e+02 9.77099990844726563e+01,
              5.15333312988281250e+02 5.12952209472656250e+02 9.77099990844726563e+01,
              5.10666656494140625e+02 5.03304534912109375e+02 9.84733352661132813e+01,
              5.11333343505859375e+02 5.03304534912109375e+02 9.84733352661132813e+01,
              5.10480529785156250e+02 5.03500000000000000e+02 9.84733352661132813e+01,
              5.10666656494140625e+02 5.03500000000000000e+02 9.86864700317382813e+01,
              5.11519470214843750e+02 5.03500000000000000e+02 9.84733352661132813e+01,
              5.11333343505859375e+02 5.03500000000000000e+02 9.86864700317382813e+01,
              5.10496948242187500e+02 5.04200012207031250e+02 9.84733352661132813e+01,
              5.10666656494140625e+02 5.04200012207031250e+02 9.86676483154296875e+01,
              5.11503051757812500e+02 5.04200012207031250e+02 9.84733352661132813e+01,
              5.11333343505859375e+02 5.04200012207031250e+02 9.86676483154296875e+01,
              5.10409637451171875e+02 5.04899993896484375e+02 9.84733352661132813e+01,
              5.10666656494140625e+02 5.05169891357421875e+02 9.84733352661132813e+01,
              5.10666656494140625e+02 5.04899993896484375e+02 9.87676467895507813e+01,
              5.11590362548828125e+02 5.04899993896484375e+02 9.84733352661132813e+01,
              5.11333343505859375e+02 5.05169891357421875e+02 9.84733352661132813e+01,
              5.11333343505859375e+02 5.04899993896484375e+02 9.87676467895507813e+01,
              5.14666687011718750e+02 5.10864471435546875e+02 9.84733352661132813e+01,
              5.15333312988281250e+02 5.10864471435546875e+02 9.84733352661132813e+01,
              5.14347106933593750e+02 5.11200012207031250e+02 9.84733352661132813e+01,
              5.14666687011718750e+02 5.11200012207031250e+02 9.88392105102539063e+01,
              5.15652893066406250e+02 5.11200012207031250e+02 9.84733352661132813e+01,
              5.15333312988281250e+02 5.11200012207031250e+02 9.88392105102539063e+01,
              5.14506347656250000e+02 5.11899993896484375e+02 9.84733352661132813e+01,
              5.14666687011718750e+02 5.11899993896484375e+02 9.86676483154296875e+01,
              5.15493652343750000e+02 5.11899993896484375e+02 9.84733352661132813e+01,
              5.15333312988281250e+02 5.11899993896484375e+02 9.86676483154296875e+01,
              5.14466918945312500e+02 5.12599975585937500e+02 9.84733352661132813e+01,
              5.14666687011718750e+02 5.12809753417968750e+02 9.84733352661132813e+01,
              5.14666687011718750e+02 5.12599975585937500e+02 9.87020492553710938e+01,
              5.15533081054687500e+02 5.12599975585937500e+02 9.84733352661132813e+01,
              5.15333312988281250e+02 5.12809753417968750e+02 9.84733352661132813e+01,
              5.15333312988281250e+02 5.12599975585937500e+02 9.87020492553710938e+01,
              "
            />
            <Normal
              DEF="VTKnormals0021"
              vector="
              -1.17450118064880371e-01 -5.59286296367645264e-01 -8.20612192153930664e-01,
              -1.08173266053199768e-01 -5.15110790729522705e-01 -8.50270211696624756e-01,
              -0.00000000000000000e+00 -5.63184201717376709e-01 -8.26331377029418945e-01,
              1.08173266053199768e-01 -5.15110790729522705e-01 -8.50270211696624756e-01,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              2.05517232418060303e-01 -9.78653490543365479e-01 -0.00000000000000000e+00,
              -9.82338547706604004e-01 -1.87112107872962952e-01 -0.00000000000000000e+00,
              5.09006381034851074e-01 -4.84767973423004150e-01 -7.11275279521942139e-01,
              4.27636295557022095e-01 -5.09090840816497803e-01 -7.46962964534759521e-01,
              3.99084240198135376e-01 -4.75100278854370117e-01 -7.84226655960083008e-01,
              1.24679714441299438e-01 -4.74970340728759766e-01 -8.71124625205993652e-01,
              1.08173266053199768e-01 -5.15110790729522705e-01 -8.50270211696624756e-01,
              -1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              7.95431733131408691e-01 6.06043219566345215e-01 -0.00000000000000000e+00,
              -5.32007515430450439e-01 -4.05339062213897705e-01 -7.43416607379913330e-01,
              -5.09006381034851074e-01 -4.84767973423004150e-01 -7.11275279521942139e-01,
              -4.77906972169876099e-01 -4.55149501562118530e-01 -7.51294791698455811e-01,
              7.95431733131408691e-01 6.06043219566345215e-01 -0.00000000000000000e+00,
              -9.82338547706604004e-01 -1.87112107872962952e-01 -0.00000000000000000e+00,
              9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              7.95431733131408691e-01 6.06043219566345215e-01 -0.00000000000000000e+00,
              -5.32007515430450439e-01 -4.05339062213897705e-01 -7.43416607379913330e-01,
              -5.32007515430450439e-01 -4.05339062213897705e-01 -7.43416607379913330e-01,
              -4.77906972169876099e-01 -4.55149501562118530e-01 -7.51294791698455811e-01,
              9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              5.09006381034851074e-01 4.84767973423004150e-01 -7.11275279521942139e-01,
              -1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              -7.95431733131408691e-01 6.06043219566345215e-01 -0.00000000000000000e+00,
              5.32007515430450439e-01 4.05339062213897705e-01 -7.43416607379913330e-01,
              4.77906972169876099e-01 4.55149501562118530e-01 -7.51294791698455811e-01,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00,
              -4.27636295557022095e-01 5.09090840816497803e-01 -7.46962964534759521e-01,
              1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              -5.09006381034851074e-01 4.84767973423004150e-01 -7.11275279521942139e-01,
              -4.77906972169876099e-01 4.55149501562118530e-01 -7.51294791698455811e-01,
              -2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              -6.43192112445831299e-01 7.65704870223999023e-01 -0.00000000000000000e+00,
              9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -6.43192112445831299e-01 7.65704870223999023e-01 -0.00000000000000000e+00,
              6.43192112445831299e-01 7.65704870223999023e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00,
              -2.02284559607505798e-01 -9.63259816169738770e-01 -1.76667734980583191e-01,
              2.02284559607505798e-01 -9.63259816169738770e-01 -1.76667734980583191e-01,
              -9.68189120292663574e-01 -1.84416979551315308e-01 -1.69116005301475525e-01,
              6.12013936042785645e-01 -5.82870423793792725e-01 -5.34510016441345215e-01,
              5.60772538185119629e-01 -6.67586386203765869e-01 -4.89757686853408813e-01,
              5.26385128498077393e-01 -6.26648962497711182e-01 -5.74656248092651367e-01,
              -0.00000000000000000e+00 -8.06293070316314697e-01 -5.91516315937042236e-01,
              1.52953147888183594e-01 -7.28348314762115479e-01 -6.67917668819427490e-01,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -9.83593881130218506e-01 -1.80397123098373413e-01,
              2.02284559607505798e-01 -9.63259816169738770e-01 -1.76667734980583191e-01,
              -1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              9.68189120292663574e-01 -1.84416979551315308e-01 -1.69116005301475525e-01,
              -6.95272028446197510e-01 -5.29731094837188721e-01 -4.85779583454132080e-01,
              -6.12013936042785645e-01 -5.82870423793792725e-01 -5.34510016441345215e-01,
              -6.12013936042785645e-01 -5.82870423793792725e-01 -5.34510016441345215e-01,
              9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -9.68189120292663574e-01 -1.84416979551315308e-01 -1.69116005301475525e-01,
              7.95431733131408691e-01 6.06043219566345215e-01 -0.00000000000000000e+00,
              -1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -6.95272028446197510e-01 -5.29731094837188721e-01 -4.85779583454132080e-01,
              -6.12013936042785645e-01 -5.82870423793792725e-01 -5.34510016441345215e-01,
              -6.12013936042785645e-01 -5.82870423793792725e-01 -5.34510016441345215e-01,
              7.95431733131408691e-01 6.06043219566345215e-01 -0.00000000000000000e+00,
              -9.68189120292663574e-01 -1.84416979551315308e-01 -1.69116005301475525e-01,
              1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              -9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              -6.46144092082977295e-01 6.15375339984893799e-01 -4.51454371213912964e-01,
              9.68189120292663574e-01 1.84416979551315308e-01 -1.69116005301475525e-01,
              7.18413829803466797e-01 6.84203624725341797e-01 -1.25487133860588074e-01,
              -6.46144092082977295e-01 6.15375339984893799e-01 -4.51454371213912964e-01,
              -6.12013936042785645e-01 5.82870423793792725e-01 -5.34510016441345215e-01,
              -2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              -6.43192112445831299e-01 7.65704870223999023e-01 -0.00000000000000000e+00,
              7.95431733131408691e-01 6.06043219566345215e-01 -0.00000000000000000e+00,
              -7.18413829803466797e-01 6.84203624725341797e-01 -1.25487133860588074e-01,
              -2.02284559607505798e-01 9.63259816169738770e-01 -1.76667734980583191e-01,
              -0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00,
              -5.60772538185119629e-01 6.67586386203765869e-01 -4.89757686853408813e-01,
              9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -5.60772538185119629e-01 6.67586386203765869e-01 -4.89757686853408813e-01,
              -6.12013936042785645e-01 5.82870423793792725e-01 -5.34510016441345215e-01,
              6.43192112445831299e-01 7.65704870223999023e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00,
              -2.05517232418060303e-01 -9.78653490543365479e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -9.83593881130218506e-01 -1.80397123098373413e-01,
              2.02284559607505798e-01 -9.63259816169738770e-01 -1.76667734980583191e-01,
              -9.82338547706604004e-01 -1.87112107872962952e-01 -0.00000000000000000e+00,
              7.18413829803466797e-01 -6.84203624725341797e-01 -1.25487133860588074e-01,
              6.43192112445831299e-01 -7.65704870223999023e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              2.02284559607505798e-01 -9.63259816169738770e-01 1.76667734980583191e-01,
              -7.95431733131408691e-01 -6.06043219566345215e-01 -0.00000000000000000e+00,
              -7.18413829803466797e-01 -6.84203624725341797e-01 -1.25487133860588074e-01,
              9.68189120292663574e-01 -1.84416979551315308e-01 1.69116005301475525e-01,
              6.12013936042785645e-01 -5.82870423793792725e-01 5.34510016441345215e-01,
              -9.68189120292663574e-01 -1.84416979551315308e-01 -1.69116005301475525e-01,
              9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              7.95431733131408691e-01 6.06043219566345215e-01 -0.00000000000000000e+00,
              -7.95431733131408691e-01 -6.06043219566345215e-01 -0.00000000000000000e+00,
              -7.18413829803466797e-01 -6.84203624725341797e-01 -1.25487133860588074e-01,
              9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              6.46144092082977295e-01 6.15375339984893799e-01 -4.51454371213912964e-01,
              -9.68189120292663574e-01 -1.84416979551315308e-01 -1.69116005301475525e-01,
              6.95272028446197510e-01 5.29731094837188721e-01 -4.85779583454132080e-01,
              6.46144092082977295e-01 6.15375339984893799e-01 -4.51454371213912964e-01,
              -1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              -1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -9.68189120292663574e-01 1.84416979551315308e-01 -1.69116005301475525e-01,
              -2.02284559607505798e-01 9.63259816169738770e-01 -1.76667734980583191e-01,
              -0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00,
              -6.43192112445831299e-01 7.65704870223999023e-01 -0.00000000000000000e+00,
              7.95431733131408691e-01 6.06043219566345215e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              -6.43192112445831299e-01 7.65704870223999023e-01 -0.00000000000000000e+00,
              9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -7.18413829803466797e-01 6.84203624725341797e-01 -1.25487133860588074e-01,
              -2.02284559607505798e-01 9.63259816169738770e-01 -1.76667734980583191e-01,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              -2.02284559607505798e-01 -9.63259816169738770e-01 1.76667734980583191e-01,
              -0.00000000000000000e+00 -9.83593881130218506e-01 1.80397123098373413e-01,
              -0.00000000000000000e+00 -9.83593881130218506e-01 1.80397123098373413e-01,
              2.02284559607505798e-01 -9.63259816169738770e-01 1.76667734980583191e-01,
              -9.68189120292663574e-01 -1.84416979551315308e-01 1.69116005301475525e-01,
              -2.18342289328575134e-01 -2.07945033907890320e-01 9.53459739685058594e-01,
              -0.00000000000000000e+00 -2.13086321949958801e-01 9.77033376693725586e-01,
              -0.00000000000000000e+00 -2.13086321949958801e-01 9.77033376693725586e-01,
              7.18413829803466797e-01 -6.84203624725341797e-01 1.25487133860588074e-01,
              2.18342289328575134e-01 -2.07945033907890320e-01 9.53459739685058594e-01,
              6.36941850185394287e-01 -7.58264124393463135e-01 1.39070272445678711e-01,
              -0.00000000000000000e+00 -9.83593881130218506e-01 1.80397123098373413e-01,
              -0.00000000000000000e+00 -9.83593881130218506e-01 1.80397123098373413e-01,
              2.02284559607505798e-01 -9.63259816169738770e-01 1.76667734980583191e-01,
              -7.87863492965698242e-01 -6.00276947021484375e-01 1.37618079781532288e-01,
              -7.18413829803466797e-01 -6.84203624725341797e-01 1.25487133860588074e-01,
              -2.23221793770790100e-01 -0.00000000000000000e+00 9.74767684936523438e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -2.13086321949958801e-01 9.77033376693725586e-01,
              -0.00000000000000000e+00 -2.13086321949958801e-01 9.77033376693725586e-01,
              -0.00000000000000000e+00 -2.13086321949958801e-01 9.77033376693725586e-01,
              6.12013936042785645e-01 -5.82870423793792725e-01 5.34510016441345215e-01,
              2.18342289328575134e-01 -2.07945033907890320e-01 9.53459739685058594e-01,
              6.53265416622161865e-01 -4.97726052999496460e-01 5.70537507534027100e-01,
              -9.68189120292663574e-01 -1.84416979551315308e-01 1.69116005301475525e-01,
              -2.18342289328575134e-01 -2.07945033907890320e-01 9.53459739685058594e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 8.04107934236526489e-02 9.96761798858642578e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.85085189342498779e-01 -0.00000000000000000e+00 1.72067284584045410e-01,
              7.87863492965698242e-01 6.00276947021484375e-01 1.37618079781532288e-01,
              2.23221793770790100e-01 -0.00000000000000000e+00 9.74767684936523438e-01,
              -9.85085189342498779e-01 -0.00000000000000000e+00 1.72067284584045410e-01,
              -2.23221793770790100e-01 -0.00000000000000000e+00 9.74767684936523438e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              8.43771398067474365e-02 2.49267742037773132e-02 9.96122062206268311e-01,
              -0.00000000000000000e+00 8.24662595987319946e-02 9.96593832969665527e-01,
              -8.43771398067474365e-02 2.49267742037773132e-02 9.96122062206268311e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              7.87863492965698242e-01 6.00276947021484375e-01 1.37618079781532288e-01,
              2.23221793770790100e-01 -0.00000000000000000e+00 9.74767684936523438e-01,
              -7.87863492965698242e-01 -6.00276947021484375e-01 1.37618079781532288e-01,
              -7.18413829803466797e-01 -6.84203624725341797e-01 1.25487133860588074e-01,
              -2.23221793770790100e-01 -0.00000000000000000e+00 9.74767684936523438e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              2.62659415602684021e-02 -0.00000000000000000e+00 9.99655008316040039e-01,
              7.39397183060646057e-02 2.17280492186546326e-01 9.73304748535156250e-01,
              -0.00000000000000000e+00 -7.64871463179588318e-02 9.97070550918579102e-01,
              -7.39397183060646057e-02 2.17280492186546326e-01 9.73304748535156250e-01,
              -2.62659415602684021e-02 -0.00000000000000000e+00 9.99655008316040039e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.85085189342498779e-01 -0.00000000000000000e+00 1.72067284584045410e-01,
              2.23221793770790100e-01 -0.00000000000000000e+00 9.74767684936523438e-01,
              -9.68189120292663574e-01 -1.84416979551315308e-01 1.69116005301475525e-01,
              -2.18342289328575134e-01 -2.07945033907890320e-01 9.53459739685058594e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              1.02960407733917236e-01 -0.00000000000000000e+00 9.94685471057891846e-01,
              1.77202463150024414e-01 -1.88880503177642822e-01 9.65879619121551514e-01,
              -0.00000000000000000e+00 -5.37065453827381134e-02 9.98556733131408691e-01,
              -1.77202463150024414e-01 -1.88880503177642822e-01 9.65879619121551514e-01,
              -1.02960407733917236e-01 -0.00000000000000000e+00 9.94685471057891846e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -9.85085189342498779e-01 -0.00000000000000000e+00 1.72067284584045410e-01,
              -2.23221793770790100e-01 -0.00000000000000000e+00 9.74767684936523438e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -9.81058850884437561e-02 9.95175957679748535e-01,
              -0.00000000000000000e+00 -1.48808108642697334e-02 9.99889254570007324e-01,
              -0.00000000000000000e+00 -9.81058850884437561e-02 9.95175957679748535e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              7.87863492965698242e-01 6.00276947021484375e-01 1.37618079781532288e-01,
              2.23221793770790100e-01 -0.00000000000000000e+00 9.74767684936523438e-01,
              -9.85085189342498779e-01 -0.00000000000000000e+00 1.72067284584045410e-01,
              -2.23221793770790100e-01 -0.00000000000000000e+00 9.74767684936523438e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.68189120292663574e-01 1.84416979551315308e-01 1.69116005301475525e-01,
              7.18413829803466797e-01 6.84203624725341797e-01 1.25487133860588074e-01,
              2.18342289328575134e-01 2.07945033907890320e-01 9.53459739685058594e-01,
              -9.68189120292663574e-01 1.84416979551315308e-01 1.69116005301475525e-01,
              -2.02284559607505798e-01 9.63259816169738770e-01 1.76667734980583191e-01,
              -2.18342289328575134e-01 2.07945033907890320e-01 9.53459739685058594e-01,
              -0.00000000000000000e+00 9.83593881130218506e-01 1.80397123098373413e-01,
              -0.00000000000000000e+00 2.13086321949958801e-01 9.77033376693725586e-01,
              -6.36941850185394287e-01 7.58264124393463135e-01 1.39070272445678711e-01,
              -0.00000000000000000e+00 2.13086321949958801e-01 9.77033376693725586e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              7.87863492965698242e-01 6.00276947021484375e-01 1.37618079781532288e-01,
              2.23221793770790100e-01 -0.00000000000000000e+00 9.74767684936523438e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 1.25487133860588074e-01,
              -2.02284559607505798e-01 9.63259816169738770e-01 1.76667734980583191e-01,
              -2.18342289328575134e-01 2.07945033907890320e-01 9.53459739685058594e-01,
              -6.36941850185394287e-01 7.58264124393463135e-01 1.39070272445678711e-01,
              -0.00000000000000000e+00 2.13086321949958801e-01 9.77033376693725586e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.68189120292663574e-01 1.84416979551315308e-01 1.69116005301475525e-01,
              7.18413829803466797e-01 6.84203624725341797e-01 1.25487133860588074e-01,
              2.18342289328575134e-01 2.07945033907890320e-01 9.53459739685058594e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 1.25487133860588074e-01,
              -2.02284559607505798e-01 9.63259816169738770e-01 1.76667734980583191e-01,
              -2.18342289328575134e-01 2.07945033907890320e-01 9.53459739685058594e-01,
              7.18413829803466797e-01 6.84203624725341797e-01 1.25487133860588074e-01,
              2.02284559607505798e-01 9.63259816169738770e-01 1.76667734980583191e-01,
              2.18342289328575134e-01 2.07945033907890320e-01 9.53459739685058594e-01,
              -2.93896496295928955e-01 -3.02355289459228516e-01 -9.06755805015563965e-01,
              -0.00000000000000000e+00 -3.42203050851821899e-01 -9.39626038074493408e-01,
              2.93896496295928955e-01 -3.02355289459228516e-01 -9.06755805015563965e-01,
              -3.02964538335800171e-01 2.89530128240585327e-01 -9.07956361770629883e-01,
              -0.00000000000000000e+00 3.06607723236083984e-01 -9.51835989952087402e-01,
              3.02964538335800171e-01 2.89530128240585327e-01 -9.07956361770629883e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -1.97459384799003601e-01 -3.83041411638259888e-01 -9.02379691600799561e-01,
              -0.00000000000000000e+00 -3.49411129951477051e-01 -9.36969518661499023e-01,
              -0.00000000000000000e+00 -3.86111140251159668e-01 -9.22452270984649658e-01,
              -0.00000000000000000e+00 -3.11248242855072021e-01 -9.50328648090362549e-01,
              1.97459384799003601e-01 -3.83041411638259888e-01 -9.02379691600799561e-01,
              -0.00000000000000000e+00 -3.49411129951477051e-01 -9.36969518661499023e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -3.79504978656768799e-01 -2.08969444036483765e-01 -9.01281177997589111e-01,
              -3.64607423543930054e-01 -0.00000000000000000e+00 -9.31161344051361084e-01,
              3.79504978656768799e-01 -2.08969444036483765e-01 -9.01281177997589111e-01,
              3.64607423543930054e-01 -0.00000000000000000e+00 -9.31161344051361084e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -3.89750212430953979e-01 2.01157703995704651e-01 -8.98682594299316406e-01,
              -3.76344740390777588e-01 -0.00000000000000000e+00 -9.26479697227478027e-01,
              -2.11527213454246521e-01 3.73709917068481445e-01 -9.03104186058044434e-01,
              -0.00000000000000000e+00 3.80629479885101318e-01 -9.24727618694305420e-01,
              3.89750212430953979e-01 2.01157703995704651e-01 -8.98682594299316406e-01,
              2.11527213454246521e-01 3.73709917068481445e-01 -9.03104186058044434e-01,
              3.76344740390777588e-01 -0.00000000000000000e+00 -9.26479697227478027e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 3.60806971788406372e-01 -9.32640492916107178e-01,
              -0.00000000000000000e+00 3.37610423564910889e-01 -9.41285908222198486e-01,
              -0.00000000000000000e+00 3.60806971788406372e-01 -9.32640492916107178e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -2.05517232418060303e-01 -9.78653490543365479e-01 -0.00000000000000000e+00,
              -6.43192112445831299e-01 -7.65704870223999023e-01 -0.00000000000000000e+00,
              -6.43192112445831299e-01 -7.65704870223999023e-01 -0.00000000000000000e+00,
              6.43192112445831299e-01 -7.65704870223999023e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.05517232418060303e-01 -9.78653490543365479e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              -6.43192112445831299e-01 -7.65704870223999023e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.82338547706604004e-01 -1.87112107872962952e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              -2.05517232418060303e-01 -9.78653490543365479e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              -6.43192112445831299e-01 -7.65704870223999023e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              7.95431733131408691e-01 -6.06043219566345215e-01 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.82338547706604004e-01 -1.87112107872962952e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              -8.83901655673980713e-01 -4.67672824859619141e-01 -0.00000000000000000e+00,
              -9.02860522270202637e-01 -4.29933577775955200e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              7.95431733131408691e-01 -6.06043219566345215e-01 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              -9.02860522270202637e-01 4.29933577775955200e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.82338547706604004e-01 -1.87112107872962952e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              -8.83901655673980713e-01 4.67672824859619141e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -3.13212007284164429e-01 9.49683248996734619e-01,
              -0.00000000000000000e+00 -2.59521692991256714e-01 9.65737283229827881e-01,
              -0.00000000000000000e+00 -3.13212007284164429e-01 9.49683248996734619e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -3.27231496572494507e-01 -0.00000000000000000e+00 9.44944202899932861e-01,
              3.27231496572494507e-01 -0.00000000000000000e+00 9.44944202899932861e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -3.21254432201385498e-01 -0.00000000000000000e+00 9.46992933750152588e-01,
              3.21254432201385498e-01 -0.00000000000000000e+00 9.46992933750152588e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              7.95431733131408691e-01 6.06043219566345215e-01 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 3.07435065507888794e-01 9.51569080352783203e-01,
              -0.00000000000000000e+00 2.78572112321853638e-01 9.60415303707122803e-01,
              -0.00000000000000000e+00 3.07435065507888794e-01 9.51569080352783203e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              7.95431733131408691e-01 6.06043219566345215e-01 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -1.90509840846061707e-01 -3.39222371578216553e-01 9.21213448047637939e-01,
              -0.00000000000000000e+00 -3.34059149026870728e-01 9.42552089691162109e-01,
              1.90509840846061707e-01 -3.39222371578216553e-01 9.21213448047637939e-01,
              -3.33529323339462280e-01 -2.02715814113616943e-01 9.20686960220336914e-01,
              -2.64497280120849609e-01 -2.81604558229446411e-01 9.22355711460113525e-01,
              -0.00000000000000000e+00 -3.18462491035461426e-01 9.47935461997985840e-01,
              3.33529323339462280e-01 -2.02715814113616943e-01 9.20686960220336914e-01,
              2.64497280120849609e-01 -2.81604558229446411e-01 9.22355711460113525e-01,
              -3.32941681146621704e-01 2.19003260135650635e-01 9.17162716388702393e-01,
              -2.12892919778823853e-01 3.33959639072418213e-01 9.18230652809143066e-01,
              -2.83988267183303833e-01 2.92479306459426880e-01 9.13130044937133789e-01,
              -0.00000000000000000e+00 3.26754629611968994e-01 9.45109188556671143e-01,
              -0.00000000000000000e+00 2.87577599287033081e-01 9.57757353782653809e-01,
              3.32941681146621704e-01 2.19003260135650635e-01 9.17162716388702393e-01,
              2.12892919778823853e-01 3.33959639072418213e-01 9.18230652809143066e-01,
              2.83988267183303833e-01 2.92479306459426880e-01 9.13130044937133789e-01,
              -2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 8.24892744421958923e-02 -9.96591925621032715e-01,
              -0.00000000000000000e+00 8.24892744421958923e-02 -9.96591925621032715e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              8.65835472941398621e-02 -0.00000000000000000e+00 -9.96244609355926514e-01,
              5.06048679351806641e-01 4.40990716218948364e-01 -7.41243481636047363e-01,
              -5.06048679351806641e-01 4.40990716218948364e-01 -7.41243481636047363e-01,
              -8.65835472941398621e-02 -0.00000000000000000e+00 -9.96244609355926514e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              7.98458307981491089e-02 -0.00000000000000000e+00 -9.96807217597961426e-01,
              4.26259607076644897e-01 -1.22755691409111023e-01 -8.96233081817626953e-01,
              -4.26259607076644897e-01 -1.22755691409111023e-01 -8.96233081817626953e-01,
              -7.98458307981491089e-02 -0.00000000000000000e+00 -9.96807217597961426e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              9.28986743092536926e-02 -0.00000000000000000e+00 -9.95675563812255859e-01,
              2.20377579331398010e-01 -2.47753679752349854e-01 -9.43425595760345459e-01,
              -2.20377579331398010e-01 -2.47753679752349854e-01 -9.43425595760345459e-01,
              -9.28986743092536926e-02 -0.00000000000000000e+00 -9.95675563812255859e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -8.85104462504386902e-02 -9.96075272560119629e-01,
              -0.00000000000000000e+00 -8.85104462504386902e-02 -9.96075272560119629e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 2.63020217418670654e-01 -9.64790344238281250e-01,
              2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.05517232418060303e-01 -9.78653490543365479e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              2.05517232418060303e-01 -9.78653490543365479e-01 -0.00000000000000000e+00,
              -2.05517232418060303e-01 -9.78653490543365479e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              -6.43192112445831299e-01 -7.65704870223999023e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.53898113965988159e-01 -9.67230975627899170e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              -6.43192112445831299e-01 -7.65704870223999023e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.82338547706604004e-01 -1.87112107872962952e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              -1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              -7.95431733131408691e-01 6.06043219566345215e-01 -0.00000000000000000e+00,
              -2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -4.43109497427940369e-02 9.99017775058746338e-01,
              -0.00000000000000000e+00 -4.43109497427940369e-02 9.99017775058746338e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -7.95431733131408691e-01 6.06043219566345215e-01 -0.00000000000000000e+00,
              -2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -4.65218164026737213e-02 -0.00000000000000000e+00 9.98917281627655029e-01,
              -3.80320191383361816e-01 -3.08164983987808228e-01 8.72003972530364990e-01,
              3.80320191383361816e-01 -3.08164983987808228e-01 8.72003972530364990e-01,
              4.65218164026737213e-02 -0.00000000000000000e+00 9.98917281627655029e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -3.15785296261310577e-02 -0.00000000000000000e+00 9.99501287937164307e-01,
              -1.97646945714950562e-01 3.35436791181564331e-01 9.21096026897430420e-01,
              1.97646945714950562e-01 3.35436791181564331e-01 9.21096026897430420e-01,
              3.15785296261310577e-02 -0.00000000000000000e+00 9.99501287937164307e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -7.95431733131408691e-01 6.06043219566345215e-01 -0.00000000000000000e+00,
              -2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.28986743092536926e-02 -0.00000000000000000e+00 9.95675563812255859e-01,
              2.26294174790382385e-01 -1.01519517600536346e-01 9.68754231929779053e-01,
              -2.26294174790382385e-01 -1.01519517600536346e-01 9.68754231929779053e-01,
              -9.28986743092536926e-02 -0.00000000000000000e+00 9.95675563812255859e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -8.85104462504386902e-02 9.96075272560119629e-01,
              -0.00000000000000000e+00 -8.85104462504386902e-02 9.96075272560119629e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 9.64790344238281250e-01,
              -6.43192112445831299e-01 7.65704870223999023e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 9.64790344238281250e-01,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -5.64619958400726318e-01 -4.20528918504714966e-01 -7.10182905197143555e-01,
              5.64619958400726318e-01 -4.20528918504714966e-01 -7.10182905197143555e-01,
              -7.06029415130615234e-01 2.67347823828458786e-02 -7.07677662372589111e-01,
              7.06029415130615234e-01 2.67347823828458786e-02 -7.07677662372589111e-01,
              -5.77127397060394287e-01 4.43638861179351807e-01 -6.85644567012786865e-01,
              5.77127397060394287e-01 4.43638861179351807e-01 -6.85644567012786865e-01,
              -5.67140281200408936e-01 -6.53124451637268066e-01 -5.01777231693267822e-01,
              5.67140281200408936e-01 -6.53124451637268066e-01 -5.01777231693267822e-01,
              -7.76926636695861816e-01 -4.05461758375167847e-01 -4.81649011373519897e-01,
              7.76926636695861816e-01 -4.05461758375167847e-01 -4.81649011373519897e-01,
              -7.77843713760375977e-01 2.63904910534620285e-02 -6.27903401851654053e-01,
              7.77843713760375977e-01 2.63904910534620285e-02 -6.27903401851654053e-01,
              -7.69179821014404297e-01 4.27596539258956909e-01 -4.74893271923065186e-01,
              -5.76093196868896484e-01 6.52857363224029541e-01 -4.91827100515365601e-01,
              7.69179821014404297e-01 4.27596539258956909e-01 -4.74893271923065186e-01,
              5.76093196868896484e-01 6.52857363224029541e-01 -4.91827100515365601e-01,
              -5.64093351364135742e-01 -6.67303800582885742e-01 4.86317127943038940e-01,
              5.64093351364135742e-01 -6.67303800582885742e-01 4.86317127943038940e-01,
              -7.83071875572204590e-01 -4.10794287919998169e-01 4.66954439878463745e-01,
              -5.64215719699859619e-01 -4.27921026945114136e-01 7.06076622009277344e-01,
              7.83071875572204590e-01 -4.10794287919998169e-01 4.66954439878463745e-01,
              5.64215719699859619e-01 -4.27921026945114136e-01 7.06076622009277344e-01,
              -8.04443001747131348e-01 5.43685071170330048e-02 5.91536581516265869e-01,
              -7.13540136814117432e-01 5.55231347680091858e-02 6.98410809040069580e-01,
              8.04443001747131348e-01 5.43685071170330048e-02 5.91536581516265869e-01,
              7.13540136814117432e-01 5.55231347680091858e-02 6.98410809040069580e-01,
              -7.45960354804992676e-01 4.47688966989517212e-01 4.93069708347320557e-01,
              -5.66872000694274902e-01 6.49725735187530518e-01 5.06470680236816406e-01,
              -5.68006336688995361e-01 4.60776746273040771e-01 6.81948363780975342e-01,
              7.45960354804992676e-01 4.47688966989517212e-01 4.93069708347320557e-01,
              5.66872000694274902e-01 6.49725735187530518e-01 5.06470680236816406e-01,
              5.68006336688995361e-01 4.60776746273040771e-01 6.81948363780975342e-01,
              -2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -3.00002563744783401e-02 -9.99549865722656250e-01,
              -0.00000000000000000e+00 -3.00002563744783401e-02 -9.99549865722656250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -3.14988158643245697e-02 -0.00000000000000000e+00 -9.99503791332244873e-01,
              -2.63757497072219849e-01 -1.91242724657058716e-01 -9.45440769195556641e-01,
              2.63757497072219849e-01 -1.91242724657058716e-01 -9.45440769195556641e-01,
              3.14988158643245697e-02 -0.00000000000000000e+00 -9.99503791332244873e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -1.30114266648888588e-02 -0.00000000000000000e+00 -9.99915361404418945e-01,
              -7.71019160747528076e-02 1.93831980228424072e-01 -9.78000223636627197e-01,
              7.71019160747528076e-02 1.93831980228424072e-01 -9.78000223636627197e-01,
              1.30114266648888588e-02 -0.00000000000000000e+00 -9.99915361404418945e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              4.66389916837215424e-02 -0.00000000000000000e+00 -9.98911798000335693e-01,
              6.98778405785560608e-02 -2.83118374645709991e-02 -9.97153699398040771e-01,
              -6.98778405785560608e-02 -2.83118374645709991e-02 -9.97153699398040771e-01,
              -4.66389916837215424e-02 -0.00000000000000000e+00 -9.98911798000335693e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -4.44225780665874481e-02 -9.99012827873229980e-01,
              -0.00000000000000000e+00 -4.44225780665874481e-02 -9.99012827873229980e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 2.63020217418670654e-01 -9.64790344238281250e-01,
              2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 3.57147306203842163e-02 -9.99362051486968994e-01,
              -0.00000000000000000e+00 -2.30502694845199585e-01 -9.73071694374084473e-01,
              2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              3.74980159103870392e-02 -0.00000000000000000e+00 -9.99296724796295166e-01,
              1.74944832921028137e-01 9.43784490227699280e-02 -9.80044364929199219e-01,
              -1.74944832921028137e-01 9.43784490227699280e-02 -9.80044364929199219e-01,
              -3.74980159103870392e-02 -0.00000000000000000e+00 -9.99296724796295166e-01,
              2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -5.26170991361141205e-03 -0.00000000000000000e+00 -9.99986171722412109e-01,
              -2.87687908858060837e-02 -9.36196669936180115e-02 -9.95192289352416992e-01,
              2.87687908858060837e-02 -9.36196669936180115e-02 -9.95192289352416992e-01,
              5.26170991361141205e-03 -0.00000000000000000e+00 -9.99986171722412109e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -8.36948119103908539e-03 -0.00000000000000000e+00 -9.99964952468872070e-01,
              -7.76865929365158081e-02 5.88680133223533630e-02 -9.95238363742828369e-01,
              7.76865929365158081e-02 5.88680133223533630e-02 -9.95238363742828369e-01,
              8.36948119103908539e-03 -0.00000000000000000e+00 -9.99964952468872070e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 7.97096081078052521e-03 -9.99968230724334717e-01,
              -0.00000000000000000e+00 7.97096081078052521e-03 -9.99968230724334717e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 -9.64790344238281250e-01,
              2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 2.63020217418670654e-01 -9.64790344238281250e-01,
              2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.05517232418060303e-01 -9.78653490543365479e-01 -0.00000000000000000e+00,
              2.05517232418060303e-01 -9.78653490543365479e-01 -0.00000000000000000e+00,
              -2.05517232418060303e-01 -9.78653490543365479e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              -6.43192112445831299e-01 -7.65704870223999023e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.05517232418060303e-01 -9.78653490543365479e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              -6.43192112445831299e-01 -7.65704870223999023e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.82338547706604004e-01 -1.87112107872962952e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              -7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 7.29087963700294495e-02 9.97338593006134033e-01,
              -0.00000000000000000e+00 7.29087963700294495e-02 9.97338593006134033e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -9.82338547706604004e-01 -1.87112107872962952e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              7.65333846211433411e-02 -0.00000000000000000e+00 9.97067034244537354e-01,
              4.66264188289642334e-01 3.90334546566009521e-01 7.93874442577362061e-01,
              -4.66264188289642334e-01 3.90334546566009521e-01 7.93874442577362061e-01,
              -7.65333846211433411e-02 -0.00000000000000000e+00 9.97067034244537354e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              6.42728507518768311e-02 -0.00000000000000000e+00 9.97932374477386475e-01,
              3.27855080366134644e-01 -2.13769182562828064e-01 9.20224845409393311e-01,
              -3.27855080366134644e-01 -2.13769182562828064e-01 9.20224845409393311e-01,
              -6.42728507518768311e-02 -0.00000000000000000e+00 9.97932374477386475e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -7.95431733131408691e-01 6.06043219566345215e-01 -0.00000000000000000e+00,
              -2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              4.66389916837215424e-02 -0.00000000000000000e+00 9.98911798000335693e-01,
              6.94385543465614319e-02 -1.15432985126972198e-01 9.90885198116302490e-01,
              -6.94385543465614319e-02 -1.15432985126972198e-01 9.90885198116302490e-01,
              -4.66389916837215424e-02 -0.00000000000000000e+00 9.98911798000335693e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -4.44225780665874481e-02 9.99012827873229980e-01,
              -0.00000000000000000e+00 -4.44225780665874481e-02 9.99012827873229980e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 9.64790344238281250e-01,
              -6.43192112445831299e-01 7.65704870223999023e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 9.64790344238281250e-01,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -9.72806215286254883e-01 -2.31620520353317261e-01 -0.00000000000000000e+00,
              6.43192112445831299e-01 -7.65704870223999023e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              6.43192112445831299e-01 -7.65704870223999023e-01 -0.00000000000000000e+00,
              2.05517232418060303e-01 -9.78653490543365479e-01 -0.00000000000000000e+00,
              -1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              -2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              6.79610848426818848e-01 -7.33572781085968018e-01 -0.00000000000000000e+00,
              2.05517232418060303e-01 -9.78653490543365479e-01 -0.00000000000000000e+00,
              -7.95431733131408691e-01 -6.06043219566345215e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 1.26164585351943970e-01 9.92009341716766357e-01,
              -0.00000000000000000e+00 -1.43924012780189514e-01 9.89588737487792969e-01,
              7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              -9.82338547706604004e-01 -1.87112107872962952e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              1.32364869117736816e-01 -0.00000000000000000e+00 9.91201043128967285e-01,
              4.49377924203872681e-01 3.57007563114166260e-01 8.18904817104339600e-01,
              -4.49377924203872681e-01 3.57007563114166260e-01 8.18904817104339600e-01,
              -1.32364869117736816e-01 -0.00000000000000000e+00 9.91201043128967285e-01,
              7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              6.43192112445831299e-01 -7.65704870223999023e-01 -0.00000000000000000e+00,
              2.05517232418060303e-01 -9.78653490543365479e-01 -0.00000000000000000e+00,
              -7.95431733131408691e-01 -6.06043219566345215e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              6.68890550732612610e-02 -0.00000000000000000e+00 9.97760415077209473e-01,
              3.20517867803573608e-01 5.36758825182914734e-03 9.47227239608764648e-01,
              -3.20517867803573608e-01 5.36758825182914734e-03 9.47227239608764648e-01,
              -6.68890550732612610e-02 -0.00000000000000000e+00 9.97760415077209473e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              -9.82338547706604004e-01 -1.87112107872962952e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              1.10050156712532043e-01 -0.00000000000000000e+00 9.93926048278808594e-01,
              5.76638221740722656e-01 -4.33495193719863892e-01 6.92510128021240234e-01,
              -5.76638221740722656e-01 -4.33495193719863892e-01 6.92510128021240234e-01,
              -1.10050156712532043e-01 -0.00000000000000000e+00 9.93926048278808594e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              8.83901655673980713e-01 -4.67672824859619141e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              9.02860522270202637e-01 -4.29933577775955200e-01 -0.00000000000000000e+00,
              -7.95431733131408691e-01 -6.06043219566345215e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -1.04868732392787933e-01 9.94486093521118164e-01,
              -0.00000000000000000e+00 -1.04868732392787933e-01 9.94486093521118164e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.02860522270202637e-01 4.29933577775955200e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -9.82338547706604004e-01 -1.87112107872962952e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              6.43192112445831299e-01 7.65704870223999023e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 9.64790344238281250e-01,
              8.83901655673980713e-01 4.67672824859619141e-01 -0.00000000000000000e+00,
              2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 9.64790344238281250e-01,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 2.80115325003862381e-02 -9.99607622623443604e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.92621869593858719e-02 -1.00443854928016663e-01 -9.94512319564819336e-01,
              -0.00000000000000000e+00 -3.26446555554866791e-02 -9.99467015266418457e-01,
              -2.92621869593858719e-02 -1.00443854928016663e-01 -9.94512319564819336e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -1.05456672608852386e-01 -0.00000000000000000e+00 -9.94423925876617432e-01,
              -9.18517932295799255e-02 -8.49619954824447632e-02 -9.92141485214233398e-01,
              -0.00000000000000000e+00 -1.39256909489631653e-01 -9.90256309509277344e-01,
              9.18517932295799255e-02 -8.49619954824447632e-02 -9.92141485214233398e-01,
              1.05456672608852386e-01 -0.00000000000000000e+00 -9.94423925876617432e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -3.97202372550964355e-02 -0.00000000000000000e+00 -9.99210834503173828e-01,
              -1.17184117436408997e-01 1.25073701143264771e-01 -9.85202729701995850e-01,
              -0.00000000000000000e+00 8.85867998003959656e-02 -9.96068477630615234e-01,
              1.17184117436408997e-01 1.25073701143264771e-01 -9.85202729701995850e-01,
              3.97202372550964355e-02 -0.00000000000000000e+00 -9.99210834503173828e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 3.78315709531307220e-02 -9.99284148216247559e-01,
              -0.00000000000000000e+00 8.59553664922714233e-02 -9.96298968791961670e-01,
              -0.00000000000000000e+00 3.78315709531307220e-02 -9.99284148216247559e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.05517232418060303e-01 -9.78653490543365479e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              2.05517232418060303e-01 -9.78653490543365479e-01 -0.00000000000000000e+00,
              -9.82338547706604004e-01 -1.87112107872962952e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              6.43192112445831299e-01 -7.65704870223999023e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              2.05517232418060303e-01 -9.78653490543365479e-01 -0.00000000000000000e+00,
              -7.95431733131408691e-01 -6.06043219566345215e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              7.95431733131408691e-01 -6.06043219566345215e-01 -0.00000000000000000e+00,
              -9.82338547706604004e-01 -1.87112107872962952e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -4.05959963798522949e-01 9.13890838623046875e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              7.95431733131408691e-01 6.06043219566345215e-01 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              -2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -3.98355782032012939e-01 -3.34482908248901367e-01 8.54068994522094727e-01,
              3.98355782032012939e-01 -3.34482908248901367e-01 8.54068994522094727e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              7.95431733131408691e-01 6.06043219566345215e-01 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -7.95431733131408691e-01 -6.06043219566345215e-01 -0.00000000000000000e+00,
              -7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -3.80316048860549927e-01 -0.00000000000000000e+00 9.24856603145599365e-01,
              3.80316048860549927e-01 -0.00000000000000000e+00 9.24856603145599365e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -9.82338547706604004e-01 -1.87112107872962952e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -4.14884358644485474e-01 -0.00000000000000000e+00 9.09874141216278076e-01,
              4.14884358644485474e-01 -0.00000000000000000e+00 9.09874141216278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              -2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 3.98328036069869995e-01 9.17243003845214844e-01,
              -0.00000000000000000e+00 3.67117494344711304e-01 9.30174589157104492e-01,
              -0.00000000000000000e+00 3.98328036069869995e-01 9.17243003845214844e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              7.95431733131408691e-01 6.06043219566345215e-01 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              -2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              -2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 9.64790344238281250e-01,
              -6.43192112445831299e-01 7.65704870223999023e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              7.95431733131408691e-01 6.06043219566345215e-01 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -6.43192112445831299e-01 7.65704870223999023e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 -4.14844095706939697e-01 9.09892499446868896e-01,
              -2.47796550393104553e-01 -3.95218670368194580e-01 8.84533286094665527e-01,
              -4.13008362054824829e-01 -4.24379527568817139e-01 8.05807769298553467e-01,
              2.47796550393104553e-01 -3.95218670368194580e-01 8.84533286094665527e-01,
              -0.00000000000000000e+00 -2.75138556957244873e-01 9.61404561996459961e-01,
              4.13008362054824829e-01 -4.24379527568817139e-01 8.05807769298553467e-01,
              -4.37548488378524780e-01 -2.24886894226074219e-01 8.70618879795074463e-01,
              -3.05487751960754395e-01 -3.48070234060287476e-01 8.86298120021820068e-01,
              -0.00000000000000000e+00 2.16882536187767982e-03 9.99997675418853760e-01,
              4.37548488378524780e-01 -2.24886894226074219e-01 8.70618879795074463e-01,
              3.05487751960754395e-01 -3.48070234060287476e-01 8.86298120021820068e-01,
              -4.65454936027526855e-01 1.93810567259788513e-01 8.63590836524963379e-01,
              -2.11623042821884155e-01 4.41660612821578979e-01 8.71866703033447266e-01,
              -3.24942201375961304e-01 3.00443381071090698e-01 8.96742045879364014e-01,
              -0.00000000000000000e+00 4.44536000490188599e-01 8.95761013031005859e-01,
              -0.00000000000000000e+00 3.11925500631332397e-01 9.50106561183929443e-01,
              4.65454936027526855e-01 1.93810567259788513e-01 8.63590836524963379e-01,
              2.11623042821884155e-01 4.41660612821578979e-01 8.71866703033447266e-01,
              3.24942201375961304e-01 3.00443381071090698e-01 8.96742045879364014e-01,
              -4.27022069692611694e-01 -4.06884312629699707e-01 -8.07525396347045898e-01,
              4.27022069692611694e-01 -4.06884312629699707e-01 -8.07525396347045898e-01,
              -5.06551980972290039e-01 -1.22398644685745239e-01 -8.53477418422698975e-01,
              5.06551980972290039e-01 -1.22398644685745239e-01 -8.53477418422698975e-01,
              -4.55892145633697510e-01 3.46451371908187866e-01 -8.19837689399719238e-01,
              4.55892145633697510e-01 3.46451371908187866e-01 -8.19837689399719238e-01,
              -4.40523505210876465e-01 -6.14656150341033936e-01 -6.54321730136871338e-01,
              4.40523505210876465e-01 -6.14656150341033936e-01 -6.54321730136871338e-01,
              -6.38638377189636230e-01 -4.15495753288269043e-01 -6.47691488265991211e-01,
              6.38638377189636230e-01 -4.15495753288269043e-01 -6.47691488265991211e-01,
              -7.47741281986236572e-01 -1.23306207358837128e-01 -6.52440428733825684e-01,
              7.47741281986236572e-01 -1.23306207358837128e-01 -6.52440428733825684e-01,
              -8.13935816287994385e-01 3.36837798357009888e-01 -4.73337918519973755e-01,
              -4.68142986297607422e-01 7.28637158870697021e-01 -4.99930024147033691e-01,
              8.13935816287994385e-01 3.36837798357009888e-01 -4.73337918519973755e-01,
              4.68142986297607422e-01 7.28637158870697021e-01 -4.99930024147033691e-01,
              -0.00000000000000000e+00 -2.13086321949958801e-01 -9.77033376693725586e-01,
              -2.18342289328575134e-01 -2.07945033907890320e-01 -9.53459739685058594e-01,
              -0.00000000000000000e+00 -2.13086321949958801e-01 -9.77033376693725586e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.18342289328575134e-01 -2.07945033907890320e-01 -9.53459739685058594e-01,
              -2.18342289328575134e-01 -2.07945033907890320e-01 -9.53459739685058594e-01,
              -0.00000000000000000e+00 -2.13086321949958801e-01 -9.77033376693725586e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.23221793770790100e-01 -0.00000000000000000e+00 -9.74767684936523438e-01,
              -2.18342289328575134e-01 -2.07945033907890320e-01 -9.53459739685058594e-01,
              -0.00000000000000000e+00 -2.13086321949958801e-01 -9.77033376693725586e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.18342289328575134e-01 -2.07945033907890320e-01 -9.53459739685058594e-01,
              -2.18342289328575134e-01 -2.07945033907890320e-01 -9.53459739685058594e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.23221793770790100e-01 -0.00000000000000000e+00 -9.74767684936523438e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.18342289328575134e-01 -2.07945033907890320e-01 -9.53459739685058594e-01,
              -2.18342289328575134e-01 2.07945033907890320e-01 -9.53459739685058594e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 2.92990934103727341e-02 -9.99570667743682861e-01,
              -0.00000000000000000e+00 7.74050876498222351e-03 -9.99970018863677979e-01,
              -0.00000000000000000e+00 2.92990934103727341e-02 -9.99570667743682861e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.23221793770790100e-01 -0.00000000000000000e+00 -9.74767684936523438e-01,
              -2.18342289328575134e-01 2.07945033907890320e-01 -9.53459739685058594e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              3.07626947760581970e-02 -0.00000000000000000e+00 -9.99526739120483398e-01,
              4.30035442113876343e-02 1.49724528193473816e-01 -9.87792134284973145e-01,
              -0.00000000000000000e+00 2.13387105613946915e-02 -9.99772310256958008e-01,
              -4.30035442113876343e-02 1.49724528193473816e-01 -9.87792134284973145e-01,
              -3.07626947760581970e-02 -0.00000000000000000e+00 -9.99526739120483398e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.18342289328575134e-01 2.07945033907890320e-01 -9.53459739685058594e-01,
              -2.18342289328575134e-01 2.07945033907890320e-01 -9.53459739685058594e-01,
              8.12857896089553833e-02 -0.00000000000000000e+00 -9.96690809726715088e-01,
              9.86579358577728271e-02 -1.21880680322647095e-01 -9.87629354000091553e-01,
              -0.00000000000000000e+00 -2.13592872023582458e-02 -9.99771893024444580e-01,
              -9.86579358577728271e-02 -1.21880680322647095e-01 -9.87629354000091553e-01,
              -8.12857896089553833e-02 -0.00000000000000000e+00 -9.96690809726715088e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.23221793770790100e-01 -0.00000000000000000e+00 -9.74767684936523438e-01,
              -2.18342289328575134e-01 2.07945033907890320e-01 -9.53459739685058594e-01,
              -0.00000000000000000e+00 -7.74388238787651062e-02 -9.96997117996215820e-01,
              -0.00000000000000000e+00 -8.77135992050170898e-03 -9.99961555004119873e-01,
              -0.00000000000000000e+00 -7.74388238787651062e-02 -9.96997117996215820e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.23221793770790100e-01 -0.00000000000000000e+00 -9.74767684936523438e-01,
              -2.18342289328575134e-01 2.07945033907890320e-01 -9.53459739685058594e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.18342289328575134e-01 2.07945033907890320e-01 -9.53459739685058594e-01,
              -2.18342289328575134e-01 2.07945033907890320e-01 -9.53459739685058594e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.23221793770790100e-01 -0.00000000000000000e+00 -9.74767684936523438e-01,
              -3.99005383253097534e-01 -8.93761694431304932e-01 2.04901695251464844e-01,
              3.99005383253097534e-01 -8.93761694431304932e-01 2.04901695251464844e-01,
              -2.18342289328575134e-01 2.07945033907890320e-01 -9.53459739685058594e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.23221793770790100e-01 -0.00000000000000000e+00 -9.74767684936523438e-01,
              -9.32126104831695557e-01 -3.06933850049972534e-01 1.92178428173065186e-01,
              -4.41488772630691528e-01 -4.03271406888961792e-01 8.01535904407501221e-01,
              9.32126104831695557e-01 -3.06933850049972534e-01 1.92178428173065186e-01,
              4.41488772630691528e-01 -4.03271406888961792e-01 8.01535904407501221e-01,
              -2.18342289328575134e-01 2.07945033907890320e-01 -9.53459739685058594e-01,
              2.18342289328575134e-01 2.07945033907890320e-01 -9.53459739685058594e-01,
              -9.69628572463989258e-01 4.92580495774745941e-02 2.39570721983909607e-01,
              -5.38299620151519775e-01 5.31123280525207520e-02 8.41078221797943115e-01,
              9.69628572463989258e-01 4.92580495774745941e-02 2.39570721983909607e-01,
              5.38299620151519775e-01 5.31123280525207520e-02 8.41078221797943115e-01,
              -8.88974606990814209e-01 3.60214889049530029e-01 2.82788664102554321e-01,
              -4.11592721939086914e-01 8.62649381160736084e-01 2.93985575437545776e-01,
              -4.36877131462097168e-01 4.35237556695938110e-01 7.87214457988739014e-01,
              8.88974606990814209e-01 3.60214889049530029e-01 2.82788664102554321e-01,
              4.11592721939086914e-01 8.62649381160736084e-01 2.93985575437545776e-01,
              4.36877131462097168e-01 4.35237556695938110e-01 7.87214457988739014e-01,
              -0.00000000000000000e+00 -9.83593881130218506e-01 -1.80397123098373413e-01,
              -2.02284559607505798e-01 -9.63259816169738770e-01 -1.76667734980583191e-01,
              -6.36941850185394287e-01 -7.58264124393463135e-01 -1.39070272445678711e-01,
              -6.36941850185394287e-01 -7.58264124393463135e-01 -1.39070272445678711e-01,
              6.36941850185394287e-01 -7.58264124393463135e-01 -1.39070272445678711e-01,
              7.18413829803466797e-01 -6.84203624725341797e-01 -1.25487133860588074e-01,
              -2.02284559607505798e-01 -9.63259816169738770e-01 -1.76667734980583191e-01,
              -7.18413829803466797e-01 -6.84203624725341797e-01 -1.25487133860588074e-01,
              -6.36941850185394287e-01 -7.58264124393463135e-01 -1.39070272445678711e-01,
              9.68189120292663574e-01 -1.84416979551315308e-01 -1.69116005301475525e-01,
              -2.02284559607505798e-01 -9.63259816169738770e-01 -1.76667734980583191e-01,
              -7.18413829803466797e-01 -6.84203624725341797e-01 -1.25487133860588074e-01,
              -6.36941850185394287e-01 -7.58264124393463135e-01 -1.39070272445678711e-01,
              7.87863492965698242e-01 -6.00276947021484375e-01 -1.37618079781532288e-01,
              7.18413829803466797e-01 -6.84203624725341797e-01 -1.25487133860588074e-01,
              -7.18413829803466797e-01 -6.84203624725341797e-01 -1.25487133860588074e-01,
              -7.18413829803466797e-01 -6.84203624725341797e-01 -1.25487133860588074e-01,
              9.68189120292663574e-01 -1.84416979551315308e-01 -1.69116005301475525e-01,
              -8.80667984485626221e-01 -4.65961873531341553e-01 -8.54602605104446411e-02,
              -8.98506522178649902e-01 -4.27860230207443237e-01 -9.80902314186096191e-02,
              7.87863492965698242e-01 -6.00276947021484375e-01 -1.37618079781532288e-01,
              7.18413829803466797e-01 -6.84203624725341797e-01 -1.25487133860588074e-01,
              -8.98506522178649902e-01 4.27860230207443237e-01 -9.80902314186096191e-02,
              9.68189120292663574e-01 -1.84416979551315308e-01 -1.69116005301475525e-01,
              -8.80667984485626221e-01 4.65961873531341553e-01 -8.54602605104446411e-02,
              -7.18413829803466797e-01 6.84203624725341797e-01 -1.25487133860588074e-01,
              7.53187596797943115e-01 -0.00000000000000000e+00 -6.57805740833282471e-01,
              7.41361081600189209e-01 -1.76514536142349243e-01 -6.47476911544799805e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 -1.25487133860588074e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 -1.25487133860588074e-01,
              7.45554208755493164e-01 1.42010316252708435e-01 -6.51139020919799805e-01,
              6.12013936042785645e-01 5.82870423793792725e-01 -5.34510016441345215e-01,
              7.53187596797943115e-01 -0.00000000000000000e+00 -6.57805740833282471e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 -9.64790344238281250e-01,
              2.66205906867980957e-01 -2.53529459238052368e-01 -9.29976999759674072e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 -1.25487133860588074e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 -1.25487133860588074e-01,
              6.53265416622161865e-01 4.97726052999496460e-01 -5.70537507534027100e-01,
              6.12013936042785645e-01 5.82870423793792725e-01 -5.34510016441345215e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 1.12564399838447571e-01 -9.93644416332244873e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 -1.25487133860588074e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 -1.25487133860588074e-01,
              7.53187596797943115e-01 -0.00000000000000000e+00 -6.57805740833282471e-01,
              7.53187596797943115e-01 -0.00000000000000000e+00 -6.57805740833282471e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              1.17977634072303772e-01 4.83796074986457825e-02 -9.91837024688720703e-01,
              -0.00000000000000000e+00 2.23531931638717651e-01 -9.74696636199951172e-01,
              -1.17977634072303772e-01 4.83796074986457825e-02 -9.91837024688720703e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 -1.25487133860588074e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 -1.25487133860588074e-01,
              7.45554208755493164e-01 1.42010316252708435e-01 -6.51139020919799805e-01,
              6.12013936042785645e-01 5.82870423793792725e-01 -5.34510016441345215e-01,
              7.53187596797943115e-01 -0.00000000000000000e+00 -6.57805740833282471e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              5.11496253311634064e-02 -0.00000000000000000e+00 -9.98691022396087646e-01,
              2.28679761290550232e-01 4.93010252714157104e-01 -8.39432239532470703e-01,
              -0.00000000000000000e+00 -9.68258827924728394e-02 -9.95301306247711182e-01,
              -2.28679761290550232e-01 4.93010252714157104e-01 -8.39432239532470703e-01,
              -5.11496253311634064e-02 -0.00000000000000000e+00 -9.98691022396087646e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 -1.25487133860588074e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 -1.25487133860588074e-01,
              6.53265416622161865e-01 4.97726052999496460e-01 -5.70537507534027100e-01,
              6.12013936042785645e-01 5.82870423793792725e-01 -5.34510016441345215e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              1.41755089163780212e-01 -0.00000000000000000e+00 -9.89901781082153320e-01,
              4.35189664363861084e-01 -4.46635991334915161e-01 -7.81745612621307373e-01,
              -0.00000000000000000e+00 -1.86706244945526123e-01 -9.82415795326232910e-01,
              -4.35189664363861084e-01 -4.46635991334915161e-01 -7.81745612621307373e-01,
              -1.41755089163780212e-01 -0.00000000000000000e+00 -9.89901781082153320e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 -1.25487133860588074e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 -1.25487133860588074e-01,
              7.53187596797943115e-01 -0.00000000000000000e+00 -6.57805740833282471e-01,
              7.53187596797943115e-01 -0.00000000000000000e+00 -6.57805740833282471e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -1.35131135582923889e-01 -9.90827739238739014e-01,
              -0.00000000000000000e+00 -3.86709459125995636e-02 -9.99252021312713623e-01,
              -0.00000000000000000e+00 -1.35131135582923889e-01 -9.90827739238739014e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 -1.25487133860588074e-01,
              -2.02284559607505798e-01 9.63259816169738770e-01 -1.76667734980583191e-01,
              7.45554208755493164e-01 1.42010316252708435e-01 -6.51139020919799805e-01,
              2.02284559607505798e-01 9.63259816169738770e-01 -1.76667734980583191e-01,
              7.53187596797943115e-01 -0.00000000000000000e+00 -6.57805740833282471e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 -9.61387813091278076e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 2.63020217418670654e-01 -9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 -1.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              2.66205906867980957e-01 2.53529459238052368e-01 -9.29976999759674072e-01,
              -0.00000000000000000e+00 -9.83593881130218506e-01 1.80397123098373413e-01,
              -2.02284559607505798e-01 -9.63259816169738770e-01 1.76667734980583191e-01,
              -6.36941850185394287e-01 -7.58264124393463135e-01 1.39070272445678711e-01,
              -6.36941850185394287e-01 -7.58264124393463135e-01 1.39070272445678711e-01,
              6.36941850185394287e-01 -7.58264124393463135e-01 1.39070272445678711e-01,
              -0.00000000000000000e+00 -2.13086321949958801e-01 9.77033376693725586e-01,
              7.18413829803466797e-01 -6.84203624725341797e-01 1.25487133860588074e-01,
              -2.02284559607505798e-01 -9.63259816169738770e-01 1.76667734980583191e-01,
              -7.18413829803466797e-01 -6.84203624725341797e-01 1.25487133860588074e-01,
              -6.36941850185394287e-01 -7.58264124393463135e-01 1.39070272445678711e-01,
              -2.18342289328575134e-01 -2.07945033907890320e-01 9.53459739685058594e-01,
              -0.00000000000000000e+00 -2.13086321949958801e-01 9.77033376693725586e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.68189120292663574e-01 -1.84416979551315308e-01 1.69116005301475525e-01,
              2.18342289328575134e-01 -2.07945033907890320e-01 9.53459739685058594e-01,
              -2.02284559607505798e-01 -9.63259816169738770e-01 1.76667734980583191e-01,
              -7.18413829803466797e-01 -6.84203624725341797e-01 1.25487133860588074e-01,
              -6.36941850185394287e-01 -7.58264124393463135e-01 1.39070272445678711e-01,
              -2.18342289328575134e-01 -2.07945033907890320e-01 9.53459739685058594e-01,
              -0.00000000000000000e+00 -2.13086321949958801e-01 9.77033376693725586e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              7.87863492965698242e-01 -6.00276947021484375e-01 1.37618079781532288e-01,
              2.23221793770790100e-01 -0.00000000000000000e+00 9.74767684936523438e-01,
              7.18413829803466797e-01 -6.84203624725341797e-01 1.25487133860588074e-01,
              -7.18413829803466797e-01 -6.84203624725341797e-01 1.25487133860588074e-01,
              -7.18413829803466797e-01 -6.84203624725341797e-01 1.25487133860588074e-01,
              -2.18342289328575134e-01 -2.07945033907890320e-01 9.53459739685058594e-01,
              -0.00000000000000000e+00 -2.13086321949958801e-01 9.77033376693725586e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.68189120292663574e-01 -1.84416979551315308e-01 1.69116005301475525e-01,
              2.18342289328575134e-01 -2.07945033907890320e-01 9.53459739685058594e-01,
              -8.80667984485626221e-01 -4.65961873531341553e-01 8.54602605104446411e-02,
              -8.98506522178649902e-01 -4.27860230207443237e-01 9.80902314186096191e-02,
              -2.18342289328575134e-01 -2.07945033907890320e-01 9.53459739685058594e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              7.87863492965698242e-01 -6.00276947021484375e-01 1.37618079781532288e-01,
              2.23221793770790100e-01 -0.00000000000000000e+00 9.74767684936523438e-01,
              7.18413829803466797e-01 -6.84203624725341797e-01 1.25487133860588074e-01,
              -8.98506522178649902e-01 4.27860230207443237e-01 9.80902314186096191e-02,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              7.18413829803466797e-01 -6.84203624725341797e-01 1.25487133860588074e-01,
              2.18342289328575134e-01 -2.07945033907890320e-01 9.53459739685058594e-01,
              6.43192112445831299e-01 -7.65704870223999023e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              2.05517232418060303e-01 -9.78653490543365479e-01 -0.00000000000000000e+00,
              -8.80667984485626221e-01 4.65961873531341553e-01 8.54602605104446411e-02,
              -7.18413829803466797e-01 6.84203624725341797e-01 1.25487133860588074e-01,
              -2.18342289328575134e-01 2.07945033907890320e-01 9.53459739685058594e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 9.68381315469741821e-02 9.95300114154815674e-01,
              -0.00000000000000000e+00 5.15572018921375275e-02 9.98670041561126709e-01,
              -0.00000000000000000e+00 9.68381315469741821e-02 9.95300114154815674e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              6.43192112445831299e-01 -7.65704870223999023e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 -1.00000000000000000e+00 -0.00000000000000000e+00,
              2.05517232418060303e-01 -9.78653490543365479e-01 -0.00000000000000000e+00,
              -7.18413829803466797e-01 6.84203624725341797e-01 1.25487133860588074e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 1.25487133860588074e-01,
              -2.18342289328575134e-01 2.07945033907890320e-01 9.53459739685058594e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              1.01631201803684235e-01 -0.00000000000000000e+00 9.94822144508361816e-01,
              2.18923017382621765e-01 2.77714639902114868e-01 9.35386180877685547e-01,
              -0.00000000000000000e+00 1.60915955901145935e-01 9.86968100070953369e-01,
              -2.18923017382621765e-01 2.77714639902114868e-01 9.35386180877685547e-01,
              -1.01631201803684235e-01 -0.00000000000000000e+00 9.94822144508361816e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -2.63020217418670654e-01 9.64790344238281250e-01,
              7.24137902259826660e-01 -6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 -2.53529459238052368e-01 9.29976999759674072e-01,
              7.95431733131408691e-01 -6.06043219566345215e-01 -0.00000000000000000e+00,
              -7.18413829803466797e-01 6.84203624725341797e-01 1.25487133860588074e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 1.25487133860588074e-01,
              -2.18342289328575134e-01 2.07945033907890320e-01 9.53459739685058594e-01,
              1.01959556341171265e-01 -0.00000000000000000e+00 9.94788527488708496e-01,
              2.27473139762878418e-01 -2.80491977930068970e-01 9.32512879371643066e-01,
              -0.00000000000000000e+00 -1.63643062114715576e-01 9.86519634723663330e-01,
              -2.27473139762878418e-01 -2.80491977930068970e-01 9.32512879371643066e-01,
              -1.01959556341171265e-01 -0.00000000000000000e+00 9.94788527488708496e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 1.12564399838447571e-01 9.93644416332244873e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              7.95431733131408691e-01 6.06043219566345215e-01 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 1.25487133860588074e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 1.25487133860588074e-01,
              -2.18342289328575134e-01 2.07945033907890320e-01 9.53459739685058594e-01,
              -0.00000000000000000e+00 -9.71513018012046814e-02 9.95269596576690674e-01,
              -0.00000000000000000e+00 -5.36136254668235779e-02 9.98561739921569824e-01,
              -0.00000000000000000e+00 -9.71513018012046814e-02 9.95269596576690674e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              1.18005700409412384e-01 4.31955046951770782e-02 9.92072999477386475e-01,
              -0.00000000000000000e+00 2.01681420207023621e-01 9.79451179504394531e-01,
              -1.18005700409412384e-01 4.31955046951770782e-02 9.92072999477386475e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              7.95431733131408691e-01 6.06043219566345215e-01 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 1.25487133860588074e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 1.25487133860588074e-01,
              -2.18342289328575134e-01 2.07945033907890320e-01 9.53459739685058594e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              4.56699803471565247e-02 -0.00000000000000000e+00 9.98956561088562012e-01,
              2.01905891299247742e-01 3.45174878835678101e-01 9.16563332080841064e-01,
              -0.00000000000000000e+00 -1.50451898574829102e-01 9.88617360591888428e-01,
              -2.01905891299247742e-01 3.45174878835678101e-01 9.16563332080841064e-01,
              -4.56699803471565247e-02 -0.00000000000000000e+00 9.98956561088562012e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              1.00000000000000000e+00 -0.00000000000000000e+00 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 1.25487133860588074e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 1.25487133860588074e-01,
              -2.18342289328575134e-01 2.07945033907890320e-01 9.53459739685058594e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              6.06688372790813446e-02 -0.00000000000000000e+00 9.98157978057861328e-01,
              2.36198201775550842e-01 -3.06110382080078125e-01 9.22229290008544922e-01,
              -0.00000000000000000e+00 -6.89888969063758850e-02 9.97617423534393311e-01,
              -2.36198201775550842e-01 -3.06110382080078125e-01 9.22229290008544922e-01,
              -6.06688372790813446e-02 -0.00000000000000000e+00 9.98157978057861328e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 1.25487133860588074e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 1.25487133860588074e-01,
              -2.18342289328575134e-01 2.07945033907890320e-01 9.53459739685058594e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -5.77897317707538605e-02 9.98328804969787598e-01,
              -0.00000000000000000e+00 -7.55746802315115929e-03 9.99971449375152588e-01,
              -0.00000000000000000e+00 -5.77897317707538605e-02 9.98328804969787598e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              7.95431733131408691e-01 6.06043219566345215e-01 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -7.18413829803466797e-01 6.84203624725341797e-01 1.25487133860588074e-01,
              -2.02284559607505798e-01 9.63259816169738770e-01 1.76667734980583191e-01,
              -2.18342289328575134e-01 2.07945033907890320e-01 9.53459739685058594e-01,
              -6.36941850185394287e-01 7.58264124393463135e-01 1.39070272445678711e-01,
              -0.00000000000000000e+00 2.13086321949958801e-01 9.77033376693725586e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 9.64790344238281250e-01,
              -6.43192112445831299e-01 7.65704870223999023e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              7.95431733131408691e-01 6.06043219566345215e-01 -0.00000000000000000e+00,
              2.75197267532348633e-01 -0.00000000000000000e+00 9.61387813091278076e-01,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -6.43192112445831299e-01 7.65704870223999023e-01 -0.00000000000000000e+00,
              -0.00000000000000000e+00 2.63020217418670654e-01 9.64790344238281250e-01,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              -0.00000000000000000e+00 -0.00000000000000000e+00 1.00000000000000000e+00,
              9.82338547706604004e-01 1.87112107872962952e-01 -0.00000000000000000e+00,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              -2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              -2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              7.24137902259826660e-01 6.89655184745788574e-01 -0.00000000000000000e+00,
              2.05517232418060303e-01 9.78653490543365479e-01 -0.00000000000000000e+00,
              2.66205906867980957e-01 2.53529459238052368e-01 9.29976999759674072e-01,
              -5.29682517051696777e-01 -4.35518771409988403e-01 -7.27846026420593262e-01,
              5.29682517051696777e-01 -4.35518771409988403e-01 -7.27846026420593262e-01,
              -5.83667516708374023e-01 3.98736953735351563e-01 -7.07347929477691650e-01,
              5.83667516708374023e-01 3.98736953735351563e-01 -7.07347929477691650e-01,
              -4.47934269905090332e-01 -3.31515908241271973e-01 -8.30332517623901367e-01,
              4.47934269905090332e-01 -3.31515908241271973e-01 -8.30332517623901367e-01,
              -6.44785583019256592e-01 4.98854927718639374e-02 -7.62733936309814453e-01,
              6.44785583019256592e-01 4.98854927718639374e-02 -7.62733936309814453e-01,
              -5.21306633949279785e-01 3.81916671991348267e-01 -7.63137638568878174e-01,
              5.21306633949279785e-01 3.81916671991348267e-01 -7.63137638568878174e-01,
              -4.87110376358032227e-01 -8.52678716182708740e-01 -1.88845053315162659e-01,
              4.87110376358032227e-01 -8.52678716182708740e-01 -1.88845053315162659e-01,
              -8.98609936237335205e-01 -4.00063574314117432e-01 -1.80137023329734802e-01,
              8.98609936237335205e-01 -4.00063574314117432e-01 -1.80137023329734802e-01,
              -9.28848385810852051e-01 2.77559131383895874e-01 -2.45360210537910461e-01,
              -4.20488536357879639e-01 6.89884006977081299e-01 -5.89278757572174072e-01,
              9.28848385810852051e-01 2.77559131383895874e-01 -2.45360210537910461e-01,
              4.20488536357879639e-01 6.89884006977081299e-01 -5.89278757572174072e-01,
              -4.26327079534530640e-01 7.03520298004150391e-01 -5.68598628044128418e-01,
              4.26327079534530640e-01 7.03520298004150391e-01 -5.68598628044128418e-01,
              -4.33874666690826416e-01 -8.69343042373657227e-01 -2.36633658409118652e-01,
              4.33874666690826416e-01 -8.69343042373657227e-01 -2.36633658409118652e-01,
              -9.38465595245361328e-01 -2.70449012517929077e-01 -2.14801371097564697e-01,
              9.38465595245361328e-01 -2.70449012517929077e-01 -2.14801371097564697e-01,
              -9.58147943019866943e-01 1.14374123513698578e-01 -2.62432962656021118e-01,
              9.58147943019866943e-01 1.14374123513698578e-01 -2.62432962656021118e-01,
              -9.14688348770141602e-01 3.69403272867202759e-01 -1.63970977067947388e-01,
              -4.82814192771911621e-01 8.58218729496002197e-01 -1.74215644598007202e-01,
              9.14688348770141602e-01 3.69403272867202759e-01 -1.63970977067947388e-01,
              4.82814192771911621e-01 8.58218729496002197e-01 -1.74215644598007202e-01,
              -4.91132169961929321e-01 -6.33469760417938232e-01 5.97917437553405762e-01,
              4.91132169961929321e-01 -6.33469760417938232e-01 5.97917437553405762e-01,
              -6.72588169574737549e-01 -4.46427673101425171e-01 5.90192735195159912e-01,
              -4.28920805454254150e-01 -3.94981861114501953e-01 8.12413871288299561e-01,
              6.72588169574737549e-01 -4.46427673101425171e-01 5.90192735195159912e-01,
              4.28920805454254150e-01 -3.94981861114501953e-01 8.12413871288299561e-01,
              -7.58943617343902588e-01 -9.64401587843894958e-02 6.43975079059600830e-01,
              -4.97613161802291870e-01 -8.48257616162300110e-02 8.63241374492645264e-01,
              7.58943617343902588e-01 -9.64401587843894958e-02 6.43975079059600830e-01,
              4.97613161802291870e-01 -8.48257616162300110e-02 8.63241374492645264e-01,
              -8.65171134471893311e-01 4.17309433221817017e-01 2.78085798025131226e-01,
              -5.60303390026092529e-01 7.74693787097930908e-01 2.93103545904159546e-01,
              -4.85756993293762207e-01 3.81325840950012207e-01 7.86530792713165283e-01,
              8.65171134471893311e-01 4.17309433221817017e-01 2.78085798025131226e-01,
              5.60303390026092529e-01 7.74693787097930908e-01 2.93103545904159546e-01,
              4.85756993293762207e-01 3.81325840950012207e-01 7.86530792713165283e-01,
              -4.70674544572830200e-01 -7.19968199729919434e-01 5.10011076927185059e-01,
              4.70674544572830200e-01 -7.19968199729919434e-01 5.10011076927185059e-01,
              -8.35106313228607178e-01 -2.83905863761901855e-01 4.71163362264633179e-01,
              -3.38736832141876221e-01 -2.32402965426445007e-01 9.11727070808410645e-01,
              8.35106313228607178e-01 -2.83905863761901855e-01 4.71163362264633179e-01,
              3.38736832141876221e-01 -2.32402965426445007e-01 9.11727070808410645e-01,
              -7.53695726394653320e-01 2.04020351171493530e-01 6.24754726886749268e-01,
              -5.15640079975128174e-01 1.80383726954460144e-01 8.37601959705352783e-01,
              7.53695726394653320e-01 2.04020351171493530e-01 6.24754726886749268e-01,
              5.15640079975128174e-01 1.80383726954460144e-01 8.37601959705352783e-01,
              -6.86490416526794434e-01 4.22261446714401245e-01 5.91968059539794922e-01,
              -4.89765882492065430e-01 6.29689335823059082e-01 6.03009760379791260e-01,
              -4.18025434017181396e-01 3.75797778367996216e-01 8.27061533927917480e-01,
              6.86490416526794434e-01 4.22261446714401245e-01 5.91968059539794922e-01,
              4.89765882492065430e-01 6.29689335823059082e-01 6.03009760379791260e-01,
              4.18025434017181396e-01 3.75797778367996216e-01 8.27061533927917480e-01,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
    </Transform>
  </Scene>
</template>

<script>
export default {
  name: "TriangleXdom",
  mounted: function() {
    this.$nextTick(function() {
      this.$emit("loaded", true);
    });
  },
};
</script>
